import React from "react";
import FeaturedShopComponent from "./Components/FeaturedShopComponent/FeaturedShopComponent";

export default function FeaturedShop() {
  return (
    <div>
      <FeaturedShopComponent />
    </div>
  );
}
