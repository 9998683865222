import React, { useEffect, useState } from "react";
import {
  useAddRefferEarningPolicyMutation,
  useEditRefferEarningPolicyMutation,
} from "../../../features/refferEarningPolicy/refferEarningPolicyApi";
import { toast } from "react-toastify";
import Input from "../../../Shared/Components/Input";
import Toast from "../../../Shared/UI/Toast";

export default function AddRefferEarningPolicy({
  categoryObj,
  setCategoryObj,
}) {
  let [name, setName] = useState("");

  useEffect(() => {
    setName(categoryObj?.policy);
  }, [categoryObj]);
  console.log("categoryObj", categoryObj);

  const [addRefferEarningPolicy, { isSuccess: isAddRefferEarningPolicy }] =
    useAddRefferEarningPolicyMutation();
  const [
    editRefferEarningPolicy,
    { isSuccess: isEditEarningPolicySuccess, isLoading, isError, error },
  ] = useEditRefferEarningPolicyMutation();

  useEffect(() => {
    if (isAddRefferEarningPolicy) {
      toast.success("Successfully Reffer Earning policy added.");
      setName("");
    }
  }, [isAddRefferEarningPolicy]);

  console.log(
    "isEditEarningPolicySuccess, isError, error",
    isEditEarningPolicySuccess,
    isError,
    error
  );

  useEffect(() => {
    if (isEditEarningPolicySuccess) {
      toast.success("Successfully Reffer Earning policy Updated.");
      setName("");
      setCategoryObj({});
    }
    if (isError) {
      toast.error(error.error);
    }
  }, [isEditEarningPolicySuccess, isError]);

  const handleCategoryAdd = (e) => {
    addRefferEarningPolicy({
      policy: name,
    });
  };

  const handleCategoryEdit = async (e) => {
    await editRefferEarningPolicy({
      id: categoryObj?.id,
      data: {
        policy: name,
      },
    });
  };

  return (
    <>
      <div className="w-1/3">
        <div className="bg-white rounded-md shadow-xl ">
          <p className="px-8 py-4 font-semibold">
            {categoryObj?.policy ? "Update" : "Add"} Category
          </p>
          <div className="border-b border-b-gray-200"></div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Category Name <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pb-8 pr-8 text-right">
            {categoryObj?.policy ? (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryEdit}
              >
                Update
              </button>
            ) : (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryAdd}
              >
                Save
              </button>
            )}
          </div>
        </div>
        <Toast />
      </div>
    </>
  );
}
