import React, { useState } from "react";
import SellersTable from "./SellersTable";
import AddSeller from "./AddSeller";

export default function SerllersComponent() {
  const [sellerObj, setSellerObj] = useState({});
  return (
    <>
      <div className="flex flex-col p-4 mb-8 ">
        <AddSeller sellerObj={sellerObj} setSellerObj={setSellerObj} />
        <SellersTable setSellerObj={setSellerObj} />
      </div>
    </>
  );
}
