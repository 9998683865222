import React, { useState } from 'react'
import AddBusiness from './AddBusiness'
import BusinessTable from './BusinessTable'

export default function BusinessComponent() {
    let [editBusiness, setEditBusiness] = useState({});

    return (
        <div className='flex p-4'>
            <BusinessTable setEditBusiness={setEditBusiness} />
            <AddBusiness editBusiness={editBusiness} setEditBusiness={setEditBusiness} />
        </div>
    )
}
