import React from "react";
import { useSellerLiveHistoryQuery } from "../../../features/sellerLiveHistory/sellerLiveHistoryApi";

export default function SellerLiveHistory({ id, sellerInfo, liveInfo }) {
  const {
    data: liveHistory,
    isLoading,
    isError,
    error,
  } = useSellerLiveHistoryQuery(id);
  console.log("live History", liveHistory);

  let content = null;
  if (isLoading) {
    content = <div>Loading</div>;
  }
  if (!isLoading && isError) {
    content = (
      <div>
        <p className="text-red-500">{error?.error}</p>
      </div>
    );
  }

  if (!isLoading && !isError) {
    content = (
      <tbody>
        {liveHistory?.data?.map((history) => (
          <tr className="border-t border-[#ddd] text-left text-dark py-4">
            <td className="py-2 px-2  text-base">
              <div className="flex items-center">
                <p>{history?.seller_info[0]?.id}</p>
              </div>
            </td>
            <td className="py-2 px-2  text-base">
              <img
                src={history?.seller_info[0]?.logo}
                alt={history?.seller_info[0]?.shop_name}
              />
            </td>
            <td className="py-2 px-4  text-base">
              {history?.seller_info[0]?.shop_name}
            </td>
            <td className="py-2 px-4  text-base">
              {history?.seller_info[0]?.email}
            </td>
            <td className="py-2 px-4  text-base">
              {history?.live_info?.fb_rtmp}
            </td>
            <td className="py-2 px-4  text-base">
              {history?.live_info?.created_at}
            </td>
            <td className="py-2 px-4  text-base">
              {history?.live_info?.updated_at}
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
  return (
    <div className="max-w-full overflow-x-auto max-h-[500px] overflow-scroll p-6">
      <table className="w-full table-auto">
        <thead>
          <tr className="text-left text-dark font-bold">
            <th className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Seller Id
            </th>
            <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Logo
            </th>
            <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Shop Name
            </th>
            <th className="w-1/6 min-w-[260px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Email
            </th>
            <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Live rtmp
            </th>
            <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Live Started
            </th>
            <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Live Updated
            </th>
          </tr>
        </thead>
        {content}
      </table>
    </div>
  );
}
