import React from 'react'

export default function InHouseReportComponent() {
    return (
        <div className='p-8'>
            <div className='max-w-6xl mx-auto bg-white p-4'>
                <div className='flex flex-col md:flex-row pb-4'>
                    <p className='w-full md:w-1/4'>Sort By Category</p>
                    <select id="selectID" className='w-full md:w-2/4  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary w-full rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500'>
                        <option className='bg-orange-400 text-white p-4'>Man Clothing & Fasion</option>
                        <option className='bg-orange-400 text-white p-4'>Women Clothing & Fasion</option>
                        <option className='bg-orange-400 text-white p-4'>Kids & Toy</option>
                    </select>
                    <div className='w-full md:w-1/4 text-right'>
                        <button className='bg-orange-500 text-white py-2 px-8 rounded-md mr-4'>Filter</button>
                    </div>
                </div>

                <div>
                    {/* Head */}
                    <div className='flex'>
                        <div className='min-w-[170px] w-1/12 border border-b-gray-200 p-4 font-bold'>
                            #
                        </div>
                        <div className='min-w-[170px] w-8/12 border border-b-gray-200 p-4 font-bold'>
                            Product Name
                        </div>
                        <div className='min-w-[170px] w-3/12 border border-b-gray-200 p-4 font-bold'>
                            Num of Sale
                        </div>
                    </div>

                    {/* Body */}
                    <div className='flex'>
                        <div className='min-w-[170px] w-1/12 border border-b-gray-200 p-4 text-gray-500'>
                            1
                        </div>
                        <div className='min-w-[170px] w-8/12 border border-b-gray-200 p-4 text-gray-500'>
                            Womens Summer Plus Size Lace Neckline Cold Shoulder Short Sleeve
                        </div>
                        <div className='min-w-[170px] w-3/12 border border-b-gray-200 p-4 text-gray-500'>
                            3
                        </div>
                    </div>
                    <div className='flex'>
                        <div className='min-w-[170px] w-1/12 border border-b-gray-200 p-4 text-gray-500'>
                            2
                        </div>
                        <div className='min-w-[170px] w-8/12 border border-b-gray-200 p-4 text-gray-500'>
                            alvin Klein Women's Scuba Sleeveless Princess Seamed Sheath Dress
                        </div>
                        <div className='min-w-[170px] w-3/12 border border-b-gray-200 p-4 text-gray-500'>
                            3
                        </div>
                    </div>
                    <div className='flex'>
                        <div className='min-w-[170px] w-1/12 border border-b-gray-200 p-4 text-gray-500'>
                            3
                        </div>
                        <div className='min-w-[170px] w-8/12 border border-b-gray-200 p-4 text-gray-500'>
                            Daily Ritual Women's Jersey Short-Sleeve Boxy Pocket T-Shirt Dress
                        </div>
                        <div className='min-w-[170px] w-3/12 border border-b-gray-200 p-4 text-gray-500'>
                            7
                        </div>
                    </div>
                    <div className='flex'>
                        <div className='min-w-[170px] w-1/12 border border-b-gray-200 p-4 text-gray-500'>
                            4
                        </div>
                        <div className='min-w-[170px] w-8/12 border border-b-gray-200 p-4 text-gray-500'>
                            Women Fit and Flare Dress
                        </div>
                        <div className='min-w-[170px] w-3/12 border border-b-gray-200 p-4 text-gray-500'>
                            2
                        </div>
                    </div>
                    <div className='flex'>
                        <div className='min-w-[170px] w-1/12 border border-b-gray-200 p-4 text-gray-500'>
                            1
                        </div>
                        <div className='min-w-[170px] w-8/12 border border-b-gray-200 p-4 text-gray-500'>
                            Womens Summer Plus Size Lace Neckline Cold Shoulder Short Sleeve
                        </div>
                        <div className='min-w-[170px] w-3/12 border border-b-gray-200 p-4 text-gray-500'>
                            3
                        </div>
                    </div>
                    <div className='flex'>
                        <div className='min-w-[170px] w-1/12 border border-b-gray-200 p-4 text-gray-500'>
                            1
                        </div>
                        <div className='min-w-[170px] w-8/12 border border-b-gray-200 p-4 text-gray-500'>
                            Womens Summer Plus Size Lace Neckline Cold Shoulder Short Sleeve
                        </div>
                        <div className='min-w-[170px] w-3/12 border border-b-gray-200 p-4 text-gray-500'>
                            3
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
