import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    // baseUrl: `http://103.174.51.244/api`,
    baseUrl: `https://api-laravel.tunibibi.com/api/`,
    prepareHeaders: (headers, { getState, endpoints }) => {
      let token = getState()?.auth?.token;
      let wirehouseToken = getState()?.wirehouseAuth?.wirehouseToken;

      if (token || wirehouseToken) {
        headers.set(
          "Authorization",
          `Bearer ${token ? token : wirehouseToken}`
        );
      }
      return headers;
    },
  }),
  tagTypes: [
    "business",
    "seller",
    "units",
    "banner",
    "category",
    "subCategory",
    "products",
    "flatDiscount",
    "percentDiscount",
    "sellerPercent",
    "sellerPaymentMethod",
    "varient",
    "sellerDeliveryTimes",
    "sellerExtraChargeType",
    "sellerExtraCharges",
    "sellerFlatDiscount",
    "sellerProduct",
    "lives",
    "refferPolicy",
    "tunnibibiAddress",
    "sellerOwnPaymentInfo",
    "shippingPackage",
    "buyerCreate",
    "featuredShop",
    "rmsMenus",
    "rmsGroupName",
    "rmsGroupMenus",
    "rmsPermission",
    "rmsUsers",
    "rmsCheckMyPermission",
    "wirehouse",
    "country",
    "buyersPaymentMethod",
    "buyerBanner",
    "buyersEarningPolicy",
    "wirehouseOrder",
    "wirehouses",
    "wirehouseCurier",
    "paymentOrder",
    "paymentDelivery",
    "dashboard",
  ],
  endpoints: (builder) => ({}),
});
