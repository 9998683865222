import React, { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import banner1 from "../../../assets/Images/banner1.jpg";
import {
  useAddBannerMutation,
  useDeleteBannerMutation,
  useEditBannerOperationMutation,
  useGetBannerQuery,
} from "../../../features/banner/bannerApi";
import { toast } from "react-toastify";
import InputFile from "../../../Shared/Components/InputFile";
import Toast from "../../../Shared/UI/Toast";
import Modal from "../../../Shared/Modal";
import DeleteConfirmation from "../../../Shared/Components/DeleteConfirmation";

export default function BannerComponent() {
  let [showAddForm, setShowAddForm] = useState(false);
  let [image, setImage] = useState("");
  let [showEditBanner, setShowEditBanner] = useState({});
  let [selectedId, setSelectedId] = useState("");
  let [showModal, setShowModal] = useState(false);

  const { data: banners, isLoading, isError, error } = useGetBannerQuery();
  const [addBanner, { isSuccess: isAddedBanner }] = useAddBannerMutation();
  const [editBannerOperation, { isSuccess: isEditBanner }] =
    useEditBannerOperationMutation();
  const [deleteBanner, { isSuccess: isDeleteBanner }] =
    useDeleteBannerMutation();

  useEffect(() => {
    if (isDeleteBanner) {
      toast.success("successfully banner deleted");
    }
  }, [isDeleteBanner]);

  useEffect(() => {
    if (isAddedBanner) {
      toast.success("successfully banner added");
      setImage("");
      setShowAddForm(false);
    }
  }, [isAddedBanner]);

  useEffect(() => {
    if (isEditBanner) {
      toast.success("successfully banner updated");
      setImage("");
      setShowAddForm(false);
      setShowEditBanner({});
    }
  }, [isEditBanner]);

  function handleImageUpload(e) {
    const file = e.target.files[0];
    console.log("file", file);
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      setImage(reader.result);
    };
  }

  const handleBannerDelete = () => {
    deleteBanner(selectedId);
  };

  const handleAddBanner = () => {
    if (image) {
      addBanner({
        image,
      });
    }
  };

  const handleEditBanner = () => {
    if (image) {
      editBannerOperation({
        id: showEditBanner?.id,
        data: {
          image,
        },
      });
    }
  };
  return (
    <div className="max-w-6xl mx-auto">
      <div className="text-right pt-6">
        <button
          className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
          onClick={() => setShowAddForm(true)}
        >
          {showEditBanner?.id ? "Edit" : "Add"} Banner
        </button>
      </div>
      {showAddForm && (
        <div className="flex flex-col p-8 pb-0 bg-white max-w-[600px] mx-auto">
          <div className="w-full px-4 ">
            <div className="flex flex-col mb-4 md:flex-row">
              <label
                for=""
                className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
              >
                Banner
              </label>
              <div className="w-full md:w-3/4">
                <InputFile onChange={handleImageUpload} />
              </div>
            </div>
            {image && (
              <img src={image} alt="Uploaded image" className="w-full h-auto" />
            )}
          </div>

          <div className="pb-8 pr-8 text-right">
            {showEditBanner?.id ? (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleEditBanner}
              >
                Edit
              </button>
            ) : (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleAddBanner}
              >
                Save
              </button>
            )}
          </div>
        </div>
      )}
      <div className="max-w-6xl mx-auto bg-white p-4 my-8">
        {banners?.data?.map((banner) => (
          <div className="flex justify-between items-center border border-gray-200 p-4">
            <div className="flex items-center space-x-4">
              <img className="w-40 h-auto" src={banner?.image} alt="banner1" />
            </div>
            <div className="flex gap-4 ">
              <FaEdit
                className="cursor-pointer"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setShowAddForm(true);
                  setShowEditBanner(banner);
                }}
              />
              <FaTrash
                className="cursor-pointer"
                onClick={() => {
                  setSelectedId(banner?.id);
                  setShowModal(true);
                }}
              />
            </div>
          </div>
        ))}
      </div>
      <Toast />

      {showModal ? (
        <Modal show={showModal}>
          <DeleteConfirmation
            handleDelete={handleBannerDelete}
            setShowModal={setShowModal}
          />
        </Modal>
      ) : null}
    </div>
  );
}
