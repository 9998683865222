import { apiSlice } from "../api/apiSlice";

export const sellerExtraChargeApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSellerExtraCharge: builder.query({
      query: () => `/admin/seller/exter/charges/1`,
      providesTags: ["sellerExtraCharges"],
    }),

    addSellerExtraCharge: builder.mutation({
      query: (data) => ({
        url: "/admin/seller/exter/charges/1",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["sellerExtraCharges"],
    }),

    editSellerExtraCharge: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/seller/exter/charges/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["sellerExtraCharges"],
    }),

    deleteSellerExtraCharge: builder.mutation({
      query: (id) => ({
        url: `/admin/seller/exter/charges/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["sellerExtraCharges"],
    }),
  }),
});

export const {
  useGetSellerExtraChargeQuery,
  useAddSellerExtraChargeMutation,
  useEditSellerExtraChargeMutation,
  useDeleteSellerExtraChargeMutation,
} = sellerExtraChargeApi;
