import React from "react";
import WirehouseHandOverCurierComponent from "./Components/WirehouseHandOverCurierComponent/WirehouseHandOverCurierComponent";

export default function WirehouseHandoverCurier() {
  return (
    <div>
      <WirehouseHandOverCurierComponent />
    </div>
  );
}
