import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  useDeleteSellerExtraChargeTypeMutation,
  useGetSellerExtraChargeTypeQuery,
} from "../../../features/sellerExtraChargetype/sellerExtraChargetypeApi";
import { FaRegEdit, FaTimes, FaTrashAlt } from "react-icons/fa";
import Toast from "../../../Shared/Components/Toast";
import Modal from "../../../Shared/Modal";
import EditOption from "../../../Shared/Components/EditOption";

export default function SellerExtraChargeTypeTable({ setPaymentMethodObj }) {
  let [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const {
    data: allSellerExtraChargeType,
    isLoading,
    isError,
    error,
  } = useGetSellerExtraChargeTypeQuery();

  const [deleteSellerExtraChargeType, { isSuccess }] =
    useDeleteSellerExtraChargeTypeMutation();

  const handleSellerDelete = () => {
    if (!selectedId) {
      toast.error("Please select delete option first.");
      return;
    }
    deleteSellerExtraChargeType(selectedId);
  };

  useEffect(() => {
    if (isSuccess) {
      setShowModal(false);
      toast.success("Successfully delted!");
    }
  }, [isSuccess]);

  let content = null;

  if (isLoading) {
    content = <div>Loading</div>;
  }
  if (!isLoading && isError) {
    content = <div>{error?.error}</div>;
  }

  if (!isLoading && !isError) {
    content = (
      <tbody>
        {allSellerExtraChargeType?.data?.map((paymentMethod) => (
          <tr className="border-t border-[#ddd] text-left text-dark py-4">
            <td className="py-2 px-2  text-base">
              <div className="flex items-center">{paymentMethod?.id}</div>
            </td>
            <td className="py-2 px-2  text-base">{paymentMethod?.name}</td>

            <td className="y-2 px-2  text-base">
              <div className="flex space-x-2 cursor-pointer">
                <EditOption
                  onClick={() => setPaymentMethodObj(paymentMethod)}
                />
                <div className="p-2 bg-red-200 rounded-full cursor-pointer">
                  <FaTrashAlt
                    className="fill-red-500"
                    onClick={() => {
                      setSelectedId(paymentMethod?.id);
                      setShowModal(true);
                    }}
                  />
                  {/* <FaTrashAlt className="fill-red-500" /> */}
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
  return (
    <>
      <div className="w-full md:w-2/3 overflow-x-hidden">
        <section className="bg-white shadow-xl rounded-md mb-4 m-0 md:m-4 py-6 lg:my-0 lg:py-8">
          <div className="flex flex-col justify-between items-center pb-4 px-4 md:flex-row">
            <p className="w-3/4 px-8 py-4 font-semibold">
              All seller extra charge type
            </p>
          </div>
          <div className="border-b border-b-gray-200"></div>
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4 mx-4">
              <div className="max-w-full overflow-x-auto">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="text-left text-dark font-bold">
                      <th className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        #
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Name
                      </th>

                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Options
                      </th>
                    </tr>
                  </thead>
                  {content}
                </table>
              </div>
            </div>
          </div>
          <Toast />
        </section>
      </div>
      {/* Delete Modal */}
      {showModal ? (
        <Modal show={showModal}>
          <div className="w-full max-w-[470px] rounded-md bg-white text-center">
            <div className="flex justify-between items-center px-8 py-4 ">
              <p className="font-semibold">Delete Confirmation</p>
              <FaTimes
                onClick={() => setShowModal(false)}
                className="fill-gray-500 cursor-pointer"
              />
            </div>
            <div className="border-b border-b-gray-200"></div>
            <span className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"></span>
            <p className="text-body-color mb-10 text-base leading-relaxed">
              Are you sure to delete this?
            </p>
            <div className="flex justify-center space-x-6 pb-10">
              <button
                onClick={() => setShowModal(false)}
                className="text-dark block rounded-md border border-[#E9EDF9] p-3 text-center text-base font-medium transition hover:border-red-600 hover:bg-red-600 hover:text-white"
              >
                Cancel
              </button>
              <button
                className="bg-orange-500 border-orange-500 block rounded-md border p-3 text-center text-base font-medium text-white transition hover:bg-opacity-90"
                onClick={() => {
                  setShowModal(false);
                  handleSellerDelete();
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
}
