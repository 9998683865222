import { apiSlice } from "../api/apiSlice";

export const SellerFlatDiscountApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSellerFlatDiscount: builder.query({
      query: (id) => `/admin/discount/flat/seller/${id}`,
      providesTags: ["sellerFlatDiscount"],
    }),

    // deleteSellers: builder.mutation({
    //   query: (id) => ({
    //     url: `/admin/discount/${id}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["sellerPercent"],
    // }),
  }),
});

export const { useGetSellerFlatDiscountQuery } = SellerFlatDiscountApi;
