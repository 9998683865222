import React, { useEffect, useState } from "react";
import {
  useAddAddressMutation,
  useEditAddressMutation,
} from "../../../features/tunnibibiAddress/tunnibibiAddressApi";
import { toast } from "react-toastify";
import Input from "../../../Shared/Components/Input";
import Toast from "../../../Shared/UI/Toast";

export default function AddAddress({ categoryObj, setCategoryObj }) {
  let [addressDetails, setAddressDetails] = useState({
    name: "",
    mobile: "",
    street: "",
    apartment: "",
    country: "",
    state: "",
    city: "",
    zip: "",
  });

  useEffect(() => {
    setAddressDetails({
      name: categoryObj?.name,
      mobile: categoryObj?.mobile,
      street: categoryObj?.street,
      apartment: categoryObj?.apartment,
      country: categoryObj?.country,
      state: categoryObj?.state,
      city: categoryObj?.city,
      zip: categoryObj?.zip,
    });
  }, [categoryObj]);
  console.log("categoryObj", categoryObj);

  const [addAddress, { isSuccess: isAddAddress }] = useAddAddressMutation();
  const [
    editRefferEarningPolicy,
    { isSuccess: isEditAddressSuccess, isLoading, isError, error },
  ] = useEditAddressMutation();

  useEffect(() => {
    if (isAddAddress) {
      toast.success("Successfully Reffer Earning policy added.");
      setAddressDetails({
        name: "",
        mobile: "",
        street: "",
        apartment: "",
        country: "",
        state: "",
        city: "",
        zip: "",
      });
    }
  }, [isAddAddress]);

  console.log(
    "isEditAddressSuccess, isError, error",
    isEditAddressSuccess,
    isError,
    error
  );

  useEffect(() => {
    if (isEditAddressSuccess) {
      toast.success("Successfully Reffer Earning policy Updated.");
      setAddressDetails({
        name: "",
        mobile: "",
        street: "",
        apartment: "",
        country: "",
        state: "",
        city: "",
        zip: "",
      });
      setCategoryObj({});
    }
    if (isError) {
      toast.error(error.error);
    }
  }, [isEditAddressSuccess, isError]);

  const handleCategoryAdd = (e) => {
    addAddress(addressDetails);
  };

  const handleCategoryEdit = async (e) => {
    await editRefferEarningPolicy({
      id: categoryObj?.id,
      data: {
        ...addressDetails,
      },
    });
  };

  return (
    <>
      <div className="w-1/3">
        <div className="bg-white rounded-md shadow-xl ">
          <p className="px-8 py-4 font-semibold">
            {categoryObj?.id ? "Update" : "Add"} Address
          </p>
          <div className="border-b border-b-gray-200"></div>
          <div className="flex flex-col p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Name <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder=""
                    value={addressDetails?.name}
                    onChange={(e) =>
                      setAddressDetails({
                        ...addressDetails,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Mobile <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="tel"
                    placeholder=""
                    value={addressDetails?.mobile}
                    onChange={(e) =>
                      setAddressDetails({
                        ...addressDetails,
                        mobile: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Street <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="tel"
                    placeholder=""
                    value={addressDetails?.street}
                    onChange={(e) =>
                      setAddressDetails({
                        ...addressDetails,
                        street: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Apartment <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="tel"
                    placeholder=""
                    value={addressDetails?.apartment}
                    onChange={(e) =>
                      setAddressDetails({
                        ...addressDetails,
                        apartment: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Country <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="tel"
                    placeholder=""
                    value={addressDetails?.country}
                    onChange={(e) =>
                      setAddressDetails({
                        ...addressDetails,
                        country: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  State <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="tel"
                    placeholder=""
                    value={addressDetails?.state}
                    onChange={(e) =>
                      setAddressDetails({
                        ...addressDetails,
                        state: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  City <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="tel"
                    placeholder=""
                    value={addressDetails?.city}
                    onChange={(e) =>
                      setAddressDetails({
                        ...addressDetails,
                        city: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Zip <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="tel"
                    placeholder=""
                    value={addressDetails?.zip}
                    onChange={(e) =>
                      setAddressDetails({
                        ...addressDetails,
                        zip: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pb-8 pr-8 text-right">
            {categoryObj?.id ? (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryEdit}
              >
                Update
              </button>
            ) : (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryAdd}
              >
                Save
              </button>
            )}
          </div>
        </div>
        <Toast />
      </div>
    </>
  );
}
