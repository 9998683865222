import React, { useEffect, useState } from "react";
import Toast from "../../../Shared/UI/Toast";
import InputFile from "../../../Shared/Components/InputFile";
import Input from "../../../Shared/Components/Input";
import { toast } from "react-toastify";
import {
  useAddSellerMutation,
  useEditSellerMutation,
} from "../../../features/saller/sallerApi";
import { useGetBusinessQuery } from "../../../features/business/businessApi";

export default function AddSeller({ sellerObj, setSellerObj }) {
  let initialObj = {
    shop_name: "",
    address: "",
    phone: "",
    business_type_id: "",
    email: "",
    password: "",
    logo: "",
  };
  const [sellerObject, setSellerObject] = useState({ ...initialObj });

  useEffect(() => {
    setSellerObject({
      shop_name: sellerObj?.shop_name,
      address: sellerObj?.address,
      phone: sellerObj?.phone,
      business_type_id: sellerObj?.business_type_id,
      email: sellerObj?.email,
      password: sellerObj?.password,
      logo: sellerObj?.logo,
    });
  }, [sellerObj]);

  const [addSeller, { isSuccess: isAddSellerSuccess, isError, error }] =
    useAddSellerMutation();

  const [
    editSeller,
    {
      isSuccess: isEditSellerSuccess,
      isLoading: isEditSellerLoading,
      isError: isEditSellerIsError,
      error: editError,
    },
  ] = useEditSellerMutation();

  console.log(
    " Edit",
    isEditSellerSuccess,
    isEditSellerLoading,
    isEditSellerIsError,
    editError
  );

  const { data: business, isLoading } = useGetBusinessQuery();

  function handleImageUpload(e) {
    const file = e.target.files[0];
    console.log("file", file);
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      setSellerObject({ ...sellerObject, logo: reader.result });
    };
  }

  useEffect(() => {
    if (isAddSellerSuccess) {
      toast.success("Seller Added Successfully");
      setSellerObj({ ...initialObj });
    }
  }, [isAddSellerSuccess]);

  useEffect(() => {
    if (isEditSellerSuccess) {
      toast.success("Seller Updated Successfully");
      setSellerObj({ ...initialObj });
    }
  }, [isEditSellerSuccess]);

  const handleAddSeller = () => {
    if (
      !sellerObject?.shop_name ||
      !sellerObject?.business_type_id ||
      !sellerObject?.country ||
      !sellerObject?.phone ||
      !sellerObject?.email ||
      !sellerObject?.password ||
      !sellerObject.logo
    ) {
      toast.error("Please fill all the required filled");
      return;
    }
    addSeller(sellerObject);
  };
  const handleEditSeller = () => {
    editSeller({
      id: sellerObj?.id,
      data: { ...sellerObject, is_active: 1 },
    });
  };

  console.log("sellerObject", sellerObject);

  return (
    <div className="max-w-3xl w-full mx-auto bg-white rounded-md shadow-xl mt-8 m-8">
      <p className="px-8 py-4 font-semibold">
        {sellerObj?.shop_name ? "Edit" : "Add"} Seller
      </p>

      <div className="border-b border-b-gray-200"></div>
      <div className="flex flex-col p-8 pb-0">
        <div className="w-full px-4">
          <div className="flex flex-col mb-4 md:flex-row">
            <label
              for=""
              className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
            >
              Shop Name <span className="text-orange-500">*</span>
            </label>
            <div className="w-full md:w-3/4">
              <Input
                type="text"
                placeholder="Name"
                value={sellerObj?.shop_name}
                onChange={(e) =>
                  setSellerObject({
                    ...sellerObject,
                    shop_name: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="w-full px-4 ">
          <div className="flex flex-col mb-4 md:flex-row">
            <label
              for=""
              className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
            >
              Business Type <span className="text-orange-500">*</span>
            </label>
            <div className="w-full md:w-3/4">
              <select
                id="selectID"
                className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
                onChange={(e) =>
                  setSellerObject({
                    ...sellerObject,
                    business_type_id: e.target.value,
                  })
                }
              >
                <option
                  className="bg-orange-400 text-white p-4 w-full"
                  value=""
                >
                  Select Business type
                </option>
                {business?.map((business) => (
                  <option
                    className="bg-orange-400 text-white p-4 w-full"
                    value={business?.id}
                  >
                    {business?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="w-full px-4 ">
          <div className="flex flex-col mb-4 md:flex-row">
            <label
              for=""
              className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
            >
              Country <span className="text-orange-500">*</span>
            </label>
            <div className="w-full md:w-3/4">
              <Input
                type="text"
                placeholder="Country"
                value={sellerObject?.country}
                onChange={(e) =>
                  setSellerObject({ ...sellerObject, country: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <div className="w-full px-4 ">
          <div className="flex flex-col mb-4 md:flex-row">
            <label
              for=""
              className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
            >
              Phone <span className="text-orange-500">*</span>
            </label>
            <div className="w-full md:w-3/4">
              <Input
                type="number"
                placeholder="Phone"
                value={sellerObject?.phone}
                onChange={(e) =>
                  setSellerObject({ ...sellerObject, phone: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <div className="w-full px-4 ">
          <div className="flex flex-col mb-4 md:flex-row">
            <label
              for=""
              className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
            >
              Email <span className="text-orange-500">*</span>
            </label>
            <div className="w-full md:w-3/4">
              <Input
                type="email"
                placeholder="Email"
                value={sellerObject?.email}
                onChange={(e) =>
                  setSellerObject({ ...sellerObject, email: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <div className="w-full px-4 ">
          <div className="flex flex-col mb-4 md:flex-row">
            <label
              for=""
              className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
            >
              Password <span className="text-orange-500">*</span>
            </label>
            <div className="w-full md:w-3/4">
              <Input
                type="password"
                placeholder="Password"
                value={sellerObject?.password}
                onChange={(e) =>
                  setSellerObject({ ...sellerObject, password: e.target.value })
                }
              />
            </div>
          </div>
        </div>

        <div className="w-full px-4 ">
          <div className="flex flex-col mb-4 md:flex-row">
            <label
              for=""
              className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
            >
              Logo
            </label>
            <div className="w-full md:w-3/4">
              <InputFile onChange={handleImageUpload} />
            </div>
          </div>
          {sellerObject?.logo && (
            <img
              src={sellerObject?.logo}
              alt="Uploaded image"
              className="w-full h-auto"
            />
          )}
        </div>
      </div>
      <div className="pb-8 pr-8 text-right mt-4">
        {sellerObj?.shop_name ? (
          <button
            className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
            onClick={handleEditSeller}
          >
            Edit
          </button>
        ) : (
          <button
            className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
            onClick={handleAddSeller}
          >
            Save
          </button>
        )}
      </div>

      <Toast />
    </div>
  );
}
