import React from "react";
import { FaTimes } from "react-icons/fa";

export default function DeleteConfirmation({ setShowModal, handleDelete }) {
  return (
    <div className="w-full max-w-[470px] rounded-md bg-white text-center">
      <div className="flex justify-between items-center px-8 py-4 ">
        <p className="font-semibold">Delete Confirmation</p>
        <FaTimes
          onClick={() => setShowModal(false)}
          className="fill-gray-500 cursor-pointer"
        />
      </div>
      <div className="border-b border-b-gray-200"></div>
      <span className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"></span>
      <p className="text-body-color mb-10 text-base leading-relaxed">
        Are you sure to delete this?
      </p>
      <div className="flex justify-center space-x-6 pb-10">
        <button
          onClick={() => setShowModal(false)}
          className="text-dark block rounded-md border border-[#E9EDF9] p-3 text-center text-base font-medium transition hover:border-red-600 hover:bg-red-600 hover:text-white"
        >
          Cancel
        </button>
        <button
          className="bg-orange-500 border-orange-500 block rounded-md border p-3 text-center text-base font-medium text-white transition hover:bg-opacity-90"
          onClick={() => {
            handleDelete();
            setShowModal(false);
          }}
        >
          Delete
        </button>
      </div>
    </div>
  );
}
