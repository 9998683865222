import { apiSlice } from "../api/apiSlice";

export const allwirehouseApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWirehouse: builder.query({
      query: () => `/admin/wirehouse`,
      providesTags: ["wirehouse"],
    }),
    addWirehouse: builder.mutation({
      query: (data) => ({
        url: "/admin/wirehouse/add",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["wirehouse"],
    }),
    editWirehouse: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/wirehouse/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["wirehouse"],
    }),
    deleteWirehouse: builder.mutation({
      query: (id) => ({
        url: `/admin/wirehouse/${id}`,
        method: "DELETE",
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["wirehouse"],
    }),
  }),
});

export const {
  useGetWirehouseQuery,
  useAddWirehouseMutation,
  useEditWirehouseMutation,
  useDeleteWirehouseMutation,
} = allwirehouseApi;
