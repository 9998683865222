import React, { useState } from "react";
import VarientTable from "./VarientTable";
import AddVarient from "./AddVarient";

export default function VarientComponent() {
  const [varientObj, setVarientObj] = useState({});
  return (
    <>
      <p className="p-4 font-bold md:pl-8">All varient</p>
      <div className="flex flex-col p-4 mb-8  md:flex-row">
        <VarientTable setPaymentMethodObj={setVarientObj} />
        <AddVarient
          setPercentDiscountObj={setVarientObj}
          percentDiscountObj={varientObj}
        />
      </div>
    </>
  );
}
