import React, { useState } from 'react'
import SubCategoryTable from './SubCategoryTable'
import AddSubCategory from './AddSubCategory'

export default function SubCategoryComponent() {
    let [subCategoryObj , setSubCategoryObj] = useState({})
    return (
        <>
            <p className='p-4 font-bold md:pl-8'>All Sub categories</p>
            <div className='flex flex-col p-4 mb-8  md:flex-row'>
                <SubCategoryTable setSubCategoryObj = {setSubCategoryObj} />
                <AddSubCategory subCategoryObj={subCategoryObj} setSubCategoryObj={setSubCategoryObj} />
            </div>
        </>
    )
}
