import React from 'react'
import AddForm from './AddForm'
import ProductConfirmation from './ProductConfirmation'

export default function ProductAdd() {
  return (
    <div className='flex flex-col m-8 space-x-0 space-y-4 md:space-y-0 md:space-x-4 md:flex-row'>
        <AddForm />
        <ProductConfirmation />
    </div>
  )
}
