import React from "react";
import { FaRegEdit } from "react-icons/fa";

export default function EditOption({ onClick }) {
  return (
    <div
      className="group p-2 bg-blue-200 hover:bg-blue-500 rounded-full transition-all duration-300"
      onClick={() => {
        window.scrollTo(0, 0);
        onClick();
      }}
    >
      <FaRegEdit className="fill-blue-500 group-hover:fill-white" />
    </div>
  );
}
