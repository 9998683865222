import { apiSlice } from "../api/apiSlice";
import { wirehouseLoggedIn } from "./wirehouseAuthSlice";

export const wirehouseAuthApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    wirehouseLogin: builder.mutation({
      query: (data) => ({
        url: "/wirehouse/login",
        method: "POST",
        body: data,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;

          // console.log("result", result);
          // console.log("arg result", arg?.email)

          localStorage.setItem(
            "wirehouse-auth",
            JSON.stringify({
              wirehouseToken: result.data.token,
              ownerData: result.data.data,
            })
          );

          dispatch(
            wirehouseLoggedIn({
              wirehouseToken: result.data.token,
              ownerData: result.data.data,
            })
          );
        } catch (err) {
          // do nothing
        }
      },
    }),
  }),
});

export const { useWirehouseLoginMutation } = wirehouseAuthApi;
