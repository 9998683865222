import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Toast({ greet, show }) {
    const notify = () => {
        toast.success(greet, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    };

    useEffect(() => {
        notify();
    }, [])

    return (
        <div>
            {/* <button onClick={notify}>{greet}</button> */}
            <ToastContainer autoClose={5000} />
        </div>
    )
}
