import React from 'react'
import Input from '../../../Shared/Components/Input'
import InputFile from '../../../Shared/Components/InputFile'

export default function AddAttribute() {
    return (
        <div className='w-full md:w-1/3'>
            <div className='bg-white rounded-md shadow-xl '>
                <p className='px-8 py-4 font-semibold'>Add New Attribute</p>
                <div className='border-b border-b-gray-200'></div>
                <div className="flex p-8 pb-0">
                    <div className="w-full px-4 w-full">
                        <div className="flex flex-col mb-4 ">
                            <label for="" className="w-full mb-3 block text-base text-black font-light">
                                Name <span className='text-orange-500'>*</span>
                            </label>
                            <div className='w-full'>
                                <Input type="text" placeholder="Name" />
                            </div>
                        </div>

                    </div>

                </div>
                <div className='pb-8 pr-8 text-right'>
                    <button className='bg-orange-500 text-white py-2 px-8 rounded-md mr-4'>Save</button>
                </div>
            </div>
        </div>
    )
}
