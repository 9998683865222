import React, { useState } from 'react'
import { FaShippingFast, FaTrashAlt, FaAngleUp, FaTimes } from "react-icons/fa";
import Modal from '../../../Shared/Modal';
import watch from "../../../assets/Images/watch.png"

export default function PostShipping() {
    let [showInput, setShowInput] = useState("");
    let [orderSummary, setOrderSummary] = useState(true);
    return (
        <>
            <div className='sticky top-[120px] right-0 w-full md:w-[500px] h-[calc(100vh-200px)]'>
                <div className=' bg-white rounded-md  p-8'>

                    {/* Filter part */}
                    <div className='flex space-x-4 pb-4'>
                        <select id="selectID" className='w-[90%]  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary w-full rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500'>
                            <option className='bg-orange-400 text-white p-4'>Hot Categories</option>
                            <option className='bg-orange-400 text-white p-4'>Party Dress</option>
                            <option className='bg-orange-400 text-white p-4'>Women Shoe</option>
                        </select>
                        <div className='bg-gray-200 flex items-cetner justify-center p-2 w-[10%] rounded-md'>
                            <FaShippingFast className='w-full h-full fill-gray-500' />
                        </div>
                    </div>
                    <div className='border border-b-gray-200'></div>

                    {/* Selected Product */}
                    <div className='h-[calc(calc(100vh-200px)-calc(150px+140px))] overflow-x-auto py-8'>
                        <div className='flex items-center space-x-2 py-4'>
                            <div className='w-1/12'>
                                <p className='font-bold cursor-pointer'>+</p>
                                <input type="number" value={2} className='w-full focus:outline-none' />
                                <p className='font-bold cursor-pointer'>-</p>
                            </div>
                            <div className='w-6/12 text-sm'>
                                <p className='text-dark font-thin'>Corsair K60 RGB Pro Mechanical Gaming Keyboard - CHERRY</p>
                                <p className='inline-block p-1 rounded-sm bg-gray-200 text-gray-400'>AliceBlue-M</p>
                            </div>
                            <div className='w-3/12'>
                                <p>$50.000 x 2</p>
                                <p>$100.000</p>
                            </div>
                            <div className='w-1/12'>
                                <div className='p-2 bg-red-200 rounded-full cursor-pointer flex items-center justify-center' >
                                    <FaTrashAlt className='fill-red-500' />
                                </div>
                            </div>
                        </div>
                        <div className='border border-b-gray-200'></div>
                        <div className='flex items-center space-x-2 py-4'>
                            <div className='w-1/12'>
                                <p className='font-bold cursor-pointer'>+</p>
                                <input type="number" value={2} className='w-full focus:outline-none' />
                                <p className='font-bold cursor-pointer'>-</p>
                            </div>
                            <div className='w-6/12 text-sm'>
                                <p className='text-dark font-thin'>Corsair K60 RGB Pro Mechanical Gaming Keyboard - CHERRY</p>
                                <p className='inline-block p-1 rounded-sm bg-gray-200 text-gray-400'>AliceBlue-M</p>
                            </div>
                            <div className='w-3/12'>
                                <p>$50.000 x 2</p>
                                <p>$100.000</p>
                            </div>
                            <div className='w-1/12'>
                                <div className='p-2 bg-red-200 rounded-full cursor-pointer flex items-center justify-center' >
                                    <FaTrashAlt className='fill-red-500' />
                                </div>
                            </div>
                        </div>
                        <div className='border border-b-gray-200'></div>
                    </div>

                    {/* Shipping Cost */}
                    <div className='h-[150px] bg-white'>
                        <div className='flex justify-between items-center text-gray-500'>
                            <p>Sub Total</p>
                            <p>$1,899.000</p>
                        </div>
                        <div className='flex justify-between items-center text-gray-500'>
                            <p>Tax</p>
                            <p>$1.000</p>
                        </div>
                        <div className='flex justify-between items-center text-gray-500'>
                            <p>Shipping</p>
                            <p>$0.000</p>
                        </div>
                        <div className='flex justify-between items-center text-gray-500'>
                            <p>Discount</p>
                            <p>$0.000</p>
                        </div>
                        <div className='border border-b-gray-200 border-'></div>
                        <div className='flex justify-between items-center font-bold text-gray-700'>
                            <p>Total</p>
                            <p>$1,900.000</p>
                        </div>
                    </div>
                </div >
                <div className='py-2'>
                    <div className='flex justify-between items-center'>
                        <div className='w-1/2 flex space-x-2'>
                            <div className='relative '>
                                <div onClick={() => {
                                    if (showInput !== "shipping") {
                                        setShowInput("shipping")
                                    } else {
                                        setShowInput("")
                                    }
                                }} className='flex items-center justify-center border border-gray-700 p-4 rounded-lg cursor-pointer'>

                                    <p>Shipping</p>
                                    < FaAngleUp />
                                </div>

                                {/* Absolute element */}
                                {
                                    showInput === "shipping" && <div className='absolute -top-20 left-0 bg-white p-4 shadow-md'>
                                        <div className='flex items-center justif-center'>
                                            <input className='border p-2 focus:outline-none focus:border-orange-400' type="number" />
                                            <div className='bg-gray-200 p-2'>Flat</div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='relative' >
                                <div className='flex items-center justify-center border border-gray-700 p-4 rounded-lg cursor-pointer' onClick={() => {
                                    if (showInput !== "discount") {
                                        setShowInput("discount")
                                    } else {
                                        setShowInput("")
                                    }
                                }}>
                                    <p>Discount</p>
                                    < FaAngleUp />
                                </div>

                                {/* Absolute element */}
                                {
                                    showInput === "discount" && <div className='absolute -top-20 left-0 bg-white p-4 shadow-md'>
                                        <div className='flex items-center justif-center'>
                                            <input className='border p-2 focus:outline-none focus:border-orange-400' type="number" />
                                            <div className='bg-gray-200 p-2'>Flat</div>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                        <div className='w-1/2 text-right'>
                            <button className='bg-orange-500 text-white py-2 px-8 rounded-md' onClick={() => setOrderSummary(true)}>Place Order</button>
                        </div>
                    </div>


                </div>
            </div >
            {
                orderSummary ? <Modal show={orderSummary}>
                    <div
                        className="w-full min-w-[500px] max-w-[1120px]  rounded-md bg-white text-center z-100"
                    >
                        <div className='flex justify-between items-center px-8 py-4 '>
                            <p className='font-semibold'>Order Summary</p>
                            <FaTimes
                                onClick={() => setOrderSummary(false)}
                                className='fill-gray-500 cursor-pointer'
                            />
                        </div>
                        <div className='border-b border-b-gray-200'></div>

                        <div className='flex flex-col space-x-4 md:flex-row max-h-[800px] scrollbar overflow-y-auto'>
                            <div className='w-full'>
                                <div className='flex items-center space-x-2'>
                                    <img src={watch} alt="watch" className='w-1/6 w-20 h-20' />
                                    <p className='w-4/6'>OnePlus 8T 5G KB2000 128GB 8GB RAM</p>
                                    <div className='w-1/6 text-right'>
                                        <p className='font-bold'>$360.000</p>
                                        <p>Qty: 1</p>
                                    </div>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <img src={watch} alt="watch" className='w-1/6 w-20 h-20' />
                                    <p className='w-4/6'>OnePlus 8T 5G KB2000 128GB 8GB RAM</p>
                                    <div className='w-1/6 text-right'>
                                        <p className='font-bold'>$360.000</p>
                                        <p>Qty: 1</p>
                                    </div>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <img src={watch} alt="watch" className='w-1/6 w-20 h-20' />
                                    <p className='w-4/6'>OnePlus 8T 5G KB2000 128GB 8GB RAM</p>
                                    <div className='w-1/6 text-right'>
                                        <p className='font-bold'>$360.000</p>
                                        <p>Qty: 1</p>
                                    </div>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <img src={watch} alt="watch" className='w-1/6 w-20 h-20' />
                                    <p className='w-4/6'>OnePlus 8T 5G KB2000 128GB 8GB RAM</p>
                                    <div className='w-1/6 text-right'>
                                        <p className='font-bold'>$360.000</p>
                                        <p>Qty: 1</p>
                                    </div>
                                </div>
                                <div className='flex items-center space-x-2'>
                                    <img src={watch} alt="watch" className='w-1/6 w-20 h-20' />
                                    <p className='w-4/6'>OnePlus 8T 5G KB2000 128GB 8GB RAM</p>
                                    <div className='w-1/6 text-right'>
                                        <p className='font-bold'>$360.000</p>
                                        <p>Qty: 1</p>
                                    </div>
                                </div>

                            </div>
                            <div className=' w-full p-2'>
                                <div className='h-[150px] bg-white'>
                                    <div className='flex justify-between items-center text-gray-500'>
                                        <p>Sub Total</p>
                                        <p>$1,899.000</p>
                                    </div>
                                    <div className='flex justify-between items-center text-gray-500'>
                                        <p>Tax</p>
                                        <p>$1.000</p>
                                    </div>
                                    <div className='flex justify-between items-center text-gray-500'>
                                        <p>Shipping</p>
                                        <p>$0.000</p>
                                    </div>
                                    <div className='flex justify-between items-center text-gray-500'>
                                        <p>Discount</p>
                                        <p>$0.000</p>
                                    </div>
                                    <div className='border border-b-gray-200 border-'></div>
                                    <div className='flex justify-between items-center font-bold text-gray-700'>
                                        <p>Total</p>
                                        <p>$1,900.000</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-center space-x-6 pb-10">
                            <button
                                onClick={() => setOrderSummary(false)}
                                className="text-dark block rounded-md border border-[#E9EDF9] p-3 text-center text-base font-medium transition hover:border-red-600 hover:bg-red-600 hover:text-white"
                            >
                                Close
                            </button>
                            <button
                                className="bg-orange-500 border-orange-500 block rounded-md border p-3 text-center text-base font-medium text-white transition hover:bg-opacity-90"
                            >
                                Payment
                            </button>
                        </div>
                    </div>
                </Modal> : null
            }
        </>
    )
}
