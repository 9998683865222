import React from "react";
import RmsPermissionComponent from "./Components/RmsPermissionComponent/RmsPermissionComponent";

export default function RmsPermission() {
  return (
    <>
      <RmsPermissionComponent />
    </>
  );
}
