import React, { useEffect, useState } from "react";
import Input from "../../../Shared/Components/Input";
import InputFile from "../../../Shared/Components/InputFile";
import {
  useAddBusinessMutation,
  useEditBusinessOperationMutation,
} from "../../../features/business/businessApi";
import { toast } from "react-toastify";
import Toast from "../../../Shared/UI/Toast";

export default function AddBusiness({ editBusiness, setEditBusiness }) {
  const [name, setName] = useState("");
  const [base64Image, setBase64Image] = useState("");

  useEffect(() => {
    setName(editBusiness?.name);
  }, [editBusiness?.name]);

  const [addBusiness, { isSuccess: isAddBusinessSuccess }] =
    useAddBusinessMutation();
  const [
    editBusinessOperation,
    { isSuccess: isEditBusinessSuccess, isError, error },
  ] = useEditBusinessOperationMutation();

  useEffect(() => {
    if (isAddBusinessSuccess) {
      toast.success("Successfully business added.");
      setName("");
      setBase64Image("");
    }
  }, [isAddBusinessSuccess]);

  useEffect(() => {
    if (isEditBusinessSuccess) {
      toast.success("Successfully business updaed.");
      setName("");
      setBase64Image("");
      setEditBusiness({});
    }
  }, [isEditBusinessSuccess]);

  console.log("isEditBusinessSuccess", isEditBusinessSuccess, isError, error);

  function handleImageUpload(e) {
    const file = e.target.files[0];
    console.log("file", file);
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      setBase64Image(reader.result);
    };
  }

  const handleBusinessAdd = () => {
    if (name && base64Image) {
      addBusiness({
        name,
        image: base64Image,
      });
    }
  };

  const handleBusinessEdit = () => {
    editBusinessOperation({
      id: editBusiness?.id,
      data: {
        name,
        image: base64Image,
      },
    });
  };

  return (
    <div className="w-1/3">
      <div className="bg-white rounded-md shadow-xl ">
        <p className="px-8 py-4 font-semibold">
          {editBusiness?.name ? "Update" : "Add new"} business
        </p>
        <div className="border-b border-b-gray-200"></div>
        <div className="flex p-8 pb-0">
          <div className="w-full px-4">
            <div className="flex flex-col mb-4 ">
              <label
                for=""
                className="w-full mb-3 block text-base text-black font-light"
              >
                Name <span className="text-orange-500">*</span>
              </label>
              <div className="w-full">
                <Input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>

            <div className="flex flex-col mb-4 ">
              <label
                for=""
                className="w-full mb-3 block text-base text-black font-light"
              >
                Brand Image <span className="text-orange-500">*</span>
              </label>
              <div className="w-full">
                <InputFile onChange={handleImageUpload} />
              </div>
              {base64Image && (
                <img
                  src={base64Image}
                  alt="Uploaded image"
                  className="w-full h-auto"
                />
              )}
            </div>

            {/* <div className="flex flex-col mb-4">
                            <label for="" className="w-full mb-3 block text-base text-black font-light">
                                Meta Title <span className='text-orange-500'>*</span>
                            </label>
                            <div className='w-full'>
                                <Input type="text" placeholder="Name" />
                            </div>
                        </div>

                        <div className="flex flex-col mb-4">
                            <label for="" className="w-full mb-3 block text-base text-black font-light">
                                Meta Description
                            </label>
                            <div className="w-full  ">
                                <textarea
                                    rows="5"
                                    placeholder="Active textarea"
                                    class="border-primary text-body-color placeholder-body-color focus:border-primary active:border-primary w-full rounded-lg border-[1.5px] py-3 px-5 font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD]"
                                ></textarea>
                            </div>
                        </div> */}
          </div>
        </div>
        <div className="pb-8 pr-8 text-right">
          {editBusiness?.name ? (
            <button
              className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
              onClick={handleBusinessEdit}
            >
              Update
            </button>
          ) : (
            <button
              className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
              onClick={handleBusinessAdd}
            >
              Save
            </button>
          )}
        </div>
      </div>
      <Toast />
    </div>
  );
}
