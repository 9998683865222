import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

// export const data = {
//   labels: [
//     "Total Published Product",
//     "Total Sellers Product",
//     "Total Admin Product",
//   ],
//   datasets: [
//     {
//       label: "# of Votes",
//       data: [12, 19, 10],
//       backgroundColor: [
//         "rgb(255, 99, 132)",
//         "rgb(54, 162, 235)",
//         "rgb(255, 206, 86)",
//       ],
//       borderColor: [
//         "rgba(255, 99, 132, 1)",
//         "rgba(54, 162, 235, 1)",
//         "rgba(255, 206, 86, 1)",
//       ],
//       borderWidth: 1,
//     },
//   ],
// };

export default function PieChat({ data }) {
  // let products = {
  //   labels: ["Total Published Products", "Total Sellers Product"],
  //   datasets: [
  //     {
  //       label: "Products",
  //       data: [12, 19],
  //     },
  //   ],
  // };

  let actualData = {
    ...data,
    datasets: [
      {
        ...data?.datasets[0],
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 206, 86)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div className="p-2" style={{ width: "100%" }}>
      <Doughnut
        data={actualData}
        options={{
          responsive: true,
          maintainAspectRatio: true,
        }}
      />
    </div>
  );
}
