import React, { useEffect, useState } from "react";
import { useGetInternationalProductQuery } from "../../../../features/wirehouseApi/wirehouseProductApi";
import Input from "../../../../Shared/Components/Input";
import Toast from "../../../../Shared/Components/Toast";

export default function WirehouseInternationalProductComponent() {
  const [orderId, setOrderId] = useState();
  const [skip, setSkip] = useState(false);

  const { data, isLoading, isError, error } = useGetInternationalProductQuery(
    { id: orderId },
    {
      skip: !skip,
    }
  );

  useEffect(() => {
    if (!isLoading) {
      setSkip(false);
    }
  }, [isLoading]);

  console.log("skip", skip);

  console.log(
    "data, isLoading, isSuccess, isError, error",
    data,
    isLoading,
    isError,
    error
  );

  const handleOrderTrack = () => {
    // getInternationalProduct({ order_id: orderId });
  };

  let content = null;

  if (isLoading) {
    content = <div>Loading</div>;
  }
  if (!isLoading && isError) {
    content = <div>{error?.error}</div>;
  }

  if (!isLoading && !isError) {
    content = (
      <div className="max-w-5xl mx-auto p-10 rounded-md shadow-md bg-white my-10">
        <div className="py-10 px-8 flex flex-col gap-2">
          <h1 className="text-center font-bold text-xl ">Seller Info</h1>
          <img
            src={data?.data?.seller_info?.logo}
            alt={data?.data?.seller_info?.shop_name}
            className="mx-auto"
          />
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Shop Name:</p>
            <p className="col-span-3">{data?.data?.seller_info?.shop_name}</p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Phone:</p>
            <p className="col-span-3">{data?.data?.seller_info?.phone}</p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Email:</p>
            <p className="col-span-3">{data?.data?.seller_info?.email}</p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Address:</p>
            <p className="col-span-3">{data?.data?.seller_info?.address}</p>
          </div>
        </div>
        <div className="py-10 px-8 flex flex-col gap-2">
          <h1 className="text-center font-bold text-xl ">Customer Info</h1>

          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Full Name:</p>
            <p className="col-span-3">
              {data?.data?.customer_address?.full_name}
            </p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Mobile:</p>
            <p className="col-span-3">{data?.data?.customer_address?.mobile}</p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Apartment:</p>
            <p className="col-span-3">
              {data?.data?.customer_address?.apartment}
            </p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Street:</p>
            <p className="col-span-3">{data?.data?.customer_address?.street}</p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">City:</p>
            <p className="col-span-3">{data?.data?.customer_address?.city}</p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Zip:</p>
            <p className="col-span-3">{data?.data?.customer_address?.zip}</p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">State:</p>
            <p className="col-span-3">{data?.data?.customer_address?.state}</p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Country:</p>
            <p className="col-span-3">
              {data?.data?.customer_address?.country}
            </p>
          </div>
        </div>
        <div className="py-10 px-8 flex flex-col gap-2">
          <h1 className="text-center font-bold text-xl ">Product Info</h1>
          <img
            src={data?.data?.product_info?.image}
            alt={data?.data?.product_info?.product_name}
            className="mx-auto"
          />
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Product Id:</p>
            <p className="col-span-3">{data?.data?.product_info?.product_id}</p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Product Name:</p>
            <p className="col-span-3">
              {data?.data?.product_info?.product_name}
            </p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Variant Info:</p>
            <p className="col-span-3 bg-gray-200 p-4 mb-2">
              {data?.data?.product_info?.varient_info?.map((variant) => (
                <div>
                  <p>
                    <span>size: </span> {variant?.size}
                  </p>
                  <p>
                    <span>color: </span> {variant?.color}
                  </p>
                  <p>
                    <span>stock: </span> {variant?.stock}
                  </p>
                </div>
              ))}
            </p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Product Quantity:</p>
            <p className="col-span-3">{data?.data?.product_info?.quantity}</p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Seller Status:</p>
            <p className="col-span-3">
              {data?.data?.product_info?.seller_status}
            </p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Wirehouse Status:</p>
            <p className="col-span-3">
              {data?.data?.product_info?.wirehouse_status}
            </p>
          </div>
        </div>

        <div className="py-10 px-8 flex flex-col gap-2">
          <h1 className="text-center font-bold text-xl ">Shipping Info</h1>

          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Destination Country:</p>
            <p className="col-span-3">
              {data?.data?.shipping_info?.Destination_Country}
            </p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Shipping Type:</p>
            <p className="col-span-3">
              {data?.data?.shipping_info?.Shipping_Type}
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex p-8 pb-0">
        <div className="w-full px-4">
          <div className="flex flex-col mb-4 ">
            <label
              for=""
              className="w-full mb-3 block text-base text-black font-light"
            >
              Order Id <span className="text-orange-500">*</span>
            </label>
            <div className="w-full">
              <Input
                type="text"
                placeholder="Order Id"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
              />
            </div>
          </div>

          <div className="pb-8 pr-8">
            <button
              className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
              onClick={() => setSkip(true)}
            >
              {isLoading ? "Processing" : "Search"}
            </button>
          </div>
        </div>
      </div>
      {data?.data?.seller_info?.shop_name ? content : null}
      <Toast />
    </div>
  );
}
