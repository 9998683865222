import React, { useState } from "react";
import SellerExtraChargeTypeTable from "./SellerExtraChargeTypeTable";
import AddSellerExtraChargeType from "./AddSellerExtraChargeType";

export default function SellerExtraChargeTypeComponent() {
  const [extraChargeTypeObj, setExtraChargeTypeObj] = useState({});
  return (
    <>
      <p className="p-4 font-bold md:pl-8">All extra charge type</p>
      <div className="flex flex-col p-4 mb-8  md:flex-row">
        <SellerExtraChargeTypeTable
          setPaymentMethodObj={setExtraChargeTypeObj}
        />
        <AddSellerExtraChargeType
          setPercentDiscountObj={setExtraChargeTypeObj}
          percentDiscountObj={extraChargeTypeObj}
        />
      </div>
    </>
  );
}
