import React, { useEffect, useState } from "react";
import {
  useAddBuyersBannerMutation,
  useEditBuyersBannerMutation,
} from "../../../features/buyersBanner/buyersBannerApi";
import { toast } from "react-toastify";
import Input from "../../../Shared/Components/Input";
import InputFile from "../../../Shared/Components/InputFile";
import Toast from "../../../Shared/Components/Toast";

export default function AddBuyersBanner({ categoryObj, setCategoryObj }) {
  let [countryDetail, setCountryDetail] = useState({
    country: "",
    image: "",
  });

  useEffect(() => {
    setCountryDetail({
      country: categoryObj?.country,
      image: categoryObj?.image,
    });
  }, [categoryObj]);

  const [addCountry, { isSuccess: isAddCategorySuccess }] =
    useAddBuyersBannerMutation();
  const [
    editCountry,
    { isSuccess: isEditCategorySuccess, isLoading, isError, error },
  ] = useEditBuyersBannerMutation();

  useEffect(() => {
    if (isAddCategorySuccess) {
      toast.success("Successfully Country added.");
      setCountryDetail({
        country: "",
        image: "",
      });
    }
  }, [isAddCategorySuccess]);

  useEffect(() => {
    if (isEditCategorySuccess) {
      toast.success("Successfully Country Updated.");
      setCountryDetail({
        country: "",
        image: "",
      });
      setCategoryObj({});
    }
    if (isError) {
      toast.error(error.error);
    }
  }, [isEditCategorySuccess, isError]);

  function handleImageUpload(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      setCountryDetail({ ...countryDetail, image: reader.result });
    };
  }

  const handleCategoryAdd = (e) => {
    addCountry(countryDetail);
  };

  const handleCategoryEdit = async (e) => {
    console.log("country Detail", countryDetail);
    await editCountry({
      id: categoryObj?.id,
      data: countryDetail,
    });
  };

  return (
    <>
      <div className="w-1/3">
        <div className="bg-white rounded-md shadow-xl ">
          <p className="px-8 py-4 font-semibold">
            {categoryObj?.country ? "Edit" : "Add"} Buyers Banner
          </p>
          <div className="border-b border-b-gray-200"></div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Country Name <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder="Name"
                    value={countryDetail?.country}
                    onChange={(e) =>
                      setCountryDetail({
                        ...countryDetail,
                        country: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Banner Image <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <InputFile onChange={handleImageUpload} />
                </div>
                {countryDetail?.country ? (
                  <img
                    src={countryDetail?.country}
                    alt="Uploaded image"
                    className="w-full h-auto pt-4"
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="pb-8 pr-8 text-right">
            {categoryObj?.country ? (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryEdit}
              >
                Edit
              </button>
            ) : (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryAdd}
              >
                Save
              </button>
            )}
          </div>
        </div>
        <Toast />
      </div>
    </>
  );
}
