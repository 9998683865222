import { apiSlice } from "../api/apiSlice";

export const featuredShopApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFeaturedShop: builder.query({
      query: () => `/admin/featured_shop`,
      providesTags: ["featuredShop"],
    }),
    addFeaturedShop: builder.mutation({
      query: (data) => ({
        url: "/admin/featured_shop",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["featuredShop"],
    }),

    editFeaturedShop: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/featured_shop/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["featuredShop"],
    }),

    deleteFeaturedShop: builder.mutation({
      query: (id) => ({
        url: `/admin/featured_shop/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["featuredShop"],
    }),
  }),
});

export const {
  useGetFeaturedShopQuery,
  useAddFeaturedShopMutation,
  useEditFeaturedShopMutation,
  useDeleteFeaturedShopMutation,
} = featuredShopApi;
