import { apiSlice } from "../api/apiSlice";

export const ordersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllOrders: builder.mutation({
      query: (data) => ({
        url: `/admin/orders/all`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["orders"],
    }),
    getOrderDetails: builder.query({
      query: (id) => `/admin/orders_details/${id}`,
      providesTags: ["orders"],
    }),
    getOrderTrack: builder.query({
      query: (id) => `/admin/orders/track/${id}`,
      providesTags: ["orders"],
    }),
    addOrderTrack: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/orders/track/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["orders"],
    }),
    editOrderTrack: builder.mutation({
      query: (data) => ({
        url: `/admin/orders/track/`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["orders"],
    }),
    deleteOrderTrack: builder.mutation({
      query: (id) => ({
        url: `/admin/orders/track/${id}`,
        method: "DELETE",
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["orders"],
    }),
  }),
});

export const {
  useGetOrderDetailsQuery,
  useGetOrderTrackQuery,
  useGetAllOrdersMutation,
  useAddOrderTrackMutation,
  useEditOrderTrackMutation,
  useDeleteOrderTrackMutation,
} = ordersApi;
