import { apiSlice } from "../api/apiSlice";

export const subCategoryApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSubCategory: builder.query({
            query: () => `/admin/subcatagory`,
            providesTags: ["subCategory"],
        }),
        addSubCategory: builder.mutation({
            query: (data) => ({
                url: "/admin/subcatagory",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["subCategory"],
        }),
        editSubcategoryOperation: builder.mutation({
            query: ({ id, data }) => ({
                url: `/admin/subcatagory/${id}`,
                method: 'PUT',
                body: data
            }),
            // Invalidates all queries that subscribe to this Post `id` only.
            invalidatesTags: ["subCategory"],
        }),
        deleteSubcategory: builder.mutation({
            query: (id) => ({
                url: `/admin/subcatagory/${id}`,
                method: 'DELETE',
            }),
            // Invalidates all queries that subscribe to this Post `id` only.
            invalidatesTags: ["subCategory"],
        })
    })
})

export const { useGetSubCategoryQuery,useDeleteSubcategoryMutation, useEditSubcategoryOperationMutation, useAddSubCategoryMutation } = subCategoryApi;