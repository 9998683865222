// import React, { PureComponent } from 'react';
// import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// const data = [
//   {
//     name: 'Page A',
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: 'Page B',
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: 'Page C',
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: 'Page D',
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: 'Page E',
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: 'Page F',
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: 'Page G',
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];

// export default class BarCharts extends PureComponent {
//   static demoUrl = 'https://codesandbox.io/s/simple-bar-chart-tpz8r';

//   render() {
//     return (
//       <ResponsiveContainer width="100%" height="100%">
//         <BarChart
//           width={500}
//           height={300}
//           data={data}
//           margin={{
//             top: 5,
//             right: 30,
//             left: 20,
//             bottom: 5,
//           }}
//         >
//           <CartesianGrid strokeDasharray="3 3" />
//           <XAxis dataKey="name" />
//           <YAxis />
//           <Tooltip />
//           <Legend />
//           <Bar dataKey="pv" fill="#875fc0" />
//           <Bar dataKey="uv" fill="#5346ba" />
//         </BarChart>
//       </ResponsiveContainer>
//     );
//   }
// }

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Category wise Product",
    },
  },
};

export default function BarCharts({ chartData }) {
  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  const data = {
    labels: chartData?.labels || [],
    datasets: [
      {
        label: "Product",
        data: chartData?.datasets[0]?.data,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      } || {},
      // {
      //   label: "Dataset 1",
      //   data: [19, 2, 0, 0, 1, 0, 0, 0, 0, 0],
      //   backgroundColor: "rgba(255, 99, 132, 0.5)",
      // },
      // {
      //   label: "Dataset 2",
      //   data: [19, 2, 0, 0, 1, 0, 0, 0, 0, 0],
      //   backgroundColor: "rgba(53, 162, 235, 0.5)",
      // },
    ],
  };
  console.log("barchar data", data);
  return <Bar options={options} data={data} />;
}
