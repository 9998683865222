import React from 'react'
import Layout from '../Layout/Layout'
import AttributesComponent from './Components/AttributesComponent/AttributesComponent'

export default function Attributes({ setShowSidebar, showSidebar }) {
  return (
    <Layout  setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
        <AttributesComponent />
    </Layout>
  )
}
