import { apiSlice } from "../api/apiSlice";

export const livesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLives: builder.query({
      query: () => `/admin/lives`,
      providesTags: ["lives"],
    }),
  }),
});

export const { useGetLivesQuery } = livesApi;
