import React, { useEffect, useState } from "react";
import {
  useAddSellerDeliveryTimesMutation,
  useEditSellerDeliveryTimesMutation,
} from "../../../features/sellerDeliveryTimes/sellerDeliveryTimesApi";
import { toast } from "react-toastify";
import Input from "../../../Shared/Components/Input";
import Toast from "../../../Shared/Components/Toast";

export default function AddSellerDeliveryTimes({
  setPercentDiscountObj,
  percentDiscountObj,
}) {
  let [percentDiscount, setPercentDiscount] = useState({
    times: "",
    minutes: "",
  });

  useEffect(() => {
    console.log("percentDiscountObj", percentDiscountObj);
    if (percentDiscountObj?.times) {
      setPercentDiscount({
        times: percentDiscountObj?.times,
        minutes: percentDiscountObj?.minutes,
      });
    }
  }, [percentDiscountObj]);

  const [addSellerDeliveryTimes, { isSuccess: ispercentDiscountSuccess }] =
    useAddSellerDeliveryTimesMutation();
  const [
    editSellerDeliveryTimes,
    { isSuccess: isEditpercentDiscountSuccess, isLoading, isError, error },
  ] = useEditSellerDeliveryTimesMutation();

  useEffect(() => {
    if (ispercentDiscountSuccess) {
      toast.success("Successfully Percent Discount added.");
      setPercentDiscount({
        times: "",
        minutes: "",
      });
    }
  }, [ispercentDiscountSuccess]);

  useEffect(() => {
    if (isEditpercentDiscountSuccess) {
      toast.success("Successfully Percent Discount Updated.");
      setPercentDiscount({
        times: "",
        minutes: "",
      });
      setPercentDiscountObj({});
    }
    if (isError) {
      console.log("error", error);
      toast.error(error.error);
    }
  }, [isEditpercentDiscountSuccess, isError]);

  const handleCategoryAdd = (e) => {
    addSellerDeliveryTimes(percentDiscount);
  };

  const handleCategoryEdit = async (e) => {
    await editSellerDeliveryTimes({
      id: percentDiscountObj?.id,
      data: {
        ...percentDiscount,
      },
    });
  };

  return (
    <>
      <div className="w-full md:w-1/3">
        <div className="bg-white rounded-md shadow-xl ">
          <p className="px-8 py-4 font-semibold">
            {percentDiscountObj?.times ? "Update" : "Add"} delivery times
          </p>
          <div className="border-b border-b-gray-200"></div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Times <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder="Name"
                    value={percentDiscount.times}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,

                        times: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Minutes <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder=""
                    value={percentDiscount?.minutes}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        minutes: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pb-8 pr-8 text-right">
            {percentDiscountObj?.times ? (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryEdit}
              >
                Update
              </button>
            ) : (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryAdd}
              >
                Save
              </button>
            )}
          </div>
        </div>
        <Toast />
      </div>
    </>
  );
}
