import { apiSlice } from "../api/apiSlice";

export const sellerProductApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSellerProduct: builder.query({
      query: (id) => `/admin/product/seller/${id}`,
      providesTags: ["sellerProduct"],
    }),
  }),
});

export const { useGetSellerProductQuery } = sellerProductApi;
