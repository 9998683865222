import React from 'react'
import Sidebar from '../Shared/Sidebar'
import Topbar from '../Views/Components/ViewComponent/Topbar'

export default function Layout({ children, showSidebar, setShowSidebar, setShow }) {
    return (
        <section className="flex flex-col md:flex-row">
            <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
            <div className='bg-[#F2F3F8] w-full min-h-screen top-0 right-0'>
                <Topbar setShowSidebar={setShowSidebar} showSidebar={showSidebar} setShow={setShow} />
                {children}
            </div>
        </section>
    )
}
