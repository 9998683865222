import React from 'react'

export default function Input({ type, placeholder, value, onChange }) {
    return (
        <input
            type={type}
            placeholder={placeholder}
            className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5  transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
            value={value}
            onChange={onChange}
        />
    )
}
