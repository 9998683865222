import React, { useEffect, useState } from "react";
import { imageToBase64 } from "../../../Shared/utils/ImageToBase64";
import Input from "../../../Shared/Components/Input";
import InputFile from "../../../Shared/Components/InputFile";
import {
  useAddCategoryMutation,
  useEditCategoryOperationMutation,
} from "../../../features/category/categoryApi";
import { toast } from "react-toastify";
import Toast from "../../../Shared/UI/Toast";

export default function AddCategory({ categoryObj, setCategoryObj }) {
  let [name, setName] = useState("");
  let [image, setImage] = useState("");

  useEffect(() => {
    setName(categoryObj?.name);
  }, [categoryObj]);

  const [addCategory, { isSuccess: isAddCategorySuccess }] =
    useAddCategoryMutation();
  const [
    editCategoryOperation,
    { isSuccess: isEditCategorySuccess, isLoading, isError, error },
  ] = useEditCategoryOperationMutation();

  useEffect(() => {
    if (isAddCategorySuccess) {
      toast.success("Successfully category added.");
      setName("");
      setImage("");
    }
  }, [isAddCategorySuccess]);

  console.log(
    "isEditCategorySuccess, isError, error",
    isEditCategorySuccess,
    isError,
    error
  );

  useEffect(() => {
    if (isEditCategorySuccess) {
      toast.success("Successfully category Updated.");
      setName("");
      setImage("");
      setCategoryObj({});
    }
    if (isError) {
      toast.error(error.error);
    }
  }, [isEditCategorySuccess, isError]);

  function handleImageUpload(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      setImage(reader.result);
    };
  }

  const handleCategoryAdd = (e) => {
    addCategory({
      name,
      image,
    });
  };

  const handleCategoryEdit = async (e) => {
    await editCategoryOperation({
      id: categoryObj?.id,
      data: {
        name,
        image,
      },
    });
  };

  return (
    <>
      <div className="w-1/3">
        <div className="bg-white rounded-md shadow-xl ">
          <p className="px-8 py-4 font-semibold">
            {categoryObj?.name ? "Update" : "Add"} Category
          </p>
          <div className="border-b border-b-gray-200"></div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Category Name <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Category Image <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <InputFile onChange={handleImageUpload} />
                </div>
                {image && (
                  <img
                    src={image}
                    alt="Uploaded image"
                    className="w-full h-auto pt-4"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="pb-8 pr-8 text-right">
            {categoryObj?.name ? (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryEdit}
              >
                Update
              </button>
            ) : (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryAdd}
              >
                Save
              </button>
            )}
          </div>
        </div>
        <Toast />
      </div>
    </>
  );
}
