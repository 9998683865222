import React, { useState } from "react";
import VoucherTable from "./VoucherTable";
import AddVoucher from "./AddVoucher";

export default function VoucherComponent() {
  let [categoryObj, setCategoryObj] = useState({});
  return (
    <>
      <p className="p-4 font-bold md:pl-8">All Voucher</p>
      <div className="flex flex-col p-4 mb-8  md:flex-row">
        <VoucherTable setCategoryObj={setCategoryObj} />
        <AddVoucher categoryObj={categoryObj} setCategoryObj={setCategoryObj} />
      </div>
    </>
  );
}
