import React from "react";
import CountryComponent from "./Components/CountryComponent/CountryComponent";

export default function Country() {
  return (
    <div>
      <CountryComponent />
    </div>
  );
}
