import React, { useEffect, useState } from "react";
import {
  useAddSellerOwnPaymentInfoMutation,
  useGetSellerOwnPaymentInfoQuery,
} from "../../../features/sellerOwnPaymentInfo/sellerOwnPaymentInfoApi";
import Input from "../../../Shared/Components/Input";
import Modal from "../../../Shared/Modal";
import { FaRegEdit, FaTimes, FaTrashAlt } from "react-icons/fa";
import {
  useDeleteSellerPaymentMethodMutation,
  useGetSellerPaymentMethodQuery,
} from "../../../features/SellerPaymentMethod/SellerPaymentMethodApi";
import { toast } from "react-toastify";

export default function SellerPaymentInfo({ id }) {
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [methodObj, setMethodObj] = useState({});
  const [paymentInfo, setPaymentInfo] = useState({
    seller_id: id,
    payment_method_id: "",
    method_details: {
      name: "",
      phone: "",
    },
  });

  const [
    addSellerOwnPaymentInfo,
    {
      isSuccess: isAddSellerInfoSuccess,
      isError: isOwnInfoErro,
      error: infoError,
    },
  ] = useAddSellerOwnPaymentInfoMutation();

  const {
    data: sellerPaymentInfo,
    isLoading,
    isError,
    error,
  } = useGetSellerOwnPaymentInfoQuery(id);
  const { data: paymentMethods } = useGetSellerPaymentMethodQuery(id);

  const [deleteSellerOwnPaymentInfo, { isSuccess }] =
    useDeleteSellerPaymentMethodMutation();

  useEffect(() => {
    if (isAddSellerInfoSuccess) {
      toast.success("Seller payment info added successfully");
      setPaymentInfo({
        seller_id: id,
        payment_method_id: "",
        method_details: {
          name: "",
          phone: "",
        },
      });
      setShowAddForm(false);
    }
  }, [isAddSellerInfoSuccess]);

  useEffect(() => {
    if (isSuccess) {
      setShowModal(false);
      toast.success("Successfully deleted!");
    }
  }, [isSuccess]);

  const handleAddPaymentInfo = () => {
    if (
      !paymentInfo?.seller_id ||
      !paymentInfo?.payment_method_id ||
      !paymentInfo?.method_details?.name ||
      !paymentInfo?.method_details?.phone
    ) {
      toast.error("Please fill all the required filled");
      return;
    }
    addSellerOwnPaymentInfo(paymentInfo);
  };

  const handleSellerDelete = () => {
    if (!selectedPaymentId) {
      toast.error("Please select delete option first.");
      return;
    }
    deleteSellerOwnPaymentInfo(selectedPaymentId);
  };

  console.log("methodObj", id, paymentInfo);

  let content = null;
  if (isLoading) {
    content = <div>Loading</div>;
  }
  if (!isLoading && isError) {
    content = (
      <div>
        <p className="text-red-500">{error?.error}</p>
      </div>
    );
  }

  if (!isLoading && !isError) {
    content = (
      <tbody>
        {sellerPaymentInfo?.data?.map((paymentinfo) => (
          <tr className="border-t border-[#ddd] text-left text-dark py-4">
            <td className="py-2 px-2  text-base">{paymentinfo?.method_name}</td>
            <td className="py-2 px-4  text-base">
              {paymentinfo?.method_details?.name}
            </td>
            <td className="py-2 px-4  text-base">
              {paymentinfo?.method_details?.phone}
            </td>
            <td className="py-2 px-4  text-base">
              <div className="flex space-x-2">
                {/* <div className="p-2 bg-red-200 rounded-full cursor-pointer">
                  <FaRegEdit className="fill-red-500" />
                </div> */}
                <div
                  className="p-2 bg-red-200 rounded-full cursor-pointer"
                  onClick={() => {
                    setSelectedPaymentId(paymentinfo?.id);
                    setShowModal(true);
                  }}
                >
                  <FaTrashAlt className="fill-red-500" />
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
  return (
    <>
      <div className="max-w-full overflow-x-auto max-h-[500px] overflow-scroll p-6">
        <button
          className="bg-red-500 text-white px-6 py-2 rounded-md"
          onClick={() => setShowAddForm(true)}
        >
          Add Payment info
        </button>
        <table className="w-full table-auto">
          <thead>
            <tr className="text-left text-dark font-bold">
              <th className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                Method Name:
              </th>
              <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                Name:
              </th>
              <th className="w-1/6 min-w-[260px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                Phone:
              </th>
              <th className="w-1/6 min-w-[260px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                Options:
              </th>
            </tr>
          </thead>
          {content}
        </table>
      </div>

      {/* Add Payment Modal */}
      {showAddForm && (
        <Modal show={showAddForm}>
          <div className="w-full max-w-[980px] rounded-md bg-white text-center">
            <div className="flex justify-between items-center px-8 py-4 ">
              <p className="font-semibold">Seller Payment Info </p>
              <FaTimes
                onClick={() => {
                  setShowAddForm(false);
                }}
                className="fill-gray-500 cursor-pointer"
              />
            </div>
            <div className="border-b border-b-gray-200"></div>
            <span className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"></span>

            <div className="w-full px-4 ">
              <div className="flex flex-col mb-4 md:flex-row">
                <label
                  for=""
                  className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
                >
                  Payment Method<span className="text-orange-500">*</span>
                </label>
                <div className="w-full md:w-3/4">
                  <select
                    id="selectID"
                    className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
                    onChange={(e) =>
                      setPaymentInfo({
                        ...paymentInfo,
                        payment_method_id: e.target.value,
                      })
                    }
                  >
                    <option
                      className="bg-orange-400 text-white p-4 w-full"
                      value=""
                    >
                      Select Payment Method
                    </option>
                    {paymentMethods?.data?.map((method) => (
                      <option
                        className="bg-orange-400 text-white p-4 w-full"
                        value={method?.id}
                      >
                        {method?.method_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div>
              <div className="w-full px-4">
                <div className="flex flex-col mb-4 md:flex-row">
                  <label
                    for=""
                    className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
                  >
                    Name <span className="text-orange-500">*</span>
                  </label>
                  <div className="w-full md:w-3/4">
                    <Input
                      type="text"
                      placeholder="Name"
                      value={paymentInfo?.method_details?.name}
                      onChange={(e) =>
                        setPaymentInfo({
                          ...paymentInfo,
                          method_details: {
                            ...paymentInfo?.method_details,
                            name: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="w-full px-4">
                <div className="flex flex-col mb-4 md:flex-row">
                  <label
                    for=""
                    className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
                  >
                    Phone <span className="text-orange-500">*</span>
                  </label>
                  <div className="w-full md:w-3/4">
                    <Input
                      type="tel"
                      placeholder="Phone"
                      value={paymentInfo?.method_details?.phone}
                      onChange={(e) =>
                        setPaymentInfo({
                          ...paymentInfo,
                          method_details: {
                            ...paymentInfo?.method_details,
                            phone: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="text-right pb-6 pr-4">
                <button
                  className="bg-red-500 px-4 py-2 rounded-md text-white"
                  onClick={handleAddPaymentInfo}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {/* Delete Payment Modal */}
      {showModal ? (
        <Modal show={showModal}>
          <div className="w-full max-w-[470px] rounded-md bg-white text-center">
            <div className="flex justify-between items-center px-8 py-4 ">
              <p className="font-semibold">Delete Confirmation</p>
              <FaTimes
                onClick={() => setShowModal(false)}
                className="fill-gray-500 cursor-pointer"
              />
            </div>
            <div className="border-b border-b-gray-200"></div>
            <span className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"></span>
            <p className="text-body-color mb-10 text-base leading-relaxed">
              Are you sure to delete this?
            </p>
            <div className="flex justify-center space-x-6 pb-10">
              <button
                onClick={() => setShowModal(false)}
                className="text-dark block rounded-md border border-[#E9EDF9] p-3 text-center text-base font-medium transition hover:border-red-600 hover:bg-red-600 hover:text-white"
              >
                Cancel
              </button>
              <button
                className="bg-orange-500 border-orange-500 block rounded-md border p-3 text-center text-base font-medium text-white transition hover:bg-opacity-90"
                onClick={() => {
                  handleSellerDelete();
                  setShowModal(false);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
}
