import React from "react";
import RmsGroupMenuComponent from "./Components/RmsGroupMenuComponent/RmsGroupMenuComponent";

export default function RmsGroupMenu() {
  return (
    <>
      <RmsGroupMenuComponent />
    </>
  );
}
