import React, { useEffect, useState } from "react";
import Toast from "../../../Shared/Components/Toast";
import {
  useDeleteBuyerCreateMutation,
  useGetBuyerCreateQuery,
} from "../../../features/buyerCreate/buyerCreateApi";
import { toast } from "react-toastify";
import { FaEye, FaRegEdit, FaTimes, FaTrashAlt } from "react-icons/fa";
import Input from "../../../Shared/Components/Input";
import Modal from "../../../Shared/Modal";
import EditOption from "../../../Shared/Components/EditOption";
import DeleteConfirmation from "../../../Shared/Components/DeleteConfirmation";

export default function BuyerTable({ setPaymentMethodObj }) {
  let [showModal, setShowModal] = useState(false);
  let [selectedId, setSelectedId] = useState("");
  let [showOption, setShowOption] = useState("");

  const {
    data: allBuyers,
    isLoading,
    isError,
    error,
  } = useGetBuyerCreateQuery();
  const [deleteBuyerCreate, { isSuccess }] = useDeleteBuyerCreateMutation();

  console.log("error", error);

  useEffect(() => {
    if (isSuccess) {
      setShowModal(false);
      toast.success("Successfully delted!");
    }
  }, [isSuccess]);

  const handleSellerDelete = () => {
    if (!selectedId) {
      toast.error("Please select delete option first.");
      return;
    }
    deleteBuyerCreate(selectedId);
  };

  let content = null;
  if (isLoading) {
    content = <div>Loading</div>;
  }
  if (!isLoading && isError) {
    content = (
      <div>
        <p className="text-red-500">{error?.error}</p>
      </div>
    );
  }

  if (!isLoading && !isError) {
    content = (
      <tbody>
        {allBuyers?.data?.map((buyer) => (
          <tr className="border-t border-[#ddd] text-left text-dark py-4">
            <td className="py-2 px-2  text-base">
              <div className="flex items-center">
                <p>{buyer?.name}</p>
              </div>
            </td>
            <td className="py-2 px-2  text-base">{buyer?.number}</td>
            <td className="py-2 px-2  text-base">{buyer?.email}</td>
            <td className="py-2 px-2  text-base">{buyer?.address}</td>
            <td className="py-2 px-2  text-base">{buyer?.city}</td>
            <td className="py-2 px-2  text-base">{buyer?.postcode}</td>
            <td className="y-2 px-2  text-base">
              <img src={buyer?.image} className="w-20 h-20" alt="Image" />
            </td>
            <td className="y-2 px-2  text-base">
              <div className="flex space-x-2">
                {/* <div
                  className="p-2 bg-green-200 rounded-full cursor-pointer"
                  onClick={() => {
                    setSelectedId(buyer?.id);
                    setShowOption(true);
                  }}
                >
                  <FaEye className="fill-green-500" />
                </div> */}

                <EditOption
                  onClick={() => {
                    setPaymentMethodObj(buyer);
                  }}
                />
                <div
                  className="p-2 bg-red-200 rounded-full cursor-pointer"
                  onClick={() => {
                    setSelectedId(buyer?.id);
                    setShowModal(true);
                  }}
                >
                  <FaTrashAlt className="fill-red-500" />
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }

  return (
    <>
      <section className="bg-white shadow-xl rounded-md m-4 py-6 px-4 overflow-hidden lg:py-8">
        <div className="flex flex-col justify-between items-center pb-4 px-4 md:flex-row">
          <p className="w-1/4px-8 py-4 font-semibold">Buyers Information</p>
          {/* <div className="w-full md:w-3/4 flex flex-col items-center space-x-0 space-y-2 md:flex-row md:space-x-2 md:space-y-0 ">
            <div className="w-full md:w-1/4">
              <Input type="text" placeholder="Type & Enter" />
            </div>
            <div className="w-full md:w-1/4">
              <select
                id="selectID"
                className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
              >
                <option className="bg-orange-400 text-white p-4">
                  All Sellers
                </option>
                <option className="bg-orange-400 text-white p-4">
                  Steve Smith
                </option>
                <option className="bg-orange-400 text-white p-4">
                  Ailster Cook
                </option>
              </select>
            </div>
            <div className="w-full md:w-1/4">
              <select
                id="selectID"
                className="w-full border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
              >
                <option className="bg-orange-400 text-white p-4">
                  Sort By
                </option>
                <option className="bg-orange-400 text-white p-4">{`Rating(High < Low)`}</option>
                <option className="bg-orange-400 text-white p-4">{`Rating(Low < High)`}</option>
                <option className="bg-orange-400 text-white p-4">{`Num of Sale(Low < High)`}</option>
                <option className="bg-orange-400 text-white p-4">{`Num of Sale(High < Low)`}</option>
              </select>
            </div>
            <div className="w-full md:w-1/4">
              <select
                id="selectID"
                className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
              >
                <option className="bg-orange-400 text-white p-4">
                  Sort By
                </option>
                <option className="bg-orange-400 text-white p-4">{`Rating(High < Low)`}</option>
                <option className="bg-orange-400 text-white p-4">{`Rating(Low < High)`}</option>
                <option className="bg-orange-400 text-white p-4">{`Num of Sale(Low < High)`}</option>
                <option className="bg-orange-400 text-white p-4">{`Num of Sale(High < Low)`}</option>
              </select>
            </div>
          </div> */}
        </div>
        <div className="border-b border-b-gray-200"></div>
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4 mx-4">
            <div className="max-w-full overflow-x-auto">
              <table className="w-full table-auto">
                <thead>
                  <tr className="text-left text-dark font-bold">
                    <th className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Name
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Number
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Email
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Address
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      City
                    </th>
                    <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      PostCode
                    </th>
                    <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Image
                    </th>
                    <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Options
                    </th>
                  </tr>
                </thead>
                {content}
              </table>
            </div>
          </div>
        </div>
        <Toast />
      </section>
      {/* Delete Modal */}
      {showModal ? (
        <Modal show={showModal}>
          <DeleteConfirmation
            handleDelete={handleSellerDelete}
            setShowModal={setShowModal}
          />
        </Modal>
      ) : null}
    </>
  );
}
