import { apiSlice } from "../api/apiSlice";

export const wirehouseProductApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInternationalProduct: builder.query({
      query: ({ id }) => `/wirehouse/receive_order/${id}`,
      providesTags: ["wirehouseOrder"],
    }),
    getWirehouseOrderTrack: builder.mutation({
      query: (data) => {
        console.log("data", data);
        return {
          url: "wirehouse/check_in/check",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["wirehouseOrder"],
    }),
    acceptProduct: builder.mutation({
      query: ({ id, data }) => ({
        url: `/wirehouse/check_in/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["wirehouseOrder"],
    }),
    sendProductWorldwide: builder.mutation({
      query: ({ id, data }) => ({
        url: `/wirehouse/check_in/send_shipment/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["wirehouseOrder"],
    }),
  }),
});

export const {
  useGetInternationalProductQuery,
  useGetWirehouseOrderTrackMutation,
  useAcceptProductMutation,
  useSendProductWorldwideMutation,
} = wirehouseProductApi;
