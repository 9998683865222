import React from "react";
import { useGetAllWirehouseQuery } from "../../../../features/wirehouseApi/wirehouseListApi";

export default function WirehouseListComponent() {
  const {
    data: allWirehouse,
    isLoading,
    isError,
    error,
  } = useGetAllWirehouseQuery();

  let content = null;

  if (isLoading) {
    content = <div>Loading</div>;
  }
  if (!isLoading && isError) {
    content = <div>{error?.error}</div>;
  }

  if (!isLoading && !isError) {
    content = (
      <tbody>
        {allWirehouse?.data?.map((country) => (
          <tr className="border-t border-[#ddd] text-left text-dark py-4">
            <td className="py-2 px-2  text-base">
              <div className="flex items-center">{country?.id}</div>
            </td>
            <td className="py-2 px-2  text-base">{country?.country}</td>
            <td className="py-2 px-2  text-base">{country?.name}</td>
            <td className="py-2 px-2  text-base">{country?.address}</td>

            <td className="py-2 px-2  text-base">{country?.number}</td>
            <td className="py-2 px-2  text-base">{country?.city}</td>
            <td className="py-2 px-2  text-base">{country?.postcode}</td>
            <td className="py-2 px-2  text-base">{country?.payment_method}</td>
            <td className="y-2 px-2  text-base">
              <div className="flex space-x-2 cursor-pointer">
                {/* <div className="p-2 bg-red-200 rounded-full">
                      <FaRegEdit
                        className="fill-red-500"
                        onClick={() => setCategoryObj(country)}
                      />
                    </div> */}
                {/* <div className="p-2 bg-red-200 rounded-full cursor-pointer">
                      <FaTrashAlt
                        className="fill-red-500"
                        onClick={() => handleDeleteCategory(country?.id)}
                      />
                    </div> */}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
  return (
    <div className="w-full my-10 overflow-x-hidden">
      <section className="bg-white shadow-xl rounded-md mb-4 m-0 md:m-4 py-6 lg:my-0 lg:py-8">
        <div className="flex flex-col justify-between items-center pb-4 px-4 md:flex-row">
          <p className="w-3/4 px-8 py-4 font-semibold">All Wirehouses</p>
        </div>
        <div className="border-b border-b-gray-200"></div>
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4 mx-4">
            <div className="max-w-full overflow-x-auto">
              <table className="w-full table-auto">
                <thead>
                  <tr className="text-left text-dark font-bold">
                    <th className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      #
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Country
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Name
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Address
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Number
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      City
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Post Code
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Payment Method
                    </th>
                    {/* <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Options
                    </th> */}
                  </tr>
                </thead>
                {content}
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
