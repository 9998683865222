import { apiSlice } from "../api/apiSlice";

export const flatDiscountApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFlatDiscount: builder.query({
      query: () => `/admin/discount/flat`,
      providesTags: ["flatDiscount"],
    }),

    addFlatDiscount: builder.mutation({
      query: (data) => ({
        url: "/admin/discount/flat/add",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["flatDiscount"],
    }),

    editFlatDiscount: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/discount/flat/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["flatDiscount"],
    }),
  }),
});

export const {
  useGetFlatDiscountQuery,
  useAddFlatDiscountMutation,
  useEditFlatDiscountMutation,
} = flatDiscountApi;
