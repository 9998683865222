import React, { useState } from "react";
import FeaturedShopTable from "./FeaturedShopTable";
import AddFeaturedShop from "./AddFeaturedShop";

export default function FeaturedShopComponent() {
  let [flatDiscountObj, setFlatDiscountObj] = useState({});
  return (
    <>
      <p className="p-4 font-bold md:pl-8">All Featured Shop</p>
      <div className="flex flex-col p-4 mb-8  md:flex-row">
        <FeaturedShopTable setFlatDiscountObj={setFlatDiscountObj} />
        <AddFeaturedShop
          setFlatDiscountObj={setFlatDiscountObj}
          flatDiscountObj={flatDiscountObj}
        />
      </div>
    </>
  );
}
