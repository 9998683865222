import React from 'react'
import Input from '../../../Shared/Components/Input'
import InputFile from '../../../Shared/Components/InputFile'

export default function AddDeliveryComponent() {
    return (
        <div className='max-w-6xl mx-auto bg-white rounded-md shadow-xl mt-8'>
            <p className='px-8 py-4 font-semibold'>Delivery Boy Information</p>
            <div className='border-b border-b-gray-200'></div>
            <div className="flex flex-col p-8 pb-0">
                <div className="w-full px-4 w-full">
                    <div className="flex flex-col mb-4 md:flex-row">
                        <label for="" className="w-full md:w-1/4 mb-3 block text-base text-black font-light">
                            Name <span className='text-orange-500'>*</span>
                        </label>
                        <div className='w-full md:w-3/4'>
                            <Input type="text" placeholder="Name" />
                        </div>
                    </div>
                </div>
                <div className="w-full px-4 w-full">
                    <div className="flex flex-col mb-4 md:flex-row">
                        <label for="" className="w-full md:w-1/4 mb-3 block text-base text-black font-light">
                            Email <span className='text-orange-500'>*</span>
                        </label>
                        <div className='w-full md:w-3/4'>
                            <Input type="email" placeholder="Email" />
                        </div>
                    </div>
                </div>
                <div className="w-full px-4 w-full">
                    <div className="flex flex-col mb-4 md:flex-row">
                        <label for="" className="w-full md:w-1/4 mb-3 block text-base text-black font-light">
                            Phone <span className='text-orange-500'>*</span>
                        </label>
                        <div className='w-full md:w-3/4'>
                            <Input type="number" placeholder="Phone" />
                        </div>
                    </div>
                </div>
                <div className="w-full px-4 w-full">
                    <div className="flex flex-col mb-4 md:flex-row">
                        <label for="" className="w-full md:w-1/4 mb-3 block text-base text-black font-light">
                            Country <span className='text-orange-500'>*</span>
                        </label>
                        <select id="selectID" className='w-full md:w-3/4  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary w-full rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500'>
                            <option className='bg-orange-400 text-white p-4'>Bangladesh</option>
                            <option className='bg-orange-400 text-white p-4'>Nepal</option>
                            <option className='bg-orange-400 text-white p-4'>China</option>
                        </select>
                    </div>
                </div>

                <div className="w-full px-4 w-full">
                    <div className="flex flex-col mb-4 md:flex-row">
                        <label for="" className="w-full md:w-1/4 mb-3 block text-base text-black font-light">
                            State
                        </label>
                        <div className='w-full md:w-3/4'>
                            <Input type="text" placeholder="State" />
                        </div>
                    </div>
                </div>

                <div className="w-full px-4 w-full">
                    <div className="flex flex-col mb-4 md:flex-row">
                        <label for="" className="w-full md:w-1/4 mb-3 block text-base text-black font-light">
                            City
                        </label>
                        <div className='w-full md:w-3/4'>
                            <Input type="text" placeholder="City" />
                        </div>
                    </div>
                </div>

                <div className="w-full px-4 w-full">
                    <div className="flex flex-col mb-4 md:flex-row">
                        <label for="" className="w-full md:w-1/4 mb-3 block text-base text-black font-light">
                            Image
                        </label>
                        <div className='w-full md:w-3/4'>
                            <InputFile />
                        </div>
                    </div>
                </div>

                <div className="w-full px-4 w-full">
                    <div className="flex flex-col mb-4 md:flex-row">
                        <label for="" className="w-full md:w-1/4 mb-3 block text-base text-black font-light">
                            Address
                        </label>
                        <div className="w-full md:w-3/4 ">
                            <textarea
                                rows="5"
                                placeholder="Active textarea"
                                class="border-primary text-body-color placeholder-body-color focus:border-primary active:border-primary w-full rounded-lg border-[1.5px] py-3 px-5 font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD]"
                            ></textarea>
                        </div>
                    </div>
                </div>

            </div>
            <div className='pb-8 pr-8 text-right'>
                <button className='bg-orange-500 text-white py-2 px-8 rounded-md mr-4'>Save</button>
            </div>
        </div>
    )
}
