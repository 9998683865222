import React, { useEffect, useState } from "react";
import {
  useDeleteBuyersPaymentMethodMutation,
  useGetBuyersPaymentMethodQuery,
} from "../../../features/buyersPaymentMethod/buyersPaymentMethodApi";
import { toast } from "react-toastify";
import { FaRegEdit, FaTrashAlt } from "react-icons/fa";
import EditOption from "../../../Shared/Components/EditOption";
import Modal from "../../../Shared/Modal";
import DeleteConfirmation from "../../../Shared/Components/DeleteConfirmation";

export default function BuyersPaymentMethodTable({ setCategoryObj }) {
  const {
    data: allCountry,
    isLoading,
    isError,
    error,
  } = useGetBuyersPaymentMethodQuery();

  let [selectedId, setSelectedId] = useState(null);
  let [showModal, setShowModal] = useState(false);

  const [deleteBuyersPaymentMethod, { isSuccess }] =
    useDeleteBuyersPaymentMethodMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Successfully buyers payment method deleted");
    }
  }, [isSuccess]);

  const handleDeleteCategory = (id) => {
    deleteBuyersPaymentMethod(selectedId);
  };
  let content = null;

  if (isLoading) {
    content = <div>Loading</div>;
  }
  if (!isLoading && isError) {
    content = <div>{error?.error}</div>;
  }

  if (!isLoading && !isError) {
    content = (
      <tbody>
        {allCountry?.data?.map((country) => (
          <tr className="border-t border-[#ddd] text-left text-dark py-4">
            <td className="py-2 px-2  text-base">
              <div className="flex items-center">{country?.id}</div>
            </td>
            <td className="py-2 px-2  text-base">{country?.name}</td>
            <td className="py-2 px-2  text-base">{country?.extra_note}</td>
            <td className="py-2 px-2  text-base">{country?.country}</td>
            <td className="py-2 px-2  text-base flex flex-col gap-2">
              {country?.details?.map((detail) => (
                <div className="bg-gray-200 px-4 py-2 rounded-md ">
                  {detail}
                </div>
              ))}
            </td>
            <td className="py-2 px-2  text-base">
              <div className="flex space-x-2">
                <img
                  src={country?.logo}
                  alt={country?.name}
                  className="w-20 h-auto"
                />
              </div>
            </td>

            <td className="y-2 px-2  text-base">
              <div className="flex space-x-2 cursor-pointer">
                <EditOption onClick={() => setCategoryObj(country)} />
                <div className="p-2 bg-red-200 rounded-full cursor-pointer">
                  <FaTrashAlt
                    className="fill-red-500"
                    onClick={() => {
                      setSelectedId(country?.id);
                      setShowModal(true);
                    }}
                  />
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
  return (
    <>
      <div className="w-full md:w-2/3 overflow-x-hidden">
        <section className="bg-white shadow-xl rounded-md mb-4 m-0 md:m-4 py-6 lg:my-0 lg:py-8">
          <div className="flex flex-col justify-between items-center pb-4 px-4 md:flex-row">
            <p className="w-3/4 px-8 py-4 font-semibold">
              All Buyers Payment Method
            </p>
          </div>
          <div className="border-b border-b-gray-200"></div>
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4 mx-4">
              <div className="max-w-full overflow-x-auto">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="text-left text-dark font-bold">
                      <th className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        #
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Name
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Extra Note
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Country
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Details
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Logo
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Options
                      </th>
                    </tr>
                  </thead>
                  {content}
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* Delete Modal */}
      {showModal ? (
        <Modal show={showModal}>
          <DeleteConfirmation
            handleDelete={handleDeleteCategory}
            setShowModal={setShowModal}
          />
        </Modal>
      ) : null}
    </>
  );
}
