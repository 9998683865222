import { apiSlice } from "../api/apiSlice";

export const dashboardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardData: builder.query({
      query: () => `/admin/dashboard`,
      providesTags: ["dashboard"],
    }),
  }),
});

export const { useGetDashboardDataQuery } = dashboardApi;
