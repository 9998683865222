import React, { useState } from "react";
// import logo from "../assets/Images/tunibibi.png";
import logo from "../assets/Images/tunibibi_transparent.png";
import {
  FaHome,
  FaCartPlus,
  FaMoneyBillAlt,
  FaPeopleArrows,
  FaUserAlt,
  FaRegFileAlt,
  FaAlignLeft,
  FaAngleDown,
  FaAngleRight,
} from "react-icons/fa";
import {
  HiOutlineViewGrid,
  HiOutlineBriefcase,
  HiOutlineLibrary,
  HiOutlineArchive,
  HiOutlineAdjustments,
  HiOutlineCollection,
  HiOutlineChartSquareBar,
  HiOutlineDesktopComputer,
  HiOutlineStatusOnline,
  HiOutlineLocationMarker,
  HiUserGroup,
  HiOutlineUsers,
  HiAdjustments,
  HiOutlineCalculator,
  HiOutlinePuzzle,
  HiLogout,
} from "react-icons/hi";

import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  buyersRouteRoute,
  categoryRoute,
  deliveryBoyRoute,
  productRoute,
  reportsRoute,
  rmsRoute,
} from "./RouteData";
import { userLoggedOut } from "../features/auth/authSLice";
import { useGetRmsCheckMyPermissionQuery } from "../features/rmsCheckMyPermission/rmsCheckMyPermissionApi";

export default function Sidebar({ showSidebar, setShowSidebar }) {
  let [productDropdown, setProductDropdown] = useState(false);
  let [buyersDropdown, setBuyersDropdown] = useState(false);
  let [categoryDropdown, setCategoryDropdown] = useState(false);
  let [deliveryBoyDropdown, setDeliveryBoyDropdown] = useState(false);
  let [customersDropdown, setCustomersDropdown] = useState(false);
  let [reportsDropdown, setReportsDropdown] = useState(false);
  let [systemDropdown, setSystemDropdown] = useState(false);
  let [posDropdown, setPosDropdown] = useState(false);
  let [upload, setUpload] = useState(false);
  let [rmsDropdown, setRmsDropdown] = useState(false);

  const auth = useSelector((state) => state.auth);

  const {
    data: checkMyPermission,
    isLoading,
    isError,
    error,
  } = useGetRmsCheckMyPermissionQuery();

  console.log("checkMyPermission", checkMyPermission);
  return (
    <div
      className={`sticky top-0 left-0 z-50 bg-gray-900 md:min-w-[270px] md:max-w-[270px] h-screen overflow-y-scroll scrollbar 
      ${showSidebar ? "" : "hidden"}
      `}
    >
      <img src={logo} alt="logo" className="w-full px-4 bg-gray-800" />
      {/* <h2 className='text-white text-4xl uppercase font-bol text-center py-4'>Tunnibibi</h2> */}
      <div>
        <NavLink
          className={({ isActive }) =>
            isActive ? "text-white font-bold" : "text-gray-400"
          }
          to="/"
          // onClick={() => setShowSidebar(false)}
        >
          <div className="flex items-center space-x-4 p-4 hover:bg-gray-800 cursor-pointer">
            <div className="flex space-x-4 items-center">
              <HiOutlineViewGrid className="w-6 h-6" />
              <p>Dashboard</p>
            </div>
          </div>
        </NavLink>

        {auth?.userType === "SUPER ADMIN" ? (
          <>
            <div
              onClick={() => {
                setProductDropdown(!productDropdown);
              }}
              className="flex items-center justify-between space-x-4 p-4  text-gray-400 hover:bg-gray-800 cursor-pointer"
            >
              <div className="flex space-x-4 items-center">
                <HiOutlineArchive className="w-6 h-6" />
                <p>Products</p>
              </div>
              {productDropdown ? <FaAngleDown /> : <FaAngleRight />}
            </div>

            {/* Dropdown */}
            {productDropdown ? (
              <>
                {productRoute?.map((route) => (
                  <NavLink
                    to={`${route?.url}`}
                    className={({ isActive }) =>
                      isActive ? "text-white font-bold" : "text-gray-400"
                    }
                    // onClick={() => setShowSidebar(false)}
                  >
                    <div className="flex items-center space-x-4 p-4  hover:bg-gray-800 cursor-pointer pl-8">
                      <div className="w-2 h-2 rounded-full bg-gray-400"></div>
                      <p>{route?.name}</p>
                    </div>
                  </NavLink>
                ))}
              </>
            ) : null}

            <div
              onClick={() => {
                setBuyersDropdown(!buyersDropdown);
              }}
              className="flex items-center justify-between space-x-4 p-4  text-gray-400 hover:bg-gray-800 cursor-pointer"
            >
              <div className="flex space-x-4 items-center">
                <HiOutlineLibrary className="w-6 h-6" />
                <p>Buyers</p>
              </div>
              {buyersDropdown ? <FaAngleDown /> : <FaAngleRight />}
            </div>

            {/* Dropdown */}
            {buyersDropdown ? (
              <>
                {buyersRouteRoute?.map((route) => (
                  <NavLink
                    to={`${route?.url}`}
                    className={({ isActive }) =>
                      isActive ? "text-white font-bold" : "text-gray-400"
                    }
                    // onClick={() => setShowSidebar(false)}
                  >
                    <div className="flex items-center space-x-4 p-4  hover:bg-gray-800 cursor-pointer pl-8">
                      <div className="w-2 h-2 rounded-full bg-gray-400"></div>
                      <p>{route?.name}</p>
                    </div>
                  </NavLink>
                ))}
              </>
            ) : null}

            <div
              onClick={() => {
                setRmsDropdown(!rmsDropdown);
              }}
              className="flex items-center justify-between space-x-4 p-4   text-gray-400 hover:bg-gray-800 cursor-pointer"
            >
              <div className="flex space-x-4 items-center">
                <HiOutlineAdjustments className="w-6 h-6" />
                <p>RMS</p>
              </div>
              {rmsDropdown ? <FaAngleDown /> : <FaAngleRight />}
            </div>

            {/* Dropdown */}
            {rmsDropdown ? (
              <>
                {rmsRoute?.map((route) => (
                  <NavLink
                    to={`${route?.url}`}
                    className={({ isActive }) =>
                      isActive ? "text-white font-bold" : "text-gray-400"
                    }
                    // onClick={() => setShowSidebar(false)}
                  >
                    <div className="flex items-center space-x-4 p-4  hover:bg-gray-800 cursor-pointer pl-8">
                      <div className="w-2 h-2 rounded-full bg-gray-400"></div>
                      <p>{route?.name}</p>
                    </div>
                  </NavLink>
                ))}
              </>
            ) : null}

            <div
              onClick={() => {
                setCategoryDropdown(!categoryDropdown);
              }}
              className="flex items-center justify-between space-x-4 p-4  text-gray-400 hover:bg-gray-800 cursor-pointer"
            >
              <div className="flex space-x-4 items-center">
                <HiOutlineCollection className="w-6 h-6" />
                <p>Category</p>
              </div>
              {categoryDropdown ? <FaAngleDown /> : <FaAngleRight />}
            </div>

            {/* Dropdown */}
            {categoryDropdown ? (
              <>
                {categoryRoute?.map((route) => (
                  <NavLink
                    to={`${route?.url}`}
                    className={({ isActive }) =>
                      isActive ? "text-white font-bold" : "text-gray-400"
                    }
                    // onClick={() => setShowSidebar(false)}
                  >
                    <div className="flex items-center space-x-4 p-4   hover:bg-gray-800 cursor-pointer pl-8">
                      <div className="w-2 h-2 rounded-full bg-gray-500"></div>
                      <p>{route?.name}</p>
                    </div>
                  </NavLink>
                ))}
              </>
            ) : null}

            <div
              onClick={() => {
                setReportsDropdown(!reportsDropdown);
              }}
              className="flex items-center justify-between space-x-4 p-4  text-gray-400 hover:bg-gray-800 cursor-pointer"
            >
              <div className="flex space-x-4 items-center">
                <HiOutlineChartSquareBar className="w-6 h-6" />
                <p>Reports</p>
              </div>
              {reportsDropdown ? <FaAngleDown /> : <FaAngleRight />}
            </div>
            {/* Reports Dropdown */}
            {reportsDropdown ? (
              <>
                {reportsRoute?.map((route) => (
                  <NavLink
                    to={`${route?.url}`}
                    className={({ isActive }) =>
                      isActive ? "text-white font-bold" : "text-gray-400"
                    }
                    // onClick={() => setShowSidebar(false)}
                  >
                    <div className="flex items-center space-x-4 p-4   hover:bg-gray-800 cursor-pointer pl-8">
                      <div className="w-2 h-2 rounded-full bg-gray-400"></div>
                      <p>{route?.name}</p>
                    </div>
                  </NavLink>
                ))}
              </>
            ) : null}

            <NavLink
              className={({ isActive }) =>
                isActive ? "text-white font-bold" : "text-gray-400"
              }
              to="/banner"
              // onClick={() => setShowSidebar(false)}
            >
              <div className="flex items-center space-x-4 p-4   hover:bg-gray-800 cursor-pointer">
                <div className="flex space-x-4 items-center">
                  <HiOutlineDesktopComputer className="w-6 h-6" />
                  <p>Banner</p>
                </div>
              </div>
            </NavLink>

            <NavLink
              className={({ isActive }) =>
                isActive ? "text-white font-bold" : "text-gray-400"
              }
              to="/lives"
              // onClick={() => setShowSidebar(false)}
            >
              <div className="flex items-center space-x-4 p-4   hover:bg-gray-800 cursor-pointer">
                <div className="flex space-x-4 items-center">
                  <HiOutlineStatusOnline className="w-6 h-6" />
                  <p>Lives</p>
                </div>
              </div>
            </NavLink>

            <NavLink
              className={({ isActive }) =>
                isActive ? "text-white font-bold" : "text-gray-400"
              }
              to="/address"
              // onClick={() => setShowSidebar(false)}
            >
              <div className="flex items-center space-x-4 p-4  hover:bg-gray-800 cursor-pointer">
                <div className="flex space-x-4 items-center">
                  <HiOutlineLocationMarker className="w-6 h-6" />
                  <p>Address</p>
                </div>
              </div>
            </NavLink>

            {/* <div
              onClick={() => {
                setDeliveryBoyDropdown(!deliveryBoyDropdown);
              }}
              className="flex items-center justify-between space-x-4 p-4  text-gray-400 hover:bg-gray-800 cursor-pointer"
            >
              <div className="flex space-x-4 items-center">
                <HiOutlineUsers className="w-6 h-6" />
                <p>Delivery Boy</p>
              </div>
              {deliveryBoyDropdown ? <FaAngleDown /> : <FaAngleRight />}
            </div>
            {deliveryBoyDropdown ? (
              <>
                {deliveryBoyRoute?.map((route) => (
                  <NavLink
                    to={`${route?.url}`}
                    className={({ isActive }) =>
                      isActive ? "text-white font-bold" : "text-gray-400"
                    }
                    // onClick={() => setShowSidebar(false)}
                  >
                    <div className="flex items-center space-x-4 p-4  hover:bg-gray-800 cursor-pointer pl-8">
                      <div className="w-2 h-2 rounded-full bg-gray-500"></div>
                      <p>{route?.name}</p>
                    </div>
                  </NavLink>
                ))}
              </>
            ) : null} */}
            {/* 
            <div
              onClick={() => {
                setCustomersDropdown(!customersDropdown);
              }}
              className="flex items-center justify-between space-x-4 p-4  text-gray-400 hover:bg-gray-800 cursor-pointer"
            >
              <div className="flex space-x-4 items-center">
                <HiUserGroup className="w-6 h-6" />
                <p>Customers</p>
              </div>
              {customersDropdown ? <FaAngleDown /> : <FaAngleRight />}
            </div>
            {customersDropdown ? (
              <>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "text-white font-bold" : "text-gray-400"
                  }
                  to="/customer-packages"
                  // onClick={() => setShowSidebar(false)}
                >
                  <div className="flex items-center space-x-4 p-4  text-white hover:bg-gray-800 cursor-pointer pl-8">
                    <div className="w-2 h-2 rounded-full bg-gray-400"></div>
                    <p>Classified Packages</p>
                  </div>
                </NavLink>
              </>
            ) : null} */}

            {/* <div
              onClick={() => {
                setSystemDropdown(!systemDropdown);
              }}
              className="flex items-center justify-between space-x-4 p-4  text-gray-400 hover:bg-gray-800 cursor-pointer"
            >
              <div className="flex space-x-4 items-center">
                <HiAdjustments className="w-6 h-6" />
                <p>System</p>
              </div>
              {systemDropdown ? <FaAngleDown /> : <FaAngleRight />}
            </div>
            {systemDropdown ? (
              <>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "text-white font-bold" : "text-gray-400"
                  }
                  to="/system/updates"
                  // onClick={() => setShowSidebar(false)}
                >
                  <div className="flex items-center space-x-4 p-4  hover:bg-gray-800 cursor-pointer pl-8">
                    <div className="w-2 h-2 rounded-full bg-gray-400"></div>
                    <p>Updates</p>
                  </div>
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "text-white font-bold" : "text-gray-400"
                  }
                  to="/system/server-status"
                  // onClick={() => setShowSidebar(false)}
                >
                  <div className="flex items-center space-x-4 p-4  hover:bg-gray-800 cursor-pointer pl-8">
                    <div className="w-2 h-2 rounded-full bg-gray-400"></div>
                    <p>Server Status</p>
                  </div>
                </NavLink>
              </>
            ) : null} */}

            {/* <div
              onClick={() => {
                setPosDropdown(!posDropdown);
              }}
              className="flex items-center justify-between space-x-4 p-4  text-gray-400 hover:bg-gray-800 cursor-pointer"
            >
              <div className="flex space-x-4 items-center">
                <HiOutlineCalculator className="w-6 h-6" />
                <p>Pos System</p>
              </div>
              {posDropdown ? <FaAngleDown /> : <FaAngleRight />}
            </div>
            {posDropdown ? (
              <>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "text-white font-bold" : "text-gray-400"
                  }
                  to="/pos"
                  // onClick={() => setShowSidebar(false)}
                >
                  <div className="flex items-center space-x-4 p-4  hover:bg-gray-800 cursor-pointer pl-8">
                    <div className="w-2 h-2 rounded-full bg-gray-400"></div>
                    <p>Pos Manager</p>
                  </div>
                </NavLink>
              </>
            ) : null} */}

            {/* <NavLink
              className={({ isActive }) =>
                isActive ? "text-white font-bold" : "text-gray-400"
              }
              to="/add-product"
              // onClick={() => {
              //   setShowSidebar(false);
              // }}
            >
              <div className="flex items-center space-x-4 p-4   hover:bg-gray-800 cursor-pointer">
                <div className="flex space-x-4 items-center">
                  <div className="flex space-x-4 items-center">
                    <FaCartPlus className="w-6 h-6" />
                    <p>Add Products</p>
                  </div>
                </div>
              </div>
            </NavLink> */}

            {/* <NavLink
              className={({ isActive }) =>
                isActive ? "text-white font-bold" : "text-gray-400"
              }
              to="/addons"
              // onClick={() => setShowSidebar(false)}
            >
              <div className="flex items-center space-x-4 p-4   hover:bg-gray-800 cursor-pointer">
                <div className="flex space-x-4 items-center">
                  <HiOutlinePuzzle className="w-6 h-6" />
                  <p>Addon Manager</p>
                </div>
              </div>
            </NavLink> */}

            {/* <div className="flex items-center space-x-4 p-4  text-gray-400 hover:bg-gray-800 cursor-pointer">
              <div className="flex space-x-4 items-center">
                <FaPeopleArrows />
                <p>Customers</p>
              </div>
            </div> */}
            {/* <div className="flex items-center space-x-4 p-4  text-gray-400 hover:bg-gray-800 cursor-pointer">
              <div className="flex space-x-4 items-center">
                <FaPeopleArrows className="w-6 h-6" />
                <p>Sellers</p>
              </div>
            </div>

            <div className="flex items-center justify-between space-x-4 p-4  text-gray-400 hover:bg-gray-800 cursor-pointer">
              <div className="flex space-x-4 items-center">
                <HiOutlineCalculator className="w-6 h-6" />
                <p>Pos System</p>
              </div>
            </div> */}

            {/* <div
              onClick={() => {
                setUpload(!upload);
              }}
              className="flex items-center justify-between space-x-4 p-4  text-gray-400 hover:bg-gray-800 cursor-pointer"
            >
              <div className="flex space-x-4 items-center">
                <FaRegFileAlt className="w-6 h-6" />
                <p>Uploaded Files</p>
              </div>
              {upload ? <FaAngleDown /> : <FaAngleRight />}
            </div>
            {upload ? (
              <>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "text-white font-bold" : "text-gray-400"
                  }
                  to="/attributes"
                  // onClick={() => setShowSidebar(false)}
                >
                  <div className="flex items-center space-x-4 p-4  hover:bg-gray-800 cursor-pointer pl-8">
                    <div className="w-2 h-2 rounded-full bg-gray-400"></div>
                    <p>Attributes</p>
                  </div>
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "text-white font-bold" : "text-gray-400"
                  }
                  to="/bulk-import"
                  // onClick={() => setShowSidebar(false)}
                >
                  <div className="flex items-center space-x-4 p-4  text-grayk-400 hover:bg-gray-800 cursor-pointer pl-8">
                    <div className="w-2 h-2 rounded-full bg-gray-400"></div>
                    <p>Buld Import</p>
                  </div>
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "text-white font-bold" : "text-gray-400"
                  }
                  to="/sales"
                  // onClick={() => setShowSidebar(false)}
                >
                  <div className="flex items-center space-x-4 p-4  hover:bg-gray-800 cursor-pointer pl-8">
                    <div className="w-2 h-2 rounded-full bg-gray-400"></div>
                    <p>Sales</p>
                  </div>
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "text-white font-bold" : "text-gray-400"
                  }
                  to="/add-product"
                  // onClick={() => setShowSidebar(false)}
                >
                  <div className="flex items-center space-x-4 p-4  hover:bg-gray-800 cursor-pointer pl-8">
                    <div className="w-2 h-2 rounded-full bg-gray-400"></div>
                    <p>Add Sale</p>
                  </div>
                </NavLink>
              </>
            ) : null} */}
          </>
        ) : null}

        {auth?.userType !== "SUPER ADMIN" &&
          checkMyPermission?.data?.map((route) => (
            <NavLink
              to={`${route?.link}`}
              className={({ isActive }) =>
                isActive ? "text-white font-bold" : "text-gray-400"
              }
              // onClick={() => setShowSidebar(false)}
            >
              <div className="flex items-center space-x-4 p-4  hover:bg-gray-800 cursor-pointer">
                <div className="flex space-x-4 items-center">
                  <FaHome className="w-6 h-6" />
                  <p>{route?.name}</p>
                </div>
              </div>
            </NavLink>
          ))}
      </div>
    </div>
  );
}
