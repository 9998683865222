import React from "react";

export default function OrderShippingDetails({ shippingObj }) {
  return (
    <div className="max-w-full overflow-x-auto max-h-[500px] overflow-scroll p-6">
      <table className="w-full table-auto">
        <thead>
          <tr className="text-left text-dark font-bold">
            <th className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Id:
            </th>
            <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Zip:
            </th>
            <th className="w-1/6 min-w-[260px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              City:
            </th>
            <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Full Name:
            </th>
            <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              State:
            </th>
            <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Mobile:
            </th>
            <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Street:
            </th>
            <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Country:
            </th>
            <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Apartment:
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-t border-[#ddd] text-left text-dark py-4">
            <td className="py-2 px-2  text-base">
              <p>{shippingObj?.id}</p>
            </td>
            <td className="py-2 px-2  text-base">{shippingObj?.zip}</td>
            <td className="py-2 px-4  text-base">{shippingObj?.city}</td>
            <td className="py-2 px-4  text-base">
              {shippingObj?.name1}" " {shippingObj?.name2}
            </td>
            <td className="py-2 px-4  text-base">{shippingObj?.state}</td>
            <td className="py-2 px-4  text-base">{shippingObj?.mobile}</td>
            <td className="py-2 px-4  text-base">{shippingObj?.street}</td>
            <td className="py-2 px-4  text-base">{shippingObj?.country}</td>
            <td className="py-2 px-4  text-base">{shippingObj?.apartment}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
