import { apiSlice } from "../api/apiSlice";

export const businessApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getBusiness: builder.query({
            query: () => `/admin/business`,
            providesTags: ["business"],
        }),
        addBusiness: builder.mutation({
            query: (data) => ({
                url: "/admin/add-business",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["business"],
        }),
        editBusinessOperation: builder.mutation({
            query: ({ id, data }) => ({
                url: `/admin/business/${id}`,
                method: 'PUT',
                body: data
            }),
            // Invalidates all queries that subscribe to this Post `id` only.
            invalidatesTags: ["business"],
        }),
        deleteBusiness: builder.mutation({
            query: (id) => ({
                url: `/admin/business/${id}`,
                method: 'DELETE',
            }),
            // Invalidates all queries that subscribe to this Post `id` only.
            invalidatesTags: ["business"],
        })
    })
})

export const { useGetBusinessQuery, useAddBusinessMutation, useDeleteBusinessMutation, useEditBusinessOperationMutation } = businessApi;