import React from 'react'
import Layout from '../Layout/Layout'
import AddDeliveryComponent from './Components/AddDeliveryComponent/AddDeliveryComponent'

export default function AddDelivery({ showSidebar, setShowSidebar }) {
  return (
    <Layout showSidebar={showSidebar} setShowSidebar={setShowSidebar}>
        <AddDeliveryComponent />
    </Layout>
  )
}
