import React from "react";
import RmsMenuComponent from "./Components/RmsMenuComponent/RmsMenuComponent";

export default function RmsMenu() {
  return (
    <>
      <RmsMenuComponent />
    </>
  );
}
