import { apiSlice } from "../api/apiSlice";

export const rmsPermissionApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRmsPermission: builder.query({
      query: () => `/admin/rms/permission`,
      providesTags: ["rmsPermission"],
    }),

    addPermission: builder.mutation({
      query: (data) => ({
        url: "/admin/rms/permission",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["rmsPermission"],
    }),

    editPermission: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/rms/permission/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["rmsPermission"],
    }),

    deletePermission: builder.mutation({
      query: (id) => ({
        url: `/admin/rms/permission/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["rmsPermission"],
    }),
  }),
});

export const {
  useGetRmsPermissionQuery,
  useAddPermissionMutation,
  useEditPermissionMutation,
  useDeletePermissionMutation,
} = rmsPermissionApi;
