import React from 'react'
import banner1 from "../../../assets/Images/banner1.jpg"
import banner2 from "../../../assets/Images/banner2.png"
import Toggle from '../../../Shared/Components/Toggle'

export default function AddonsComponent() {
    return (
        <div className='max-w-6xl mx-auto bg-white p-4 my-8'>
            <div className='flex justify-between items-center border border-gray-200 p-4'>
                <div className='flex items-center space-x-4'>
                    <img className='w-40 h-auto' src={banner1} alt="banner1" />
                    <p>Offline Payment</p>
                    <p>Version: 1.3</p>
                </div>
                <Toggle />
            </div>
            <div className='flex justify-between items-center border border-gray-200 p-4'>
                <div className='flex items-center space-x-4'>
                    <img className='w-40 h-auto' src={banner2} alt="banner2" />
                    <p>Club_point</p>
                    <p>Version: 1.6</p>
                </div>
                <Toggle />
            </div>
            <div className='flex justify-between items-center border border-gray-200 p-4'>
                <div className='flex items-center space-x-4'>
                    <img className='w-40 h-auto' src={banner1} alt="banner1" />
                    <p>Offline Payment</p>
                    <p>Version: 1.3</p>
                </div>
                <Toggle  isChecked={true}/>
            </div>
            <div className='flex justify-between items-center border border-gray-200 p-4'>
                <div className='flex items-center space-x-4'>
                    <img className='w-40 h-auto' src={banner2} alt="banner2" />
                    <p>Club_point</p>
                    <p>Version: 1.6</p>
                </div>
                <Toggle />
            </div>
            <div className='flex justify-between items-center border border-gray-200 p-4'>
                <div className='flex items-center space-x-4'>
                    <img className='w-40 h-auto' src={banner1} alt="banner1" />
                    <p>Offline Payment</p>
                    <p>Version: 1.3</p>
                </div>
                <Toggle isChecked={true} />
            </div>
            <div className='flex justify-between items-center border border-gray-200 p-4'>
                <div className='flex items-center space-x-4'>
                    <img className='w-40 h-auto' src={banner2} alt="banner2" />
                    <p>Club_point</p>
                    <p>Version: 1.6</p>
                </div>
                <Toggle />
            </div>
            <div className='flex justify-between items-center border border-gray-200 p-4'>
                <div className='flex items-center space-x-4'>
                    <img className='w-40 h-auto' src={banner1} alt="banner1" />
                    <p>Offline Payment</p>
                    <p>Version: 1.3</p>
                </div>
                <Toggle />
            </div>
            <div className='flex justify-between items-center border border-gray-200 p-4'>
                <div className='flex items-center space-x-4'>
                    <img className='w-40 h-auto' src={banner2} alt="banner2" />
                    <p>Club_point</p>
                    <p>Version: 1.6</p>
                </div>
                <Toggle />
            </div>
        </div>
    )
}
