import React from "react";

export default function Wirehouse() {
  let localAuth = localStorage.getItem("wirehouse-auth");

  let { ownerData } = JSON.parse(localAuth);
  return (
    <div>
      <div className="max-w-5xl mx-auto p-10 rounded-md shadow-md bg-white my-10">
        <div className="py-10 px-8 flex flex-col gap-2">
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Name:</p>
            <p className="col-span-3">{ownerData?.name}</p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Country:</p>
            <p className="col-span-3">{ownerData?.country}</p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Address:</p>
            <p className="col-span-3">{ownerData?.address}</p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">City:</p>
            <p className="col-span-3">{ownerData?.city}</p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Post Code:</p>
            <p className="col-span-3">{ownerData?.postcode}</p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Number:</p>
            <p className="col-span-3">{ownerData?.number}</p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Payment Method:</p>
            <p className="col-span-3">{ownerData?.payment_method}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
