import React, { useEffect, useState } from "react";
import {
  useAddBuyerEarningPolicyMutation,
  useEditBuyerEarningPolicyMutation,
} from "../../../features/buyerEarningPolicy/buyerEarningPolicyApi";
import { toast } from "react-toastify";
import Input from "../../../Shared/Components/Input";
import Toast from "../../../Shared/Components/Toast";

export default function AddBuyerEarningPolicy({ categoryObj, setCategoryObj }) {
  let [policy, setPolicy] = useState("");

  useEffect(() => {
    setPolicy(categoryObj?.policy);
  }, [categoryObj]);

  const [
    addBuyerEarningPolicy,
    { isLoading: addIsLoading, isSuccess: isAddCategorySuccess },
  ] = useAddBuyerEarningPolicyMutation();
  const [
    editBuyerEarningPolicy,
    {
      isSuccess: isEditCategorySuccess,
      isLoading: editIsLoading,
      isError,
      error,
    },
  ] = useEditBuyerEarningPolicyMutation();

  useEffect(() => {
    if (isAddCategorySuccess) {
      toast.success("Successfully Country added.");
      setPolicy("");
    }
  }, [isAddCategorySuccess]);

  console.log(
    "isEditCategorySuccess, isError, error",
    isEditCategorySuccess,
    isError,
    error
  );

  useEffect(() => {
    if (isEditCategorySuccess) {
      toast.success("Successfully Country Updated.");
      setPolicy("");
      setCategoryObj({});
    }
    if (isError) {
      toast.error(error.error);
    }
  }, [isEditCategorySuccess, isError]);

  const handleCategoryAdd = (e) => {
    console.log("Policy", policy);
    addBuyerEarningPolicy({ policy });
  };

  const handleCategoryEdit = async (e) => {
    console.log("Policy", policy);
    await editBuyerEarningPolicy({
      id: categoryObj?.id,
      data: { policy },
    });
  };

  return (
    <>
      <div className="w-1/3">
        <div className="bg-white rounded-md shadow-xl ">
          <p className="px-8 py-4 font-semibold">
            {categoryObj?.policy ? "Update" : "Add"} Earning Policy
          </p>
          <div className="border-b border-b-gray-200"></div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Policy <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder="Name"
                    value={policy}
                    onChange={(e) => setPolicy(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pb-8 pr-8 text-right">
            {categoryObj?.policy ? (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryEdit}
                disabled={editIsLoading}
              >
                {editIsLoading ? "Processing" : "Update"}
              </button>
            ) : (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryAdd}
                disabled={addIsLoading}
              >
                {addIsLoading ? "Processing" : "Save"}
              </button>
            )}
          </div>
        </div>
        <Toast />
      </div>
    </>
  );
}
