import React from 'react'
import BannerComponent from './Components/BannerComponent/BannerComponent'

export default function Banner() {
    return (
        <>
            <BannerComponent />
        </>
    )
}
