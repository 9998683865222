import React, { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import {
  useDeleteUnitMutation,
  useGetUnitsQuery,
} from "../../../features/units/unitsApi";
import { toast } from "react-toastify";
import Toast from "../../../Shared/UI/Toast";
import Modal from "../../../Shared/Modal";
import DeleteConfirmation from "../../../Shared/Components/DeleteConfirmation";

export default function AllUnits({ setEditUnit }) {
  const { data: units, isLoading, isError, error } = useGetUnitsQuery();
  const [deleteUnit, { isSuccess }] = useDeleteUnitMutation();
  let [selectedId, setSelectedId] = useState(null);
  let [showModal, setShowModal] = useState(null);

  useEffect(() => {
    if (isSuccess) {
      toast.success("successfully unit deleted.");
    }
  }, [isSuccess]);

  const handleDelete = () => {
    deleteUnit(selectedId);
  };
  return (
    <div className="w-2/3">
      <section className="bg-white shadow-xl rounded-md m-4 py-6 lg:py-8">
        <div className="flex flex-col justify-between items-center pb-4 px-4 md:flex-row">
          <p className="w-3/4 px-2 py-4 font-semibold">All units</p>
          {/* <div className='w-full md:w-3/4 flex flex-col items-center space-x-2 md:flex-row '>
                        <div className='w-full'>
                            <Input type="text" placeholder="Type & Enter" />
                        </div>
                    </div> */}
        </div>
        <div className="flex gap-2 px-6 flex-wrap">
          {units?.data?.map((unit) => (
            <span className="bg-gray-300 text-dark p-2 rounded-lg flex gap-6 items-center">
              {unit?.unit_name}
              <div className="flex gap-4">
                <FaEdit
                  className="cursor-pointer"
                  onClick={() => setEditUnit(unit)}
                />
                <FaTrash
                  className="cursor-pointer"
                  onClick={() => {
                    setSelectedId(unit?.id);
                    setShowModal(true);
                  }}
                />
              </div>
            </span>
          ))}
        </div>
      </section>
      <Toast />

      {/* Delete Modal */}
      {showModal ? (
        <Modal show={showModal}>
          <DeleteConfirmation
            setShowModal={setShowModal}
            handleDelete={handleDelete}
          />
        </Modal>
      ) : null}
    </div>
  );
}
