import React from "react";
import VoucherComponent from "./Components/VoucherComponent/VoucherComponent";

export default function Voucher() {
  return (
    <div>
      <VoucherComponent />
    </div>
  );
}
