import { apiSlice } from "../api/apiSlice";

export const orderPaymentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentPendingOrder: builder.query({
      query: (id) => `/admin/orders/pending`,
      providesTags: ["paymentOrder"],
    }),
    paymentConfirmOrder: builder.query({
      query: (id) => `/orders/confirm_payment/${id}`,
      providesTags: ["paymentOrder"],
    }),
  }),
});

export const { useGetPaymentPendingOrderQuery, usePaymentConfirmOrderQuery } =
  orderPaymentApi;
