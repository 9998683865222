import React, { useState } from "react";
import SellerExtraChargeTable from "./SellerExtraChargeTable";
import AddSellerExtraCharge from "./AddSellerExtraCharge";

export default function SellerExtraChargeComponent() {
  const [extraChargeObj, setExtraChargeObj] = useState({});
  return (
    <>
      <p className="p-4 font-bold md:pl-8">All extra charge</p>
      <div className="flex flex-col p-4 mb-8  md:flex-row">
        <SellerExtraChargeTable setPaymentMethodObj={setExtraChargeObj} />
        <AddSellerExtraCharge
          setPercentDiscountObj={setExtraChargeObj}
          percentDiscountObj={extraChargeObj}
        />
      </div>
    </>
  );
}
