import React, { useState } from "react";
import RmsPermissionTable from "./RmsPermissionTable";
import AddRmsPermission from "./AddRmsPermission";

export default function RmsPermissionComponent() {
  let [rmsPermissionObj, setrmsPermissionObj] = useState({});
  return (
    <>
      <p className="p-4 font-bold md:pl-8">All Permissions</p>
      <div className="flex flex-col p-4 mb-8  md:flex-row">
        <RmsPermissionTable setCategoryObj={setrmsPermissionObj} />
        <AddRmsPermission
          categoryObj={rmsPermissionObj}
          setCategoryObj={setrmsPermissionObj}
        />
      </div>
    </>
  );
}
