import React from 'react'
import Layout from '../Layout/Layout'
import BulkImportComponent from './Components/BulkImportComponent/BulkImportComponent'

export default function BulkImport({ showSidebar, setShowSidebar }) {
  return (
    <Layout  showSidebar={showSidebar} setShowSidebar={setShowSidebar}>
        <BulkImportComponent />
    </Layout>
  )
}
