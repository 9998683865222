import React, { useEffect, useState } from "react";
import Input from "../../../Shared/Components/Input";
import {
  useDeleteSellersMutation,
  useGetSellersQuery,
} from "../../../features/saller/sallerApi";
import { toast } from "react-toastify";
import { FaEye, FaRegEdit, FaTimes, FaTrashAlt } from "react-icons/fa";
import Modal from "../../../Shared/Modal";
import Toast from "../../../Shared/UI/Toast";
import SellerflatDiscount from "./SellerflatDiscount";
import SellerPercentDiscount from "./SellerPercentDiscount";
import SellerProduct from "./SellerProduct";
import SellerLiveHistory from "./SellerLiveHistory";
import SellerPaymentInfo from "./SellerPaymentInfo";
import EditOption from "../../../Shared/Components/EditOption";
import DeleteConfirmation from "../../../Shared/Components/DeleteConfirmation";

export default function SellersTable({ setSellerObj }) {
  let [showModal, setShowModal] = useState(false);
  let [selectedId, setSelectedId] = useState("");
  let [showOption, setShowOption] = useState("");
  let [showSellerProduct, setShowSellerProduct] = useState(false);
  let [showPercentDiscount, setShowPercentDiscount] = useState(false);
  let [showFlatDiscount, setShowFlatDiscount] = useState(false);
  let [showLiveHistory, setShowLiveHistory] = useState(false);
  let [showPaymentInfo, setShowPaymentInfo] = useState(false);

  const { data: allSellers, isLoading, isError, error } = useGetSellersQuery();
  const [deleteSellers, { isSuccess }] = useDeleteSellersMutation();

  console.log("error", error);

  useEffect(() => {
    if (isSuccess) {
      setShowModal(false);
      toast.success("Successfully delted!");
    }
  }, [isSuccess]);

  const handleSellerDelete = () => {
    if (!selectedId) {
      toast.error("Please select delete option first.");
      return;
    }
    deleteSellers(selectedId);
  };

  let content = null;
  if (isLoading) {
    content = <div>Loading</div>;
  }
  if (!isLoading && isError) {
    content = (
      <div>
        <p className="text-red-500">{error?.error}</p>
      </div>
    );
  }

  if (!isLoading && !isError) {
    content = (
      <tbody>
        {allSellers?.data?.map((seller) => (
          <tr className="border-t border-[#ddd] text-left text-dark py-4">
            <td className="py-2 px-2  text-base">
              <div className="flex items-center">
                <p>{seller?.shop_name}</p>
              </div>
            </td>
            <td className="py-2 px-2  text-base">{seller?.phone}</td>
            <td className="py-2 px-2  text-base">
              {seller?.business_type_name}
            </td>
            {/* <td className="py-2 px-2  text-base">{seller?.address}</td> */}
            <td className="py-2 px-2  text-base">{seller?.email}</td>
            <td className="y-2 px-2  text-base">
              <img src={seller?.logo} className="w-20 h-20" alt="logo" />
            </td>
            <td className="y-2 px-2  text-base">
              <div className="flex space-x-2">
                <div
                  className="p-2 bg-green-200 rounded-full cursor-pointer"
                  onClick={() => {
                    setSelectedId(seller?.id);
                    setShowOption(true);
                  }}
                >
                  <FaEye className="fill-green-500" />
                </div>
                <EditOption onClick={() => setSellerObj(seller)} />
                <div
                  className="p-2 bg-red-200 rounded-full cursor-pointer"
                  onClick={() => {
                    setSelectedId(seller?.id);
                    setShowModal(true);
                  }}
                >
                  <FaTrashAlt className="fill-red-500" />
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
  return (
    <>
      <section className="bg-white shadow-xl rounded-md m-4 py-6 px-4 overflow-hidden lg:py-8">
        <div className="flex flex-col justify-between items-center pb-4 px-4 md:flex-row">
          <p className="w-1/4px-8 py-4 font-semibold">Sellers Information</p>
          {/* <div className="w-full md:w-3/4 flex flex-col items-center space-x-0 space-y-2 md:flex-row md:space-x-2 md:space-y-0 ">
            <div className="w-full md:w-1/4">
              <Input type="text" placeholder="Type & Enter" />
            </div>
            <div className="w-full md:w-1/4">
              <select
                id="selectID"
                className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
              >
                <option className="bg-orange-400 text-white p-4">
                  All Sellers
                </option>
                <option className="bg-orange-400 text-white p-4">
                  Steve Smith
                </option>
                <option className="bg-orange-400 text-white p-4">
                  Ailster Cook
                </option>
              </select>
            </div>
            <div className="w-full md:w-1/4">
              <select
                id="selectID"
                className="w-full border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
              >
                <option className="bg-orange-400 text-white p-4">
                  Sort By
                </option>
                <option className="bg-orange-400 text-white p-4">{`Rating(High < Low)`}</option>
                <option className="bg-orange-400 text-white p-4">{`Rating(Low < High)`}</option>
                <option className="bg-orange-400 text-white p-4">{`Num of Sale(Low < High)`}</option>
                <option className="bg-orange-400 text-white p-4">{`Num of Sale(High < Low)`}</option>
              </select>
            </div>
            <div className="w-full md:w-1/4">
              <select
                id="selectID"
                className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
              >
                <option className="bg-orange-400 text-white p-4">
                  Sort By
                </option>
                <option className="bg-orange-400 text-white p-4">{`Rating(High < Low)`}</option>
                <option className="bg-orange-400 text-white p-4">{`Rating(Low < High)`}</option>
                <option className="bg-orange-400 text-white p-4">{`Num of Sale(Low < High)`}</option>
                <option className="bg-orange-400 text-white p-4">{`Num of Sale(High < Low)`}</option>
              </select>
            </div>
          </div> */}
        </div>
        <div className="border-b border-b-gray-200"></div>
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4 mx-4">
            <div className="max-w-full overflow-x-auto">
              <table className="w-full table-auto">
                <thead>
                  <tr className="text-left text-dark font-bold">
                    <th className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Shop Name
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Phone
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Business Type
                    </th>
                    {/* <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Address
                    </th> */}
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Email
                    </th>
                    <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Logo
                    </th>
                    <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Options
                    </th>
                  </tr>
                </thead>
                {content}
              </table>
            </div>
          </div>
        </div>
        <Toast />
      </section>

      {/* Option Modal */}
      {showOption ? (
        <Modal show={showOption}>
          <div className="w-full max-w-[470px]  rounded-md bg-white text-center">
            <div className="flex justify-between items-center px-8 py-4 ">
              <p className="font-semibold">Seller details</p>
              <FaTimes
                onClick={() => {
                  setShowSellerProduct(false);
                  setShowFlatDiscount(false);
                  setShowPercentDiscount(false);
                  setShowOption(false);
                }}
                className="fill-gray-500 cursor-pointer"
              />
            </div>
            <div className="border-b border-b-gray-200"></div>
            <span className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"></span>
            <p
              className="text-body-color mb-4 text-base leading-relaxed bg-red-500 p-2 mx-3 rounded-md cursor-pointer text-white"
              onClick={() => setShowSellerProduct(true)}
            >
              Seller Product
            </p>

            <p
              className="text-body-color mb-4 text-base leading-relaxed bg-red-500 p-2 mx-3 rounded-md cursor-pointer text-white"
              onClick={() => setShowPercentDiscount(true)}
            >
              Percent Discount
            </p>

            <p
              className="text-body-color mb-4 text-base leading-relaxed bg-red-500 p-2 mx-3 rounded-md cursor-pointer text-white"
              onClick={() => setShowFlatDiscount(true)}
            >
              Flat Discount
            </p>

            <p
              className="text-body-color mb-4 text-base leading-relaxed bg-red-500 p-2 mx-3 rounded-md cursor-pointer text-white"
              onClick={() => setShowPaymentInfo(true)}
            >
              Payment Info
            </p>

            <p
              className="text-body-color mb-4 text-base leading-relaxed bg-red-500 p-2 mx-3 rounded-md cursor-pointer text-white"
              onClick={() => setShowLiveHistory(true)}
            >
              Live History
            </p>

            <div className="flex justify-center space-x-6 pb-10">
              <button
                onClick={() => {
                  setShowSellerProduct(false);
                  setShowFlatDiscount(false);
                  setShowPercentDiscount(false);
                  setShowOption(false);
                }}
                className="text-dark block rounded-md border border-[#E9EDF9] p-3 text-center text-base font-medium transition hover:border-red-600 hover:bg-red-600 hover:text-white"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      ) : null}

      {showPercentDiscount ? (
        <Modal show={showPercentDiscount}>
          <div className="w-full max-w-[980px] rounded-md bg-white text-center">
            <div className="flex justify-between items-center px-8 py-4 ">
              <p className="font-semibold">Seller Percent Discount</p>
              <FaTimes
                onClick={() => {
                  setShowPercentDiscount(false);
                }}
                className="fill-gray-500 cursor-pointer"
              />
            </div>
            <div className="border-b border-b-gray-200"></div>
            <span className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"></span>
            <SellerPercentDiscount id={selectedId} />
          </div>
        </Modal>
      ) : null}

      {showFlatDiscount ? (
        <Modal show={showFlatDiscount}>
          <div className="w-full max-w-[980px] rounded-md bg-white text-center">
            <div className="flex justify-between items-center px-8 py-4 ">
              <p className="font-semibold">Seller Flat Discount</p>
              <FaTimes
                onClick={() => {
                  setShowFlatDiscount(false);
                }}
                className="fill-gray-500 cursor-pointer"
              />
            </div>
            <div className="border-b border-b-gray-200"></div>
            <span className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"></span>

            <SellerflatDiscount id={selectedId} />
          </div>
        </Modal>
      ) : null}

      {showSellerProduct ? (
        <Modal show={showSellerProduct}>
          <div className="w-full max-w-[980px] rounded-md bg-white text-center">
            <div className="flex justify-between items-center px-8 py-4 ">
              <p className="font-semibold">Seller Product</p>
              <FaTimes
                onClick={() => {
                  setShowSellerProduct(false);
                }}
                className="fill-gray-500 cursor-pointer"
              />
            </div>
            <div className="border-b border-b-gray-200"></div>
            <span className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"></span>
            <SellerProduct id={selectedId} />
          </div>
        </Modal>
      ) : null}

      {showLiveHistory ? (
        <Modal show={showLiveHistory}>
          <div className="w-full max-w-[980px] rounded-md bg-white text-center">
            <div className="flex justify-between items-center px-8 py-4 ">
              <p className="font-semibold">Seller Live</p>
              <FaTimes
                onClick={() => {
                  setShowLiveHistory(false);
                }}
                className="fill-gray-500 cursor-pointer"
              />
            </div>
            <div className="border-b border-b-gray-200"></div>
            <span className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"></span>
            <SellerLiveHistory id={selectedId} />
          </div>
        </Modal>
      ) : null}

      {showPaymentInfo ? (
        <Modal show={showPaymentInfo}>
          <div className="w-full max-w-[980px] rounded-md bg-white text-center">
            <div className="flex justify-between items-center px-8 py-4 ">
              <p className="font-semibold">Payment Info</p>
              <FaTimes
                onClick={() => {
                  setShowPaymentInfo(false);
                }}
                className="fill-gray-500 cursor-pointer"
              />
            </div>
            <div className="border-b border-b-gray-200"></div>
            <span className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"></span>
            <SellerPaymentInfo id={selectedId} />
          </div>
        </Modal>
      ) : null}

      {/* Delete Modal */}
      {showModal ? (
        <Modal show={showModal}>
          <DeleteConfirmation
            setShowModal={setShowModal}
            handleDelete={handleSellerDelete}
          />
        </Modal>
      ) : null}
    </>
  );
}
