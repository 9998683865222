import { apiSlice } from "../api/apiSlice";

export const shippingPackageApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getShippingPackage: builder.query({
      query: () => `/admin/shippingPackage`,
      providesTags: ["shippingPackage"],
    }),
    addShippingPackage: builder.mutation({
      query: (data) => ({
        url: "/admin/shippingPackage",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["shippingPackage"],
    }),

    editShippingPackage: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/shippingPackage/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["shippingPackage"],
    }),

    deleteShippingPackage: builder.mutation({
      query: (id) => ({
        url: `/admin/shippingPackage/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["shippingPackage"],
    }),
  }),
});

export const {
  useGetShippingPackageQuery,
  useAddShippingPackageMutation,
  useEditShippingPackageMutation,
  useDeleteShippingPackageMutation,
} = shippingPackageApi;
