import React, { useState } from 'react'
import Input from './Components/Input'
import Toggle from './Components/Toggle'
import { FaEye, FaRegEdit, FaCopy, FaTrashAlt, FaTimes } from "react-icons/fa";
import watch from "../assets/Images/watch.png"
import Modal from './Modal';

export default function Table() {
    let [showModal, setShowModal] = useState(false);
    return (
        <>
            <section className="bg-white shadow-xl rounded-md m-4 py-6 px-4 overflow-hidden lg:py-8">
                <div className='flex flex-col justify-between items-center pb-4 px-4 md:flex-row'>
                    <p className='w-1/4px-8 py-4 font-semibold'>Product Information</p>
                    <div className='w-full md:w-3/4 flex flex-col items-center space-x-0 space-y-2 md:flex-row md:space-x-2 md:space-y-0 '>
                        <div className='w-full md:w-1/4'>
                            <Input type="text" placeholder="Type & Enter" />
                        </div>
                        <div className='w-full md:w-1/4'>
                            <select id="selectID" className='w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary w-full rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500'>
                                <option className='bg-orange-400 text-white p-4'>All Sellers</option>
                                <option className='bg-orange-400 text-white p-4'>Steve Smith</option>
                                <option className='bg-orange-400 text-white p-4'>Ailster Cook</option>
                            </select>
                        </div>
                        <div className='w-full md:w-1/4'>
                            <select id="selectID" className='w-full border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary w-full rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500'>
                                <option className='bg-orange-400 text-white p-4'>Sort By</option>
                                <option className='bg-orange-400 text-white p-4'>{`Rating(High < Low)`}</option>
                                <option className='bg-orange-400 text-white p-4'>{`Rating(Low < High)`}</option>
                                <option className='bg-orange-400 text-white p-4'>{`Num of Sale(Low < High)`}</option>
                                <option className='bg-orange-400 text-white p-4'>{`Num of Sale(High < Low)`}</option>
                            </select>
                        </div>
                        <div className='w-full md:w-1/4'>
                            <select id="selectID" className='w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary w-full rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500'>
                                <option className='bg-orange-400 text-white p-4'>Sort By</option>
                                <option className='bg-orange-400 text-white p-4'>{`Rating(High < Low)`}</option>
                                <option className='bg-orange-400 text-white p-4'>{`Rating(Low < High)`}</option>
                                <option className='bg-orange-400 text-white p-4'>{`Num of Sale(Low < High)`}</option>
                                <option className='bg-orange-400 text-white p-4'>{`Num of Sale(High < Low)`}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='border-b border-b-gray-200'></div>
                <div className="-mx-4 flex flex-wrap">
                    <div className="w-full px-4 mx-4">
                        <div className="max-w-full overflow-x-auto">
                            <table className="w-full table-auto">
                                <thead>
                                    <tr className="text-left text-dark font-bold">
                                        <th
                                            className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Name
                                        </th>
                                        <th
                                            className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Added By
                                        </th>
                                        <th
                                            className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Info
                                        </th>
                                        <th
                                            className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Total Stock
                                        </th>
                                        <th
                                            className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Todays Deal
                                        </th>
                                        <th
                                            className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Published
                                        </th>
                                        <th
                                            className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Featured
                                        </th>
                                        <th
                                            className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Options
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='border-t border-[#ddd] text-left text-dark py-4'>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div className='flex items-center'>
                                                <img src={watch} alt="watch" className='w-20 h-20' />
                                                <p>Apple iPhone 14 Pro , 128GB, 512GB, Deep Purple- Unlocked</p>
                                            </div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            William C. Schroyer
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div><span className='font-bold'>Num of Sales:</span><span>1 times</span></div>
                                            <div><span className='font-bold'>Base Price:</span><span>$999.000</span></div>
                                            <div><span className='font-bold'>Rating:</span><span>5</span></div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div>128GB - 4999</div>
                                            <div>512GB - 5000</div>
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-2'>
                                                <div className='p-2 bg-green-200 rounded-full cursor-pointer'>
                                                    <FaEye className='fill-green-500' />
                                                </div>
                                                <div className='p-2 bg-red-200 rounded-full cursor-pointer'>
                                                    <FaRegEdit className='fill-red-500' />
                                                </div>
                                                <div className='p-2 bg-yellow-200 rounded-full cursor-pointer'>
                                                    <FaCopy className='fill-yellow-500' />
                                                </div>
                                                <div className='p-2 bg-red-200 rounded-full cursor-pointer' onClick={() => setShowModal(true)}>
                                                    <FaTrashAlt className='fill-red-500' />
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                    <tr className='border-t border-[#ddd] text-left text-dark py-4'>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div className='flex items-center'>
                                                <img src={watch} alt="watch" className='w-20 h-20' />
                                                <p>Apple iPhone 14 Pro , 128GB, 512GB, Deep Purple- Unlocked</p>
                                            </div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            William C. Schroyer
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div><span className='font-bold'>Num of Sales:</span><span>1 times</span></div>
                                            <div><span className='font-bold'>Base Price:</span><span>$999.000</span></div>
                                            <div><span className='font-bold'>Rating:</span><span>5</span></div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div>128GB - 4999</div>
                                            <div>512GB - 5000</div>
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle isChecked={true} />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-2'>
                                                <div className='p-2 bg-green-200 rounded-full cursor-pointer'>
                                                    <FaEye className='fill-green-500' />
                                                </div>
                                                <div className='p-2 bg-red-200 rounded-full cursor-pointer'>
                                                    <FaRegEdit className='fill-red-500' />
                                                </div>
                                                <div className='p-2 bg-yellow-200 rounded-full cursor-pointer'>
                                                    <FaCopy className='fill-yellow-500' />
                                                </div>
                                                <div className='p-2 bg-red-200 rounded-full cursor-pointer' onClick={() => setShowModal(true)}>
                                                    <FaTrashAlt className='fill-red-500' />
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                    <tr className='border-t border-[#ddd] text-left text-dark py-4'>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div className='flex items-center'>
                                                <img src={watch} alt="watch" className='w-20 h-20' />
                                                <p>Apple iPhone 14 Pro , 128GB, 512GB, Deep Purple- Unlocked</p>
                                            </div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            William C. Schroyer
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div><span className='font-bold'>Num of Sales:</span><span>1 times</span></div>
                                            <div><span className='font-bold'>Base Price:</span><span>$999.000</span></div>
                                            <div><span className='font-bold'>Rating:</span><span>5</span></div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div>128GB - 4999</div>
                                            <div>512GB - 5000</div>
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-2'>
                                                <div className='p-2 bg-green-200 rounded-full cursor-pointer'>
                                                    <FaEye className='fill-green-500' />
                                                </div>
                                                <div className='p-2 bg-red-200 rounded-full cursor-pointer'>
                                                    <FaRegEdit className='fill-red-500' />
                                                </div>
                                                <div className='p-2 bg-yellow-200 rounded-full cursor-pointer'>
                                                    <FaCopy className='fill-yellow-500' />
                                                </div>
                                                <div className='p-2 bg-red-200 rounded-full cursor-pointer' onClick={() => setShowModal(true)}>
                                                    <FaTrashAlt className='fill-red-500' />
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                    <tr className='border-t border-[#ddd] text-left text-dark py-4'>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div className='flex items-center'>
                                                <img src={watch} alt="watch" className='w-20 h-20' />
                                                <p>Apple iPhone 14 Pro , 128GB, 512GB, Deep Purple- Unlocked</p>
                                            </div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            William C. Schroyer
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div><span className='font-bold'>Num of Sales:</span><span>1 times</span></div>
                                            <div><span className='font-bold'>Base Price:</span><span>$999.000</span></div>
                                            <div><span className='font-bold'>Rating:</span><span>5</span></div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div>128GB - 4999</div>
                                            <div>512GB - 5000</div>
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle isChecked={true} />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-2'>
                                                <div className='p-2 bg-green-200 rounded-full cursor-pointer'>
                                                    <FaEye className='fill-green-500' />
                                                </div>
                                                <div className='p-2 bg-red-200 rounded-full cursor-pointer'>
                                                    <FaRegEdit className='fill-red-500' />
                                                </div>
                                                <div className='p-2 bg-yellow-200 rounded-full cursor-pointer'>
                                                    <FaCopy className='fill-yellow-500' />
                                                </div>
                                                <div className='p-2 bg-red-200 rounded-full cursor-pointer' onClick={() => setShowModal(true)}>
                                                    <FaTrashAlt className='fill-red-500' />
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                    <tr className='border-t border-[#ddd] text-left text-dark py-4'>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div className='flex items-center'>
                                                <img src={watch} alt="watch" className='w-20 h-20' />
                                                <p>Apple iPhone 14 Pro , 128GB, 512GB, Deep Purple- Unlocked</p>
                                            </div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            William C. Schroyer
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div><span className='font-bold'>Num of Sales:</span><span>1 times</span></div>
                                            <div><span className='font-bold'>Base Price:</span><span>$999.000</span></div>
                                            <div><span className='font-bold'>Rating:</span><span>5</span></div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div>128GB - 4999</div>
                                            <div>512GB - 5000</div>
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-2'>
                                                <div className='p-2 bg-green-200 rounded-full cursor-pointer'>
                                                    <FaEye className='fill-green-500' />
                                                </div>
                                                <div className='p-2 bg-red-200 rounded-full cursor-pointer'>
                                                    <FaRegEdit className='fill-red-500' />
                                                </div>
                                                <div className='p-2 bg-yellow-200 rounded-full cursor-pointer'>
                                                    <FaCopy className='fill-yellow-500' />
                                                </div>
                                                <div className='p-2 bg-red-200 rounded-full cursor-pointer' onClick={() => setShowModal(true)}>
                                                    <FaTrashAlt className='fill-red-500' />
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                    <tr className='border-t border-[#ddd] text-left text-dark py-4'>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div className='flex items-center'>
                                                <img src={watch} alt="watch" className='w-20 h-20' />
                                                <p>Apple iPhone 14 Pro , 128GB, 512GB, Deep Purple- Unlocked</p>
                                            </div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            William C. Schroyer
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div><span className='font-bold'>Num of Sales:</span><span>1 times</span></div>
                                            <div><span className='font-bold'>Base Price:</span><span>$999.000</span></div>
                                            <div><span className='font-bold'>Rating:</span><span>5</span></div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div>128GB - 4999</div>
                                            <div>512GB - 5000</div>
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle isChecked={true} />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-2'>
                                                <div className='p-2 bg-green-200 rounded-full cursor-pointer'>
                                                    <FaEye className='fill-green-500' />
                                                </div>
                                                <div className='p-2 bg-red-200 rounded-full cursor-pointer'>
                                                    <FaRegEdit className='fill-red-500' />
                                                </div>
                                                <div className='p-2 bg-yellow-200 rounded-full cursor-pointer'>
                                                    <FaCopy className='fill-yellow-500' />
                                                </div>
                                                <div className='p-2 bg-red-200 rounded-full cursor-pointer' onClick={() => setShowModal(true)}>
                                                    <FaTrashAlt className='fill-red-500' />
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                    <tr className='border-t border-[#ddd] text-left text-dark py-4'>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div className='flex items-center'>
                                                <img src={watch} alt="watch" className='w-20 h-20' />
                                                <p>Apple iPhone 14 Pro , 128GB, 512GB, Deep Purple- Unlocked</p>
                                            </div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            William C. Schroyer
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div><span className='font-bold'>Num of Sales:</span><span>1 times</span></div>
                                            <div><span className='font-bold'>Base Price:</span><span>$999.000</span></div>
                                            <div><span className='font-bold'>Rating:</span><span>5</span></div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div>128GB - 4999</div>
                                            <div>512GB - 5000</div>
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-2'>
                                                <div className='p-2 bg-green-200 rounded-full cursor-pointer'>
                                                    <FaEye className='fill-green-500' />
                                                </div>
                                                <div className='p-2 bg-red-200 rounded-full cursor-pointer'>
                                                    <FaRegEdit className='fill-red-500' />
                                                </div>
                                                <div className='p-2 bg-yellow-200 rounded-full cursor-pointer'>
                                                    <FaCopy className='fill-yellow-500' />
                                                </div>
                                                <div className='p-2 bg-red-200 rounded-full cursor-pointer' onClick={() => setShowModal(true)}>
                                                    <FaTrashAlt className='fill-red-500' />
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                    <tr className='border-t border-[#ddd] text-left text-dark py-4'>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div className='flex items-center'>
                                                <img src={watch} alt="watch" className='w-20 h-20' />
                                                <p>Apple iPhone 14 Pro , 128GB, 512GB, Deep Purple- Unlocked</p>
                                            </div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            William C. Schroyer
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div><span className='font-bold'>Num of Sales:</span><span>1 times</span></div>
                                            <div><span className='font-bold'>Base Price:</span><span>$999.000</span></div>
                                            <div><span className='font-bold'>Rating:</span><span>5</span></div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div>128GB - 4999</div>
                                            <div>512GB - 5000</div>
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <Toggle />
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-2'>
                                                <div className='p-2 bg-green-200 rounded-full cursor-pointer'>
                                                    <FaEye className='fill-green-500' />
                                                </div>
                                                <div className='p-2 bg-red-200 rounded-full cursor-pointer'>
                                                    <FaRegEdit className='fill-red-500' />
                                                </div>
                                                <div className='p-2 bg-yellow-200 rounded-full cursor-pointer'>
                                                    <FaCopy className='fill-yellow-500' />
                                                </div>
                                                <div className='p-2 bg-red-200 rounded-full cursor-pointer' onClick={() => setShowModal(true)}>
                                                    <FaTrashAlt className='fill-red-500' />
                                                </div>
                                            </div>
                                        </td>

                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>


            {/* Modal */}
            {
                showModal ? <Modal show={showModal} >
                    <div
                        className="w-full max-w-[470px] rounded-md bg-white text-center"
                    >
                        <div className='flex justify-between items-center px-8 py-4 '>
                            <p className='font-semibold'>Delete Confirmation</p>
                            <FaTimes
                                onClick={() => setShowModal(false)}
                                className='fill-gray-500 cursor-pointer'
                            />
                        </div>
                        <div className='border-b border-b-gray-200'></div>
                        <span
                            className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"
                        ></span>
                        <p className="text-body-color mb-10 text-base leading-relaxed">
                            Are you sure to delete this?
                        </p>
                        <div className="flex justify-center space-x-6 pb-10">
                            <button
                                onClick={() => setShowModal(false)}
                                className="text-dark block rounded-md border border-[#E9EDF9] p-3 text-center text-base font-medium transition hover:border-red-600 hover:bg-red-600 hover:text-white"
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-orange-500 border-orange-500 block rounded-md border p-3 text-center text-base font-medium text-white transition hover:bg-opacity-90"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </Modal> : null
            }
        </>
    )
}
