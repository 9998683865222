import React from "react";
import BarChartCustom from "../../../Shared/BarChartCustom";
import BarCharts from "../../../Shared/BarCharts";

export default function ProductsAnalytics({ allDashbaordData }) {
  console.log("allDashbaordData", allDashbaordData);
  return (
    <div className="flex flex-col space-y-6 p-4 overflow-hidden md:flex-row md:space-y-0 md:space-x-6">
      <div className=" bg-white h-[100%] w-full shadow-lg">
        <p className="text-xl p-4 text-gray-500">Category wise product sale</p>
        <div className="border-b border-b-gray-300"></div>
        <div className="px-4 py-8">
          <BarCharts chartData={allDashbaordData?.category_wise_products} />
        </div>
      </div>
      {/* <div className=" bg-white h-[100%] w-full md:w-1/2 shadow-lg">
        <p className="text-xl p-4 text-gray-500">Category wise product stock</p>
        <div className="border-b border-b-gray-300"></div>
        <div className="px-4 py-8">
          <BarCharts />
        </div>
      </div> */}
    </div>
  );
}
