import React, { useEffect, useState } from "react";
import {
  useAddSellerPaymentMethodMutation,
  useEditSellerPaymentMethodMutation,
} from "../../../features/SellerPaymentMethod/SellerPaymentMethodApi";
import { toast } from "react-toastify";
import Input from "../../../Shared/Components/Input";
import Toast from "../../../Shared/Components/Toast";

export default function AddPaymentMethod({
  setPercentDiscountObj,
  percentDiscountObj,
}) {
  let [percentDiscount, setPercentDiscount] = useState({
    name: "",
    details: {
      name: "",
      phone: "",
    },
  });

  useEffect(() => {
    console.log("percentDiscountObj", percentDiscountObj);
    setPercentDiscount({
      name: percentDiscountObj?.method_name,
      details: {
        name: percentDiscountObj?.method_details?.name,
        phone: percentDiscountObj?.method_details?.phone,
      },
    });
  }, [percentDiscountObj]);

  const [addPercentDiscount, { isSuccess: ispercentDiscountSuccess }] =
    useAddSellerPaymentMethodMutation();
  const [
    editpercentDiscount,
    { isSuccess: isEditpercentDiscountSuccess, isLoading, isError, error },
  ] = useEditSellerPaymentMethodMutation();

  useEffect(() => {
    if (ispercentDiscountSuccess) {
      toast.success("Successfully Percent Discount added.");
      setPercentDiscount({
        name: "",
        details: {
          name: "",
          phone: "",
        },
      });
    }
  }, [ispercentDiscountSuccess]);

  useEffect(() => {
    if (isEditpercentDiscountSuccess) {
      toast.success("Successfully Percent Discount Updated.");
      setPercentDiscount({
        name: "",
        details: {
          name: "",
          phone: "",
        },
      });
      setPercentDiscountObj({});
    }
    if (isError) {
      console.log("error", error);
      toast.error(error.error);
    }
  }, [isEditpercentDiscountSuccess, isError]);

  const handleCategoryAdd = (e) => {
    addPercentDiscount(percentDiscount);
  };

  const handleCategoryEdit = async (e) => {
    await editpercentDiscount({
      id: percentDiscountObj?.id,
      data: {
        ...percentDiscount,
      },
    });
  };

  return (
    <>
      <div className="w-full md:w-1/3">
        <div className="bg-white rounded-md shadow-xl ">
          <p className="px-8 py-4 font-semibold">
            {percentDiscountObj?.method_name ? "Update" : "Add"} payment method
          </p>
          <div className="border-b border-b-gray-200"></div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Method Name <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder="Name"
                    value={percentDiscount.name}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,

                        name: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Seller Name <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder=""
                    value={percentDiscount.details?.name}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        details: {
                          ...percentDiscount?.details,
                          name: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Seller Phone <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="number"
                    placeholder=""
                    value={percentDiscount.details?.phone}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        details: {
                          ...percentDiscount?.details,
                          phone: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pb-8 pr-8 text-right">
            {percentDiscountObj?.method_name ? (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryEdit}
              >
                Update
              </button>
            ) : (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryAdd}
              >
                Save
              </button>
            )}
          </div>
        </div>
        <Toast />
      </div>
    </>
  );
}
