import { apiSlice } from "../api/apiSlice";

export const wirehouseListApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllWirehouse: builder.query({
      query: () => `/wirehouse/wirehouses`,
      providesTags: ["wirehouses"],
    }),
  }),
});

export const { useGetAllWirehouseQuery } = wirehouseListApi;
