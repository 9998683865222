import React, { useEffect, useState } from "react";
import { useGetAllOrdersMutation } from "../../../features/orders/ordersApi";
import Input from "../../../Shared/Components/Input";
import { FaEye, FaTimes } from "react-icons/fa";
import Modal from "../../../Shared/Modal";
import OrderDetails from "./OrderDetails";

export default function OrdersTable({ setCategoryObj }) {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [showDetail, setShowDetail] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const [getAllOrders, { data, isLoading, isSuccess, isError, error }] =
    useGetAllOrdersMutation();

  const handleFilter = () => {
    getAllOrders({
      order_id: "",
      from_date: fromDate,
      to_date: toDate,
      status: null,
    });
  };

  let content = null;

  if (isLoading) {
    content = <div>Loading</div>;
  }
  if (!isLoading && isError) {
    content = <div>{error?.error}</div>;
  }

  if (!isLoading && !isError) {
    content = (
      <>
        {data?.data?.length ? (
          <thead className=" w-full">
            <tr className="w-full  text-left text-dark font-bold">
              <th className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                #
              </th>
              <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                Customer Order Id
              </th>
              <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                Amount
              </th>
              <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                Order Status
              </th>
              <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                Payment Status
              </th>
              <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                Options
              </th>
            </tr>
          </thead>
        ) : null}

        <tbody className="w-full">
          {data?.data?.map((order) => (
            <tr className="border-t border-[#ddd] text-left text-dark py-4">
              <td className="w-1/6 py-2 px-2  text-base">{order?.id}</td>
              <td className="w-1/6 py-2 px-2  text-base">
                {order?.customer_order_id}
              </td>
              <td className="w-1/6 py-2 px-2  text-base">{order?.amount}</td>

              <td className="w-1/6 py-2 px-2  text-base">
                {order?.order_status}
              </td>
              <td className="py-2 px-2  text-base">{order?.payment_status}</td>
              <td className="y-2 px-2  text-base">
                <div className="flex space-x-2 cursor-pointer">
                  <div className="p-2 bg-green-200 rounded-full cursor-pointer">
                    <FaEye
                      className="fill-green-500"
                      onClick={() => {
                        setSelectedId(order?.customer_order_id);
                        setShowDetail(true);
                      }}
                    />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </>
    );
  }
  return (
    <>
      <div className="w-full overflow-x-hidden">
        <section className="bg-white shadow-xl rounded-md mb-4 m-0 md:m-4 py-6 lg:my-0 lg:py-8">
          <div className="flex flex-col justify-between items-center pb-4 px-4 md:flex-row">
            <p className="w-1/4 px-8 py-4 font-semibold">Orders</p>
            <div className="w-full md:w-3/4 flex flex-col items-center space-x-0 space-y-2 md:flex-row md:space-x-2 md:space-y-0 ">
              <div className="w-full md:w-1/3 flex gap-2 items-center">
                <p className="font-bold">From:</p>
                <Input
                  type="date"
                  placeholder="From"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div className="w-full md:w-1/3 flex gap-2 items-center">
                <p className="font-bold">To:</p>
                <Input
                  type="date"
                  placeholder="To"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <div className="w-full md:w-1/3 flex-end">
                <button
                  className="w-full px-4 py-3 rounded-md bg-orange-500 text-white text-center "
                  onClick={handleFilter}
                >
                  Filter
                </button>
              </div>
            </div>
          </div>
          <div className="border-b border-b-gray-200"></div>
          <div className="mx-4 flex flex-wrap">
            <div className="w-full px-4 mx-4">
              <div className="max-w-full overflow-x-auto">
                <table className="w-full table-auto ">{content}</table>
              </div>
            </div>
          </div>
        </section>
      </div>

      {showDetail ? (
        <Modal show={showDetail}>
          <div className="w-full max-w-[780px]  rounded-md bg-white text-center">
            <div className="flex justify-between items-center px-8 py-4 ">
              <p className="font-semibold">Order details</p>
              <FaTimes
                onClick={() => {
                  setShowDetail(false);
                }}
                className="fill-gray-500 cursor-pointer"
              />
            </div>
            <div className="border-b border-b-gray-200"></div>
            <span className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"></span>

            <OrderDetails selectedId={selectedId} />

            <div className="flex justify-center space-x-6 pb-10">
              <button
                onClick={() => {
                  setShowDetail(false);
                }}
                className="text-dark block rounded-md border border-[#E9EDF9] p-3 text-center text-base font-medium transition hover:border-red-600 hover:bg-red-600 hover:text-white"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
}
