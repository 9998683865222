import React from 'react'
import Layout from '../Layout/Layout'
import UpdatesComponent from './Components/UpdatesComponent/UpdatesComponent'

export default function Updates({ showSidebar, setShowSidebar }) {
  return (
    <Layout showSidebar={showSidebar} setShowSidebar={setShowSidebar}>
        <UpdatesComponent />
    </Layout>
  )
}
