import { apiSlice } from "../api/apiSlice";

export const wirehouseProductApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHandoverCurier: builder.query({
      query: () => `/wirehouse/receive_order/courier_hand_over/list`,
      providesTags: ["wirehouseCurier"],
    }),
    postHandOverToCurier: builder.mutation({
      query: ({ id, data }) => ({
        url: `/wirehouse/receive_order/courier_hand_over/list/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["wirehouseCurier"],
    }),

    postDeliveredProduct: builder.mutation({
      query: ({ id, data }) => ({
        url: `/wirehouse/receive_order/delivery_confirm/list/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["wirehouseCurier"],
    }),
  }),
});

export const {
  useGetHandoverCurierQuery,
  usePostHandOverToCurierMutation,
  usePostDeliveredProductMutation,
} = wirehouseProductApi;
