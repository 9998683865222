import React from 'react'
import BusinessComponent from './Components/BusinessComponent/BusinessComponent'

export default function Business() {
    return (
        <>
            <BusinessComponent />
        </>
    )
}
