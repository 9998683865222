import React from "react";
import logo from "../assets/Images/tunibibi_transparent.png";
import { FaCartPlus, FaHome } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { wirehouseLoggedOut } from "../features/auth/wirehouseAuthSlice";

export default function WirehouseSidebar() {
  const dispatch = useDispatch();
  return (
    <div
      className={`sticky top-0 left-0 z-50 bg-gray-900 md:min-w-[270px] md:max-w-[270px] h-screen overflow-y-scroll scrollbar`}
    >
      <img src={logo} alt="logo" className="w-full px-4 bg-gray-800" />
      <div>
        <NavLink
          className={({ isActive }) =>
            isActive ? "text-white font-bold" : "text-gray-400"
          }
          to="/warehouse-details"
          //   onClick={() => setShowSidebar(false)}
        >
          <div className="flex items-center space-x-4 p-4 hover:bg-gray-800 cursor-pointer">
            <div className="flex space-x-4 items-center">
              <FaHome />
              <p>Warehouse</p>
            </div>
          </div>
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive ? "text-white font-bold" : "text-gray-400"
          }
          to="/warehouse/warehouses"
          //   onClick={() => setShowSidebar(false)}
        >
          <div className="flex items-center space-x-4 p-4 hover:bg-gray-800 cursor-pointer">
            <div className="flex space-x-4 items-center">
              <FaHome />
              <p>Warehouses</p>
            </div>
          </div>
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive ? "text-white font-bold" : "text-gray-400"
          }
          to="/warehouse/order-track"
          //   onClick={() => setShowSidebar(false)}
        >
          <div className="flex items-center space-x-4 p-4 hover:bg-gray-800 cursor-pointer">
            <div className="flex space-x-4 items-center">
              <FaHome />
              <p>Order Track</p>
            </div>
          </div>
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive ? "text-white font-bold" : "text-gray-400"
          }
          to="/warehouse/international-product"
          //   onClick={() => setShowSidebar(false)}
        >
          <div className="flex items-center space-x-4 p-4 hover:bg-gray-800 cursor-pointer">
            <div className="flex space-x-4 items-center">
              <FaHome />
              <p>International Product</p>
            </div>
          </div>
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            isActive ? "text-white font-bold" : "text-gray-400"
          }
          to="/warehouse/handover-curier"
          //   onClick={() => setShowSidebar(false)}
        >
          <div className="flex items-center space-x-4 p-4 hover:bg-gray-800 cursor-pointer">
            <div className="flex space-x-4 items-center">
              <FaHome />
              <p>Handover to Curier</p>
            </div>
          </div>
        </NavLink>

        <div
          onClick={() => {
            dispatch(wirehouseLoggedOut());
          }}
          className="flex items-center justify-between space-x-4 p-4  text-gray-400 hover:bg-gray-800 cursor-pointer"
        >
          <div className="flex space-x-4 items-center">
            <FaCartPlus />
            <p>Logout</p>
          </div>
        </div>
      </div>
    </div>
  );
}
