import React, { useState } from "react";
import GroupNameTable from "./GroupNameTable";
import AddRmsGroupName from "./AddRmsGroupName";

export default function RmsGroupNameComponent() {
  let [rmsGroupNameObj, setrmsGroupNameObj] = useState({});
  return (
    <>
      <p className="p-4 font-bold md:pl-8">All Group Name</p>
      <div className="flex flex-col p-4 mb-8  md:flex-row">
        <GroupNameTable setCategoryObj={setrmsGroupNameObj} />
        <AddRmsGroupName
          categoryObj={rmsGroupNameObj}
          setCategoryObj={setrmsGroupNameObj}
        />
      </div>
    </>
  );
}
