import React, { useEffect, useState } from "react";
import { FaEye, FaRegEdit, FaCopy, FaTrashAlt } from "react-icons/fa";
import Input from "../../../Shared/Components/Input";
import {
  useDeleteBusinessMutation,
  useGetBusinessQuery,
} from "../../../features/business/businessApi";
import { toast } from "react-toastify";
import Toast from "../../../Shared/UI/Toast";
import EditOption from "../../../Shared/Components/EditOption";
import Modal from "../../../Shared/Modal";
import DeleteConfirmation from "../../../Shared/Components/DeleteConfirmation";

export default function BusinessTable({ setEditBusiness }) {
  let [showModal, setShowModal] = useState(false);
  let [selectedId, setSelectedId] = useState("");

  const { data: business, isLoading, isError, error } = useGetBusinessQuery();
  const [deleteBusiness, { isSuccess }] = useDeleteBusinessMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success("successfull deleted");
    }
  }, [isSuccess]);

  const handleBusinessDelete = () => {
    deleteBusiness(selectedId);
  };

  return (
    <div className="w-2/3">
      <section className="bg-white shadow-xl rounded-md m-4 py-6 lg:py-8">
        <div className="flex flex-col justify-between items-center pb-4 px-4 md:flex-row">
          <p className="w-3/4 px-8 py-4 font-semibold">Business</p>
          {/* <div className="w-full md:w-3/4 flex flex-col items-center space-x-2 md:flex-row ">
            <div className="w-full">
              <Input type="text" placeholder="Type & Enter" />
            </div>
          </div> */}
        </div>
        <div className="border-b border-b-gray-200"></div>
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4 mx-4">
            <div className="max-w-full overflow-x-auto">
              <table className="w-full table-auto">
                <thead>
                  <tr className="text-left text-dark font-bold">
                    <th className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      #
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Name
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Logo
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Options
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {business?.map((data) => (
                    <tr className="border-t border-[#ddd] text-left text-dark py-4">
                      <td className="py-2 px-2  text-base">
                        <div className="flex items-center">{data?.id}</div>
                      </td>
                      <td className="py-2 px-2  text-base">{data?.name}</td>
                      <td className="py-2 px-2  text-base">
                        <img src={data?.image} alt="" className="w-20 h-auto" />
                      </td>
                      <td className="y-2 px-2  text-base">
                        <div className="flex space-x-2 cursor-pointer">
                          <EditOption
                            onClick={() => {
                              setEditBusiness(data);
                            }}
                          />
                          <div
                            className="p-2 bg-red-200 rounded-full cursor-pointer"
                            onClick={() => {
                              setSelectedId(data?.id);
                              setShowModal(true);
                            }}
                          >
                            <FaTrashAlt className="fill-red-500" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Toast />

        {showModal ? (
          <Modal show={showModal}>
            <DeleteConfirmation
              setShowModal={setShowModal}
              handleDelete={handleBusinessDelete}
            />
          </Modal>
        ) : null}
      </section>
    </div>
  );
}
