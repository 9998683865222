import React from "react";
import LivesComponent from "./Components/LivesComponent/LivesComponent";

export default function Lives() {
  return (
    <div>
      <LivesComponent />
    </div>
  );
}
