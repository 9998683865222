import React from "react";
import VarientComponent from "./Components/VarientComponent/VarientComponent";

export default function Varient() {
  return (
    <div>
      <VarientComponent />
    </div>
  );
}
