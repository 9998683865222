import { apiSlice } from "../api/apiSlice";

export const buyersBannerApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBuyersBanner: builder.query({
      query: () => `/admin/buyer/banner`,
      providesTags: ["buyerBanner"],
    }),
    addBuyersBanner: builder.mutation({
      query: (data) => ({
        url: "/admin/buyer/banner",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["buyerBanner"],
    }),
    editBuyersBanner: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/buyer/banner/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["buyerBanner"],
    }),
    deleteBuyersBanner: builder.mutation({
      query: (id) => ({
        url: `/admin/buyer/banner/${id}`,
        method: "DELETE",
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["buyerBanner"],
    }),
  }),
});

export const {
  useGetBuyersBannerQuery,
  useAddBuyersBannerMutation,
  useEditBuyersBannerMutation,
  useDeleteBuyersBannerMutation,
} = buyersBannerApi;
