import React, { useState } from 'react'
import AllUnits from './AllUnits'
import AddUnit from './AddUnit'

export default function UnitComponents() {
    let [editUnit, setEditUnit] = useState({});
    return (
        <div className='flex p-4'>
            <AllUnits setEditUnit={setEditUnit} />
            <AddUnit editUnit={editUnit} setEditUnit={setEditUnit} />
        </div>
    )
}
