import React from "react";
import OrdersComponent from "./Components/OrdersComponent/OrdersComponent";

export default function Orders() {
  return (
    <div>
      <OrdersComponent />
    </div>
  );
}
