import React, { useState } from "react";
import CategoryTable from "./CategoryTable";
import AddCategory from "./AddCategory";

export default function CategoryComponents() {
  let [categoryObj, setCategoryObj] = useState({});
  return (
    <>
      <p className="p-4 font-bold md:pl-8">All Attributes</p>
      <div className="flex flex-col p-4 mb-8  md:flex-row">
        <CategoryTable setCategoryObj={setCategoryObj} />
        <AddCategory
          categoryObj={categoryObj}
          setCategoryObj={setCategoryObj}
        />
      </div>
    </>
  );
}
