import React from "react";
import WirehouseOrderTrackComponent from "./Components/WirehouseOrderTrackComponent/WirehouseOrderTrackComponent";

export default function WirehouseOrderTrack() {
  return (
    <div>
      <WirehouseOrderTrackComponent />
    </div>
  );
}
