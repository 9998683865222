import React from "react";
import { useGetSellerPercentDiscountQuery } from "../../../features/SellerPercentDiscount/SellerPercentDiscount";

export default function SellerPercentDiscount({ id }) {
  const {
    data: sellerPercentDiscount,
    isLoading,
    isError,
    error,
  } = useGetSellerPercentDiscountQuery(id);

  let content = null;
  if (isLoading) {
    content = <div>Loading</div>;
  }
  if (!isLoading && isError) {
    content = (
      <div>
        <p className="text-red-500">{error?.error}</p>
      </div>
    );
  }

  if (!isLoading && !isError) {
    content = (
      <tbody>
        {sellerPercentDiscount?.data?.map((discount) => (
          <tr className="border-t border-[#ddd] text-left text-dark py-4">
            <td className="py-2 px-2  text-base">
              <div className="flex items-center">
                <p>{discount?.discount_type}</p>
              </div>
            </td>
            <td className="py-2 px-2  text-base">{discount?.coupon_code}</td>
            <td className="py-2 px-4  text-base">{discount?.discount_value}</td>
            <td className="py-2 px-4  text-base">
              {discount?.max_disc_amount}
            </td>
            <td className="py-2 px-4  text-base">
              {discount?.min_order_amount}
            </td>
            <td className="py-2 px-4  text-base">{discount?.min_qty}</td>
          </tr>
        ))}
      </tbody>
    );
  }
  return (
    <div className="max-w-full overflow-x-auto max-h-[500px] overflow-scroll p-6">
      <table className="w-full table-auto">
        <thead>
          <tr className="text-left text-dark font-bold">
            <th className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Discount Type:
            </th>
            <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Coupon Code:
            </th>
            <th className="w-1/6 min-w-[260px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Discount Value:
            </th>
            <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Max Disc Amount:
            </th>
            <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Min Order Amount:
            </th>
            <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Min Qty:
            </th>
          </tr>
        </thead>
        {content}
      </table>
    </div>
  );
}
