import { apiSlice } from "../api/apiSlice";

export const unitsApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUnits: builder.query({
            query: () => `/admin/units`,
            providesTags: ["units"],
        }),
        addUnit: builder.mutation({
            query: (data) => ({
                url: "/admin/units",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["units"],
        }),
        editUnitOperation: builder.mutation({
            query: ({ id, data }) => ({
                url: `/admin/units/${id}`,
                method: 'PUT',
                body: data
            }),
            // Invalidates all queries that subscribe to this Post `id` only.
            invalidatesTags: ["units"],
        }),
        deleteUnit: builder.mutation({
            query: (id) => ({
                url: `/admin/units/${id}`,
                method: 'DELETE',
            }),
            // Invalidates all queries that subscribe to this Post `id` only.
            invalidatesTags: ['units'],
        })
    })
})

export const { useGetUnitsQuery, useDeleteUnitMutation, useAddUnitMutation, useEditUnitOperationMutation } = unitsApi;