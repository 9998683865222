import React, { useEffect, useState } from "react";
import Toast from "../../../Shared/Components/Toast";
import {
  useAddWirehouseMutation,
  useEditWirehouseMutation,
} from "../../../features/allwirehouse/allwirehouseApi";
import { toast } from "react-toastify";
import Input from "../../../Shared/Components/Input";

export default function AddWireHouse({ categoryObj, setCategoryObj }) {
  let [addressDetails, setAddressDetails] = useState({
    name: "",
    country: "",
    address: "",
    created_at: "",
    updated_at: "",
    number: "",
    city: "",
    postcode: "",
    payment_method: "",
  });

  useEffect(() => {
    setAddressDetails({
      name: categoryObj?.name,
      country: categoryObj?.country,
      address: categoryObj?.address,
      created_at: categoryObj?.created_at,
      updated_at: categoryObj?.updated_at,
      number: categoryObj?.number,
      city: categoryObj?.city,
      postcode: categoryObj?.postcode,
      payment_method: categoryObj?.payment_method,
    });
  }, [categoryObj]);

  const [addWirehouse, { isSuccess: isAddAddress }] = useAddWirehouseMutation();
  const [
    editWirehouse,
    { isSuccess: isEditAddressSuccess, isLoading, isError, error },
  ] = useEditWirehouseMutation();

  useEffect(() => {
    if (isAddAddress) {
      toast.success("Successfully Wirehouse added.");
      setAddressDetails({
        name: "",
        country: "",
        address: "",
        created_at: "",
        updated_at: "",
        number: "",
        city: "",
        postcode: "",
        payment_method: "",
      });
    }
  }, [isAddAddress]);

  console.log(
    "isEditAddressSuccess, isError, error",
    isEditAddressSuccess,
    isError,
    error
  );

  useEffect(() => {
    if (isEditAddressSuccess) {
      toast.success("Successfully Wirehouse Updated.");
      setAddressDetails({
        name: "",
        country: "",
        address: "",
        created_at: "",
        updated_at: "",
        number: "",
        city: "",
        postcode: "",
        payment_method: "",
      });
      setCategoryObj({});
    }
    if (isError) {
      toast.error(error.error);
    }
  }, [isEditAddressSuccess, isError]);

  const handleCategoryAdd = (e) => {
    addWirehouse(addressDetails);
  };

  const handleCategoryEdit = async (e) => {
    await editWirehouse({
      id: categoryObj?.id,
      data: {
        ...addressDetails,
      },
    });
  };

  return (
    <>
      <div className="w-1/3">
        <div className="bg-white rounded-md shadow-xl ">
          <p className="px-8 py-4 font-semibold">
            {categoryObj?.id ? "Update" : "Add"} Warehouse
          </p>
          <div className="border-b border-b-gray-200"></div>
          <div className="flex flex-col p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Name <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder=""
                    value={addressDetails?.name}
                    onChange={(e) =>
                      setAddressDetails({
                        ...addressDetails,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Country <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="tel"
                    placeholder=""
                    value={addressDetails?.country}
                    onChange={(e) =>
                      setAddressDetails({
                        ...addressDetails,
                        country: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Address <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="tel"
                    placeholder=""
                    value={addressDetails?.address}
                    onChange={(e) =>
                      setAddressDetails({
                        ...addressDetails,
                        address: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Number <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="tel"
                    placeholder=""
                    value={addressDetails?.number}
                    onChange={(e) =>
                      setAddressDetails({
                        ...addressDetails,
                        number: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  City <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder=""
                    value={addressDetails?.city}
                    onChange={(e) =>
                      setAddressDetails({
                        ...addressDetails,
                        city: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Post Code <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder=""
                    value={addressDetails?.postcode}
                    onChange={(e) =>
                      setAddressDetails({
                        ...addressDetails,
                        postcode: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Password <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="password"
                    placeholder=""
                    value={addressDetails?.password}
                    onChange={(e) =>
                      setAddressDetails({
                        ...addressDetails,
                        password: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pb-8 pr-8 text-right">
            {categoryObj?.id ? (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryEdit}
              >
                Update
              </button>
            ) : (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryAdd}
              >
                Save
              </button>
            )}
          </div>
        </div>
        <Toast />
      </div>
    </>
  );
}
