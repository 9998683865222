import React, { useState } from "react";
import BuyerTable from "./BuyerTable";
import AddBuyer from "./AddBuyer";

export default function BuyerComponent() {
  const [paymentMethodObj, setPaymentMethodObj] = useState({});
  return (
    <>
      <div className="flex flex-col p-4 mb-8 ">
        <AddBuyer
          setPaymentMethodObj={setPaymentMethodObj}
          paymentMethodObj={paymentMethodObj}
        />
        <BuyerTable setPaymentMethodObj={setPaymentMethodObj} />
      </div>
    </>
  );
}
