import React from 'react'
import Layout from '../Layout/Layout'
import Packages from './Components/ClassifiedPackageComponent/Packages'

export default function ClassifiedPackage({ showSidebar, setShowSidebar }) {
    return (
        <Layout  showSidebar={showSidebar} setShowSidebar={setShowSidebar}>
            <Packages />
        </Layout>
    )
}
