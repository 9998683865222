import React, { useState } from "react";
import Analytics from "./Components/ViewComponent/Analytics";
import ProductsAnalytics from "./Components/ViewComponent/ProductsAnalytics";
import Layout from "../Layout/Layout";
import Products from "./Components/ViewComponent/Products";
import Modal from "../Shared/Modal";
import Toast from "../Shared/Components/Toast";
import { useGetDashboardDataQuery } from "../features/dashboardApi/dashboardApi";

export default function View({ setShowSidebar, showSidebar }) {
  let [show, setShow] = useState(false);

  const { data: allDashbaordData } = useGetDashboardDataQuery();

  return (
    <Layout
      showSidebar={showSidebar}
      setShowSidebar={setShowSidebar}
      setShow={setShow}
    >
      <Analytics allDashbaordData={allDashbaordData} />
      <ProductsAnalytics allDashbaordData={allDashbaordData} />
      {/* <Products /> */}
      <Modal show={show} setShow={setShow} />
      {showSidebar ? <Toast greet="Sidebar Open" /> : null}
    </Layout>
  );
}
