import { apiSlice } from "../api/apiSlice";

export const percentDiscountApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPercentDiscount: builder.query({
      query: () => `/admin/discount/percent`,
      providesTags: ["percentDiscount"],
    }),

    addPercentDiscount: builder.mutation({
      query: (data) => ({
        url: "/admin/discount/percent/add",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["percentDiscount"],
    }),

    editPercentDiscount: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/discount/percent/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["percentDiscount"],
    }),
  }),
});

export const {
  useGetPercentDiscountQuery,
  useAddPercentDiscountMutation,
  useEditPercentDiscountMutation,
} = percentDiscountApi;
