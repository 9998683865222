import React, { useState } from "react";
import RefferEarningPolicyTable from "./RefferEarningPolicyTable";
import AddRefferEarningPolicy from "./AddRefferEarningPolicy";

export default function RefferEarningPolicyComponent() {
  let [categoryObj, setCategoryObj] = useState({});
  return (
    <>
      <p className="p-4 font-bold md:pl-8">All RefferEarning</p>
      <div className="flex flex-col p-4 mb-8  md:flex-row">
        <RefferEarningPolicyTable setCategoryObj={setCategoryObj} />
        <AddRefferEarningPolicy
          categoryObj={categoryObj}
          setCategoryObj={setCategoryObj}
        />
      </div>
    </>
  );
}
