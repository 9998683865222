import { apiSlice } from "../api/apiSlice";

export const sellerDeliveryTimesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSellerDelilveryTimes: builder.query({
      query: () => `/admin/seller/delivery/times`,
      providesTags: ["sellerDeliveryTimes"],
    }),

    addSellerDeliveryTimes: builder.mutation({
      query: (data) => ({
        url: "/admin/seller/delivery/times",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["sellerDeliveryTimes"],
    }),

    editSellerDeliveryTimes: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/seller/delivery/times/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["sellerDeliveryTimes"],
    }),

    deleteSellerDeliveryTimes: builder.mutation({
      query: (id) => ({
        url: `/admin/seller/delivery/times/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["sellerDeliveryTimes"],
    }),
  }),
});

export const {
  useGetSellerDelilveryTimesQuery,
  useAddSellerDeliveryTimesMutation,
  useEditSellerDeliveryTimesMutation,
  useDeleteSellerDeliveryTimesMutation,
} = sellerDeliveryTimesApi;
