import React from "react";
import RmsGroupNameComponent from "./Components/RmsGroupNameComponent/RmsGroupNameComponent";

export default function RmsGroupName() {
  return (
    <>
      <RmsGroupNameComponent />
    </>
  );
}
