import React, { useEffect } from "react";
import {
  usePostDeliveredProductMutation,
  usePostHandOverToCurierMutation,
} from "../../../../features/wirehouseApi/wirehouseHandoverCurier";
import { toast } from "react-toastify";
import Toast from "../../../../Shared/Components/Toast";

export default function WirehosueCurierProductInfo({
  productInfo,
  setProductInfo,
  setShowProductInfo,
}) {
  const [postHandOverToCurier, { data, isSuccess }] =
    usePostHandOverToCurierMutation();

  const [postDeliveredProduct, { isSuccess: deliveredIsSuccess }] =
    usePostDeliveredProductMutation();

  console.log("post Data", data, isSuccess);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Successfully Handover to Curier");
      setProductInfo({});
      setShowProductInfo(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (deliveredIsSuccess) {
      toast.success("Successfully Delivered Product");
      setProductInfo({});
      setShowProductInfo(false);
    }
  }, [deliveredIsSuccess]);

  const handleCourier = (id) => {
    postHandOverToCurier({ id });
  };

  const handleDelivered = (id) => {
    postDeliveredProduct({ id });
  };

  return (
    <div className="max-w-5xl mx-auto p-10 rounded-md bg-white my-10">
      <div className="py-10 px-8 flex flex-col gap-2">
        <h1 className="text-center font-bold text-xl ">Product Info</h1>
        <img
          src={productInfo[0]?.image}
          alt={productInfo[0]?.product_name}
          className="mx-auto"
        />
        <div className="grid gap-2 grid-cols-4 text-left">
          <p className="col-span-1 font-bold">Product Name:</p>
          <p className="col-span-3">{productInfo[0]?.product_name}</p>
        </div>
        <div className="grid gap-2 grid-cols-4 text-left">
          <p className="col-span-1 font-bold">Quantity:</p>
          <p className="col-span-3">{productInfo[0]?.quantity}</p>
        </div>
        <div className="grid gap-2 grid-cols-4 text-left">
          <p className="col-span-1 font-bold">Track Id:</p>
          <p className="col-span-3">{productInfo[0]?.track_id}</p>
        </div>
        <div className="grid gap-2 grid-cols-4 text-left">
          <p className="col-span-1 font-bold">Variant Info:</p>
          <p className="col-span-3 bg-gray-200 p-4 mb-2">
            {productInfo[0]?.varient_info?.map((variant) => (
              <div>
                <p>
                  <span>size: </span> {variant?.size}
                </p>
                <p>
                  <span>color: </span> {variant?.color}
                </p>
                <p>
                  <span>stock: </span> {variant?.stock}
                </p>
              </div>
            ))}
          </p>
        </div>
        <div className="grid gap-2 grid-cols-4 text-left">
          <p className="col-span-1 font-bold">Wirehouse Status:</p>
          <p className="col-span-3">{productInfo[0]?.wirehouse_status}</p>
        </div>

        {productInfo[0]?.wirehouse_status !== "Sent Courier" &&
        productInfo[0]?.wirehouse_status !== "Delivered" ? (
          <div className="pb-8 px-8">
            <button
              className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
              onClick={() => handleCourier(productInfo[0]?.track_id)}
            >
              Send To Curier
            </button>
          </div>
        ) : null}

        {productInfo[0]?.wirehouse_status !== "Delivered" ? (
          <div className="pb-8 px-8">
            <button
              className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
              onClick={() => handleDelivered(productInfo[0]?.track_id)}
            >
              Product Delivered
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
