import React from "react";
import AddressComponent from "./Components/AddressComponent/AddressComponent";

export default function Address() {
  return (
    <div>
      <AddressComponent />
    </div>
  );
}
