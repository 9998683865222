import React, { useEffect, useState } from "react";
import {
  useDeleteSellerDeliveryTimesMutation,
  useGetSellerDelilveryTimesQuery,
} from "../../../features/sellerDeliveryTimes/sellerDeliveryTimesApi";
import { toast } from "react-toastify";
import { FaRegEdit, FaTimes, FaTrashAlt } from "react-icons/fa";
import Modal from "../../../Shared/Modal";
import Toast from "../../../Shared/Components/Toast";
import EditOption from "../../../Shared/Components/EditOption";
import DeleteConfirmation from "../../../Shared/Components/DeleteConfirmation";

export default function SellerDeliveryTimesTable({ setPaymentMethodObj }) {
  let [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const {
    data: allSellerDeliveryTimes,
    isLoading,
    isError,
    error,
  } = useGetSellerDelilveryTimesQuery();

  const [deleteSellerDeliveryTimes, { isSuccess }] =
    useDeleteSellerDeliveryTimesMutation();

  const handleSellerDelete = () => {
    if (!selectedId) {
      toast.error("Please select delete option first.");
      return;
    }
    deleteSellerDeliveryTimes(selectedId);
  };

  useEffect(() => {
    if (isSuccess) {
      setShowModal(false);
      toast.success("Successfully delted!");
    }
  }, [isSuccess]);

  let content = null;

  if (isLoading) {
    content = <div>Loading</div>;
  }
  if (!isLoading && isError) {
    content = <div>{error?.error}</div>;
  }

  if (!isLoading && !isError) {
    content = (
      <tbody>
        {allSellerDeliveryTimes?.data?.map((paymentMethod) => (
          <tr className="border-t border-[#ddd] text-left text-dark py-4">
            <td className="py-2 px-2  text-base">
              <div className="flex items-center">{paymentMethod?.id}</div>
            </td>
            <td className="py-2 px-2  text-base">{paymentMethod?.minutes}</td>
            <td className="py-2 px-2  text-base">{paymentMethod?.times}</td>

            <td className="y-2 px-2  text-base">
              <div className="flex space-x-2 cursor-pointer">
                <EditOption
                  onClick={() => setPaymentMethodObj(paymentMethod)}
                />
                <div className="p-2 bg-red-200 rounded-full cursor-pointer">
                  <FaTrashAlt
                    className="fill-red-500"
                    onClick={() => {
                      setSelectedId(paymentMethod?.id);
                      setShowModal(true);
                    }}
                  />
                  {/* <FaTrashAlt className="fill-red-500" /> */}
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
  return (
    <>
      <div className="w-full md:w-2/3 overflow-x-hidden">
        <section className="bg-white shadow-xl rounded-md mb-4 m-0 md:m-4 py-6 lg:my-0 lg:py-8 overflow-x-hidden">
          <div className="flex flex-col justify-between items-center pb-4 px-4 md:flex-row">
            <p className="w-3/4 px-8 py-4 font-semibold">All delivery times</p>
          </div>
          <div className="border-b border-b-gray-200"></div>
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4 mx-4">
              <div className="max-w-full overflow-x-auto">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="text-left text-dark font-bold">
                      <th className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        #
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Minutes
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Times
                      </th>

                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Options
                      </th>
                    </tr>
                  </thead>
                  {content}
                </table>
              </div>
            </div>
          </div>
          <Toast />
        </section>
      </div>
      {/* Delete Modal */}
      {showModal ? (
        <Modal show={showModal}>
          <DeleteConfirmation
            handleDelete={handleSellerDelete}
            setShowModal={setShowModal}
          />
        </Modal>
      ) : null}
    </>
  );
}
