import React, { useState } from "react";
import BuyersPaymentMethodTable from "./BuyersPaymentMethodTable";
import AddBuyersPaymentMethod from "./AddBuyersPaymentMethod";

export default function BuyersPaymentMethodComponent() {
  let [categoryObj, setCategoryObj] = useState({});
  console.log("category", categoryObj);
  return (
    <>
      <p className="p-4 font-bold md:pl-8">All Buyers Payment Method</p>
      <div className="flex flex-col p-4 mb-8  md:flex-row">
        <BuyersPaymentMethodTable setCategoryObj={setCategoryObj} />
        <AddBuyersPaymentMethod
          categoryObj={categoryObj}
          setCategoryObj={setCategoryObj}
        />
      </div>
    </>
  );
}
