import React, { useEffect, useState } from "react";
import {
  useAddProductsMutation,
  useEditProductsMutation,
} from "../../../features/products/productsApi";
import { toast } from "react-toastify";
import Input from "../../../Shared/Components/Input";
import Toast from "../../../Shared/Components/Toast";
import { useGetCategoryQuery } from "../../../features/category/categoryApi";
import { useGetSubCategoryQuery } from "../../../features/subCategoryApi/subCategoryApi";
import { useGetVarientQuery } from "../../../features/varient/varientApi";
import { useGetSellersQuery } from "../../../features/saller/sallerApi";

export default function AddProduct({
  percentDiscountObj,
  setPercentDiscountObj,
}) {
  let [percentDiscount, setPercentDiscount] = useState({
    seller_id: "",
    product_name: "",
    catagory_id: "",
    sub_catagory_id: "",
    product_details: "",
    weight_unit: "",
    weight_type: "",
    product_code: "",
    video_url: "",
    product_origin: "",
    wholesale_price: [],
    product_varient: [],
  });

  const [wholeSaleObj, setWholeSaleObj] = useState({
    min_quantity: "",
    max_quantity: "",
    amount: "",
    unit: "",
  });

  useEffect(() => {
    console.log("percentDiscountObj", percentDiscountObj);
    if (percentDiscountObj?.product_name) {
      setPercentDiscount({
        seller_id: percentDiscountObj?.seller_id || null,
        product_name: percentDiscountObj?.product_name,
        catagory_id: percentDiscountObj?.catagory?.id,
        sub_catagory_id: percentDiscountObj?.sub_catagory_id?.id,
        product_details: percentDiscountObj?.product_details,
        weight_unit: percentDiscountObj?.weight_unit,
        weight_type: percentDiscountObj?.weight_type,
        product_code: percentDiscountObj?.product_code,
        video_url: percentDiscountObj?.video_url,
        product_origin: percentDiscountObj?.product_origin,
        wholesale_price: percentDiscountObj?.wholesale_price || [],
        product_varient: percentDiscountObj?.product_varient || [],
      });
    }
  }, [percentDiscountObj]);

  const { data: varients } = useGetVarientQuery();
  const { data: categories } = useGetCategoryQuery();
  const { data: subCategories } = useGetSubCategoryQuery();

  const {
    data: allSellers,
    isLoading: sellerLoading,
    isError: sellerIsError,
    error: sellerError,
  } = useGetSellersQuery();

  const [addProducts, { isSuccess: isProductSuccess }] =
    useAddProductsMutation();
  const [
    editProducts,
    { isSuccess: isEditProductSuccess, isLoading, isError, error },
  ] = useEditProductsMutation();

  const handleWholeSalePrice = () => {
    setPercentDiscount({
      ...percentDiscount,
      wholesale_price: [...percentDiscount?.wholesale_price, wholeSaleObj],
    });
    setWholeSaleObj({
      min_quantity: "",
      max_quantity: "",
      amount: "",
      unit: "",
    });
  };

  useEffect(() => {
    console.log("isProductSuccess", isProductSuccess);
    if (isProductSuccess) {
      toast.success("Successfully Product added.");
      setPercentDiscount({
        seller_id: "",
        product_name: "",
        catagory_id: "",
        sub_catagory_id: "",
        product_details: "",
        weight_unit: "",
        weight_type: "",
        product_code: "",
        video_url: "",
        product_origin: "",
        wholesale_price: [],
        product_varient: [],
      });
    }
  }, [isProductSuccess]);

  useEffect(() => {
    if (isEditProductSuccess) {
      toast.success("Successfully Percent Discount Updated.");
      setPercentDiscount({
        seller_id: "",
        product_name: "",
        catagory_id: "",
        sub_catagory_id: "",
        product_details: "",
        weight_unit: "",
        weight_type: "",
        product_code: "",
        video_url: "",
        product_origin: "",
        wholesale_price: [],
        product_varient: [],
      });
      setPercentDiscountObj({});
    }
    if (isError) {
      console.log("error", error);
      toast.error(error.error);
    }
  }, [isEditProductSuccess, isError]);

  const handleCategoryAdd = (e) => {
    addProducts(percentDiscount);
  };

  const handleCategoryEdit = async (e) => {
    await editProducts({
      id: percentDiscountObj?.id,
      data: {
        ...percentDiscount,
      },
    });
  };

  console.log("percentDiscount", percentDiscount);

  return (
    <>
      <div className="w-2/3 mx-auto">
        <div className="bg-white rounded-md shadow-xl ">
          <p className="px-8 py-4 font-semibold">
            {percentDiscountObj?.product_name ? "Update" : "Add"} product
          </p>
          <div className="border-b border-b-gray-200"></div>

          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Seller Id <span className="text-orange-500">*</span>
                </label>
                <div className="w-full ">
                  <select
                    id="selectID"
                    className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        seller_id: e.target.value,
                      })
                    }
                  >
                    <option
                      className="bg-orange-400 text-white p-4 w-full"
                      value=""
                    >
                      Select Seller
                    </option>
                    {allSellers?.data?.map((seller) => (
                      <option
                        className="bg-orange-400 text-white p-4 w-full"
                        value={seller?.id}
                      >
                        {seller?.shop_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="flex px-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Product Name <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder=""
                    value={percentDiscount?.product_name}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        product_name: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full px-12">
            <select
              id="selectID"
              className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
              onChange={(e) =>
                setPercentDiscount({
                  ...percentDiscount,
                  catagory_id: e.target.value,
                })
              }
            >
              <option className="bg-orange-400 text-white p-4 w-full" value="">
                Select Category
              </option>
              {categories?.data?.map((category) => (
                <option
                  className="bg-orange-400 text-white p-4 w-full"
                  value={category?.id}
                >
                  {category?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full px-12 py-2">
            <select
              id="selectID"
              className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
              onChange={(e) =>
                setPercentDiscount({
                  ...percentDiscount,
                  sub_catagory_id: e.target.value,
                })
              }
            >
              <option className="bg-orange-400 text-white p-4 w-full" value="">
                Select Sub Category
              </option>
              {subCategories?.data?.map((category) => (
                <option
                  className="bg-orange-400 text-white p-4 w-full"
                  value={category?.id}
                >
                  {category?.sub_catagory_name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex px-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Product details
                  <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="phone"
                    placeholder=""
                    value={percentDiscount.product_details}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        product_details: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex px-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Weight Unit
                  <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="phone"
                    placeholder=""
                    value={percentDiscount.weight_unit}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        weight_unit: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex px-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Weight Type
                  <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="phone"
                    placeholder=""
                    value={percentDiscount.weight_type}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        weight_type: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex px-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Product Code
                  <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="phone"
                    placeholder=""
                    value={percentDiscount.product_code}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        product_code: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex px-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Video Url
                  <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="phone"
                    placeholder=""
                    value={percentDiscount.video_url}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        video_url: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex px-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Product Origin
                  <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="phone"
                    placeholder=""
                    value={percentDiscount.product_origin}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        product_origin: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full px-12 py-2">
            <select
              id="selectID"
              className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
              onChange={(e) =>
                setPercentDiscount({
                  ...percentDiscount,
                  product_varient: [
                    ...percentDiscount?.product_varient,
                    e.target.value,
                  ],
                })
              }
            >
              <option className="bg-orange-400 text-white p-4 w-full" value="">
                Select Product Varient
              </option>
              {varients?.data?.map((varient) => (
                <option
                  className="bg-orange-400 text-white p-4 w-full"
                  value={varient?.id}
                >
                  {varient?.name}
                </option>
              ))}
            </select>
          </div>

          <div className="bg-gray-100 my-2 py-4">
            <h2 className="px-12">Whole Sale</h2>

            <div className="flex  gap-2 flex-col px-12">
              {percentDiscount?.wholesale_price?.map((wholeSale) => (
                <div className="bg-gray-200 p-2">
                  <p>
                    <span className="font-bold">Min Quantity:</span>{" "}
                    {wholeSale?.min_quantity}
                  </p>
                  <p>
                    <span className="font-bold">Nax Quantity: </span>
                    {wholeSale?.max_quantity}
                  </p>
                  <p>
                    <span className="font-bold">Amount: </span>
                    {wholeSale?.amount}
                  </p>
                  <p>
                    <span className="font-bold">Unit: </span>
                    {wholeSale?.unit}
                  </p>
                </div>
              ))}
            </div>
            <div className="flex px-8 pb-0 ">
              <div className="w-full px-4">
                <div className="flex flex-col mb-4 ">
                  <label
                    for=""
                    className="w-full mb-3 block text-base text-black font-light"
                  >
                    Min Quantity <span className="text-orange-500">*</span>
                  </label>
                  <div className="w-full">
                    <Input
                      type="text"
                      placeholder=""
                      value={wholeSaleObj.min_quantity}
                      onChange={(e) =>
                        setWholeSaleObj({
                          ...wholeSaleObj,
                          min_quantity: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex px-8 pb-0 ">
              <div className="w-full px-4">
                <div className="flex flex-col mb-4 ">
                  <label
                    for=""
                    className="w-full mb-3 block text-base text-black font-light"
                  >
                    Max Quantity <span className="text-orange-500">*</span>
                  </label>
                  <div className="w-full">
                    <Input
                      type="text"
                      placeholder=""
                      value={wholeSaleObj.max_quantity}
                      onChange={(e) =>
                        setWholeSaleObj({
                          ...wholeSaleObj,
                          max_quantity: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex px-8 pb-0 ">
              <div className="w-full px-4">
                <div className="flex flex-col mb-4 ">
                  <label
                    for=""
                    className="w-full mb-3 block text-base text-black font-light"
                  >
                    Unit <span className="text-orange-500">*</span>
                  </label>
                  <div className="w-full">
                    <Input
                      type="text"
                      placeholder=""
                      value={wholeSaleObj.unit}
                      onChange={(e) =>
                        setWholeSaleObj({
                          ...wholeSaleObj,
                          unit: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex px-8 pb-0 ">
              <div className="w-full px-4">
                <div className="flex flex-col mb-4 ">
                  <label
                    for=""
                    className="w-full mb-3 block text-base text-black font-light"
                  >
                    Amount <span className="text-orange-500">*</span>
                  </label>
                  <div className="w-full">
                    <Input
                      type="text"
                      placeholder=""
                      value={wholeSaleObj.amount}
                      onChange={(e) =>
                        setWholeSaleObj({
                          ...wholeSaleObj,
                          amount: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="px-12">
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleWholeSalePrice}
              >
                Add WholeSale
              </button>
            </div>
          </div>

          <div className="pb-8 pr-8 text-right">
            {percentDiscountObj?.product_name ? (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryEdit}
              >
                Update
              </button>
            ) : (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryAdd}
              >
                Save
              </button>
            )}
          </div>
        </div>
        <Toast />
      </div>
    </>
  );
}
