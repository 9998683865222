import { apiSlice } from "../api/apiSlice";

export const rmsCheckMyPermissionApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRmsCheckMyPermission: builder.query({
      query: () => `/admin/rms/my-permissions/`,
      providesTags: ["rmsCheckMyPermission"],
    }),
  }),
});

export const { useGetRmsCheckMyPermissionQuery } = rmsCheckMyPermissionApi;
