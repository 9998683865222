import React from 'react'
import Layout from '../Layout/Layout'
import AddonsComponent from './Components/AddonsComponent/AddonsComponent'

export default function Addons({ showSidebar, setShowSidebar }) {
    return (
        <Layout showSidebar={showSidebar} setShowSidebar={setShowSidebar}>
            <AddonsComponent />
        </Layout>
    )
}
