import { apiSlice } from "../api/apiSlice";

export const bannerApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBanner: builder.query({
      query: () => `/admin/banners`,
      providesTags: ["banner"],
    }),
    addBanner: builder.mutation({
      query: (data) => ({
        url: "/admin/add-banner",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["banner"],
    }),
    editBannerOperation: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/banner/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["banner"],
    }),
    deleteBanner: builder.mutation({
      query: (id) => ({
        url: `/admin/banner/${id}`,
        method: "DELETE",
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["banner"],
    }),
  }),
});

export const {
  useGetBannerQuery,
  useAddBannerMutation,
  useEditBannerOperationMutation,
  useDeleteBannerMutation,
} = bannerApi;
