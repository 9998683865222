import React, { useEffect, useState } from "react";
import {
  useDeleteProductsMutation,
  useGetProductsQuery,
} from "../../../features/products/productsApi";
import {
  FaExternalLinkAlt,
  FaPlus,
  FaRegEdit,
  FaTimes,
  FaTrashAlt,
} from "react-icons/fa";
import Input from "../../../Shared/Components/Input";
import InputFile from "../../../Shared/Components/InputFile";
import Modal from "../../../Shared/Modal";
import { toast } from "react-toastify";
import {
  useAddProductImageMutation,
  useDeleteProductImageMutation,
} from "../../../features/productImage/productImageApi";
import EditOption from "../../../Shared/Components/EditOption";
import DeleteConfirmation from "../../../Shared/Components/DeleteConfirmation";

export default function ProductsTable({ setPercentDiscountObj }) {
  let [showModal, setShowModal] = useState(false);
  let [showImageDelete, setShowImageDelete] = useState(false);
  let [showAddImage, setShowAddImage] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedImageId, setSelectedImageId] = useState("");
  const [image, setImage] = useState("");

  const { data: products, isLoading, isError, error } = useGetProductsQuery();
  const [deleteProducts, { isSuccess }] = useDeleteProductsMutation();
  const [deleteProductImage, { isSuccess: isImageDelte }] =
    useDeleteProductImageMutation();

  const [addProductImage, { isSuccess: isProductImageSuccess }] =
    useAddProductImageMutation();

  console.log("products", products);

  useEffect(() => {
    if (isSuccess || isImageDelte) {
      setShowModal(false);
      toast.success("Successfully delted!");
    }
  }, [isSuccess, isImageDelte]);

  useEffect(() => {
    if (isProductImageSuccess) {
      toast.success("Successfully Product Image added.");
      setImage("");
      setSelectedId(null);
    }
  }, [isProductImageSuccess]);

  function handleImageUpload(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      setImage(reader.result);
    };
  }

  const handleImageAdd = () => {
    addProductImage({
      product_id: selectedId,
      image: [image],
    });
  };

  const handleSellerDelete = () => {
    if (!selectedId) {
      toast.error("Please select delete option first.");
      return;
    }
    deleteProducts(selectedId);
  };

  const handleImageDelete = () => {
    if (!selectedImageId) {
      toast.error("Please select Image for deleting");
      return;
    }
    deleteProductImage(selectedImageId);
  };

  return (
    <>
      <section className="bg-white shadow-xl rounded-md m-4 py-6 px-4 overflow-hidden lg:py-8">
        <div className="flex flex-col justify-between items-center pb-4 px-4 md:flex-row">
          <p className="w-1/4px-8 py-4 font-semibold">Product information</p>
          {/* <div className="w-full md:w-3/4 flex flex-col items-center space-x-0 space-y-2 md:flex-row md:space-x-2 md:space-y-0 ">
            <div className="w-full md:w-1/4">
              <Input type="text" placeholder="Type & Enter" />
            </div>
            <div className="w-full md:w-1/4">
              <select
                id="selectID"
                className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
              >
                <option className="bg-orange-400 text-white p-4">
                  All Sellers
                </option>
                <option className="bg-orange-400 text-white p-4">
                  Steve Smith
                </option>
                <option className="bg-orange-400 text-white p-4">
                  Ailster Cook
                </option>
              </select>
            </div>
            <div className="w-full md:w-1/4">
              <select
                id="selectID"
                className="w-full border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
              >
                <option className="bg-orange-400 text-white p-4">
                  Sort By
                </option>
                <option className="bg-orange-400 text-white p-4">{`Rating(High < Low)`}</option>
                <option className="bg-orange-400 text-white p-4">{`Rating(Low < High)`}</option>
                <option className="bg-orange-400 text-white p-4">{`Num of Sale(Low < High)`}</option>
                <option className="bg-orange-400 text-white p-4">{`Num of Sale(High < Low)`}</option>
              </select>
            </div>
            <div className="w-full md:w-1/4">
              <select
                id="selectID"
                className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
              >
                <option className="bg-orange-400 text-white p-4">
                  Sort By
                </option>
                <option className="bg-orange-400 text-white p-4">{`Rating(High < Low)`}</option>
                <option className="bg-orange-400 text-white p-4">{`Rating(Low < High)`}</option>
                <option className="bg-orange-400 text-white p-4">{`Num of Sale(Low < High)`}</option>
                <option className="bg-orange-400 text-white p-4">{`Num of Sale(High < Low)`}</option>
              </select>
            </div>
          </div> */}
        </div>
        <div className="border-b border-b-gray-200"></div>
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4 mx-4">
            <div className="max-w-full overflow-x-auto">
              <table className="w-full table-auto">
                <thead>
                  <tr className="text-left text-dark font-bold">
                    <th className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Product Name
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Product Details
                    </th>
                    <th className="w-1/6 min-w-[360px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Product images
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Weight Unit
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Weight Type
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Product Code
                    </th>
                    <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      video
                    </th>
                    <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Product Origin
                    </th>
                    {/* <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                    Images
                  </th> */}
                    <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 text-lg font-semibold lg:py-7">
                      In Stock
                    </th>
                    <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Options
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {products?.data?.map((product) => (
                    <tr className="border-t border-[#ddd] text-left text-dark py-4">
                      <td className="py-2 px-2  text-base">
                        <div className="flex items-center">
                          <p>{product?.product_name}</p>
                        </div>
                      </td>
                      <td className="py-2 px-2  text-base">
                        {product?.product_details}
                      </td>
                      <td className="py-2 px-2  text-base">
                        <div className="flex flex-wrap">
                          {product?.images?.map((image) => (
                            <div className="relative group">
                              <img
                                src={image?.img}
                                alt=""
                                key={image?.id}
                                className="w-14 h-14"
                              />
                              <FaTrashAlt
                                className="hidden group-hover:block group-hover:absolute group-hover:top-0 group-hover:right-0 cursor-pointer"
                                onClick={() => {
                                  setSelectedImageId(image?.id);
                                  setShowImageDelete(true);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                        <div
                          className="flex gap-2 items-center justify-center bg-red-200 mt-2 py-2 rounded-md w-[150px] cursor-pointer"
                          onClick={() => {
                            setSelectedId(product?.id);
                            setShowAddImage(true);
                          }}
                        >
                          Add Image
                          <FaPlus />
                        </div>
                      </td>
                      <td className="py-2 px-2  text-base">
                        <span className="font-bold">
                          {product?.weight_unit}
                        </span>
                      </td>
                      <td className="py-2 px-2  text-base">
                        <span className="font-bold">
                          {product?.weight_type}
                        </span>
                      </td>
                      <td className="py-2 px-2  text-base">
                        <span className="font-bold">
                          {product?.product_code}:
                        </span>
                      </td>
                      <td className="py-2 px-2 ">
                        <div className="flex space-x-2">
                          <div className="p-2 bg-green-200 rounded-full cursor-pointer">
                            <a href={product?.video_url} target="_blank">
                              <FaExternalLinkAlt className="fill-green-500" />
                            </a>
                          </div>
                        </div>
                      </td>
                      <td className="py-2 px-2  text-base">
                        <span className="font-bold">
                          {product?.product_origin}:
                        </span>
                      </td>
                      {/* <td className="py-2 px-2  text-base">
                      <div className="flex">
                        {product?.images?.map((image) => (
                          <img
                            src={image?.img}
                            alt="watch"
                            className="w-20 h-20"
                          />
                        ))}
                      </div>
                    </td> */}
                      <td className="py-2 px-2  text-base">
                        <span className="font-bold">{product?.in_stock}:</span>
                      </td>
                      <td className="y-2 px-2  text-base">
                        <div className="flex space-x-2">
                          <EditOption
                            onClick={() => setPercentDiscountObj(product)}
                          />

                          <div className="p-2 bg-red-200 rounded-full cursor-pointer">
                            <FaTrashAlt
                              className="fill-red-500"
                              onClick={() => {
                                setSelectedId(product?.id);
                                setShowModal(true);
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      {/* Delete Modal */}
      {showModal ? (
        <Modal show={showModal}>
          <DeleteConfirmation
            handleDelete={handleSellerDelete}
            setShowModal={setShowModal}
          />
        </Modal>
      ) : null}

      {/* Add Image modal */}
      {showAddImage ? (
        <Modal show={showAddImage}>
          <div className="w-full max-w-[470px] rounded-md bg-white text-center">
            <div className="flex justify-between items-center px-8 py-4 ">
              <p className="font-semibold">Add Image</p>
              <FaTimes
                onClick={() => setShowAddImage(false)}
                className="fill-gray-500 cursor-pointer"
              />
            </div>
            <div className="border-b border-b-gray-200"></div>
            <span className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"></span>
            <div className="w-full px-4 ">
              <div className="flex flex-col mb-4 md:flex-row">
                <label
                  for=""
                  className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
                >
                  Image
                </label>
                <div className="w-full md:w-3/4">
                  <InputFile onChange={handleImageUpload} />
                </div>
              </div>
              {image && (
                <img
                  src={image}
                  alt="Uploaded image"
                  className="w-full h-auto"
                />
              )}
            </div>
            <div className="flex justify-center space-x-6 pb-10">
              <button
                onClick={() => setShowAddImage(false)}
                className="text-dark block rounded-md border border-[#E9EDF9] p-3 text-center text-base font-medium transition hover:border-red-600 hover:bg-red-600 hover:text-white"
              >
                Cancel
              </button>
              <button
                className="bg-orange-500 border-orange-500 block rounded-md border p-3 text-center text-base font-medium text-white transition hover:bg-opacity-90"
                onClick={() => {
                  setShowAddImage(false);
                  handleImageAdd();
                }}
              >
                Add
              </button>
            </div>
          </div>
        </Modal>
      ) : null}

      {/* Delete Image modal */}
      {showImageDelete ? (
        <Modal show={showImageDelete}>
          <div className="w-full max-w-[470px] rounded-md bg-white text-center">
            <div className="flex justify-between items-center px-8 py-4 ">
              <p className="font-semibold">Delete Image Confirmation</p>
              <FaTimes
                onClick={() => setShowImageDelete(false)}
                className="fill-gray-500 cursor-pointer"
              />
            </div>
            <div className="border-b border-b-gray-200"></div>
            <span className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"></span>
            <p className="text-body-color mb-10 text-base leading-relaxed">
              Are you sure to delete this?
            </p>
            <div className="flex justify-center space-x-6 pb-10">
              <button
                onClick={() => setShowModal(false)}
                className="text-dark block rounded-md border border-[#E9EDF9] p-3 text-center text-base font-medium transition hover:border-red-600 hover:bg-red-600 hover:text-white"
              >
                Cancel
              </button>
              <button
                className="bg-orange-500 border-orange-500 block rounded-md border p-3 text-center text-base font-medium text-white transition hover:bg-opacity-90"
                onClick={() => {
                  setShowImageDelete(false);
                  handleImageDelete();
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
}
