import React, { useEffect, useState } from "react";
import { useGetRmsGroupMenuQuery } from "../../../../features/rmsGroupMenu/rmsGroupMenuApi";
import {
  useAddPermissionMutation,
  useEditPermissionMutation,
} from "../../../../features/rmsPermission/rmsPermissionApi";
import { toast } from "react-toastify";
import Toast from "../../../../Shared/Components/Toast";
import { useGetRmsUserQuery } from "../../../../features/rmsUsers/rmsUsersApi";

export default function AddRmsPermission({ categoryObj, setCategoryObj }) {
  let [groupId, setGroupId] = useState("");
  let [userId, setUserId] = useState("");
  let [menu, setMenu] = useState("");
  let [menus, setMenus] = useState([]);

  useEffect(() => {
    if (categoryObj?.id) {
      setGroupId("");
      setUserId(categoryObj?.user_info?.id);
    }
  }, [categoryObj]);

  const { data: allRmsGroupMenu } = useGetRmsGroupMenuQuery();
  const { data: allRmsUser } = useGetRmsUserQuery();
  const [addPermission, { isSuccess: isAddCategorySuccess }] =
    useAddPermissionMutation();
  const [
    editPermission,
    { isSuccess: isEditCategorySuccess, isLoading, isError, error },
  ] = useEditPermissionMutation();

  useEffect(() => {
    if (isAddCategorySuccess) {
      toast.success("Successfully category added.");
      setGroupId("");
      setUserId("");
    }
  }, [isAddCategorySuccess]);

  useEffect(() => {
    if (isEditCategorySuccess) {
      toast.success("Successfully category Updated.");
      setGroupId("");
      setUserId("");
      setCategoryObj({});
    }
    if (isError) {
      toast.error(error.error);
    }
  }, [isEditCategorySuccess, isError]);

  const handleCategoryAdd = (e) => {
    if (!groupId || !userId) {
      toast.error("please select all the required field");
      return;
    }
    addPermission({
      group_id: [groupId],
      user_id: userId,
    });
  };

  const handleCategoryEdit = async (e) => {
    await editPermission({
      id: categoryObj?.id,
      data: {
        group_id: [groupId],
        user_id: userId,
      },
    });
  };

  return (
    <>
      <div className="w-1/3">
        <div className="bg-white rounded-md shadow-xl ">
          <p className="px-8 py-4 font-semibold">
            {categoryObj?.id ? "Update" : "Add"} permission
          </p>
          <div className="border-b border-b-gray-200"></div>
          <div className="w-full px-10 pt-4">
            <div className="flex flex-col  ">
              <label
                for=""
                className="w-full  mb-3 block text-base text-black font-light"
              >
                Select Group <span className="text-orange-500">*</span>
              </label>
              <div className="w-full ">
                <select
                  id="selectID"
                  className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
                  onChange={(e) => setGroupId(e.target.value)}
                >
                  <option
                    className="bg-orange-400 text-white p-4 w-full"
                    value=""
                  >
                    Select Group
                  </option>
                  {allRmsGroupMenu?.data?.map((menu) => (
                    <option
                      className="bg-orange-400 text-white p-4 w-full"
                      value={menu?.id}
                    >
                      {menu?.group_info?.group_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="w-full px-10 pt-4">
            <div className="flex flex-col  ">
              <label
                for=""
                className="w-full  mb-3 block text-base text-black font-light"
              >
                Select User <span className="text-orange-500">*</span>
              </label>
              <div className="w-full ">
                <select
                  id="selectID"
                  className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
                  onChange={(e) => setUserId(e.target.value)}
                >
                  <option
                    className="bg-orange-400 text-white p-4 w-full"
                    value=""
                  >
                    Select User
                  </option>
                  {allRmsUser?.data?.map((user) => (
                    <option
                      className="bg-orange-400 text-white p-4 w-full"
                      value={user?.id}
                    >
                      {user?.email}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="pb-8 pr-8 text-right pt-4">
            {categoryObj?.id ? (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryEdit}
              >
                Update
              </button>
            ) : (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryAdd}
              >
                Save
              </button>
            )}
          </div>
        </div>
        <Toast />
      </div>
    </>
  );
}
