import React from "react";
import FlatDiscountComponent from "./Components/FlatDiscountComponent/FlatDiscountComponent";

export default function FlatDiscount() {
  return (
    <div>
      <FlatDiscountComponent />
    </div>
  );
}
