import React, { useEffect, useState } from "react";
import Input from "../../../../Shared/Components/Input";
import {
  useGetWirehouseOrderTrackMutation,
  useSendProductWorldwideMutation,
} from "../../../../features/wirehouseApi/wirehouseProductApi";
import { useAcceptProductMutation } from "../../../../features/wirehouseApi/wirehouseProductApi";
import { toast } from "react-toastify";
import Toast from "../../../../Shared/Components/Toast";
import { useGetAllWirehouseQuery } from "../../../../features/wirehouseApi/wirehouseListApi";

export default function WirehouseOrderTrackComponent() {
  const [showWorldWide, setShowWorldWide] = useState(false);
  const [worldwideNote, setWorldwideNote] = useState("");
  const [destination, setDestination] = useState("");
  const [showNote, setShowNote] = useState(false);
  const [note, setNote] = useState("");
  const [orderId, setOrderId] = useState("");

  const [
    getWirehouseOrderTrack,
    { data, isLoading, isSuccess, isError, error },
  ] = useGetWirehouseOrderTrackMutation();

  const [
    sendProductWorldwide,
    {
      data: worldWideData,
      isLoading: worldWideIsLoadign,
      isSuccess: worldwideIsSuccess,
      isError: worldwideIsError,
      error: worldwideError,
    },
  ] = useSendProductWorldwideMutation();

  const { data: allWirehouse } = useGetAllWirehouseQuery();

  const [
    acceptProduct,
    {
      data: acceptProductData,
      isLoading: isAcceptLoading,
      isSuccess: isAcceptIsSuccess,
      isError: isAcceptIsError,
      error: acceptError,
    },
  ] = useAcceptProductMutation();

  // console.log(
  //   "Worldwide",
  //   worldWideData,
  //   worldWideIsLoadign,
  //   worldwideIsSuccess,
  //   worldwideIsError,
  //   worldwideError
  // );

  useEffect(() => {
    if (worldwideIsSuccess) {
      toast.success("Successfully Check In");
      setDestination("");
      setWorldwideNote("");
    }
  }, [worldwideIsSuccess]);

  useEffect(() => {
    if (isAcceptIsSuccess) {
      toast.success("Successfully Check In");
      setNote("");
    }
  }, [isAcceptIsSuccess]);

  const handleOrderTrack = () => {
    getWirehouseOrderTrack({ order_id: orderId });
  };

  const handleConfirm = () => {
    acceptProduct({ id: orderId, data: { note } });
  };

  const handleWorldWide = () => {
    sendProductWorldwide({
      id: orderId,
      data: {
        destination_wirehouse_id: destination,
        note: worldwideNote,
      },
    });
  };

  let content = null;

  if (isLoading) {
    content = <div>Loading</div>;
  }
  if (!isLoading && isError) {
    content = <div>{error?.error}</div>;
  }

  if (!isLoading && !isError) {
    content = (
      <div className="max-w-5xl mx-auto p-10 rounded-md shadow-md bg-white my-10">
        <div className="py-10 px-8 flex flex-col gap-2">
          <h1 className="text-center font-bold text-xl ">Seller Info</h1>
          <img
            src={data?.data?.seller_info?.logo}
            alt={data?.data?.seller_info?.shop_name}
            className="mx-auto"
          />
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Shop Name:</p>
            <p className="col-span-3">{data?.data?.seller_info?.shop_name}</p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Phone:</p>
            <p className="col-span-3">{data?.data?.seller_info?.phone}</p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Email:</p>
            <p className="col-span-3">{data?.data?.seller_info?.email}</p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Address:</p>
            <p className="col-span-3">{data?.data?.seller_info?.address}</p>
          </div>
        </div>
        <div className="py-10 px-8 flex flex-col gap-2">
          <h1 className="text-center font-bold text-xl ">Product Info</h1>
          <img
            src={data?.data?.product_info?.image}
            alt={data?.data?.product_info?.product_name}
            className="mx-auto"
          />
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Product Id:</p>
            <p className="col-span-3">{data?.data?.product_info?.product_id}</p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Product Name:</p>
            <p className="col-span-3">
              {data?.data?.product_info?.product_name}
            </p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Variant Info:</p>
            <p className="col-span-3 bg-gray-200 p-4 mb-2">
              {data?.data?.product_info?.varient_info?.map((variant) => (
                <div>
                  <p>
                    <span>size: </span> {variant?.size}
                  </p>
                  <p>
                    <span>color: </span> {variant?.color}
                  </p>
                  <p>
                    <span>stock: </span> {variant?.stock}
                  </p>
                </div>
              ))}
            </p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Product Quantity:</p>
            <p className="col-span-3">{data?.data?.product_info?.quantity}</p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Seller Status:</p>
            <p className="col-span-3">
              {data?.data?.product_info?.seller_status}
            </p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Wirehouse Status:</p>
            <p className="col-span-3">
              {data?.data?.product_info?.wirehouse_status}
            </p>
          </div>
        </div>

        <div className="py-10 px-8 flex flex-col gap-2">
          <h1 className="text-center font-bold text-xl ">Shipping Info</h1>

          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Destination Country:</p>
            <p className="col-span-3">
              {data?.data?.shipping_info?.Destination_Country}
            </p>
          </div>
          <div className="grid gap-2 grid-cols-4 text-left">
            <p className="col-span-1 font-bold">Shipping Type:</p>
            <p className="col-span-3">
              {data?.data?.shipping_info?.Shipping_Type}
            </p>
          </div>
        </div>
        {!showNote ? (
          <div className="pb-8 px-8">
            <button
              className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
              onClick={() => setShowNote(true)}
            >
              Accept Product
            </button>
          </div>
        ) : null}
        {showNote ? (
          <>
            <div className="flex flex-col mb-4 px-8">
              <label
                for=""
                className="w-full mb-3 block text-base text-black font-light"
              >
                Note: <span className="text-orange-500">*</span>
              </label>
              <div className="w-full">
                <Input
                  type="text"
                  placeholder="Accept Note"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </div>
            <div className="pb-8 px-8">
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleConfirm}
              >
                {isAcceptLoading ? "Processing" : "Confirm"}
              </button>
            </div>
          </>
        ) : null}
        {!showWorldWide ? (
          <div className="pb-8 px-8">
            <button
              className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
              onClick={() => setShowWorldWide(true)}
            >
              Send Product Worldwide
            </button>
          </div>
        ) : null}

        {showWorldWide ? (
          <>
            <div className="w-full px-8">
              <select
                id="selectID"
                className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
                value={destination}
                onChange={(e) => setDestination(e.target.value)}
              >
                <option
                  className="bg-orange-400 text-white p-4 w-full"
                  value=""
                >
                  Select Category
                </option>
                {allWirehouse?.data?.map((wirehouse) => (
                  <option
                    className="bg-orange-400 text-white p-4 w-full"
                    value={wirehouse?.id}
                  >
                    {wirehouse?.country}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col mb-4 px-8">
              <label
                for=""
                className="w-full mb-3 block text-base text-black font-light"
              >
                Note: <span className="text-orange-500">*</span>
              </label>
              <div className="w-full">
                <Input
                  type="text"
                  placeholder="Accept Note"
                  value={worldwideNote}
                  onChange={(e) => setWorldwideNote(e.target.value)}
                />
              </div>
            </div>
            <div className="pb-8 px-8">
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleWorldWide}
                disabled={worldWideIsLoadign}
              >
                {worldWideIsLoadign ? "Processing" : "Confirm"}
              </button>
            </div>
          </>
        ) : null}
      </div>
    );
  }

  return (
    <div>
      <div className="flex p-8 pb-0">
        <div className="w-full px-4">
          <div className="flex flex-col mb-4 ">
            <label
              for=""
              className="w-full mb-3 block text-base text-black font-light"
            >
              Order Id <span className="text-orange-500">*</span>
            </label>
            <div className="w-full">
              <Input
                type="text"
                placeholder="Order Id"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
              />
            </div>
          </div>

          <div className="pb-8 pr-8">
            <button
              className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
              onClick={handleOrderTrack}
            >
              {isLoading ? "Processing" : "Search"}
            </button>
          </div>
        </div>
      </div>
      {data?.data?.seller_info?.shop_name ? content : null}
      <Toast />
    </div>
  );
}
