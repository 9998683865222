import React, { useEffect, useState } from "react";
import {
  useDeleteSubcategoryMutation,
  useGetSubCategoryQuery,
} from "../../../features/subCategoryApi/subCategoryApi";
import { FaRegEdit, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import EditOption from "../../../Shared/Components/EditOption";
import Modal from "../../../Shared/Modal";
import DeleteConfirmation from "../../../Shared/Components/DeleteConfirmation";

export default function SubCategoryTable({ setSubCategoryObj }) {
  let [selectedId, setSelectedId] = useState(null);
  let [showModal, setShowModal] = useState(false);

  const {
    data: allSubCategory,
    isLoading,
    isError,
    error,
  } = useGetSubCategoryQuery();
  const [deleteSubcategory, { isSuccess }] = useDeleteSubcategoryMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Successfully category deleted");
    }
  }, [isSuccess]);

  const handleDeleteSubCategory = () => {
    deleteSubcategory(selectedId);
  };
  return (
    <div className="w-full md:w-2/3 overflow-x-hidden">
      <section className="bg-white shadow-xl rounded-md mb-4 m-0 md:m-4 py-6 lg:my-0 lg:py-8">
        <div className="flex flex-col justify-between items-center pb-4 px-4 md:flex-row">
          <p className="w-3/4 px-8 py-4 font-semibold">All sub category</p>
        </div>
        <div className="border-b border-b-gray-200"></div>
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4 mx-4">
            <div className="max-w-full overflow-x-auto">
              <table className="w-full table-auto">
                <thead>
                  <tr className="text-left text-dark font-bold">
                    <th className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      #
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Category Name
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Sub Category Name
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Image
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Options
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allSubCategory?.data?.map((category) => (
                    <tr className="border-t border-[#ddd] text-left text-dark py-4">
                      <td className="py-2 px-2  text-base">
                        <div className="flex items-center">{category?.id}</div>
                      </td>
                      <td className="py-2 px-2  text-base">
                        {category?.catagory_name}
                      </td>
                      <td className="py-2 px-2  text-base">
                        {category?.sub_catagory_name}
                      </td>
                      <td className="py-2 px-2  text-base">
                        <div className="flex space-x-2">
                          <img
                            src={category?.image}
                            alt={category?.name}
                            className="w-20 h-auto"
                          />
                        </div>
                      </td>
                      <td className="y-2 px-2  text-base">
                        <div className="flex space-x-2 cursor-pointer">
                          <EditOption
                            onClick={() => setSubCategoryObj(category)}
                          />
                          <div className="p-2 bg-red-200 rounded-full cursor-pointer">
                            <FaTrashAlt
                              className="fill-red-500"
                              onClick={() => {
                                setSelectedId(category?.id);
                                setShowModal(true);
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      {/* Delete Modal */}
      {showModal ? (
        <Modal show={showModal}>
          <DeleteConfirmation
            handleDelete={handleDeleteSubCategory}
            setShowModal={setShowModal}
          />
        </Modal>
      ) : null}
    </div>
  );
}
