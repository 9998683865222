import React, { useState } from "react";
import Toast from "../../../Shared/UI/Toast";
import { useGetLivesQuery } from "../../../features/lives/livesApi";
import { FaEye, FaTimes } from "react-icons/fa";
import Modal from "../../../Shared/Modal";
import SellerLiveInfo from "./SellerLiveInfo";
import SellerInfo from "./SellerInfo";

export default function LivesTable() {
  const [sellerInfo, setSellerInfo] = useState({});
  const [liveInfo, setLiveInfo] = useState({});

  console.log("sellerInfo", sellerInfo);

  const { data: allLives, isLoading, isError, error } = useGetLivesQuery();

  let content = null;

  if (isLoading) {
    content = <div>Loading</div>;
  }
  if (!isLoading && isError) {
    content = <div>{error?.error}</div>;
  }

  if (!isLoading && !isError) {
    content = (
      <tbody>
        {allLives?.data?.map((live) => (
          <tr className="border-t border-[#ddd] text-left text-dark py-4">
            <td className="py-2 px-4  text-base">{live?.seller_info[0]?.id}</td>
            <td className="py-2 px-4  text-base">
              <FaEye onClick={() => setSellerInfo(live?.seller_info[0])} />
            </td>
            <td className="py-2 px-4  text-base">
              <FaEye onClick={() => setLiveInfo(live?.live_info)} />
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
  return (
    <>
      <div className="w-full md:w-2/3 overflow-x-hidden">
        <section className="bg-white shadow-xl rounded-md mb-4 m-0 md:m-4 py-6 lg:my-0 lg:py-8">
          <div className="flex flex-col justify-between items-center pb-4 px-4 md:flex-row">
            <p className="w-3/4 px-8 py-4 font-semibold">All Lives</p>
          </div>
          <div className="border-b border-b-gray-200"></div>
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4 mx-4">
              <div className="max-w-full overflow-x-auto">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="text-left text-dark font-bold">
                      <th className="w-1/6 min-w-[100px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        #
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Seller Info
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Live Info
                      </th>
                    </tr>
                  </thead>
                  {content}
                </table>
              </div>
            </div>
          </div>
          <Toast />
        </section>
        {sellerInfo?.id && (
          <Modal show={sellerInfo?.id}>
            <div className="w-full max-w-[980px] rounded-md bg-white text-center">
              <div className="flex justify-between items-center px-8 py-4 ">
                <p className="font-semibold">Seller Info</p>
                <FaTimes
                  onClick={() => {
                    setSellerInfo({});
                  }}
                  className="fill-gray-500 cursor-pointer"
                />
              </div>
              <div className="border-b border-b-gray-200"></div>
              <span className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"></span>
              <SellerInfo sellerInfo={sellerInfo} />
            </div>
          </Modal>
        )}
        {liveInfo?.id && (
          <Modal show={liveInfo?.id}>
            <div className="w-full max-w-[980px] rounded-md bg-white text-center">
              <div className="flex justify-between items-center px-8 py-4 ">
                <p className="font-semibold">Live Info</p>
                <FaTimes
                  onClick={() => {
                    setLiveInfo({});
                  }}
                  className="fill-gray-500 cursor-pointer"
                />
              </div>
              <div className="border-b border-b-gray-200"></div>
              <span className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"></span>
              <SellerLiveInfo liveInfo={liveInfo} />
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}
