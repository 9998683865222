import React from 'react'
import UnitComponents from './Components/UnitComponents/UnitComponents'

export default function Units() {
    return (
        <>
            <UnitComponents />
        </>
    )
}
