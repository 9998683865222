import { apiSlice } from "../api/apiSlice";
import { userLoggedIn } from "./authSLice";

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: "/admin/login",
        method: "POST",
        body: data,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;

          // console.log("result", result);
          // console.log("arg result", arg?.email)

          localStorage.setItem(
            "auth",
            JSON.stringify({
              token: result.data.token,
              user: arg?.email,
              userType: result.data.user_type,
            })
          );

          dispatch(
            userLoggedIn({
              token: result.data.token,
              user: arg.email,
              userType: result.data.user_type,
            })
          );
        } catch (err) {
          // do nothing
        }
      },
    }),
  }),
});

export const { useLoginMutation } = authApi;
