import React, { useEffect, useState } from "react";
import {
  useAddGroupMenuMutation,
  useEditGroupMenuMutation,
} from "../../../../features/rmsGroupMenu/rmsGroupMenuApi";
import { toast } from "react-toastify";
import Input from "../../../../Shared/Components/Input";
import Toast from "../../../../Shared/Components/Toast";
import { useGetRmsMenuQuery } from "../../../../features/rmsMenu/rmsMenuApi";
import { FaTrashAlt } from "react-icons/fa";
import { useGetGroupNameQuery } from "../../../../features/rmsGroupName/rmsGroupNameApi";

export default function AddRmsGroupMenu({ categoryObj, setCategoryObj }) {
  let [groupId, setGroupId] = useState("");
  let [menu, setMenu] = useState("");
  let [menus, setMenus] = useState([]);

  console.log("categoryObj", categoryObj);
  useEffect(() => {
    if (categoryObj?.group_id) {
      setMenus(categoryObj?.menus);
      setGroupId(categoryObj?.group_id);
    }
  }, [categoryObj]);

  const { data: allRmsGroupName } = useGetGroupNameQuery();
  const [addGroupMenu, { isSuccess: isAddCategorySuccess }] =
    useAddGroupMenuMutation();
  const [
    editGroupMenu,
    { isSuccess: isEditCategorySuccess, isLoading, isError, error },
  ] = useEditGroupMenuMutation();

  useEffect(() => {
    if (isAddCategorySuccess) {
      toast.success("Successfully category added.");
      setMenus([]);
      setGroupId("");
    }
  }, [isAddCategorySuccess]);

  console.log(
    "isEditCategorySuccess, isError, error",
    isEditCategorySuccess,
    isError,
    error
  );

  useEffect(() => {
    if (isEditCategorySuccess) {
      toast.success("Successfully category Updated.");
      setMenus([]);
      setGroupId("");
      setCategoryObj({});
    }
    if (isError) {
      toast.error(error.error);
    }
  }, [isEditCategorySuccess, isError]);

  const handleAddMenu = () => {
    console.log("menu", menu, menus);
    if (menu) {
      setMenus([...menus, menu]);
      setMenu("");
    }
  };

  const handleCategoryAdd = (e) => {
    addGroupMenu({
      group_id: groupId,
      menus,
    });
  };

  const handleDeleteMenu = (menu) => {
    setMenus((allMenu) => allMenu?.filter((indMenu) => indMenu !== menu));
  };

  console.log("menus", menus);

  const handleCategoryEdit = async (e) => {
    await editGroupMenu({
      id: categoryObj?.id,
      data: {
        group_id: groupId,
        menus,
      },
    });
  };

  return (
    <>
      <div className="w-1/3">
        <div className="bg-white rounded-md shadow-xl ">
          <p className="px-8 py-4 font-semibold">
            {categoryObj?.id ? "Update" : "Add"} group menu
          </p>
          <div className="border-b border-b-gray-200"></div>
          <div className="w-full px-10 pt-4">
            <div className="flex flex-col  ">
              <label
                for=""
                className="w-full  mb-3 block text-base text-black font-light"
              >
                Select Group <span className="text-orange-500">*</span>
              </label>
              <div className="w-full ">
                <select
                  id="selectID"
                  className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
                  onChange={(e) => setGroupId(e.target.value)}
                >
                  <option
                    className="bg-orange-400 text-white p-4 w-full"
                    value=""
                  >
                    Select Group
                  </option>
                  {allRmsGroupName?.data?.map((menu) => (
                    <option
                      className="bg-orange-400 text-white p-4 w-full"
                      value={menu?.id}
                    >
                      {menu?.group_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="px-10 flex gap-4 mt-4">
            {menus?.map((menu) => (
              <div className="group relative">
                <p className=" bg-gray-200 px-4 py-2 rounded-md">{menu}</p>
                <FaTrashAlt
                  className="hidden group-hover:block absolute top-0 right-0 cursor-pointer"
                  onClick={() => handleDeleteMenu(menu)}
                />
              </div>
            ))}
          </div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Menu <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder="Name"
                    value={menu}
                    onChange={(e) => setMenu(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pb-8 pr-8 mt-4 text-right">
            <button
              className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
              onClick={handleAddMenu}
            >
              Add menu
            </button>
          </div>
          <div className="pb-8 pr-8 text-right">
            {categoryObj?.id ? (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryEdit}
              >
                Update
              </button>
            ) : (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryAdd}
              >
                Save
              </button>
            )}
          </div>
        </div>
        <Toast />
      </div>
    </>
  );
}
