import React from 'react'
import Input from '../../../Shared/Components/Input'
import Toggle from '../../../Shared/Components/Toggle'

export default function DeliveryConfigurationComponent() {
    return (
        <div className='p-8'>
            <div className='w-full flex flex-col items-start space-x-0 space-y-8 md:flex-row md:space-x-8 md:space-y-0'>
                <div className='w-full md:w-1/2 bg-white rounded-md shadow-xl '>
                    <p className='px-8 py-4 font-semibold'>Payment Configuration</p>
                    <div className='border-b border-b-gray-200'></div>
                    <div className="flex p-8">
                        <div className="w-full px-4 w-full">
                            <div className="flex flex-col mb-4 md:flex-row">
                                <label for="" className="w-full md:w-2/4 mb-3 block text-base text-black font-light">
                                    Monthly Salary
                                </label>
                                <div className="w-full md:w-2/4 ">
                                    <Toggle />
                                </div>
                            </div>

                            <div className="flex flex-col mb-4 md:flex-row">
                                <label for="" className="w-full md:w-2/4 mb-3 block text-base text-black font-light">
                                    Per Order Commission
                                </label>
                                <div className="w-full md:w-2/4 ">
                                    <Toggle isChecked={true} />
                                </div>
                            </div>

                            <div className="flex flex-col mb-4 md:flex-row">
                                <label for="" className="w-full md:w-2/4 mb-3 block text-base text-black font-light">
                                    Commission Rate
                                </label>
                                <div className="w-full md:w-2/4 ">
                                    <Input type="number" placeholder="10" />
                                </div>
                            </div>
                            <div className='pb-8 text-right'>
                                <button className='bg-orange-500 text-white py-2 px-8 rounded-md'>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full md:w-1/2 bg-white rounded-md shadow-xl '>
                    <p className='px-8 py-4 font-semibold'>Payment Configuration</p>
                    <div className='border-b border-b-gray-200'></div>
                    <div className="flex p-8">
                        <div className="w-full px-4 w-full">
                            <div className="flex flex-col mb-4 md:flex-row">
                                <label for="" className="w-full md:w-2/4 mb-3 block text-base text-black font-light">
                                    Send mail
                                </label>
                                <div className="w-full md:w-2/4 ">
                                    <Toggle />
                                </div>
                            </div>

                            <div className="flex flex-col mb-4 md:flex-row">
                                <label for="" className="w-full md:w-2/4 mb-3 block text-base text-black font-light">
                                    Send OTP
                                </label>
                                <div className="w-full md:w-2/4 ">
                                    <Toggle isChecked={true} />
                                </div>
                            </div>

                            <div className='pb-8 text-right'>
                                <button className='bg-orange-500 text-white py-2 px-8 rounded-md mr-4'>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className='max-w-6xl mx-auto bg-white rounded-md shadow-xl mt-8 m-8'>
                <p className='px-8 py-4 font-semibold'>Delivery Boy Information</p>
                <div className='border-b border-b-gray-200'></div>
                <div className="flex flex-col p-8 pb-0">
                    <div className="w-full px-4 w-full">
                        <div className="flex flex-col mb-4 md:flex-row">
                            <label for="" className="w-full md:w-1/4 mb-3 block text-base text-black font-light">
                                Longitude <span className='text-orange-500'>*</span>
                            </label>
                            <div className='w-full md:w-3/4'>
                                <Input type="text" placeholder="Longitude" />
                            </div>
                        </div>
                    </div>
                    <div className="w-full px-4 w-full">
                        <div className="flex flex-col mb-4 md:flex-row">
                            <label for="" className="w-full md:w-1/4 mb-3 block text-base text-black font-light">
                                Latitude <span className='text-orange-500'>*</span>
                            </label>
                            <div className='w-full md:w-3/4'>
                                <Input type="email" placeholder="Latitude" />
                            </div>
                        </div>
                    </div>


                </div>
                <div className='pb-8 pr-8 text-right'>
                    <button className='bg-orange-500 text-white py-2 px-8 rounded-md mr-4'>Save</button>
                </div>
            </div>
        </div>
    )
}
