import { apiSlice } from "../api/apiSlice";

export const rmsUserApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRmsUser: builder.query({
      query: () => `/admin/rms/users`,
      providesTags: ["rmsUsers"],
    }),
  }),
});

export const { useGetRmsUserQuery } = rmsUserApi;
