import React from 'react'
import ProductBulk from './ProductBulk'

export default function BulkImportComponent() {
  return (
    <div className='p-8'>
        <ProductBulk />
    </div>
  )
}
