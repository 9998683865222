import { apiSlice } from "../api/apiSlice";

export const buyersPaymentMethodApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBuyersPaymentMethod: builder.query({
      query: () => `/admin/buyer/payment_methods`,
      providesTags: ["buyersPaymentMethod"],
    }),
    addBuyersPaymentMethod: builder.mutation({
      query: (data) => ({
        url: "/admin/buyer/payment_methods",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["buyersPaymentMethod"],
    }),
    editBuyersPaymentMethod: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/buyer/payment_methods/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["buyersPaymentMethod"],
    }),
    deleteBuyersPaymentMethod: builder.mutation({
      query: (id) => ({
        url: `/admin/buyer/payment_methods/${id}`,
        method: "DELETE",
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["buyersPaymentMethod"],
    }),
  }),
});

export const {
  useGetBuyersPaymentMethodQuery,
  useAddBuyersPaymentMethodMutation,
  useEditBuyersPaymentMethodMutation,
  useDeleteBuyersPaymentMethodMutation,
} = buyersPaymentMethodApi;
