import React, { useState } from "react";
import RmsMenuTable from "./RmsMenuTable";
import AddRmsMenu from "./AddRmsMenu";

export default function RmsMenuComponent() {
  let [rmsMenuObj, setrmsMenuObj] = useState({});
  return (
    <>
      <p className="p-4 font-bold md:pl-8">All Menu</p>
      <div className="flex flex-col p-4 mb-8  md:flex-row">
        <RmsMenuTable setCategoryObj={setrmsMenuObj} />
        <AddRmsMenu categoryObj={rmsMenuObj} setCategoryObj={setrmsMenuObj} />
      </div>
    </>
  );
}
