import { useState } from "react";
import View from "./Views/View";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sales from "./Views/Sales";
import AddProduct from "./Views/AddProduct";
import Attributes from "./Views/Attributes";
import BulkImport from "./Views/BulkImport";
import AddDelivery from "./Views/AddDelivery";
import DeliveryConfiguration from "./Views/DeliveryConfiguration";
import ClassifiedPackage from "./Views/ClassifiedPackage";
import InHourseReport from "./Views/InHourseReport";
import Updates from "./Views/Updates";
import ServerStatus from "./Views/ServerStatus";
import Addons from "./Views/Addons";
import Pos from "./Views/Pos";
import Login from "./Pages/Login/Login";
import ProtectedRoute from "./Shared/ProtectedRoute/ProtectedRoute";
import useAuthCheck from "./hooks/useAuthCheck";
import Business from "./Views/Business";
import Sellers from "./Views/Sellers";
import Layout from "./Layout/Layout";
import Units from "./Views/Units";
import Banner from "./Views/Banner";
import Category from "./Views/Category";
import SubCategory from "./Views/SubCategory";
import AllProducts from "./Views/AllProducts";
import FlatDiscount from "./Views/FlatDiscount";
import PercentDiscount from "./Views/PercentDiscount";
import SellerPaymentMethod from "./Views/SellerPaymentMethod";
import Varient from "./Views/Varient";
import SellerDeliveryTimes from "./Views/SellerDeliveryTimes";
import SellerExtraChargeType from "./Views/SellerExtraChargeType";
import SellerExtraCharge from "./Views/SellerExtraCharge";
import Lives from "./Views/Lives";
import RefferEarningPolicy from "./Views/RefferEarningPolicy";
import Address from "./Views/Address";
import ShippingPackage from "./Views/ShippingPackage";
import Buyer from "./Views/Buyer";
import RmsMenu from "./Views/RMS/RmsMenu";
import RmsGroupName from "./Views/RMS/RmsGroupName";
import RmsGroupMenu from "./Views/RMS/RmsGroupMenu";
import RmsPermission from "./Views/RMS/RmsPermission";
import ProtectedPermission from "./Shared/ProtectedRoute/ProtectedPermission";
import RouteDenied from "./Views/RouteDenied";
import FeaturedShop from "./Views/FeaturedShop";
import AllWirehouse from "./Views/AllWirehouse";
import Country from "./Views/Country";
import BuyersPaymentMethod from "./Views/BuyersPaymentMethod";
import BuyersBanner from "./Views/BuyersBanner";
import Voucher from "./Views/Voucher";
import BuyerEarningPolicy from "./Views/BuyerEarningPolicy";
import Orders from "./Views/Orders";
import WirehouseLogin from "./Pages/wirehouseLogin/WirehouseLogin";
import useWirehouseAuthCheck from "./hooks/useWirehouseAuthCheck";
import WirehouseProtectedRoute from "./Shared/ProtectedRoute/WirehouseProtectedRoute";
import Wirehouse from "./Views/Wirehouse/Wirehouse";
import WirehouseLayout from "./Layout/WirehouseLayout";
import WirehouseOrderTrack from "./Views/Wirehouse/WirehouseOrderTrack";
import WirehouseList from "./Views/Wirehouse/WirehouseList";
import WirehouseInternationalProduct from "./Views/Wirehouse/WirehouseInternationalProduct";
import WirehouseHandoverCurier from "./Views/Wirehouse/WirehouseHandoverCurier";
import OrderPaymentPending from "./Views/OrderPaymentPending";
import DeliveryPayment from "./Views/DeliveryPayment";

function App() {
  let [showSidebar, setShowSidebar] = useState(true);

  const authIsReady = useAuthCheck();
  const wirehouseIsReady = useWirehouseAuthCheck();
  return !authIsReady ? (
    <div>Checking Authentication.......</div>
  ) : (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <View setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
            </ProtectedRoute>
          }
        />
        {/* Implemented */}
        <Route
          path="/business"
          element={
            <ProtectedPermission link="/business">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <Business
                  setShowSidebar={setShowSidebar}
                  showSidebar={showSidebar}
                />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/sellers"
          element={
            <ProtectedPermission link="/sellers">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <Sellers />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/units"
          element={
            <ProtectedPermission link="/units">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <Units />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/banner"
          element={
            <ProtectedRoute>
              <ProtectedPermission link="/banner">
                <Layout
                  setShowSidebar={setShowSidebar}
                  showSidebar={showSidebar}
                >
                  <Banner />
                </Layout>
              </ProtectedPermission>
            </ProtectedRoute>
          }
        />
        <Route
          path="/category"
          element={
            <ProtectedPermission link="/category">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <Category />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/sub-category"
          element={
            <ProtectedPermission link="/sub-category">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <SubCategory />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/all-products"
          element={
            <ProtectedPermission link="/all-products">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <AllProducts />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/flat-discount"
          element={
            <ProtectedPermission link="/flat-discount">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <FlatDiscount />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/percent-discount"
          element={
            <ProtectedPermission link="/percent-discount">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <PercentDiscount />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/featured-shop"
          element={
            <ProtectedPermission link="/featured-shop">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <FeaturedShop />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/seller/payment-method"
          element={
            <ProtectedPermission link="/seller/payment-method">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <SellerPaymentMethod />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/varient"
          element={
            <ProtectedPermission link="/varient">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <Varient />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/seller-delivery-times"
          element={
            <ProtectedPermission link="/seller-delivery-times">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <SellerDeliveryTimes />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/seller-extra-charge-types"
          element={
            <ProtectedPermission link="/seller-extra-charge-types">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <SellerExtraChargeType />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/seller-extra-charge"
          element={
            <ProtectedPermission link="/seller-extra-charge">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <SellerExtraCharge />
              </Layout>
            </ProtectedPermission>
          }
        />

        <Route
          path="/lives"
          element={
            <ProtectedPermission link="/lives">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <Lives />
              </Layout>
            </ProtectedPermission>
          }
        />

        <Route
          path="/reffer-earning-policy"
          element={
            <ProtectedPermission link="/reffer-earning-policy">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <RefferEarningPolicy />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/address"
          element={
            <ProtectedPermission link="/address">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <Address />
              </Layout>
            </ProtectedPermission>
          }
        />

        <Route
          path="/shipping-package"
          element={
            <ProtectedPermission link="/shipping-package">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <ShippingPackage />
              </Layout>
            </ProtectedPermission>
          }
        />

        <Route
          path="/buyers"
          element={
            <ProtectedPermission link="/buyers">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <Buyer />
              </Layout>
            </ProtectedPermission>
          }
        />

        {/* Rms Route */}
        <Route
          path="/rms/menus"
          element={
            <ProtectedPermission link="/rms/menus">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <RmsMenu />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/rms/group-name"
          element={
            <ProtectedPermission link="/rms/group-name">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <RmsGroupName />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/rms/group-menu"
          element={
            <ProtectedPermission link="/rms/group-menu">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <RmsGroupMenu />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/rms/permissions"
          element={
            <ProtectedPermission link="/rms/permissions">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <RmsPermission />
              </Layout>
            </ProtectedPermission>
          }
        />

        <Route
          path="/all-warehouse"
          element={
            <ProtectedPermission link="/all-warehouse">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <AllWirehouse />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/all-country"
          element={
            <ProtectedPermission link="/all-country">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <Country />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/buyers-payment-method"
          element={
            <ProtectedPermission link="/buyers-payment-method">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <BuyersPaymentMethod />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/buyers-banner"
          element={
            <ProtectedPermission link="/buyers-banner">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <BuyersBanner />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/voucher"
          element={
            <ProtectedPermission link="/voucher">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <Voucher />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/buyer-earning-policy"
          element={
            <ProtectedPermission link="/buyer-earning-policy">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <BuyerEarningPolicy />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/orders"
          element={
            <ProtectedPermission link="/orders">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <Orders />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/payment-pending-order"
          element={
            <ProtectedPermission link="/payment-pending-orde">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <OrderPaymentPending />
              </Layout>
            </ProtectedPermission>
          }
        />
        <Route
          path="/delivery-pending-order"
          element={
            <ProtectedPermission link="/delivery-pending-order">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <DeliveryPayment />
              </Layout>
            </ProtectedPermission>
          }
        />

        {/* Not Implemented */}
        <Route
          path="/sales"
          element={
            <ProtectedPermission link="/sales">
              <Layout setShowSidebar={setShowSidebar} showSidebar={showSidebar}>
                <Sales
                  setShowSidebar={setShowSidebar}
                  showSidebar={showSidebar}
                />
              </Layout>
            </ProtectedPermission>
          }
        />

        <Route
          path="/add-product"
          element={
            <AddProduct
              setShowSidebar={setShowSidebar}
              showSidebar={showSidebar}
            />
          }
        />
        <Route
          path="/attributes"
          element={
            <Attributes
              setShowSidebar={setShowSidebar}
              showSidebar={showSidebar}
            />
          }
        />
        <Route
          path="/bulk-import"
          element={
            <BulkImport
              setShowSidebar={setShowSidebar}
              showSidebar={showSidebar}
            />
          }
        />
        <Route
          path="/delivery-boys/create"
          element={
            <AddDelivery
              setShowSidebar={setShowSidebar}
              showSidebar={showSidebar}
            />
          }
        />
        <Route
          path="/delivery-boys/configuration"
          element={
            <DeliveryConfiguration
              setShowSidebar={setShowSidebar}
              showSidebar={showSidebar}
            />
          }
        />
        <Route
          path="/customer-packages"
          element={
            <ClassifiedPackage
              setShowSidebar={setShowSidebar}
              showSidebar={showSidebar}
            />
          }
        />
        <Route
          path="/in-house-sale-report"
          element={
            <InHourseReport
              setShowSidebar={setShowSidebar}
              showSidebar={showSidebar}
            />
          }
        />
        <Route
          path="/system/updates"
          element={
            <Updates
              setShowSidebar={setShowSidebar}
              showSidebar={showSidebar}
            />
          }
        />
        <Route
          path="/system/server-status"
          element={
            <ServerStatus
              setShowSidebar={setShowSidebar}
              showSidebar={showSidebar}
            />
          }
        />
        <Route
          path="/addons"
          element={
            <Addons setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
          }
        />
        <Route
          path="/pos"
          element={
            <Pos setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
          }
        />

        {/* Wire House Part */}

        <Route
          path="/warehouse-details"
          element={
            <WirehouseProtectedRoute>
              <WirehouseLayout
                setShowSidebar={setShowSidebar}
                showSidebar={showSidebar}
              >
                <Wirehouse />
              </WirehouseLayout>
            </WirehouseProtectedRoute>
          }
        />
        <Route
          path="/warehouse/warehouses"
          element={
            <WirehouseProtectedRoute>
              <WirehouseLayout
                setShowSidebar={setShowSidebar}
                showSidebar={showSidebar}
              >
                <WirehouseList />
              </WirehouseLayout>
            </WirehouseProtectedRoute>
          }
        />
        <Route
          path="/warehouse/order-track"
          element={
            <WirehouseProtectedRoute>
              <WirehouseLayout
                setShowSidebar={setShowSidebar}
                showSidebar={showSidebar}
              >
                <WirehouseOrderTrack />
              </WirehouseLayout>
            </WirehouseProtectedRoute>
          }
        />
        <Route
          path="/warehouse/international-product"
          element={
            <WirehouseProtectedRoute>
              <WirehouseLayout
                setShowSidebar={setShowSidebar}
                showSidebar={showSidebar}
              >
                <WirehouseInternationalProduct />
              </WirehouseLayout>
            </WirehouseProtectedRoute>
          }
        />
        <Route
          path="/warehouse/handover-curier"
          element={
            <WirehouseProtectedRoute>
              <WirehouseLayout
                setShowSidebar={setShowSidebar}
                showSidebar={showSidebar}
              >
                <WirehouseHandoverCurier />
              </WirehouseLayout>
            </WirehouseProtectedRoute>
          }
        />

        {/* Login */}
        <Route path="/login" element={<Login />} />
        <Route path="/warehouse-login" element={<WirehouseLogin />} />
        <Route path="/route-denied" element={<RouteDenied />} />
      </Routes>
    </Router>
  );
}

export default App;
