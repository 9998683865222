import React, { useState } from "react";
import { useGetHandoverCurierQuery } from "../../../../features/wirehouseApi/wirehouseHandoverCurier";
import { FaEye, FaTimes } from "react-icons/fa";
import WirehosueCurierProductInfo from "./WirehosueCurierProductInfo";
import Modal from "../../../../Shared/Modal";
import WirehouseCurierCustomerInfo from "./WirehouseCurierCustomerInfo";
import Toast from "../../../../Shared/Components/Toast";

export default function WirehouseHandOverCurierComponent() {
  const [showProductInfo, setShowProductInfo] = useState(false);
  const [productInfo, setProductInfo] = useState({});

  const [showCustomerInfo, setShowCustomerInfo] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({});

  const {
    data: allHandoverCurier,
    isLoading,
    isError,
    error,
  } = useGetHandoverCurierQuery();

  let content = null;

  if (isLoading) {
    content = <div>Loading</div>;
  }
  if (!isLoading && isError) {
    content = <div>{error?.error}</div>;
  }

  if (!isLoading && !isError) {
    content = (
      <tbody>
        {allHandoverCurier?.data?.map((curier) => (
          <tr className="border-t border-[#ddd] text-left text-dark py-4">
            <td className="py-2 px-2  text-base">
              <div className="flex items-center">{curier?.id}</div>
            </td>
            <td className="py-2 px-2  text-base">
              <div className="flex space-x-2 justify-center cursor-pointer">
                <div className="p-2 bg-green-200 rounded-full">
                  <FaEye
                    className="fill-green-500"
                    onClick={() => {
                      setShowProductInfo(true);
                      setProductInfo(curier?.products);
                    }}
                  />
                </div>
              </div>
            </td>
            <td className="py-2 px-2  text-base">
              <div className="flex space-x-2 justify-center cursor-pointer">
                <div className="p-2 bg-green-200 rounded-full">
                  <FaEye
                    className="fill-green-500"
                    onClick={() => {
                      setShowCustomerInfo(true);
                      setCustomerInfo(curier?.customer_info);
                    }}
                  />
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
  return (
    <>
      <div className="max-w-5xl mx-auto  overflow-x-hidden my-10">
        <section className="bg-white shadow-xl rounded-md mb-4 m-0 md:m-4 py-6 lg:my-0 lg:py-8">
          <div className="flex flex-col justify-between items-center pb-4 px-4 md:flex-row">
            <p className="w-3/4 px-8 py-4 font-semibold">All Category</p>
          </div>
          <div className="border-b border-b-gray-200"></div>
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4 mx-4">
              <div className="max-w-full overflow-x-auto">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="text-left text-dark font-bold">
                      <th className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        #
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Products Info
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Customer Info
                      </th>
                    </tr>
                  </thead>
                  {content}
                </table>
              </div>
            </div>
          </div>
        </section>
        {showProductInfo && (
          <Modal show={showProductInfo}>
            <div className="w-full max-w-[770px]  rounded-md bg-white text-center">
              <div className="flex justify-between items-center px-8 py-4 ">
                <p className="font-semibold">Seller details</p>
                <FaTimes
                  onClick={() => {
                    setShowProductInfo(false);
                  }}
                  className="fill-gray-500 cursor-pointer"
                />
              </div>
              <div className="border-b border-b-gray-200"></div>
              <span className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"></span>
              <WirehosueCurierProductInfo
                productInfo={productInfo}
                setProductInfo={setProductInfo}
                setShowProductInfo={setShowProductInfo}
              />
            </div>
          </Modal>
        )}
        {showCustomerInfo && (
          <Modal show={showCustomerInfo}>
            <div className="w-full max-w-[770px]  rounded-md bg-white text-center">
              <div className="flex justify-between items-center px-8 py-4 ">
                <p className="font-semibold">Customer details</p>
                <FaTimes
                  onClick={() => {
                    setShowCustomerInfo(false);
                  }}
                  className="fill-gray-500 cursor-pointer"
                />
              </div>
              <div className="border-b border-b-gray-200"></div>
              <span className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"></span>
              <WirehouseCurierCustomerInfo customerInfo={customerInfo} />
            </div>
          </Modal>
        )}

        <Toast />
      </div>
    </>
  );
}
