import React, { useEffect, useState } from "react";
import {
  useAddPercentDiscountMutation,
  useEditPercentDiscountMutation,
} from "../../../features/percentDiscount/percentDiscountApi";
import { toast } from "react-toastify";
import Input from "../../../Shared/Components/Input";
import Toast from "../../../Shared/Components/Toast";
import { useGetSellersQuery } from "../../../features/saller/sallerApi";

export default function AddPercentDiscount({
  setPercentDiscountObj,
  percentDiscountObj,
}) {
  let [percentDiscount, setPercentDiscount] = useState({
    seller_id: "",
    code: "",
    disc_percent: "",
    min_quanty: "",
    min_order_amount: "",
    max_disc_amount: "",
    show_coustomer: true,
  });

  useEffect(() => {
    if (percentDiscountObj?.seller_id) {
      setPercentDiscount({
        seller_id: percentDiscountObj?.seller_id,
        code: percentDiscountObj?.coupon_code,
        disc_percent: percentDiscountObj?.min_qty,
        min_quanty: percentDiscountObj?.discount_value,
        min_order_amount: percentDiscountObj?.min_order_amount,
        max_disc_amount: percentDiscountObj?.max_disc_amount,
        show_coustomer: percentDiscountObj?.show_coustomer || true,
      });
    }
  }, [percentDiscountObj]);

  const {
    data: allSellers,
    isLoading: sellerLoading,
    isError: sellerIsError,
    error: sellerError,
  } = useGetSellersQuery();

  const [addPercentDiscount, { isSuccess: ispercentDiscountSuccess }] =
    useAddPercentDiscountMutation();
  const [
    editpercentDiscount,
    { isSuccess: isEditpercentDiscountSuccess, isLoading, isError, error },
  ] = useEditPercentDiscountMutation();

  useEffect(() => {
    if (ispercentDiscountSuccess) {
      toast.success("Successfully Percent Discount added.");
      setPercentDiscount({
        seller_id: "",
        code: "",
        disc_percent: "",
        min_quanty: "",
        min_order_amount: "",
        max_disc_amount: "",
        show_coustomer: true,
      });
    }
  }, [ispercentDiscountSuccess]);

  useEffect(() => {
    if (isEditpercentDiscountSuccess) {
      toast.success("Successfully Percent Discount Updated.");
      setPercentDiscount({
        seller_id: "",
        code: "",
        disc_percent: "",
        min_quanty: "",
        min_order_amount: "",
        show_coustomer: true,
      });
      setPercentDiscountObj({});
    }
    if (isError) {
      console.log("error", error);
      toast.error(error.error);
    }
  }, [isEditpercentDiscountSuccess, isError]);

  const handleCategoryAdd = (e) => {
    addPercentDiscount(percentDiscount);
  };

  const handleCategoryEdit = async (e) => {
    await editpercentDiscount({
      id: percentDiscountObj?.id,
      data: {
        ...percentDiscount,
      },
    });
  };

  return (
    <>
      <div className="w-full md:w-1/3">
        <div className="bg-white rounded-md shadow-xl ">
          <p className="px-8 py-4 font-semibold">
            {percentDiscountObj?.seller_id ? "Update" : "Add"} percent discount
          </p>
          <div className="border-b border-b-gray-200"></div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col  ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Seller Id <span className="text-orange-500">*</span>
                </label>
                <div className="w-full ">
                  <select
                    id="selectID"
                    className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        seller_id: e.target.value,
                      })
                    }
                  >
                    <option
                      className="bg-orange-400 text-white p-4 w-full"
                      value=""
                    >
                      Select Seller
                    </option>
                    {allSellers?.data?.map((seller) => (
                      <option
                        className="bg-orange-400 text-white p-4 w-full"
                        value={seller?.id}
                      >
                        {seller?.shop_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Code <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder=""
                    value={percentDiscount.code}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        code: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col  ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Discount Percent <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder=""
                    value={percentDiscount.disc_percent}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        disc_percent: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Min Quanty <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder=""
                    value={percentDiscount.min_quanty}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        min_quanty: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Min Order Amount <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder=""
                    value={percentDiscount.min_order_amount}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        min_order_amount: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Max Disc Amount <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder=""
                    value={percentDiscount.max_disc_amount}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        max_disc_amount: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pb-8 pr-8 text-right">
            {percentDiscountObj?.seller_id ? (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryEdit}
              >
                Update
              </button>
            ) : (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryAdd}
              >
                Save
              </button>
            )}
          </div>
        </div>
        <Toast />
      </div>
    </>
  );
}
