import React from 'react'

export default function InputFile({onChange}) {
    return (
        <input
            type="file"
            className="border-form-stroke text-gray-500 placeholder-gray-500 focus:border-primary active:border-primary file:border-form-stroke file:text-gray-500 file:hover:bg-primary w-full cursor-pointer rounded-lg border-[1.5px] font-medium outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-solid file:bg-[#F5F7FD] file:py-3 file:px-5 file:hover:bg-opacity-10 disabled:cursor-default disabled:bg-[#F5F7FD]"
            onChange={onChange}
        />
    )
}
