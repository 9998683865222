import React, { useState } from "react";
import SellerDeliveryTimesTable from "./SellerDeliveryTimesTable";
import AddSellerDeliveryTimes from "./AddSellerDeliveryTimes";

export default function SellerDeliveryTimesComponents() {
  const [deliveryTimeObj, setDeliveryTimeObj] = useState({});
  return (
    <>
      <p className="p-4 font-bold md:pl-8">All delivery times</p>
      <div className="flex flex-col p-4 mb-8  md:flex-row">
        <SellerDeliveryTimesTable setPaymentMethodObj={setDeliveryTimeObj} />
        <AddSellerDeliveryTimes
          setPercentDiscountObj={setDeliveryTimeObj}
          percentDiscountObj={deliveryTimeObj}
        />
      </div>
    </>
  );
}
