import React, { useEffect, useState } from "react";
import {
  useAddGroupNameMutation,
  useEditGroupNameMutation,
} from "../../../../features/rmsGroupName/rmsGroupNameApi";
import { toast } from "react-toastify";
import Input from "../../../../Shared/Components/Input";
import Toast from "../../../../Shared/Components/Toast";

export default function AddRmsGroupName({ categoryObj, setCategoryObj }) {
  let [name, setName] = useState("");

  useEffect(() => {
    setName(categoryObj?.group_name);
  }, [categoryObj]);

  const [addGroupName, { isSuccess: isAddCategorySuccess }] =
    useAddGroupNameMutation();
  const [
    editGroupName,
    { isSuccess: isEditCategorySuccess, isLoading, isError, error },
  ] = useEditGroupNameMutation();

  useEffect(() => {
    if (isAddCategorySuccess) {
      toast.success("Successfully category added.");
      setName("");
    }
  }, [isAddCategorySuccess]);

  console.log(
    "isEditCategorySuccess, isError, error",
    isEditCategorySuccess,
    isError,
    error
  );

  useEffect(() => {
    if (isEditCategorySuccess) {
      toast.success("Successfully category Updated.");
      setName("");
      setCategoryObj({});
    }
    if (isError) {
      toast.error(error.error);
    }
  }, [isEditCategorySuccess, isError]);

  const handleCategoryAdd = (e) => {
    addGroupName({
      name,
    });
  };

  const handleCategoryEdit = async (e) => {
    await editGroupName({
      id: categoryObj?.id,
      data: {
        name,
      },
    });
  };

  return (
    <>
      <div className="w-1/3">
        <div className="bg-white rounded-md shadow-xl ">
          <p className="px-8 py-4 font-semibold">
            {categoryObj?.group_name ? "Update" : "Add"} group name
          </p>
          <div className="border-b border-b-gray-200"></div>
          <div className="flex p-8 ">
            <div className="w-full px-4">
              <div className="flex flex-col ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Name <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pb-8 pr-8 text-right">
            {categoryObj?.group_name ? (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryEdit}
              >
                Update
              </button>
            ) : (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryAdd}
              >
                Save
              </button>
            )}
          </div>
        </div>
        <Toast />
      </div>
    </>
  );
}
