import React, { useState } from "react";
import BuyerEarningPolicyTable from "./BuyerEarningPolicyTable";
import AddBuyerEarningPolicy from "./AddBuyerEarningPolicy";

export default function BuyerEarningPolicyComponent() {
  let [categoryObj, setCategoryObj] = useState({});
  return (
    <>
      <p className="p-4 font-bold md:pl-8">All Earning Policy</p>
      <div className="flex flex-col p-4 mb-8  md:flex-row">
        <BuyerEarningPolicyTable setCategoryObj={setCategoryObj} />
        <AddBuyerEarningPolicy
          categoryObj={categoryObj}
          setCategoryObj={setCategoryObj}
        />
      </div>
    </>
  );
}
