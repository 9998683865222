import React from 'react'
import Layout from '../Layout/Layout'
import InHouseReportComponent from './Components/InHouseReportComponent/InHouseReportComponent'

export default function InHourseReport({ showSidebar, setShowSidebar }) {
  return (
    <Layout showSidebar={showSidebar} setShowSidebar={setShowSidebar}>
        <InHouseReportComponent />
    </Layout>
  )
}
