import React, { useEffect, useState } from "react";
import {
  useAddBuyersPaymentMethodMutation,
  useEditBuyersPaymentMethodMutation,
} from "../../../features/buyersPaymentMethod/buyersPaymentMethodApi";
import { toast } from "react-toastify";
import Input from "../../../Shared/Components/Input";
import InputFile from "../../../Shared/Components/InputFile";
import Toast from "../../../Shared/Components/Toast";
import { useGetAllCountryQuery } from "../../../features/country/CountryApi";

export default function AddBuyersPaymentMethod({
  categoryObj,
  setCategoryObj,
}) {
  const { data: allCountry } = useGetAllCountryQuery();

  let [paymentDetail, setPaymentDetail] = useState({
    name: "",
    details: ["sdfs"],
    extra_note: "",
    country: "",
    is_bank: true,
    logo: "",
  });
  const [detail, setDetail] = useState("");

  useEffect(() => {
    console.log("categoryObj", categoryObj);
    setPaymentDetail({
      name: categoryObj?.name,
      details: categoryObj?.details || [],
      extra_note: categoryObj?.extra_note,
      country: categoryObj?.country,
      is_bank: categoryObj?.is_bank || false,
      logo: categoryObj?.logo,
    });
  }, [categoryObj]);

  const [addBuyersPaymentMethod, { isSuccess: isAddCategorySuccess }] =
    useAddBuyersPaymentMethodMutation();
  const [
    editBuyersPaymentMethod,
    { isSuccess: isEditCategorySuccess, isLoading, isError, error },
  ] = useEditBuyersPaymentMethodMutation();

  useEffect(() => {
    if (isAddCategorySuccess) {
      toast.success("Successfully banner added.");
      setPaymentDetail({
        name: "",
        details: [],
        extra_note: "",
        country: "",
        is_bank: true,
        logo: "",
      });
    }
  }, [isAddCategorySuccess]);

  useEffect(() => {
    if (isEditCategorySuccess) {
      toast.success("Successfully banner Updated.");
      setPaymentDetail({
        name: "",
        details: [],
        extra_note: "",
        country: "",
        is_bank: true,
        logo: "",
      });
      setCategoryObj({});
    }
    if (isError) {
      toast.error(error.error);
    }
  }, [isEditCategorySuccess, isError]);

  const handleDetails = () => {
    setPaymentDetail({
      ...paymentDetail,
      details: [...paymentDetail?.details, detail],
    });

    setDetail("");
  };

  function handleImageUpload(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      setPaymentDetail({ ...paymentDetail, logo: reader.result });
    };
  }

  const handleCategoryAdd = (e) => {
    console.log("paymentDetail", paymentDetail);
    addBuyersPaymentMethod(paymentDetail);
  };

  const handleCategoryEdit = async (e) => {
    console.log("country Detail", paymentDetail);
    await editBuyersPaymentMethod({
      id: categoryObj?.id,
      data: paymentDetail,
    });
  };

  console.log("sdf", paymentDetail);

  return (
    <>
      <div className="w-1/3">
        <div className="bg-white rounded-md shadow-xl ">
          <p className="px-8 py-4 font-semibold">
            {categoryObj?.name ? "Update" : "Add"} Buyers Payment Method
          </p>
          <div className="border-b border-b-gray-200"></div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Name <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder="Name"
                    value={paymentDetail?.name}
                    onChange={(e) =>
                      setPaymentDetail({
                        ...paymentDetail,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Extra Note <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder="Code"
                    value={paymentDetail?.extra_note}
                    onChange={(e) =>
                      setPaymentDetail({
                        ...paymentDetail,
                        extra_note: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="w-full mb-4 ">
                <select
                  id="selectID"
                  className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
                  onChange={(e) =>
                    setPaymentDetail({
                      ...paymentDetail,
                      country: e.target.value,
                    })
                  }
                >
                  <option
                    className="bg-orange-400 text-white p-4 w-full"
                    value=""
                  >
                    Select Country
                  </option>
                  {allCountry?.data?.map((country) => (
                    <option
                      className="bg-orange-400 text-white p-4 w-full"
                      value={country?.name}
                    >
                      {country?.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex  gap-2 flex-col ">
                {paymentDetail?.details?.map((detail) => (
                  <div className="bg-gray-200 p-2">
                    <p>{detail}</p>
                  </div>
                ))}
              </div>

              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Detail <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder=""
                    value={detail}
                    onChange={(e) => setDetail(e.target.value)}
                  />
                </div>
              </div>

              <div className="">
                <button
                  className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                  onClick={handleDetails}
                >
                  Add
                </button>
              </div>

              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Category Image <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <InputFile onChange={handleImageUpload} />
                </div>
                {paymentDetail?.logo ? (
                  <img
                    src={paymentDetail?.logo}
                    alt="Uploaded image"
                    className="w-full h-auto pt-4"
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="pb-8 pr-8 text-right">
            {categoryObj?.name ? (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryEdit}
              >
                Update
              </button>
            ) : (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryAdd}
              >
                Save
              </button>
            )}
          </div>
        </div>
        <Toast />
      </div>
    </>
  );
}
