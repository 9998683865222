import React from 'react'
import Input from '../../../Shared/Components/Input'
import Toggle from '../../../Shared/Components/Toggle'

export default function ProductConfirmation() {
  return (
    <div className='w-full md:w-1/3'>
      <div className='bg-white rounded-md shadow-xl '>
        <p className='px-8 py-4 font-semibold'>Shipping Configuration</p>
        <div className='border-b border-b-gray-200'></div>
        <div className="flex p-8">
          <div className="w-full px-4 w-full">
            <div className="flex flex-col mb-4 md:flex-row">
              <label for="" className="w-full md:w-2/4 mb-3 block text-base text-black font-light">
                Free Shipping
              </label>
              <div className="w-full md:w-2/4 ">
                <Toggle />
              </div>
            </div>

            <div className="flex flex-col mb-4 md:flex-row">
              <label for="" className="w-full md:w-2/4 mb-3 block text-base text-black font-light">
                Flat Rate
              </label>
              <div className="w-full md:w-2/4 ">
                <Toggle />
              </div>
            </div>

            <div className="flex flex-col mb-4 md:flex-row">
              <label for="" className="w-full md:w-2/4 mb-3 block text-base text-black font-light">
                Is Product Quantity Mulitiply
              </label>
              <div className="w-full md:w-2/4 ">
                <Toggle />
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className='bg-white rounded-md shadow-xl mt-8'>
        <p className='px-8 py-4 font-semibold'>Stock Visibility State</p>
        <div className='border-b border-b-gray-200'></div>
        <div className="flex p-8">
          <div className="w-full px-4 w-full">

            <div className="flex flex-col mb-4 md:flex-row">
              <label for="" className="w-full md:w-2/4 mb-3 block text-base text-black font-light">
                Quantity
              </label>
              <Input type="number" placeholder="1" />
            </div>
          </div>
        </div>
      </div>


      <div className='bg-white rounded-md shadow-xl mt-8'>
        <p className='px-8 py-4 font-semibold'>Stock Visibility State</p>
        <div className='border-b border-b-gray-200'></div>
        <div className="flex p-8">
          <div className="w-full px-4 w-full">
            <div className="flex flex-col mb-4 md:flex-row">
              <label for="" className="w-full md:w-2/4 mb-3 block text-base text-black font-light">
                Show Stock Quantity
              </label>
              <div className="w-full md:w-2/4 ">
              <Toggle />
              </div>
            </div>

            <div className="flex flex-col mb-4 md:flex-row">
              <label for="" className="w-full md:w-2/4 mb-3 block text-base text-black font-light">
                Show Stock With Text Only
              </label>
              <div className="w-full md:w-2/4 ">
              <Toggle />
              </div>
            </div>

            <div className="flex flex-col mb-4 md:flex-row">
              <label for="" className="w-full md:w-2/4 mb-3 block text-base text-black font-light">
                Hide Stock
              </label>
              <div className="w-full md:w-2/4 ">
              <Toggle />
              </div>
            </div>
          </div>
        </div>
      </div>



    </div>
  )
}
