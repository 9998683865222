import React from "react";
import WirehouseListComponent from "./Components/WirehouseListComponent/WirehouseListComponent";

export default function WirehouseList() {
  return (
    <div>
      <WirehouseListComponent />
    </div>
  );
}
