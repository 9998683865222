import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../features/auth/authApi";
import { useWirehouseLoginMutation } from "../../features/auth/wirehouseAuthApi";

export default function WirehouseLogin() {
  let [phone, setPhone] = useState("");
  let [password, setPassword] = useState("");
  let [error, setError] = useState("");

  const navigate = useNavigate();

  const [wirehouseLogin, { data, isLoading, error: responseError }] =
    useWirehouseLoginMutation();

  console.log(
    "data, isLoading, error: responseError ",
    data,
    isLoading,
    responseError
  );

  useEffect(() => {
    if (responseError?.data) {
      setError(responseError?.data);
    }
    if (data?.token && !data?.error) {
      navigate("/warehouse-details");
    }
  }, [data, responseError]);

  const handleSubmit = (e) => {
    e.preventDefault();

    wirehouseLogin({
      phone,
      password,
    });
  };

  return (
    <div className="bg-primary min-h-screen min-w-full flex justify-center items-center">
      <form
        className="bg-red-500 p-8 w-[500px]"
        onSubmit={handleSubmit}
        method="POST"
      >
        <h1 className="text-xl font-bold text-white text-center">
          Warehouse Login
        </h1>
        <div className="flex flex-col">
          <label className="text-white">Phone Number</label>
          <input
            className="px-4 py-2 rounded-md focus:outline-none"
            type="text"
            placeholder="Phone Number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="flex flex-col pb-4">
          <label className="text-white">Password</label>
          <input
            className="px-4 py-2 rounded-md focus:outline-none"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <input
            className="w-full bg-white px-4 rounded-md text-xl cursor-pointer"
            type="submit"
            value={isLoading ? "Processing" : "Login"}
            disabled={isLoading}
          />
        </div>

        {error !== "" && <p className="text-red-500">{error}</p>}
      </form>
    </div>
  );
}
