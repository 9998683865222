import React, { useState } from "react";
import SellerPaymentMethodTable from "./SellerPaymentMethodTable";
import AddPaymentMethod from "./AddPaymentMethod";

export default function SellerPaymentMethodComponent() {
  const [paymentMethodObj, setPaymentMethodObj] = useState({});
  return (
    <>
      <p className="p-4 font-bold md:pl-8">All Payment Method</p>
      <div className="flex flex-col p-4 mb-8  md:flex-row">
        <SellerPaymentMethodTable setPaymentMethodObj={setPaymentMethodObj} />
        <AddPaymentMethod
          setPercentDiscountObj={setPaymentMethodObj}
          percentDiscountObj={paymentMethodObj}
        />
      </div>
    </>
  );
}
