import useAuth from "../../hooks/useAuth";
import { useGetRmsCheckMyPermissionQuery } from "../../features/rmsCheckMyPermission/rmsCheckMyPermissionApi";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function ProtectedPermission({ children, link }) {
  const {
    data: checkMyPermission,
    isLoading,
    isError,
    error,
  } = useGetRmsCheckMyPermissionQuery();

  const logedInUser = useAuth();
  const auth = useSelector((state) => state.auth);

  console.log("logedInUser", logedInUser);
  console.log("auth", auth);

  console.log("isLoading", !isLoading);
  console.log("isError", !isError);
  console.log("logedInUser", !logedInUser);
  console.log("error", error);
  console.log("checkMyPermission", checkMyPermission);

  return !isLoading &&
    !isError &&
    logedInUser &&
    (auth?.userType === "SUPER ADMIN"
      ? true
      : checkMyPermission?.data?.some((obj) => obj?.link === link)) ? (
    children
  ) : !isLoading && !isError && logedInUser ? (
    <Navigate replace to="/route-denied" />
  ) : (
    !isLoading && !isError && !logedInUser && <Navigate replace to="/login" />
  );
}
