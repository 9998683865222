import React from "react";
import DeliveryPaymentComponent from "./Components/DeliveryPaymentComponent/DeliveryPaymentComponent";

export default function DeliveryPayment() {
  return (
    <div>
      <DeliveryPaymentComponent />
    </div>
  );
}
