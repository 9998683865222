import { apiSlice } from "../api/apiSlice";

export const rmsMenuApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRmsMenu: builder.query({
      query: () => `/admin/rms/menus`,
      providesTags: ["rmsMenus"],
    }),

    addMenu: builder.mutation({
      query: (data) => ({
        url: "/admin/rms/menus",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["rmsMenus"],
    }),

    editMenu: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/rms/menus/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["rmsMenus"],
    }),

    deleteMenu: builder.mutation({
      query: (id) => ({
        url: `/admin/rms/menus/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["rmsMenus"],
    }),
  }),
});

export const {
  useGetRmsMenuQuery,
  useAddMenuMutation,
  useEditMenuMutation,
  useDeleteMenuMutation,
} = rmsMenuApi;
