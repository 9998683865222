import React from "react";
import BuyerComponent from "./Components/BuyerComponent/BuyerComponent";

export default function Buyer() {
  return (
    <div>
      <BuyerComponent />
    </div>
  );
}
