import { apiSlice } from "../api/apiSlice";

export const deliveryPaymentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentPendingDelivery: builder.query({
      query: (id) => `admin/orders/delivery/pending`,
      providesTags: ["paymentDelivery"],
    }),
    paymentConfirmDelivery: builder.query({
      query: (id) => `admin/orders/delivery/confirm_payment/${id}`,
      providesTags: ["paymentDelivery"],
    }),
  }),
});

export const {
  useGetPaymentPendingDeliveryQuery,
  usePaymentConfirmDeliveryQuery,
} = deliveryPaymentApi;
