import React from 'react'
import AddAttribute from './AddAttribute'
import AttributeTable from './AttributeTable'

export default function AttributesComponent() {
    return (
        <>
        <p className='p-4 font-bold md:pl-8'>All Attributes</p>
        <div className='flex flex-col p-4 mb-8  md:flex-row'>
            <AttributeTable />
            <AddAttribute />
        </div>
        </>
    )
}
