import React from "react";
import PercentDiscountComponent from "./Components/PercentDiscountComponent/PercentDiscountComponent";

export default function PercentDiscount() {
  return (
    <div>
      <PercentDiscountComponent />
    </div>
  );
}
