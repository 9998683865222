import React from "react";

export default function RouteDenied() {
  return (
    <div className="h-screen w-full bg-gray-400 flex flex-col gap-6 justify-center items-center">
      <h1 className="text-7xl">Route Denied!</h1>
      <p>You have no loger access to this route.</p>
    </div>
  );
}
