import React, { useEffect, useState } from "react";
import {
  useAddOrderTrackMutation,
  useDeleteOrderTrackMutation,
  useGetOrderTrackQuery,
} from "../../../features/orders/ordersApi";
import { FaPlusCircle, FaTimes, FaTrashAlt } from "react-icons/fa";
import Modal from "../../../Shared/Modal";
import Input from "../../../Shared/Components/Input";

export default function OrderTrack({ trackId }) {
  const [track, setTrack] = useState({
    status: "",
    message: "",
  });
  const [showAddModal, setShowAddModal] = useState(false);

  const {
    data: trackOrders,
    isLoading,
    isError,
    error,
  } = useGetOrderTrackQuery(trackId);

  const [addOrderTrack, { isSuccess: isAddCategorySuccess }] =
    useAddOrderTrackMutation();

  const [deleteOrderTrack, { isSuccess }] = useDeleteOrderTrackMutation();

  useEffect(() => {
    if (isAddCategorySuccess) {
      setShowAddModal(false);
    }
  }, [isAddCategorySuccess]);

  const handleDeleteOrderTrack = (id) => {
    deleteOrderTrack(id);
  };

  const handleTrackAdd = (id) => {
    addOrderTrack({ id: trackId, data: track });
  };

  let content = null;
  if (isLoading) {
    content = <div>Loading</div>;
  }
  if (!isLoading && isError) {
    content = (
      <div>
        <p className="text-red-500">{error?.error}</p>
      </div>
    );
  }

  if (!isLoading && !isError) {
    content = (
      <tbody>
        {trackOrders?.tracks?.map((track) => (
          <tr className="border-t border-[#ddd] text-left text-dark py-4">
            <td className="py-2 px-2  text-base">
              <p>{track?.id}</p>
            </td>
            <td className="py-2 px-2  text-base">{track?.status}</td>
            <td className="py-2 px-4  text-base">{track?.date}</td>
            <td className="py-2 px-4  text-base">{track?.message}</td>
            <td className="py-2 px-4  text-base">
              <div className="flex space-x-2 cursor-pointer">
                <div className="p-2 bg-red-200 rounded-full cursor-pointer">
                  <FaTrashAlt
                    className="fill-red-500"
                    onClick={() => handleDeleteOrderTrack(track?.id)}
                  />
                </div>
                <div className="p-2 bg-green-200 rounded-full cursor-pointer">
                  <FaPlusCircle
                    className="fill-green-500"
                    onClick={() => setShowAddModal(true)}
                  />
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
  return (
    <>
      <div className="max-w-full overflow-x-auto max-h-[500px] overflow-scroll p-6">
        <table className="w-full table-auto">
          <thead>
            <tr className="text-left text-dark font-bold">
              <th className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                Id:
              </th>
              <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                Status:
              </th>
              <th className="w-1/6 min-w-[260px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                Date:
              </th>
              <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                Message:
              </th>
              <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                Options:
              </th>
            </tr>
          </thead>
          {content}
        </table>
      </div>

      {showAddModal ? (
        <Modal show={showAddModal}>
          <div className="w-full max-w-[780px]  rounded-md bg-white text-center">
            <div className="flex justify-between items-center px-8 py-4 ">
              <p className="font-semibold">Shipping details</p>
              <FaTimes
                onClick={() => {
                  setShowAddModal(false);
                }}
                className="fill-gray-500 cursor-pointer"
              />
            </div>
            <div className="border-b border-b-gray-200"></div>
            <span className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"></span>

            <div>
              <div className="flex p-8 pb-0">
                <div className="w-full px-4">
                  <div className="flex flex-col mb-4 ">
                    <label
                      for=""
                      className="w-full mb-3 block text-base text-black font-light text-left"
                    >
                      Status <span className="text-orange-500">*</span>
                    </label>
                    <div className="w-full">
                      <Input
                        type="text"
                        placeholder="Status"
                        value={track?.status}
                        onChange={(e) =>
                          setTrack({
                            ...track,
                            status: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex p-8 pb-0">
                <div className="w-full px-4">
                  <div className="flex flex-col mb-4 ">
                    <label
                      for=""
                      className="w-full mb-3 block text-base text-black font-light text-left"
                    >
                      Message <span className="text-orange-500">*</span>
                    </label>
                    <div className="w-full">
                      <Input
                        type="text"
                        placeholder="Message"
                        value={track?.message}
                        onChange={(e) =>
                          setTrack({
                            ...track,
                            message: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="pb-8 pr-8 text-right">
                {/* {categoryObj?.name ? (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryEdit}
              >
                Edit
              </button>
            ) : (
                <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryAdd}
              >
                Add
              </button>
            )} */}

                <button
                  className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                  onClick={handleTrackAdd}
                >
                  Add
                </button>
              </div>
            </div>

            <div className="flex justify-center space-x-6 pb-10">
              <button
                onClick={() => {
                  setShowAddModal(false);
                }}
                className="text-dark block rounded-md border border-[#E9EDF9] p-3 text-center text-base font-medium transition hover:border-red-600 hover:bg-red-600 hover:text-white"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
}
