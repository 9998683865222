import React from "react";

export default function Modal({ show, children }) {
  return (
    <>
      {show && (
        <div
          x-show="modalOpen"
          x-transition
          className="fixed top-0 left-0 flex h-full min-h-screen w-full items-center justify-center bg-black bg-opacity-50 px-4 z-50"
        >
          {children}
        </div>
      )}
    </>
  );
}
