import React from "react";
import OrderPaymentPendingComponent from "./Components/OrderPaymentPendingComponent/OrderPaymentPendingComponent";

export default function OrderPaymentPending() {
  return (
    <div className="py-6">
      <OrderPaymentPendingComponent />
    </div>
  );
}
