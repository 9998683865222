import React from "react";
import LivesTable from "./LivesTable";

export default function LivesComponent() {
  return (
    <div className="flex justify-center items-center py-20">
      <LivesTable />
    </div>
  );
}
