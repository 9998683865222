import React, { useEffect, useState } from "react";
import {
  useAddVarientMutation,
  useEditVarientMutation,
} from "../../../features/varient/varientApi";
import { toast } from "react-toastify";
import Input from "../../../Shared/Components/Input";
import Toast from "../../../Shared/Components/Toast";
import InputFile from "../../../Shared/Components/InputFile";
import { useGetSellersQuery } from "../../../features/saller/sallerApi";

export default function AddVarient({
  percentDiscountObj,
  setPercentDiscountObj,
}) {
  let [percentDiscount, setPercentDiscount] = useState({
    seller_id: "",
    name: "",
    color: "",
    varients: [],
  });

  const [varientObj, setVarientObj] = useState({
    size: "",
    color: "",
    stock: "",
  });

  useEffect(() => {
    console.log("percentDiscountObj", percentDiscountObj);
    if (percentDiscountObj?.name) {
      setPercentDiscount({
        seller_id: percentDiscountObj?.seller_id || null,
        name: percentDiscountObj?.name,
        color: percentDiscountObj?.color,
        varients: percentDiscountObj?.varients || [],
      });
    }
  }, [percentDiscountObj]);

  const {
    data: allSellers,
    isLoading: sellerLoading,
    isError: sellerIsError,
    error: sellerError,
  } = useGetSellersQuery();

  const [addVarient, { isSuccess: isAddVarientSuccess }] =
    useAddVarientMutation();
  const [
    editVarient,
    { isSuccess: isEditVarientSuccess, isLoading, isError, error },
  ] = useEditVarientMutation();

  function handleImageUpload(e) {
    const file = e.target.files[0];
    console.log("file", file);
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      setPercentDiscount({ ...percentDiscount, color: reader.result });
    };
  }

  useEffect(() => {
    if (isAddVarientSuccess) {
      toast.success("Successfully Percent Discount added.");
      setPercentDiscount({
        seller_id: "",
        name: "",
        color: "",
        varients: [],
      });
    }
  }, [isAddVarientSuccess]);

  useEffect(() => {
    if (isEditVarientSuccess) {
      toast.success("Successfully Percent Discount Updated.");
      setPercentDiscount({
        seller_id: "",
        name: "",
        color: "",
        varients: [],
      });
      setPercentDiscountObj({});
    }
    if (isError) {
      console.log("error", error);
      toast.error(error.error);
    }
  }, [isEditVarientSuccess, isError]);

  const handleAddVarient = () => {
    setPercentDiscount({
      ...percentDiscount,
      varients: [...percentDiscount?.varients, varientObj],
    });
    setVarientObj({
      size: "",
      color: "",
      stock: "",
    });
  };

  const handleCategoryAdd = (e) => {
    addVarient(percentDiscount);
  };

  const handleCategoryEdit = async (e) => {
    const editData = {
      color: percentDiscount?.color,
      name: percentDiscount?.name,
      varients: percentDiscount?.varients,
    };
    console.log("whie edit percentDiscount", percentDiscountObj?.id, editData);
    await editVarient({
      id: percentDiscountObj?.id,
      data: {
        ...editData,
      },
    });
  };

  return (
    <>
      <div className="w-full md:w-1/3">
        <div className="bg-white rounded-md shadow-xl ">
          <p className="px-8 py-4 font-semibold">
            {percentDiscountObj?.name ? "Update" : "Add"} varient
          </p>
          <div className="border-b border-b-gray-200"></div>

          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Seller Id <span className="text-orange-500">*</span>
                </label>
                <div className="w-full ">
                  <select
                    id="selectID"
                    className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        seller_id: e.target.value,
                      })
                    }
                  >
                    <option
                      className="bg-orange-400 text-white p-4 w-full"
                      value=""
                    >
                      Select Seller
                    </option>
                    {allSellers?.data?.map((seller) => (
                      <option
                        className="bg-orange-400 text-white p-4 w-full"
                        value={seller?.id}
                      >
                        {seller?.shop_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="flex px-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Name <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder="Name"
                    value={percentDiscount.name}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full px-8 ">
            <div className="flex flex-col mb-4 px-4">
              <label
                for=""
                className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
              >
                Logo
              </label>
              <div className="w-full ">
                <InputFile onChange={handleImageUpload} />
              </div>
            </div>
            {percentDiscount?.color && (
              <img
                src={percentDiscount?.color}
                alt="Uploaded image"
                className="w-full h-auto"
              />
            )}
          </div>

          <div className="bg-gray-100 my-2 py-4">
            <h2 className="px-12">Varient</h2>

            <div className="flex  gap-2 flex-col px-12">
              {percentDiscount?.varients?.map((varientObj) => (
                <div className="bg-gray-200 p-2">
                  <p>
                    <span className="font-bold">Color:</span>{" "}
                    {varientObj?.color}
                  </p>
                  <p>
                    <span className="font-bold">Size: </span>
                    {varientObj?.size}
                  </p>
                  <p>
                    <span className="font-bold">Stock: </span>
                    {varientObj?.stock}
                  </p>
                </div>
              ))}
            </div>
            <div className="flex px-8 pb-0 ">
              <div className="w-full px-4">
                <div className="flex flex-col mb-4 ">
                  <label
                    for=""
                    className="w-full mb-3 block text-base text-black font-light"
                  >
                    Size <span className="text-orange-500">*</span>
                  </label>
                  <div className="w-full">
                    <Input
                      type="text"
                      placeholder=""
                      value={varientObj.size}
                      onChange={(e) =>
                        setVarientObj({
                          ...varientObj,
                          size: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex px-8 pb-0 ">
              <div className="w-full px-4">
                <div className="flex flex-col mb-4 ">
                  <label
                    for=""
                    className="w-full mb-3 block text-base text-black font-light"
                  >
                    color <span className="text-orange-500">*</span>
                  </label>
                  <div className="w-full">
                    <Input
                      type="text"
                      placeholder=""
                      value={varientObj.color}
                      onChange={(e) =>
                        setVarientObj({
                          ...varientObj,
                          color: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex px-8 pb-0 ">
              <div className="w-full px-4">
                <div className="flex flex-col mb-4 ">
                  <label
                    for=""
                    className="w-full mb-3 block text-base text-black font-light"
                  >
                    Stock <span className="text-orange-500">*</span>
                  </label>
                  <div className="w-full">
                    <Input
                      type="text"
                      placeholder=""
                      value={varientObj.stock}
                      onChange={(e) =>
                        setVarientObj({
                          ...varientObj,
                          stock: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="px-12">
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleAddVarient}
              >
                Add Varient
              </button>
            </div>
          </div>
          <div className="pb-8 pr-8 text-right">
            {percentDiscountObj?.name ? (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryEdit}
              >
                Update
              </button>
            ) : (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryAdd}
              >
                Save
              </button>
            )}
          </div>
        </div>
        <Toast />
      </div>
    </>
  );
}
