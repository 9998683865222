import React from "react";
import SellerPaymentMethodComponent from "./Components/SellerPaymentMethodComponent/SellerPaymentMethodComponent";

export default function SellerPaymentMethod() {
  return (
    <div>
      <SellerPaymentMethodComponent />
    </div>
  );
}
