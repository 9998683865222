import { Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useWirehouseAuth from "../../hooks/useWirehouseAuth";

export default function WirehouseProtectedRoute({ children }) {
  const isLogedIn = useWirehouseAuth();

  console.log("isLogedIn", isLogedIn);

  return isLogedIn ? children : <Navigate to="/wirehouse-login" />;
}
