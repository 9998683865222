import React from "react";
import OrdersTable from "./OrdersTable";

export default function OrdersComponent() {
  return (
    <div>
      <OrdersTable />
    </div>
  );
}
