import React from "react";
import SellerExtraChargeTypeComponent from "./Components/SellerExtraChargeTypeComponent/SellerExtraChargeTypeComponent";

export default function SellerExtraChargeType() {
  return (
    <div>
      <SellerExtraChargeTypeComponent />
    </div>
  );
}
