import React from "react";
import OrderPaymentPendingTable from "./OrderPaymentPendingTable";

export default function OrderPaymentPendingComponent() {
  return (
    <div>
      <OrderPaymentPendingTable />
    </div>
  );
}
