import React from "react";
import BuyersBannerComponent from "./Components/BuyersBannerComponent/BuyersBannerComponent";

export default function BuyersBanner() {
  return (
    <div>
      <BuyersBannerComponent />
    </div>
  );
}
