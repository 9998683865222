import React, { useState } from "react";
import FlatDiscountTable from "./FlatDiscountTable";
import AddFlatDiscount from "./AddFlatDiscount";

export default function FlatDiscountComponent() {
  let [flatDiscountObj, setFlatDiscountObj] = useState({});
  return (
    <>
      <p className="p-4 font-bold md:pl-8">All Flat discount</p>
      <div className="flex flex-col p-4 mb-8  md:flex-row">
        <FlatDiscountTable setFlatDiscountObj={setFlatDiscountObj} />
        <AddFlatDiscount
          setFlatDiscountObj={setFlatDiscountObj}
          flatDiscountObj={flatDiscountObj}
        />
      </div>
    </>
  );
}
