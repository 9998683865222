import React from 'react'
import PosProduct from './PosProduct'
import PostShipping from './PostShipping'

export default function PosComponent() {
  return (
    <div className='flex flex-col p-8 space-y-4 md:space-y-0 md:flex-row'>
        <PosProduct />
        <PostShipping />
    </div>
  )
}
