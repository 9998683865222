import { apiSlice } from "../api/apiSlice";

export const countryApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCountry: builder.query({
      query: () => `/admin/country`,
      providesTags: ["country"],
    }),
    addCountry: builder.mutation({
      query: (data) => ({
        url: "/admin/country/add",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["country"],
    }),
    editCountry: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/country/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["country"],
    }),
    deleteCountry: builder.mutation({
      query: (id) => ({
        url: `/admin/country/${id}`,
        method: "DELETE",
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["country"],
    }),
  }),
});

export const {
  useGetAllCountryQuery,
  useAddCountryMutation,
  useEditCountryMutation,
  useDeleteCountryMutation,
} = countryApi;
