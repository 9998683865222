import { apiSlice } from "../api/apiSlice";

export const SellerPaymentMethodApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSellerPaymentMethod: builder.query({
      query: () => `/admin/payment_method/seller`,
      providesTags: ["sellerPaymentMethod"],
    }),

    addSellerPaymentMethod: builder.mutation({
      query: (data) => ({
        url: "/admin/payment_method/seller/add",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["sellerPaymentMethod"],
    }),

    editSellerPaymentMethod: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/payment_method/seller/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["sellerPaymentMethod"],
    }),

    deleteSellerPaymentMethod: builder.mutation({
      query: (id) => ({
        url: `/admin/payment_method/seller/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["sellerPaymentMethod"],
    }),
  }),
});

export const {
  useGetSellerPaymentMethodQuery,
  useAddSellerPaymentMethodMutation,
  useEditSellerPaymentMethodMutation,
  useDeleteSellerPaymentMethodMutation,
} = SellerPaymentMethodApi;
