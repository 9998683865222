import { apiSlice } from "../api/apiSlice";

export const tunnibibiAddressApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAddress: builder.query({
      query: () => `/admin/settings/address`,
      providesTags: ["tunnibibiAddress"],
    }),
    addAddress: builder.mutation({
      query: (data) => ({
        url: "/admin/settings/address",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["tunnibibiAddress"],
    }),

    editAddress: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/settings/address/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["tunnibibiAddress"],
    }),

    deleteAddress: builder.mutation({
      query: (id) => ({
        url: `/admin/refer-policy/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["tunnibibiAddress"],
    }),
  }),
});

export const {
  useGetAddressQuery,
  useAddAddressMutation,
  useEditAddressMutation,
  useDeleteAddressMutation,
} = tunnibibiAddressApi;
