import React, { useState } from "react";
import ShippingPackageTable from "./ShippingPackageTable";
import AddShippingPackage from "./AddShippingPackage";

export default function ShippingPackageComponent() {
  const [paymentMethodObj, setPaymentMethodObj] = useState({});
  return (
    <>
      <p className="p-4 font-bold md:pl-8">All Shipping Package</p>
      <div className="flex flex-col p-4 mb-8  md:flex-row">
        <ShippingPackageTable setPaymentMethodObj={setPaymentMethodObj} />
        <AddShippingPackage
          setPercentDiscountObj={setPaymentMethodObj}
          percentDiscountObj={paymentMethodObj}
        />
      </div>
    </>
  );
}
