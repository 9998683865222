import { apiSlice } from "../api/apiSlice";

export const SellerPercentDiscountApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSellerPercentDiscount: builder.query({
      query: (id) => `/admin/discount/percent/seller/${id}`,
      providesTags: ["sellerPercent"],
    }),

    // deleteSellers: builder.mutation({
    //   query: (id) => ({
    //     url: `/admin/discount/${id}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["sellerPercent"],
    // }),
  }),
});

export const { useGetSellerPercentDiscountQuery, useDeleteSellersMutation } =
  SellerPercentDiscountApi;
