import React, { useState } from "react";
import {
  useGetPaymentPendingOrderQuery,
  usePaymentConfirmOrderQuery,
} from "../../../features/orders/orderPaymentApi";
import { FaPlus, FaRegEdit, FaTimes } from "react-icons/fa";
import Modal from "../../../Shared/Modal";

export default function OrderPaymentPendingTable() {
  const [selectedId, setSelectedId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const {
    data: allPaymentPendingOrder,
    isLoading,
    isError,
    error,
  } = useGetPaymentPendingOrderQuery();
  // const {
  //   data: paymentConfirm
  // } = usePaymentConfirmOrderQuery();

  // const handleConfirmPayment= () => {
  //   paymentConfirm(selectedId);
  // }

  let content = null;

  if (isLoading) {
    content = <div>Loading</div>;
  }
  if (!isLoading && isError) {
    content = <div>{error?.error}</div>;
  }

  if (!isLoading && !isError) {
    content = (
      <tbody>
        {allPaymentPendingOrder?.data?.map((order) => (
          <tr className="border-t border-[#ddd] text-left text-dark py-4">
            <td className="py-2 px-3  text-base">
              <div className="flex items-center">{order?.id}</div>
            </td>
            <td className="py-2 px-3  text-base">{order?.customer_order_id}</td>
            <td className="py-2 px-3  text-base">
              <div className="flex space-x-2">
                <img
                  src={order?.product_images[0]}
                  alt=""
                  className="w-20 h-auto"
                />
              </div>
            </td>
            <td className="py-2 pl-14 text-base">{order?.total_item}</td>
            <td className="py-2 pl-10  text-base">{order?.amount}</td>
            <td className="py-2 pl-6  text-base">{order?.payment_status}</td>
            <td className="py-2 px-3  text-base">{order?.date}</td>

            {/* <td className="y-2 px-3  text-base">
              <div className="flex space-x-2 cursor-pointer">
                <div className="p-2 bg-green-200 rounded-md">
                  <button
                    onClick={() => {
                      setSelectedId(order?.customer_order_id);
                      setShowModal(true);
                    }}
                  >
                    Make Payment
                  </button>
                </div>
              </div>
            </td> */}
          </tr>
        ))}
      </tbody>
    );
  }
  return (
    <>
      <div className="w-full  overflow-x-hidden">
        <section className="bg-white shadow-xl rounded-md mb-4 m-0 md:m-4 py-6 lg:my-0 lg:py-8 overflow-x-hidden">
          <div className="flex flex-col justify-between items-center pb-4 px-4 md:flex-row">
            <p className="w-3/4 px-8 py-4 font-semibold">
              All Payment Pending Order
            </p>
          </div>
          <div className="border-b border-b-gray-200"></div>
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4 mx-4">
              <div className="max-w-full overflow-x-auto">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="text-left text-dark font-bold">
                      <th className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        #
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Customer Id
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Image
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Total Item
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Amount
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Payment Status
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Date
                      </th>
                      {/* <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Options
                      </th> */}
                    </tr>
                  </thead>
                  {content}
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* Delete Modal */}
      {showModal ? (
        <Modal show={showModal}>
          <div className="w-full max-w-[470px] rounded-md bg-white text-center">
            <div className="flex justify-between items-center px-8 py-4 ">
              <p className="font-semibold">Confirm Payment </p>
              <FaTimes
                onClick={() => setShowModal(false)}
                className="fill-gray-500 cursor-pointer"
              />
            </div>
            <div className="border-b border-b-gray-200"></div>
            <span className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"></span>
            <p className="text-body-color mb-10 text-base leading-relaxed">
              Are you sure to confirm payment?
            </p>
            <div className="flex justify-center space-x-6 pb-10">
              <button
                onClick={() => setShowModal(false)}
                className="text-dark block rounded-md border border-[#E9EDF9] p-3 text-center text-base font-medium transition hover:border-red-600 hover:bg-red-600 hover:text-white"
              >
                Cancel
              </button>
              <button
                className="bg-orange-500 border-orange-500 block rounded-md border p-3 text-center text-base font-medium text-white transition hover:bg-opacity-90"
                onClick={() => {
                  setShowModal(false);
                  // handleConfirmPayment();
                }}
              >
                Confirm Payment
              </button>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
}
