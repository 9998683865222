import React from "react";
import SellerExtraChargeComponent from "./Components/SellerExtraChargeComponent/SellerExtraChargeComponent";

export default function SellerExtraCharge() {
  return (
    <div>
      <SellerExtraChargeComponent />
    </div>
  );
}
