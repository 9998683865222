import React from "react";
import AllWirehouseComponent from "./Components/AllWirehouseComponent/AllWirehouseComponent";

export default function AllWirehouse() {
  return (
    <div>
      <AllWirehouseComponent />
    </div>
  );
}
