import { apiSlice } from "../api/apiSlice";

export const sellerLiveHistory = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sellerLiveHistory: builder.query({
      query: (id) => `/admin/lives/seller/${id}`,
      providesTags: ["sellerProduct"],
    }),
  }),
});

export const { useSellerLiveHistoryQuery } = sellerLiveHistory;
