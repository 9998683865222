import React from 'react'
import Layout from '../Layout/Layout'
import ServerStatusComponent from './Components/ServerStatusComponent/ServerStatusComponent'

export default function ServerStatus({ showSidebar, setShowSidebar }) {
  return (
    <Layout showSidebar={showSidebar} setShowSidebar={setShowSidebar}>
        <ServerStatusComponent />
    </Layout>
  )
}
