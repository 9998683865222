import React, { useState } from "react";
import { FaAlignLeft, FaPrint, FaGlobeAmericas, FaBell } from "react-icons/fa";
import profile from "../../../assets/Images/profile.jpg";
import { HiLogout } from "react-icons/hi";
import { userLoggedOut } from "../../../features/auth/authSLice";
import { useDispatch } from "react-redux";

export default function Topbar({ setShowSidebar, showSidebar, setShow }) {
  let [showPopup, setShowPopup] = useState(false);

  const dispatch = useDispatch();

  return (
    <>
      <div className="sticky top-0 right-0 left-0 z-10 bg-white flex items-center justify-between space-x-4 shadow-sm max-h-40 p-4">
        <div className="flex items-center space-x-2">
          <div className="cursor-pointer flex justify-center items-center">
            <button onClick={() => setShowSidebar(!showSidebar)}>
              <FaAlignLeft className="w-6 h-6" />
            </button>
          </div>
          {/* <div className="bg-gray-100 p-4 rounded-full cursor-pointer duration-200 ">
            <FaPrint className="fill-gray-600" />
          </div> */}

          <>
            <button
              data-tooltip-target="tooltip-default"
              type="button"
              className="group relative  bg-gray-100 p-4 rounded-full cursor-pointer duration-200 hover:bg-blue-400 "
            >
              <FaGlobeAmericas className="fill-gray-600 group-hover:fill-white" />

              <span class="absolute -bottom-9 -left-1/3 scale-0 w-[130px] rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
                Browse Website
              </span>
            </button>

            <div
              id="tooltip-default"
              role="tooltip"
              className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
            >
              Tooltip content
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
          </>

          {/* <button
            onClick={() => setShow(true)}
            className="group flex items-center justify-center space-x-2 bg-pink-100 px-4 py-2 text-pink-500 rounded-md hover:bg-pink-500 hover:text-white transition duration-200"
          >
            <FaGlobeAmericas className=" fill-pink-500 group-hover:duration-200 group-hover:fill-white" />
            <p className="hidden md:block">Clear Cache</p>
          </button> */}
        </div>
        <div className="flex space-x-2 items-center">
          {/* <div className="p-4 rounded-full cursor-pointer duration-200  ">
            <FaBell className="fill-gray-600" />
          </div> */}
          <div
            className="group relative flex items-center space-x-2  cursor-pointer"
            onClick={() => setShowPopup(!showPopup)}
          >
            <img
              src={profile}
              alt="admin profile"
              className="w-10 h-10 rounded-full object-fit"
            />
            <div>
              <p className=" font-bold text-gray-500">Salauddin</p>
              <p classname="text-thin text-gray-400">Admin</p>
            </div>
            {showPopup ? (
              <div class="absolute -bottom-12 -left-1/3  w-[180px] rounded bg-white border text-black shadow-md p-2 text-xs  scale-100">
                <div
                  className="flex space-x-4 items-center hover:bg-blue-500 hover:text-white  p-2"
                  onClick={() => {
                    dispatch(userLoggedOut());
                  }}
                >
                  <HiLogout className="w-4 h-4" />
                  <p>Logout</p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
