import React, { useEffect, useState } from "react";
import {
  useAddSubCategoryMutation,
  useEditSubcategoryOperationMutation,
} from "../../../features/subCategoryApi/subCategoryApi";
import { useGetCategoryQuery } from "../../../features/category/categoryApi";
import Input from "../../../Shared/Components/Input";
import InputFile from "../../../Shared/Components/InputFile";
import { toast } from "react-toastify";
import Toast from "../../../Shared/UI/Toast";

export default function AddSubCategory({ subCategoryObj, setSubCategoryObj }) {
  let [categoryId, setCategoryId] = useState("");
  let [name, setName] = useState("");
  let [image, setImage] = useState("");

  const { data: categories, isLoading } = useGetCategoryQuery();
  const [
    addSubCategory,
    { isSuccess: isAddSubCategorySuccess, isError, error },
  ] = useAddSubCategoryMutation();
  const [editSubcategoryOperation, { isSuccess: isEditSubCategorySuccess }] =
    useEditSubcategoryOperationMutation();

  useEffect(() => {
    if (subCategoryObj?.id) {
      setName(subCategoryObj?.catagory_name);
    }
  }, [subCategoryObj]);

  useEffect(() => {
    if (isAddSubCategorySuccess) {
      toast.success("Successfully Sub category added.");
      setName("");
      setImage("");
      setCategoryId("");
    }
  }, [isAddSubCategorySuccess]);

  useEffect(() => {
    if (isEditSubCategorySuccess) {
      toast.success("Successfully Sub category updated.");
      setName("");
      setImage("");
      setCategoryId("");
      setSubCategoryObj({});
    }
  }, [isEditSubCategorySuccess]);

  function handleImageUpload(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      setImage(reader.result);
    };
  }

  const handleSubCategoryAdd = (e) => {
    if (!categoryId || !name || !image) {
      toast.error("please all required field");
      return;
    }
    addSubCategory({
      catagory_id: categoryId,
      sub_catagory_name: name,
      image,
    });
  };

  const handleSubCategoryEdit = (e) => {
    if (!categoryId || !name || !image) {
      toast.error("please all required field");
      return;
    }
    editSubcategoryOperation({
      id: subCategoryObj?.id,
      data: {
        catagory_id: categoryId,
        sub_catagory_name: name,
        image,
      },
    });
  };

  return (
    <div className="w-1/3">
      <div className="bg-white rounded-md shadow-xl ">
        <p className="px-8 py-4 font-semibold">
          {subCategoryObj?.id ? "Update" : "Add"} sub category
        </p>
        <div className="border-b border-b-gray-200"></div>
        <div className="flex p-8 pb-0">
          <div className="w-full px-4">
            <div className="w-full">
              <select
                id="selectID"
                className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
                onChange={(e) => setCategoryId(e.target.value)}
              >
                <option
                  className="bg-orange-400 text-white p-4 w-full"
                  value=""
                >
                  Select Category
                </option>
                {categories?.data?.map((category) => (
                  <option
                    className="bg-orange-400 text-white p-4 w-full"
                    value={category?.id}
                  >
                    {category?.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex flex-col mb-4 ">
              <label
                for=""
                className="w-full mb-3 block text-base text-black font-light"
              >
                Category Name <span className="text-orange-500">*</span>
              </label>
              <div className="w-full">
                <Input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>

            <div className="flex flex-col mb-4 ">
              <label
                for=""
                className="w-full mb-3 block text-base text-black font-light"
              >
                Category Image <span className="text-orange-500">*</span>
              </label>
              <div className="w-full">
                <InputFile onChange={handleImageUpload} />
              </div>
              {image && (
                <img
                  src={image}
                  alt="Uploaded image"
                  className="w-full h-auto pt-4"
                />
              )}
            </div>
          </div>
        </div>
        <div className="pb-8 pr-8 text-right">
          {subCategoryObj?.id ? (
            <button
              className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
              onClick={handleSubCategoryEdit}
            >
              Update
            </button>
          ) : (
            <button
              className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
              onClick={handleSubCategoryAdd}
            >
              Save
            </button>
          )}
        </div>
      </div>
      <Toast />
    </div>
  );
}
