import React from 'react'
import { FaCheck } from "react-icons/fa";

export default function ServerStatusComponent() {
    return (
        <div className='max-w-6xl mx-auto my-8'>
            <div className='bg-white mt-8 mx-6 rounded shadow-md'>
                <p className='px-8 py-4 font-semibold'>Server information</p>
                <div className='border-b border-b-gray-200'></div>
                <div className="p-8 overflow-x-auto">
                    {/* Working Like Table Head */}
                    <div className="w-full flex px-4">
                        <div className='w-1/4 py-4 font-bold'>
                            Name
                        </div>
                        <div className='w-1/4 py-4 font-bold'>
                            Current Version
                        </div>
                        <div className='w-1/4 py-4 font-bold'>
                            Required Versione
                        </div>
                        <div className='w-1/4 py-4 font-bold'>
                            Status
                        </div>
                    </div>
                    {/* Working Like Table Body Row */}
                    <div className='[&>*:nth-child(odd)]:bg-gray-100 [&>*:nth-child(even)]:bg-white'>
                        <div className="w-full flex px-4">
                            <div className='w-1/4 px-2 py-4'>
                                Php versions
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                7.4.26
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                7.3 or 7.4
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                <FaCheck className='fill-green-500' />
                            </div>
                        </div>
                        <div className="w-full flex px-4">
                            <div className='w-1/4 px-2 py-4'>
                                MySQL
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                10.1.48-MariaDB
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                5.6+
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                <FaCheck className='fill-green-500' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Another Part */}
            <div className='bg-white mt-8 mx-6 rounded shadow-md'>
                <p className='px-8 py-4 font-semibold'>php.ini Config</p>
                <div className='border-b border-b-gray-200'></div>
                <div className="p-8 overflow-x-auto">
                    {/* Working Like Table Head */}
                    <div className="w-full flex px-4">
                        <div className='w-1/4 py-4 font-bold'>
                            Config Name
                        </div>
                        <div className='w-1/4 py-4 font-bold'>
                            Current
                        </div>
                        <div className='w-1/4 py-4 font-bold'>
                            Recommended
                        </div>
                        <div className='w-1/4 py-4 font-bold'>
                            Status
                        </div>
                    </div>
                    {/* Working Like Table Body Row */}
                    <div className='[&>*:nth-child(odd)]:bg-gray-100 [&>*:nth-child(even)]:bg-white'>
                        <div className="w-full flex px-4">
                            <div className='w-1/4 px-2 py-4'>
                                file_uploads
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                On
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                On
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                <FaCheck className='fill-green-500' />
                            </div>
                        </div>
                        <div className="w-full flex px-4">
                            <div className='w-1/4 px-2 py-4'>
                                max_file_uploads
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                20
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                20+
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                <FaCheck className='fill-green-500' />
                            </div>
                        </div>
                        <div className="w-full flex px-4">
                            <div className='w-1/4 px-2 py-4'>
                                file_uploads
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                On
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                On
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                <FaCheck className='fill-green-500' />
                            </div>
                        </div>
                        <div className="w-full flex px-4">
                            <div className='w-1/4 px-2 py-4'>
                                max_file_uploads
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                200M+
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                200M
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                <FaCheck className='fill-green-500' />
                            </div>
                        </div>
                        <div className="w-full flex px-4">
                            <div className='w-1/4 px-2 py-4'>
                                file_uploads
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                On
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                On
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                <FaCheck className='fill-green-500' />
                            </div>
                        </div>
                        <div className="w-full flex px-4">
                            <div className='w-1/4 px-2 py-4'>
                                max_file_uploads
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                200M+
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                200M
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                <FaCheck className='fill-green-500' />
                            </div>
                        </div>
                        <div className="w-full flex px-4">
                            <div className='w-1/4 px-2 py-4'>
                                file_uploads
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                On
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                On
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                <FaCheck className='fill-green-500' />
                            </div>
                        </div>
                        <div className="w-full flex px-4">
                            <div className='w-1/4 px-2 py-4'>
                                max_file_uploads
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                20
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                20+
                            </div>
                            <div className='w-1/4 px-2 py-4'>
                                <FaCheck className='fill-green-500' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Another Part */}
            <div className='bg-white mt-8 mx-6 rounded shadow-md'>
                <p className='px-8 py-4 font-semibold'>Extensions information</p>
                <div className='border-b border-b-gray-200'></div>
                <div className="p-8 overflow-x-auto">
                    {/* Working Like Table Head */}
                    <div className="w-full flex px-4">
                        <div className='w-4/6 py-4 font-bold'>
                        Extension Name
                        </div>
                        <div className='w-2/6 py-4 font-bold'>
                        Status
                        </div>
                    </div>
                    {/* Working Like Table Body Row */}
                    <div className='[&>*:nth-child(odd)]:bg-gray-100 [&>*:nth-child(even)]:bg-white'>
                        <div className="w-full flex px-4">
                            <div className='w-4/6 px-2 py-4'>
                            bcmath
                            </div>
                            <div className='w-2/6 px-2 py-4'>
                                <FaCheck className='fill-green-500' />
                            </div>
                        </div>
                        <div className="w-full flex px-4">
                            <div className='w-4/6 px-2 py-4'>
                            bcmath
                            </div>
                            <div className='w-2/6 px-2 py-4'>
                                <FaCheck className='fill-green-500' />
                            </div>
                        </div>
                        <div className="w-full flex px-4">
                            <div className='w-4/6 px-2 py-4'>
                            bcmath
                            </div>
                            <div className='w-2/6 px-2 py-4'>
                                <FaCheck className='fill-green-500' />
                            </div>
                        </div>
                        <div className="w-full flex px-4">
                            <div className='w-4/6 px-2 py-4'>
                            bcmath
                            </div>
                            <div className='w-2/6 px-2 py-4'>
                                <FaCheck className='fill-green-500' />
                            </div>
                        </div>
                        <div className="w-full flex px-4">
                            <div className='w-4/6 px-2 py-4'>
                            bcmath
                            </div>
                            <div className='w-2/6 px-2 py-4'>
                                <FaCheck className='fill-green-500' />
                            </div>
                        </div>
                        <div className="w-full flex px-4">
                            <div className='w-4/6 px-2 py-4'>
                            bcmath
                            </div>
                            <div className='w-2/6 px-2 py-4'>
                                <FaCheck className='fill-green-500' />
                            </div>
                        </div>
                        <div className="w-full flex px-4">
                            <div className='w-4/6 px-2 py-4'>
                            bcmath
                            </div>
                            <div className='w-2/6 px-2 py-4'>
                                <FaCheck className='fill-green-500' />
                            </div>
                        </div>
                        <div className="w-full flex px-4">
                            <div className='w-4/6 px-2 py-4'>
                            bcmath
                            </div>
                            <div className='w-2/6 px-2 py-4'>
                                <FaCheck className='fill-green-500' />
                            </div>
                        </div>
                        <div className="w-full flex px-4">
                            <div className='w-4/6 px-2 py-4'>
                            bcmath
                            </div>
                            <div className='w-2/6 px-2 py-4'>
                                <FaCheck className='fill-green-500' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
