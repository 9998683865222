import React from "react";
import WirehouseInternationalProductComponent from "./Components/WirehouseInternationalProductComponent/WirehouseInternationalProductComponent";

export default function WirehouseInternationalProduct() {
  return (
    <div>
      <WirehouseInternationalProductComponent />
    </div>
  );
}
