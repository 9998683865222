import React from "react";
import BuyersPaymentMethodComponent from "./Components/BuyersPaymentMethodComponent/BuyersPaymentMethodComponent";

export default function BuyersPaymentMethod() {
  return (
    <div>
      <BuyersPaymentMethodComponent />
    </div>
  );
}
