import { apiSlice } from "../api/apiSlice";

export const sellerOwnPaymentInfoApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSellerOwnPaymentInfo: builder.query({
      query: (id) => `/admin/seller/payment/details/${id}`,
      providesTags: ["sellerOwnPaymentInfo"],
    }),
    addSellerOwnPaymentInfo: builder.mutation({
      query: (data) => ({
        url: "/admin/seller/payment/details",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["sellerOwnPaymentInfo"],
    }),

    editSellerOwnPaymentInfo: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/seller/payment/details/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["sellerOwnPaymentInfo"],
    }),

    deleteSellerOwnPaymentInfo: builder.mutation({
      query: (id) => ({
        url: `/admin/seller/payment/details/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["sellerOwnPaymentInfo"],
    }),
  }),
});

export const {
  useGetSellerOwnPaymentInfoQuery,
  useAddSellerOwnPaymentInfoMutation,
  useEditSellerOwnPaymentInfoMutation,
  useDeleteSellerOwnPaymentInfoMutation,
} = sellerOwnPaymentInfoApi;
