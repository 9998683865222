import { apiSlice } from "../api/apiSlice";

export const sellerExtraChargeTypeApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSellerExtraChargeType: builder.query({
      query: () => `/admin/seller/exter/charges/type`,
      providesTags: ["sellerExtraChargeType"],
    }),

    addSellerExtraChargeType: builder.mutation({
      query: (data) => ({
        url: "/admin/seller/exter/charges/type",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["sellerExtraChargeType"],
    }),

    editSellerExtraChargeType: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/seller/exter/charges/type/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["sellerExtraChargeType"],
    }),

    deleteSellerExtraChargeType: builder.mutation({
      query: (id) => ({
        url: `/admin/seller/exter/charges/type/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["sellerExtraChargeType"],
    }),
  }),
});

export const {
  useGetSellerExtraChargeTypeQuery,
  useAddSellerExtraChargeTypeMutation,
  useEditSellerExtraChargeTypeMutation,
  useDeleteSellerExtraChargeTypeMutation,
} = sellerExtraChargeTypeApi;
