import { apiSlice } from "../api/apiSlice";

export const voucherApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVoucher: builder.query({
      query: () => `/admin/buyer/vouchers`,
      providesTags: ["buyerBanner"],
    }),
    addVoucher: builder.mutation({
      query: (data) => ({
        url: "/admin/buyer/vouchers",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["buyerBanner"],
    }),
    editVoucher: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/buyer/vouchers/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["buyerBanner"],
    }),
    deleteVoucher: builder.mutation({
      query: (id) => ({
        url: `/admin/buyer/vouchers/${id}`,
        method: "DELETE",
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["buyerBanner"],
    }),
  }),
});

export const {
  useGetVoucherQuery,
  useAddVoucherMutation,
  useEditVoucherMutation,
  useDeleteVoucherMutation,
} = voucherApi;
