import { apiSlice } from "../api/apiSlice";

export const productsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: () => `/admin/product`,
      providesTags: ["products"],
    }),
    addProducts: builder.mutation({
      query: (data) => ({
        url: "/admin/product",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["products"],
    }),

    editProducts: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/product/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["products"],
    }),

    deleteProducts: builder.mutation({
      query: (id) => ({
        url: `/admin/product/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["products"],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useAddProductsMutation,
  useEditProductsMutation,
  useDeleteProductsMutation,
} = productsApi;
