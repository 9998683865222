import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userLoggedIn } from "../features/auth/authSLice";

export default function useAuthCheck() {
  let [authIsReady, setAuthIsReady] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let localAuth = localStorage.getItem("auth");

    if (localAuth) {
      let auth = JSON.parse(localAuth);

      if (auth?.token && auth?.user) {
        dispatch(
          userLoggedIn({
            token: auth?.token,
            user: auth?.user,
            userType: auth?.userType,
          })
        );
      }
    }

    setAuthIsReady(true);
  }, [dispatch]);

  return authIsReady;
}
