import React, { useEffect, useState } from "react";
import Toast from "../../../Shared/Components/Toast";
import {
  useAddFlatDiscountMutation,
  useEditFlatDiscountMutation,
} from "../../../features/flatDiscount/flatDiscountApi";
import { toast } from "react-toastify";
import Input from "../../../Shared/Components/Input";
import { useGetSellersQuery } from "../../../features/saller/sallerApi";

export default function AddFlatDiscount({
  flatDiscountObj,
  setFlatDiscountObj,
}) {
  let [flatDiscount, setFlatDiscount] = useState({
    seller_id: "",
    code: "",
    min_quanty: "",
    disc_amount: "",
    min_order_amount: "",
    show_coustomer: true,
  });

  useEffect(() => {
    console.log("flatDiscountObj", flatDiscountObj);
    setFlatDiscount({
      seller_id: flatDiscountObj?.seller_id,
      code: flatDiscountObj?.coupon_code,
      min_quanty: flatDiscountObj?.min_qty,
      disc_amount: flatDiscountObj?.discount_value,
      min_order_amount: flatDiscountObj?.min_order_amount,
      show_coustomer: flatDiscount?.show_coustomer || true,
    });
  }, [flatDiscountObj]);

  const {
    data: allSellers,
    isLoading: sellerLoading,
    isError: sellerIsError,
    error: sellerError,
  } = useGetSellersQuery();

  const [addFlatDiscount, { isSuccess: isFlatDiscountSuccess }] =
    useAddFlatDiscountMutation();
  const [
    editFlatDiscount,
    { isSuccess: isEditFlatDiscountSuccess, isLoading, isError, error },
  ] = useEditFlatDiscountMutation();

  useEffect(() => {
    if (isFlatDiscountSuccess) {
      toast.success("Successfully Flat Discount added.");
      setFlatDiscount({
        seller_id: "",
        code: "",
        min_quanty: "",
        disc_amount: "",
        min_order_amount: "",
        show_coustomer: true,
      });
    }
  }, [isFlatDiscountSuccess]);

  useEffect(() => {
    if (isEditFlatDiscountSuccess) {
      toast.success("Successfully  Flat Discount Updated.");
      setFlatDiscount({
        seller_id: "",
        code: "",
        min_quanty: "",
        disc_amount: "",
        min_order_amount: "",
        show_coustomer: true,
      });
      setFlatDiscountObj({});
    }
    if (isError) {
      console.log("error", error);
      toast.error(error.error);
    }
  }, [isEditFlatDiscountSuccess, isError]);

  const handleCategoryAdd = (e) => {
    // console.log("flatDiscount", flatDiscount);
    addFlatDiscount(flatDiscount);
  };

  const handleCategoryEdit = async (e) => {
    await editFlatDiscount({
      id: flatDiscountObj?.id,
      data: {
        ...flatDiscount,
      },
    });
  };

  console.log("flatDiscount", flatDiscount);

  return (
    <>
      <div className="w-full md:w-1/3">
        <div className="bg-white rounded-md shadow-xl ">
          <p className="px-8 py-4 font-semibold">
            {flatDiscountObj?.seller_id ? "Update" : "Add"} flat discount
          </p>
          <div className="border-b border-b-gray-200"></div>

          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Seller Id <span className="text-orange-500">*</span>
                </label>
                <div className="w-full ">
                  <select
                    id="selectID"
                    className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
                    onChange={(e) =>
                      setFlatDiscount({
                        ...flatDiscount,
                        seller_id: e.target.value,
                      })
                    }
                  >
                    <option
                      className="bg-orange-400 text-white p-4 w-full"
                      value=""
                    >
                      Select Seller
                    </option>
                    {allSellers?.data?.map((seller) => (
                      <option
                        className="bg-orange-400 text-white p-4 w-full"
                        value={seller?.id}
                      >
                        {seller?.shop_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Code <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder=""
                    value={flatDiscount.code}
                    onChange={(e) =>
                      setFlatDiscount({
                        ...flatDiscount,
                        code: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Min Quanty <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder=""
                    value={flatDiscount.min_quanty}
                    onChange={(e) =>
                      setFlatDiscount({
                        ...flatDiscount,
                        min_quanty: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Disc Amount <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder=""
                    value={flatDiscount.disc_amount}
                    onChange={(e) =>
                      setFlatDiscount({
                        ...flatDiscount,
                        disc_amount: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Min Order Amount <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder=""
                    value={flatDiscount.min_order_amount}
                    onChange={(e) =>
                      setFlatDiscount({
                        ...flatDiscount,
                        min_order_amount: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pb-8 pr-8 text-right">
            {flatDiscountObj?.seller_id ? (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryEdit}
              >
                Update
              </button>
            ) : (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryAdd}
              >
                Save
              </button>
            )}
          </div>
        </div>
        <Toast />
      </div>
    </>
  );
}
