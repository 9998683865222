import React from "react";

export default function SellerInfo({ sellerInfo }) {
  return (
    <div className="p-10">
      <div className="flex justify-center items-center pb-10">
        <img src={sellerInfo?.logo} alt={sellerInfo?.shop_name} />
      </div>
      <div className="flex justify-between gap-4 bg-slate-100 p-2 mb-2">
        <p className="font-bold">Id:</p>
        <p className="">{sellerInfo?.id}</p>
      </div>
      <div className="flex justify-between gap-4 bg-slate-100 p-2 mb-2">
        <p className="font-bold">Shop Name:</p>
        <p className="">{sellerInfo?.shop_name}</p>
      </div>
      <div className="flex justify-between gap-4 bg-slate-100 p-2 mb-2">
        <p className="font-bold">Email:</p>
        <p className="">{sellerInfo?.email}</p>
      </div>
      <div className="flex justify-between gap-4 bg-slate-100 p-2 mb-2">
        <p className="font-bold">Phone:</p>
        <p className="">{sellerInfo?.phone}</p>
      </div>
      <div className="flex justify-between gap-4 bg-slate-100 p-2 mb-2">
        <p className="font-bold">Business Type Name:</p>
        <p className="">{sellerInfo?.business_type_name}</p>
      </div>
      <div className="flex justify-between gap-4 bg-slate-100 p-2 mb-2">
        <p className="font-bold">Address:</p>
        <p className="">{sellerInfo?.address}</p>
      </div>
      <div className="flex justify-between gap-4 bg-slate-100 p-2 mb-2">
        <p className="font-bold">Slug:</p>
        <p className="">{sellerInfo?.slug}</p>
      </div>
    </div>
  );
}
