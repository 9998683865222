import React from 'react'
import Input from '../../../Shared/Components/Input'
import InputFile from '../../../Shared/Components/InputFile'
import Toggle from '../../../Shared/Components/Toggle'

export default function AddForm() {
    return (
        <div className='w-full md:w-2/3'>
            <div className='bg-white rounded-md shadow-xl '>
                <p className='px-8 py-4 font-semibold'>Product Information</p>
                <div className='border-b border-b-gray-200'></div>
                <div className="flex p-8">
                    <div className="w-full px-4 w-full">
                        <div className="flex flex-col mb-4 md:flex-row">
                            <label for="" className="w-full md:w-1/4 mb-3 block text-base text-black font-light">
                                Product Name <span className='text-orange-500'>*</span>
                            </label>
                            <div className='w-full md:w-3/4'>
                                <Input type="text" placeholder="Product Name" />
                            </div>
                        </div>

                        <div className="flex flex-col mb-4 md:flex-row">
                            <label for="" className="w-full md:w-1/4 mb-3 block text-base text-black font-light">
                                Category <span className='text-orange-500'>*</span>
                            </label>
                            <select id="selectID" className='w-full md:w-3/4  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary w-full rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500'>
                                <option className='bg-orange-400 text-white p-4'>Hot Categories</option>
                                <option className='bg-orange-400 text-white p-4'>Party Dress</option>
                                <option className='bg-orange-400 text-white p-4'>Women Shoe</option>
                            </select>
                        </div>

                        <div className="flex flex-col mb-4 md:flex-row">
                            <label for="" className="w-full md:w-1/4 mb-3 block text-base text-black font-light">
                                Unit
                            </label>
                            <div className='w-full md:w-3/4'>
                                <Input type="text" placeholder="Unit(e.g. KG,Pc etc)" />
                            </div>
                        </div>

                        <div className="flex flex-col mb-4 md:flex-row">
                            <label for="" className="w-full md:w-1/4 mb-3 block text-base text-black font-light">
                                Weight
                            </label>
                            <div className='w-full md:w-3/4'>
                                <Input type="number" placeholder="0.00" />
                            </div>
                        </div>

                        <div className="flex flex-col mb-4 md:flex-row">
                            <label for="" className="w-full md:w-1/4 mb-3 block text-base text-black font-light">
                                Minimum Purchase Qty <span className='text-orange-500'>*</span>
                            </label>
                            <div className='w-full md:w-3/4'>
                                <Input type="text" placeholder="" />
                            </div>
                        </div>

                        <div className="flex flex-col mb-4 md:flex-row">
                            <label for="" className="w-full md:w-1/4 mb-3 block text-base text-black font-light">
                                Refundable
                            </label>
                            <div className='w-full md:w-3/4'>
                                <Toggle />
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            {/* Image Container */}
            <div className='bg-white rounded-md shadow-xl mt-8'>
                <p className='px-8 py-4 font-semibold'>Product Information</p>
                <div className='border-b border-b-gray-200'></div>
                <div className="flex p-8">
                    <div className="w-full px-4 w-full">
                        <div className="flex flex-col mb-4 md:flex-row">
                            <label for="" className="w-full md:w-1/4 mb-3 block text-base text-black font-light">
                                Gallery Image
                            </label>
                            <div className="w-full md:w-3/4 ">
                                <InputFile />
                            </div>
                        </div>

                        <div className="flex flex-col mb-4 md:flex-row">
                            <label for="" className="w-full md:w-1/4 mb-3 block text-base text-black font-light">
                                Thumbnail Image
                            </label>
                            <div className="w-full md:w-3/4 ">
                                <InputFile />
                                <p className='font-thin text-xs mt-2 text-gray-500'>This image is visible in all product box. Use 300x300 sizes image. Keep some blank space around main object of your image as we had to crop some edge in different devices to make it responsive.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Meta Tag */}
            <div className='bg-white rounded-md shadow-xl mt-8'>
                <p className='px-8 py-4 font-semibold'>SEO Meta Tags</p>
                <div className='border-b border-b-gray-200'></div>
                <div className="flex p-8">
                    <div className="w-full px-4 w-full">
                        <div className="flex flex-col mb-4 md:flex-row">
                            <label for="" className="w-full md:w-1/4 mb-3 block text-base text-black font-light">
                                Meta Title
                            </label>
                            <div className="w-full md:w-3/4 ">
                                <Input />
                            </div>
                        </div>

                        <div className="flex flex-col mb-4 md:flex-row">
                            <label for="" className="w-full md:w-1/4 mb-3 block text-base text-black font-light">
                                Description
                            </label>
                            <div className="w-full md:w-3/4 ">
                                <textarea
                                    rows="5"
                                    placeholder="Active textarea"
                                    class="border-primary text-body-color placeholder-body-color focus:border-primary active:border-primary w-full rounded-lg border-[1.5px] py-3 px-5 font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD]"
                                ></textarea>
                            </div>
                        </div>



                        <div className="flex flex-col mb-4 md:flex-row">
                            <label for="" className="w-full md:w-1/4 mb-3 block text-base text-black font-light">
                                Meta Image
                            </label>
                            <div className="w-full md:w-3/4 ">
                                <InputFile />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
