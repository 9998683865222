import React, { useEffect, useState } from "react";
import {
  useAddVoucherMutation,
  useEditVoucherMutation,
} from "../../../features/voucher/voucherApi";
import { toast } from "react-toastify";
import Input from "../../../Shared/Components/Input";
import Toast from "../../../Shared/Components/Toast";
import { useGetBuyerCreateQuery } from "../../../features/buyerCreate/buyerCreateApi";

export default function AddVoucher({ categoryObj, setCategoryObj }) {
  let [countryDetail, setCountryDetail] = useState({
    user_id: "",
    voucher_code: "",
    min_amount: "",
    amount: "",
    expire_date: "",
  });

  useEffect(() => {
    setCountryDetail({
      user_id: categoryObj?.user_info?.id,
      voucher_code: categoryObj?.voucher_code,
      min_amount: categoryObj?.min_amount,
      amount: categoryObj?.amount,
      expire_date: categoryObj?.expire_date,
    });
  }, [categoryObj]);

  const { data: AllBuyers } = useGetBuyerCreateQuery();

  const [
    addVoucher,
    { isLoading: isAddLoading, isSuccess: isAddCategorySuccess },
  ] = useAddVoucherMutation();
  const [
    editVoucher,
    {
      isSuccess: isEditCategorySuccess,
      isLoading: isEditLoading,
      isError,
      error,
    },
  ] = useEditVoucherMutation();

  useEffect(() => {
    if (isAddCategorySuccess) {
      toast.success("Successfully voucher added.");
      setCountryDetail({
        user_id: "",
        voucher_code: "",
        min_amount: "",
        amount: "",
        expire_date: "",
      });
    }
  }, [isAddCategorySuccess]);

  console.log(
    "isEditCategorySuccess, isError, error",
    isEditCategorySuccess,
    isError,
    error
  );

  useEffect(() => {
    if (isEditCategorySuccess) {
      toast.success("Successfully Voucher Updated.");
      setCountryDetail({
        user_id: "",
        voucher_code: "",
        min_amount: "",
        amount: "",
        expire_date: "",
      });
      setCategoryObj({});
    }
    if (isError) {
      toast.error(error.error);
    }
  }, [isEditCategorySuccess, isError]);

  const handleCategoryAdd = (e) => {
    addVoucher(countryDetail);
  };

  const handleCategoryEdit = async (e) => {
    await editVoucher({
      id: categoryObj?.id,
      data: countryDetail,
    });
  };

  console.log("categoryObj", categoryObj);
  console.log("countryDetail", countryDetail);

  return (
    <>
      <div className="w-1/3">
        <div className="bg-white rounded-md shadow-xl ">
          <p className="px-8 py-4 font-semibold">
            {categoryObj?.id ? "Update" : "Add"} Voucher
          </p>
          <div className="border-b border-b-gray-200"></div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="w-full mb-4 ">
                <select
                  id="selectID"
                  className="w-full  border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500"
                  value={countryDetail?.user_id}
                  onChange={(e) =>
                    setCountryDetail({
                      ...countryDetail,
                      user_id: e.target.value,
                    })
                  }
                >
                  <option
                    className="bg-orange-400 text-white p-4 w-full"
                    value=""
                  >
                    Select Buyer
                  </option>
                  {AllBuyers?.data?.map((buyer) => (
                    <option
                      className="bg-orange-400 text-white p-4 w-full"
                      value={buyer?.id}
                    >
                      {buyer?.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Voucher Code <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder="Voucher Code"
                    value={countryDetail?.voucher_code}
                    onChange={(e) =>
                      setCountryDetail({
                        ...countryDetail,
                        voucher_code: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Min Amount <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder="Min Amount"
                    value={countryDetail?.min_amount}
                    onChange={(e) =>
                      setCountryDetail({
                        ...countryDetail,
                        min_amount: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Amount <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder="Amount"
                    value={countryDetail?.amount}
                    onChange={(e) =>
                      setCountryDetail({
                        ...countryDetail,
                        amount: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Expire Date <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="date"
                    placeholder="Expire Date"
                    value={countryDetail?.expire_date}
                    onChange={(e) =>
                      setCountryDetail({
                        ...countryDetail,
                        expire_date: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pb-8 pr-8 text-right">
            {categoryObj?.id ? (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryEdit}
                disabled={isAddLoading}
              >
                {isEditLoading ? "Processing" : "Update"}
              </button>
            ) : (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryAdd}
                disabled={isAddLoading}
              >
                {isAddLoading ? "Processing" : "Save"}
              </button>
            )}
          </div>
        </div>
        <Toast />
      </div>
    </>
  );
}
