import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { wirehouseLoggedIn } from "../features/auth/wirehouseAuthSlice";

export default function useWirehouseAuthCheck() {
  let [authIsReady, setAuthIsReady] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let localAuth = localStorage.getItem("wirehouse-auth");

    if (localAuth) {
      let auth = JSON.parse(localAuth);

      if (auth?.wirehouseToken) {
        dispatch(
          wirehouseLoggedIn({
            wirehouseToken: auth?.wirehouseToken,
          })
        );
      }
    }

    setAuthIsReady(true);
  }, [dispatch]);

  return authIsReady;
}
