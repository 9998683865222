import React from 'react'
import Layout from '../Layout/Layout'
import ProductAdd from './Components/ProductComponent/ProductAdd'

export default function AddProduct({ showSidebar, setShowSidebar }) {
  return (
    <Layout showSidebar={showSidebar} setShowSidebar={setShowSidebar}>
      <ProductAdd />
      <div className='m-8 text-right'>
        <button className='bg-orange-500 text-white py-2 px-8 rounded-md mr-4'>Save & Unpublish</button>
        <button className='bg-green-500 text-white py-2 px-8 rounded-md'>Save & Publish</button>
      </div>
    </Layout>
  )
}
