import React from 'react'
import Layout from '../Layout/Layout'
import PosComponent from './Components/PosComponent/PosComponent'

export default function Pos({ showSidebar, setShowSidebar }) {
  return (
    <Layout showSidebar={showSidebar} setShowSidebar={setShowSidebar}>
        <PosComponent />
    </Layout>
  )
}
