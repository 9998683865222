import React from "react";
import { useGetSellerFlatDiscountQuery } from "../../../features/sellerFlatDiscountApi/sellerFlatDiscountApi";
import { useGetSellerProductQuery } from "../../../features/sellerProduct/sellerProductApi";
import { FaExternalLinkAlt } from "react-icons/fa";

export default function SellerProduct({ id }) {
  const {
    data: sellerProduct,
    isLoading,
    isError,
    error,
  } = useGetSellerProductQuery(id);
  console.log("sellerProduct", id, sellerProduct);

  let content = null;
  if (isLoading) {
    content = <div>Loading</div>;
  }
  if (!isLoading && isError) {
    content = (
      <div>
        <p className="text-red-500">{error?.error}</p>
      </div>
    );
  }

  if (!isLoading && !isError) {
    content = (
      <tbody>
        {sellerProduct?.data?.map((seller) => (
          <tr className="border-t border-[#ddd] text-left text-dark py-4">
            <td className="py-2 px-2  text-base">
              <div className="flex items-center">
                <p>{seller?.product_name}</p>
              </div>
            </td>
            <td className="py-2 px-2  text-base">{seller?.product_code}</td>
            <td className="py-2 px-4  text-base">{seller?.product_details}</td>
            <td className="py-2 px-4  text-base">{seller?.product_origin}</td>
            <td className="py-2 px-4  text-base">{seller?.weight_type}</td>
            <td className="py-2 px-4  text-base">{seller?.weight_unit}</td>
            <td className="py-2 px-4  text-base">{seller?.in_stock}</td>
            <td className="py-2 px-4  text-base">
              <a href={seller?.video_url} target="_blank">
                <FaExternalLinkAlt />
              </a>
            </td>
            {/* <td className="y-2 px-2  text-base">
              <img src={seller?.logo} className="w-20 h-20" alt="logo" />
            </td> */}
            {/* <td className="y-2 px-2  text-base">
              <div className="flex space-x-2">
                <div className="p-2 bg-red-200 rounded-full cursor-pointer">
                  <FaRegEdit className="fill-red-500" />
                </div>
                <div
                  className="p-2 bg-red-200 rounded-full cursor-pointer"
                  onClick={() => {
                    setSelectedId(seller?.id);
                    setShowModal(true);
                  }}
                >
                  <FaTrashAlt className="fill-red-500" />
                </div>
              </div>
            </td> */}
          </tr>
        ))}
      </tbody>
    );
  }
  return (
    <div className="max-w-full overflow-x-auto max-h-[500px] overflow-scroll p-6">
      <table className="w-full table-auto">
        <thead>
          <tr className="text-left text-dark font-bold">
            <th className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Product Name
            </th>
            <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Product Code
            </th>
            <th className="w-1/6 min-w-[260px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Product Details
            </th>
            <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Product Origin
            </th>
            <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Weight Type
            </th>
            <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Weight Unit
            </th>
            <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              In Stock
            </th>
            <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
              Video Url
            </th>
          </tr>
        </thead>
        {content}
      </table>
    </div>
  );
}
