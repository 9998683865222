import React from 'react'
import { FaEye, FaRegEdit, FaCopy, FaTrashAlt } from "react-icons/fa";
import Input from '../../../Shared/Components/Input';

export default function AttributeTable() {
    return (
        <div className='w-full md:w-2/3 overflow-x-hidden'>
            <section className="bg-white shadow-xl rounded-md mb-4 m-0 md:m-4 py-6 lg:my-0 lg:py-8">
                <div className='flex flex-col justify-between items-center pb-4 px-4 md:flex-row'>
                    <p className='w-3/4 px-8 py-4 font-semibold'>Attributes</p>
                    <div className='w-full md:w-3/4 flex flex-col items-center space-x-2 md:flex-row '>
                        <div className='w-full'>
                            <Input type="text" placeholder="Type & Enter" />
                        </div>
                    </div>
                </div>
                <div className='border-b border-b-gray-200'></div>
                <div className="-mx-4 flex flex-wrap">
                    <div className="w-full px-4 mx-4">
                        <div className="max-w-full overflow-x-auto">
                            <table className="w-full table-auto">
                                <thead>
                                    <tr className="text-left text-dark font-bold">
                                        <th
                                            className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            #
                                        </th>
                                        <th
                                            className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Name
                                        </th>
                                        <th
                                            className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Values
                                        </th>
                                        <th
                                            className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4"
                                        >
                                            Options
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='border-t border-[#ddd] text-left text-dark py-4'>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div className='flex items-center'>
                                                1
                                            </div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            Liter
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-2'>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>1 Ltr</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>2 Ltr</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>5 Ltr</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>10 Ltr</span>
                                            </div>
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-2 cursor-pointer'>
                                                <div className='p-2 bg-red-200 rounded-full'>
                                                    <FaRegEdit className='fill-red-500' />
                                                </div>
                                                <div className='p-2 bg-red-200 rounded-full cursor-pointer'>
                                                    <FaTrashAlt className='fill-red-500' />
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                    <tr className='border-t border-[#ddd] text-left text-dark py-4'>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div className='flex items-center'>
                                                2
                                            </div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            Wheel
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            {/* <div className='flex space-x-2'>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>1 Ltr</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>2 Ltr</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>5 Ltr</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>10 Ltr</span>
                                            </div> */}
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-2 cursor-pointer'>
                                                <div className='p-2 bg-red-200 rounded-full'>
                                                    <FaRegEdit className='fill-red-500' />
                                                </div>
                                                <div className='p-2 bg-red-200 rounded-full cursor-pointer'>
                                                    <FaTrashAlt className='fill-red-500' />
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                    <tr className='border-t border-[#ddd] text-left text-dark py-4'>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div className='flex items-center'>
                                                3
                                            </div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            Sleeve
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-2'>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>Bell Sleves</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>Cap Sleves</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>Raglan Sleves</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>Flutter Sleves</span>
                                            </div>
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-2 cursor-pointer'>
                                                <div className='p-2 bg-red-200 rounded-full'>
                                                    <FaRegEdit className='fill-red-500' />
                                                </div>
                                                <div className='p-2 bg-red-200 rounded-full cursor-pointer'>
                                                    <FaTrashAlt className='fill-red-500' />
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                    <tr className='border-t border-[#ddd] text-left text-dark py-4'>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div className='flex items-center'>
                                                4
                                            </div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            Fabric
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-2'>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>Bell Sleves</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>Cap Sleves</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>Raglan Sleves</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>Flutter Sleves</span>
                                            </div>
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-2 cursor-pointer'>
                                                <div className='p-2 bg-red-200 rounded-full'>
                                                    <FaRegEdit className='fill-red-500' />
                                                </div>
                                                <div className='p-2 bg-red-200 rounded-full cursor-pointer'>
                                                    <FaTrashAlt className='fill-red-500' />
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                    <tr className='border-t border-[#ddd] text-left text-dark py-4'>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div className='flex items-center'>
                                                4
                                            </div>
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            Size
                                        </td>
                                        <td
                                            className="py-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-2'>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>M</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>L</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>XL</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>XXL</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>S</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>64GB</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>128GB</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>512GB</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>1TB</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>3/32GB</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>4/64GB</span>
                                                <span className='bg-gray-300 text-dark p-2 rounded-lg'>4/128GB</span>
                                            </div>
                                        </td>
                                        <td
                                            className="y-2 px-2  text-base"
                                        >
                                            <div className='flex space-x-2 cursor-pointer'>
                                                <div className='p-2 bg-red-200 rounded-full'>
                                                    <FaRegEdit className='fill-red-500' />
                                                </div>
                                                <div className='p-2 bg-red-200 rounded-full cursor-pointer'>
                                                    <FaTrashAlt className='fill-red-500' />
                                                </div>
                                            </div>
                                        </td>

                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
