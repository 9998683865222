import React, { useState } from "react";
import { useGetOrderDetailsQuery } from "../../../features/orders/ordersApi";
import { FaEye, FaTimes } from "react-icons/fa";
import Modal from "../../../Shared/Modal";
import OrderShippingDetails from "./OrderShippingDetails";
import OrderTrack from "./OrderTrack";

export default function OrderDetails({ selectedId }) {
  const [trackId, setTrackId] = useState("");
  const [showTrack, setShowTrack] = useState(false);

  const [showShipping, setShowShipping] = useState(false);
  const [shippingObj, setShippingObj] = useState({});

  const {
    data: orderDetail,
    isLoading,
    isError,
    error,
  } = useGetOrderDetailsQuery(selectedId);

  if (isLoading) {
    return <div>Loading</div>;
  }

  if (!isLoading && isError) {
    return (
      <div>
        <p className="text-red-500">{error?.error}</p>
      </div>
    );
  }

  console.log("order Details", orderDetail);

  return (
    <>
      <div>
        {orderDetail?.data?.map((order) => (
          <div className="py-10 px-8 flex flex-col gap-2">
            <div className="grid gap-2 grid-cols-4 text-left">
              <p className="col-span-1 font-bold">Id:</p>
              <p className="col-span-3">{order?.id}</p>
            </div>
            <div className="grid gap-2 grid-cols-4 text-left">
              <p className="col-span-1 font-bold">Order Id:</p>
              <p className="col-span-3">{order?.order_id}</p>
            </div>
            <div className="grid gap-2 grid-cols-4 text-left">
              <p className="col-span-1 font-bold">Order Date:</p>
              <p className="col-span-3">{order?.order_date}</p>
            </div>
            <div className="grid gap-2 grid-cols-4 text-left">
              <p className="col-span-1 font-bold">Total Item:</p>
              <p className="col-span-3">{order?.total_item}</p>
            </div>
            <div className="grid gap-2 grid-cols-4 text-left">
              <p className="col-span-1 font-bold">Total Amount:</p>
              <p className="col-span-3">{order?.total_amount}</p>
            </div>
            <div className="grid gap-2 grid-cols-4 text-left">
              <p className="col-span-1 font-bold">Discount Amount:</p>
              <p className="col-span-3">{order?.discount_amount}</p>
            </div>
            <div className="grid gap-2 grid-cols-4 text-left">
              <p className="col-span-1 font-bold">Payable Amount:</p>
              <p className="col-span-3">{order?.payable_amount}</p>
            </div>
            <div className="grid gap-2 grid-cols-4 text-left">
              <p className="col-span-1 font-bold">Order Status:</p>
              <p className="col-span-3">{order?.order_status}</p>
            </div>
            {order?.products?.length ? (
              <div className="max-w-full overflow-x-auto max-h-[500px] overflow-scroll p-6">
                <h1 className="font-bold">Products</h1>
                <table className="w-full table-auto">
                  <thead>
                    <tr className="text-left text-dark font-bold">
                      <th className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Id:
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Track Id:
                      </th>
                      <th className="w-1/6 min-w-[260px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Name:
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Price:
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Shipping Charge:
                      </th>
                      <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Img:
                      </th>
                      <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Shipping Address:
                      </th>
                      <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Order Track:
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {order?.products?.map((product) => (
                      <tr className="border-t border-[#ddd] text-left text-dark py-4">
                        <td className="py-2 px-2  text-base">
                          <p>{product?.id}</p>
                        </td>
                        <td className="py-2 px-2  text-base">
                          {product?.track_id}
                        </td>
                        <td className="py-2 px-4  text-base">
                          {product?.name} ({product?.quantity})
                        </td>
                        <td className="py-2 px-4  text-base">
                          {product?.price}
                        </td>
                        <td className="py-2 px-4  text-base">
                          {product?.shipping_charge}
                        </td>
                        <td className="py-2 px-4  text-base">
                          <img src={product?.image} alt="" />
                        </td>
                        <td className="py-2 px-4  text-base">
                          <div className="flex space-x-2 cursor-pointer">
                            <div className="p-2 bg-green-200 rounded-full cursor-pointer">
                              <FaEye
                                className="fill-green-500"
                                onClick={() => {
                                  setShippingObj(
                                    product?.buyer_shipping_addresses_info[0]
                                  );
                                  setShowShipping(true);
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td className="py-2 px-4  text-base">
                          <div className="flex space-x-2 cursor-pointer">
                            <div className="p-2 bg-green-200 rounded-full cursor-pointer">
                              <FaEye
                                className="fill-green-500"
                                onClick={() => {
                                  setTrackId(product?.track_id);
                                  setShowTrack(true);
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : null}

            {order?.payments?.length ? (
              <div className="max-w-full overflow-x-auto max-h-[500px] overflow-scroll p-6">
                <h1 className="font-bold">Products</h1>
                <table className="w-full table-auto">
                  <thead>
                    <tr className="text-left text-dark font-bold">
                      <th className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Id:
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Order Id:
                      </th>
                      <th className="w-1/6 min-w-[260px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Amount:
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Payment Type:
                      </th>
                      <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Trx Id:
                      </th>
                      <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Trx Img:
                      </th>
                      <th className="w-1/6 min-w-[160px] border-r border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                        Payment Method:
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {order?.payments?.map((payment) => (
                      <tr className="border-t border-[#ddd] text-left text-dark py-4">
                        <td className="py-2 px-2  text-base">
                          <p>{payment?.id}</p>
                        </td>
                        <td className="py-2 px-2  text-base">
                          {payment?.order_id}
                        </td>
                        <td className="py-2 px-4  text-base">
                          {payment?.amount}
                        </td>
                        <td className="py-2 px-4  text-base">
                          {payment?.payment_type}
                        </td>
                        <td className="py-2 px-4  text-base">
                          {payment?.trx_id}
                        </td>
                        <td className="py-2 px-4  text-base">
                          <img src={payment?.trx_img} alt="" />
                        </td>
                        <td className="py-2 px-4  text-base">
                          {payment?.payment_method}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : null}
          </div>
        ))}
      </div>
      {showShipping ? (
        <Modal show={showShipping}>
          <div className="w-full max-w-[780px]  rounded-md bg-white text-center">
            <div className="flex justify-between items-center px-8 py-4 ">
              <p className="font-semibold">Shipping details</p>
              <FaTimes
                onClick={() => {
                  setShowShipping(false);
                }}
                className="fill-gray-500 cursor-pointer"
              />
            </div>
            <div className="border-b border-b-gray-200"></div>
            <span className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"></span>
            {/* <p
              className="text-body-color mb-4 text-base leading-relaxed bg-red-500 p-2 mx-3 rounded-md cursor-pointer text-white"
              onClick={() => setShowSellerProduct(true)}
            >
              Seller Product
            </p> */}
            <OrderShippingDetails shippingObj={shippingObj} />

            <div className="flex justify-center space-x-6 pb-10">
              <button
                onClick={() => {
                  setShowShipping(false);
                }}
                className="text-dark block rounded-md border border-[#E9EDF9] p-3 text-center text-base font-medium transition hover:border-red-600 hover:bg-red-600 hover:text-white"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      ) : null}

      {showTrack ? (
        <Modal show={showTrack}>
          <div className="w-full max-w-[780px]  rounded-md bg-white text-center">
            <div className="flex justify-between items-center px-8 py-4 ">
              <p className="font-semibold">Order Track</p>
              <FaTimes
                onClick={() => {
                  setShowTrack(false);
                }}
                className="fill-gray-500 cursor-pointer"
              />
            </div>
            <div className="border-b border-b-gray-200"></div>
            <span className="bg-primary mx-auto mb-6 inline-block h-1 w-[90px] rounded"></span>

            <OrderTrack trackId={trackId} />

            <div className="flex justify-center space-x-6 pb-10">
              <button
                onClick={() => {
                  setShowTrack(false);
                }}
                className="text-dark block rounded-md border border-[#E9EDF9] p-3 text-center text-base font-medium transition hover:border-red-600 hover:bg-red-600 hover:text-white"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
}
