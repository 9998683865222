import React from "react";
import SellerDeliveryTimesComponents from "./Components/SellerDeliveryTimesComponents/SellerDeliveryTimesComponents";

export default function SellerDeliveryTimes() {
  return (
    <div>
      <SellerDeliveryTimesComponents />
    </div>
  );
}
