import { apiSlice } from "../api/apiSlice";

export const refferEarningPolicyApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRefferEarningPolicy: builder.query({
      query: () => `/admin/refer-policy`,
      providesTags: ["refferPolicy"],
    }),
    addRefferEarningPolicy: builder.mutation({
      query: (data) => ({
        url: "/admin/refer-policy",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["refferPolicy"],
    }),

    editRefferEarningPolicy: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/refer-policy/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["refferPolicy"],
    }),

    deleteRefferEarningPolicy: builder.mutation({
      query: (id) => ({
        url: `/admin/refer-policy/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["refferPolicy"],
    }),
  }),
});

export const {
  useGetRefferEarningPolicyQuery,
  useAddRefferEarningPolicyMutation,
  useEditRefferEarningPolicyMutation,
  useDeleteRefferEarningPolicyMutation,
} = refferEarningPolicyApi;
