import React from 'react'
import InputFile from '../../../Shared/Components/InputFile'

export default function UpdatesComponent() {
    return (
        <div className='max-w-6xl mx-auto bg-white m-8 rounded-md shadow-md'>
            <p className='px-8 py-4 font-semibold'>Delivery Boy Information</p>
            <div className='border-b border-b-gray-200'></div>
            <div className='bg-sky-200 border border-sky-300 p-4 m-4 rounded-md'>
                <div className='flex items-center space-x-4 ml-10'>
                    <div className='bg-sky-900 w-2 h-2 rounded-full'></div>
                    <div>Make sure your server has matched with all requirements. <span className='text-orange-500'>Check Here</span></div>
                </div>
                <div className='flex items-center space-x-4 ml-10'>
                    <div className='bg-sky-900 w-2 h-2 rounded-full'></div>
                    <div>Download latest version from codecanyon.</div>
                </div>
                <div className='flex items-center space-x-4 ml-10'>
                    <div className='bg-sky-900 w-2 h-2 rounded-full'></div>
                    <div>Extract downloaded zip. You will find updates.zip file in those extraced files.</div>
                </div>
                <div className='flex items-center space-x-4 ml-10'>
                    <div className='bg-sky-900 w-2 h-2 rounded-full'></div>
                    <div>Upload that zip file here and click update now.</div>
                </div>
            </div>
            <div className='flex space-x-4 p-4'>
                <div className='w-3/4'>
                    <InputFile />
                </div>
                <button className='w-1/4 bg-orange-500 text-white py-2 px-8 rounded-md mr-4'>Save</button>
            </div>
        </div>
    )
}
