import React from 'react'
import basic from "../../../assets/Images/basic.png"
import standard from "../../../assets/Images/standard.png"
import premium from "../../../assets/Images/premium.png"

export default function Packages() {
    return (
        <div className='p-8'>
            <div className='flex justify-between items-center'>
                <p>All Classifies Packages</p>
                <button className='bg-sky-500 text-white py-2 px-8 rounded-full mr-4'>Add New Package</button>
            </div>
            <div className='flex flex-col md:flex-row space-x-0 md:space-x-8 space-y-8 md:space-y-0 mt-8'>
                <div className='w-full md:w-1/3 lg:w-1/4 bg-white shadow-md text-center p-8 rounded-md'>
                    <img src={basic} alt="basic" className='mx-auto' />
                    <p className='font-bold pt-2'>Basic</p>
                    <p className='font-bold pt-2'>$0.000</p>
                    <p className='pt-2'>Product Upload: 2</p>
                    <div className='flex justify-center items-center pt-2'>
                        <button className='bg-sky-500 text-white py-2 px-8 rounded-md mr-4'>Edit</button>
                        <button className='bg-red-500 text-white py-2 px-8 rounded-md mr-4'>Delete</button>
                    </div>
                </div>
                <div className='w-full md:w-1/3 lg:w-1/4 bg-white shadow-md text-center p-8 rounded-md'>
                    <img src={standard} alt="basic" className='mx-auto' />
                    <p className='font-bold pt-2'>Standard</p>
                    <p className='font-bold pt-2'>$20.000</p>
                    <p className='pt-2'>Product Upload: 200</p>
                    <div className='flex justify-center items-center pt-2'>
                        <button className='bg-sky-500 text-white py-2 px-8 rounded-md mr-4'>Edit</button>
                        <button className='bg-red-500 text-white py-2 px-8 rounded-md mr-4'>Delete</button>
                    </div>
                </div>
                <div className='w-full md:w-1/3 lg:w-1/4 bg-white shadow-md text-center p-8 rounded-md'>
                    <img src={premium} alt="basic" className='mx-auto' />
                    <p className='font-bold pt-2'>Premium</p>
                    <p className='font-bold pt-2'>$30.000</p>
                    <p className='pt-2'>Product Upload: 300</p>
                    <div className='flex justify-center items-center pt-2'>
                        <button className='bg-sky-500 text-white py-2 px-8 rounded-md mr-4'>Edit</button>
                        <button className='bg-red-500 text-white py-2 px-8 rounded-md mr-4'>Delete</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
