import { apiSlice } from "../api/apiSlice";

export const buyersEarningPolicyApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBuyerEarningPolicy: builder.query({
      query: () => `/admin/buyer/refer-policy`,
      providesTags: ["buyersEarningPolicy"],
    }),
    addBuyerEarningPolicy: builder.mutation({
      query: (data) => ({
        url: "/admin/buyer/refer-policy",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["buyersEarningPolicy"],
    }),
    editBuyerEarningPolicy: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/buyer/refer-policy/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["buyersEarningPolicy"],
    }),
    deleteBuyerEarningPolicy: builder.mutation({
      query: (id) => ({
        url: `/admin/buyer/refer-policy/${id}`,
        method: "DELETE",
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["buyersEarningPolicy"],
    }),
  }),
});

export const {
  useGetBuyerEarningPolicyQuery,
  useAddBuyerEarningPolicyMutation,
  useEditBuyerEarningPolicyMutation,
  useDeleteBuyerEarningPolicyMutation,
} = buyersEarningPolicyApi;
