import React from "react";
import ShippingPackageComponent from "./Components/ShippingPackageComponent/ShippingPackageComponent";

export default function ShippingPackage() {
  return (
    <div>
      <ShippingPackageComponent />
    </div>
  );
}
