import React from "react";
import RefferEarningPolicyComponent from "./Components/RefferEarningPolicyComponent/RefferEarningPolicyComponent";

export default function RefferEarningPolicy() {
  return (
    <div>
      <RefferEarningPolicyComponent />
    </div>
  );
}
