import React from "react";

export default function WirehouseCurierCustomerInfo({ customerInfo }) {
  return (
    <div className="max-w-5xl mx-auto p-10 rounded-md bg-white my-10">
      <div className="py-10 px-8 flex flex-col gap-2">
        <h1 className="text-center font-bold text-xl ">Customer Info</h1>

        <div className="grid gap-2 grid-cols-4 text-left">
          <p className="col-span-1 font-bold">Full Name:</p>
          <p className="col-span-3">{customerInfo?.full_name}</p>
        </div>
        <div className="grid gap-2 grid-cols-4 text-left">
          <p className="col-span-1 font-bold">Mobile:</p>
          <p className="col-span-3">{customerInfo?.mobile}</p>
        </div>
        <div className="grid gap-2 grid-cols-4 text-left">
          <p className="col-span-1 font-bold">Apartment:</p>
          <p className="col-span-3">{customerInfo?.apartment}</p>
        </div>

        <div className="grid gap-2 grid-cols-4 text-left">
          <p className="col-span-1 font-bold">Street:</p>
          <p className="col-span-3">{customerInfo?.street}</p>
        </div>

        <div className="grid gap-2 grid-cols-4 text-left">
          <p className="col-span-1 font-bold">City:</p>
          <p className="col-span-3">{customerInfo?.city}</p>
        </div>
        <div className="grid gap-2 grid-cols-4 text-left">
          <p className="col-span-1 font-bold">Zip:</p>
          <p className="col-span-3">{customerInfo?.zip}</p>
        </div>
        <div className="grid gap-2 grid-cols-4 text-left">
          <p className="col-span-1 font-bold">State:</p>
          <p className="col-span-3">{customerInfo?.state}</p>
        </div>
        <div className="grid gap-2 grid-cols-4 text-left">
          <p className="col-span-1 font-bold">Country:</p>
          <p className="col-span-3">{customerInfo?.country}</p>
        </div>
      </div>
    </div>
  );
}
