import { apiSlice } from "../api/apiSlice";

export const sallerApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSellers: builder.query({
      query: () => `/admin/sellers`,
      providesTags: ["seller"],
    }),

    addSeller: builder.mutation({
      query: (data) => ({
        url: "/admin/add-seller",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["seller"],
    }),

    editSeller: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/add-seller/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["seller"],
    }),

    deleteSellers: builder.mutation({
      query: (id) => ({
        url: `/admin/add-seller/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["seller"],
    }),
  }),
});

export const {
  useGetSellersQuery,
  useDeleteSellersMutation,
  useAddSellerMutation,
  useEditSellerMutation,
} = sallerApi;
