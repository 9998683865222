import { apiSlice } from "../api/apiSlice";

export const buyerCreateApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBuyerCreate: builder.query({
      query: () => `/admin/Buyer`,
      providesTags: ["buyerCreate"],
    }),
    addBuyerCreate: builder.mutation({
      query: (data) => ({
        url: "/admin/Buyer",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["buyerCreate"],
    }),

    editBuyerCreate: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/Buyer/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["buyerCreate"],
    }),

    deleteBuyerCreate: builder.mutation({
      query: (id) => ({
        url: `/admin/Buyer/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["buyerCreate"],
    }),
  }),
});

export const {
  useGetBuyerCreateQuery,
  useAddBuyerCreateMutation,
  useEditBuyerCreateMutation,
  useDeleteBuyerCreateMutation,
} = buyerCreateApi;
