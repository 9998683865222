import React from "react";

export default function SellerLiveInfo({ liveInfo }) {
  return (
    <div className="p-10">
      <div className="flex justify-between gap-4 bg-slate-100 p-2 mb-2">
        <p className="font-bold">Id:</p>
        <p className="">{liveInfo?.id}</p>
      </div>
      <div className="flex justify-between gap-4 bg-slate-100 p-2 mb-2">
        <p className="font-bold">Seller Id:</p>
        <p className="">{liveInfo?.seller_id}</p>
      </div>
      <div className="flex justify-between gap-4 bg-slate-100 p-2 mb-2">
        <p className="font-bold">Fb RTMP:</p>
        <p className="">{liveInfo?.fb_rtmp}</p>
      </div>
      <div className="flex justify-between gap-4 bg-slate-100 p-2 mb-2">
        <p className="font-bold">products:</p>
        <p className="">
          {liveInfo?.products?.map((product) => `${product} ,`)}
        </p>
      </div>
      <div className="flex justify-between gap-4 bg-slate-100 p-2 mb-2">
        <p className="font-bold">Created At:</p>
        <p className="">{liveInfo?.created_at}</p>
      </div>
      <div className="flex justify-between gap-4 bg-slate-100 p-2 mb-2">
        <p className="font-bold">Updated At:</p>
        <p className="">{liveInfo?.updated_at}</p>
      </div>
    </div>
  );
}
