import React, { useState } from "react";
import ProductsTable from "./ProductsTable";
import AddProduct from "./AddProduct";

export default function ProductsComponent() {
  const [productObj, setProductObj] = useState({});
  return (
    <div className="flex p-4 flex-col">
      <AddProduct
        percentDiscountObj={productObj}
        setPercentDiscountObj={setProductObj}
      />
      <ProductsTable setPercentDiscountObj={setProductObj} />
    </div>
  );
}
