import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wirehouseToken: undefined,
  ownerData: undefined,
};

const wirehouseAuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    wirehouseLoggedIn: (state, action) => {
      state.wirehouseToken = action.payload.wirehouseToken;
      state.ownerData = action.payload.ownerData;
    },
    wirehouseLoggedOut: (state, action) => {
      state.wirehouseToken = undefined;
      state.ownerData = undefined;

      localStorage.removeItem("wirehouse-auth");
    },
  },
});

export const { wirehouseLoggedIn, wirehouseLoggedOut } =
  wirehouseAuthSlice.actions;
export default wirehouseAuthSlice.reducer;
