import React, { useState } from "react";
import AllWirehouseTable from "./AllWirehouseTable";
import AddWireHouse from "./AddWireHouse";

export default function AllWirehouseComponent() {
  let [categoryObj, setCategoryObj] = useState({});
  return (
    <>
      <p className="p-4 font-bold md:pl-8">All Warehouse</p>
      <div className="flex flex-col p-4 mb-8  md:flex-row">
        <AllWirehouseTable setCategoryObj={setCategoryObj} />
        <AddWireHouse
          categoryObj={categoryObj}
          setCategoryObj={setCategoryObj}
        />
      </div>
    </>
  );
}
