import React from 'react'
import InputFile from '../../../Shared/Components/InputFile'

export default function ProductBulk() {
    return (
        <div className='w-full '>
            <div className='bg-white rounded-md shadow-xl '>
                <p className='px-8 py-4 font-semibold'>Product Bulk Upload</p>
                <div className='border-b border-b-gray-200'></div>
                <div className="p-8 pb-0 bg-blue-200 m-6 rounded-md border border-blue-300">
                    <p className='font-bold'>Step 1</p>
                    <p className='py-2 font-thin'>1. Download the skeleton file and fill it with proper data.</p>
                    <p className='py-2 font-thin'>2. You can download the example file to understand how the data must be filled.</p>
                    <p className='py-2 font-thin'>3. Once you have downloaded and filled the skeleton file, upload it in the form below and submit.</p>
                    <p className='py-2 font-thin'>4. After uploading products you need to edit them and set product's images and choices.</p>
                </div>
                <div className='pb-8 pl-8 text-left'>
                    <button className='bg-sky-500 text-white py-2 px-8 rounded-md mr-4 duration-200 hover:bg-sky-600'>Download CSV</button>
                </div>
                <div className="p-8 pb-0 bg-blue-200 m-6 rounded-md border border-blue-300">
                    <p className='font-bold'>Step 2</p>
                    <p className='py-2 font-thin'>1. Category and Brand should be in numerical id.</p>
                    <p className='py-2 font-thin'>2. You can download the pdf to get Category and Brand id.</p>
                </div>
                <div className='pb-8 pl-8 text-left'>
                    <button className='bg-sky-500 text-white py-2 px-8 rounded-md mr-4 duration-200 hover:bg-sky-600'>Download CSV</button>
                    <button className='bg-sky-500 text-white py-2 px-8 rounded-md mr-4 duration-200 hover:bg-sky-600'>Download Brand</button>
                </div>
            </div>
            <div className='bg-white rounded-md shadow-xl mt-6'>
                <p className='px-8 py-4 font-semibold'>Upload Product File</p>
                <div className='border-b border-b-gray-200'></div>
                <div className="p-8 pb-0">
                    <InputFile />
                </div>
                <div className='py-8 pl-8 text-left'>
                    <button className='bg-sky-500 text-white py-2 px-8 rounded-md mr-4 duration-200 hover:bg-sky-600'>Upload CSV</button>
                </div>
            </div>
        </div>
    )
}
