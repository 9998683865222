import React from "react";
import ProductsComponent from "./Components/ProductsComponent/ProductsComponent";

export default function AllProducts() {
  return (
    <div>
      <ProductsComponent />
    </div>
  );
}
