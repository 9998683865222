import React from "react";
import Table from "../Shared/Table";

export default function Sales() {
  return (
    <>
      <Table />
    </>
  );
}
