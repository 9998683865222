import React from 'react'
import Layout from '../Layout/Layout'
import DeliveryConfigurationComponent from './Components/DeliveryConfigurationComponent/DeliveryConfigurationComponent'

export default function DeliveryConfiguration({ showSidebar, setShowSidebar }) {
    return (
        <Layout showSidebar={showSidebar} setShowSidebar={setShowSidebar}>
            <DeliveryConfigurationComponent />
        </Layout>
    )
}
