import { apiSlice } from "../api/apiSlice";

export const productImageApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addProductImage: builder.mutation({
      query: (data) => ({
        url: "/admin/product/image",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["products"],
    }),

    deleteProductImage: builder.mutation({
      query: (id) => ({
        url: `/admin/product/image/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["products"],
    }),
  }),
});

export const { useAddProductImageMutation, useDeleteProductImageMutation } =
  productImageApi;
