export const productRoute = [
  {
    id: 1,
    name: "Sellers",
    url: "/sellers",
  },
  {
    id: 2,
    name: "Buyers",
    url: "/buyers",
  },
  {
    id: 3,
    name: "Business",
    url: "/business",
  },
  {
    id: 4,
    name: "Units",
    url: "/units",
  },
  {
    id: 5,
    name: "Varient",
    url: "/varient",
  },
  {
    id: 6,
    name: "All Products",
    url: "/all-products",
  },
  {
    id: 7,
    name: "Flat Discount",
    url: "/flat-discount",
  },
  {
    id: 8,
    name: "Percent Discount",
    url: "/percent-discount",
  },
  {
    id: 9,
    name: "Seller Payment Method",
    url: "/seller/payment-method",
  },
  {
    id: 10,
    name: "Seller Delivery Times",
    url: "/seller-delivery-times",
  },
  {
    id: 11,
    name: "Seller Extra Charge Types",
    url: "/seller-extra-charge-types",
  },
  // {
  //   id: 12,
  //   name: "seller extra charge",
  //   url: "/seller-extra-charge",
  // },
  {
    id: 13,
    name: "Featured Shop",
    url: "/featured-shop",
  },
];

export const rmsRoute = [
  {
    id: 1,
    name: "Rms Menus",
    url: "/rms/menus",
  },
  {
    id: 2,
    name: "Rms Group Name",
    url: "/rms/group-name",
  },
  {
    id: 3,
    name: "Rms Group Menu",
    url: "/rms/group-menu",
  },
  {
    id: 4,
    name: "Rms Permissions",
    url: "/rms/permissions",
  },
];

export const buyersRouteRoute = [
  {
    id: 1,
    name: "Warehouse",
    url: "/all-warehouse",
  },
  {
    id: 2,
    name: "Country",
    url: "/all-country",
  },
  {
    id: 3,
    name: "Buyers Payment Method",
    url: "/buyers-payment-method",
  },
  {
    id: 4,
    name: "Buyers Banner",
    url: "/buyers-banner",
  },
  {
    id: 5,
    name: "Voucher",
    url: "/voucher",
  },
  {
    id: 6,
    name: "Buyer Earning Policy",
    url: "/buyer-earning-policy",
  },
  // {
  //   id: 7,
  //   name: "Orders",
  //   url: "/orders",
  // },
  {
    id: 8,
    name: "Payment Pending Order",
    url: "/payment-pending-order",
  },
  {
    id: 9,
    name: "Delivery Pending Order",
    url: "/delivery-pending-order",
  },
];

export const categoryRoute = [
  {
    id: 1,
    name: "Category",
    url: "/category",
  },
  {
    id: 2,
    name: "Sub Category",
    url: "/sub-category",
  },
];

export const deliveryBoyRoute = [
  {
    id: 1,
    name: "Delivery Boys Create",
    url: "/delivery-boys/create",
  },
  {
    id: 2,
    name: "Delivery Boys Configuration",
    url: "/delivery-boys/configuration",
  },
];

export const reportsRoute = [
  {
    id: 1,
    name: "Reffer Earning Policy",
    url: "/reffer-earning-policy",
  },
  {
    id: 2,
    name: "Shipping Package",
    url: "/shipping-package",
  },
  // {
  //   id: 3,
  //   name: "in-house-sale-report",
  //   url: "/in-house-sale-report",
  // },
];

export const systemsRoute = [
  {
    id: 1,
    name: "system/updates",
    url: "/system/updates",
  },
  {
    id: 2,
    name: "system/server-status",
    url: "/system/server-status",
  },
];
