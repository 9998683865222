import React from 'react'
import Input from '../../../Shared/Components/Input'
import watch from "../../../assets/Images/watch.png"
import { FaPlus } from "react-icons/fa";

export default function PosProduct() {
    return (
        <div className='w-full md:w-[calc(100%-500px)] px-4'>
            <div className='sticky top-[84px] left-0 bg-gray-100 p-2 z-10 flex flex-col space-x-0 mb-6 md:space-x-2 md:flex-row'>
                <div className='w-full md:w-2/4'>
                    <Input type='text' placeholder="Search By Product Name/Barcode" />
                </div>
                <div className='w-full md:w-1/4'>
                    <select id="selectID" className='w-full border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary w-full rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500'>
                        <option className='bg-orange-400 text-white p-4'>Hot Categories</option>
                        <option className='bg-orange-400 text-white p-4'>Party Dress</option>
                        <option className='bg-orange-400 text-white p-4'>Women Shoe</option>
                    </select>
                </div>
                <div className='w-full md:w-1/4'>
                    <select id="selectID" className='w-full border-form-stroke font-light text-body-color placeholder-body-color  active:border-primary w-full rounded-md border-[1.5px] py-3 px-5   transition disabled:cursor-default disabled:bg-[#F5F7FD] focus:outline-orange-500'>
                        <option className='bg-orange-400 text-white p-4'>Hot Categories</option>
                        <option className='bg-orange-400 text-white p-4'>Party Dress</option>
                        <option className='bg-orange-400 text-white p-4'>Women Shoe</option>
                    </select>
                </div>
            </div>

            <div className='flex justify-center items-center  flex-wrap space-x-0 space-y-6 md:space-x-4'>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        {/* <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div> */}
                        {/* <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div> */}
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            {/* <p className='line-through'>$230.000</p> */}
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            {/* <p className='line-through'>$230.000</p> */}
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        {/* <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div> */}
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
                <div className='group relative bg-white rounded-sm w-[240px]'>
                    <div className='relative'>
                        <img src={watch} alt="watch" />
                        <div className='absolute top-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-emerald-500 text-white'>
                            In Stock: 499
                        </div>
                        <div className='absolute bottom-[5px] left-[5px] p-1 text-sm rounded-sm inline-block bg-yellow-500 text-dark'>
                            Black
                        </div>
                    </div>
                    <div className='p-4'>
                        <h6 className='font-bold'>Men's Machine Stainles...</h6>
                        <div className='flex space-x-2 text-sm mt-2'>
                            <p className='line-through'>$230.000</p>
                            <p>$190.000</p>
                        </div>
                    </div>
                    <div className='hidden group-hover:block group-hover:absolute top-0 left-0 right-0 bottom-0  cursor-pointer'>
                        <div className=' w-full h-full bg-gray-900 opacity-50'>
                        </div>
                        <FaPlus className='hidden group-hover:block group-hover:absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 fill-white w-[50px] h-[50px]' />
                    </div>
                </div>
            </div>
        </div>
    )
}
