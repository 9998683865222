import React, { useEffect, useState } from "react";
import {
  useAddShippingPackageMutation,
  useEditShippingPackageMutation,
} from "../../../features/shippingPackage/shippingPackageApi";
import { toast } from "react-toastify";
import Input from "../../../Shared/Components/Input";
import Toast from "../../../Shared/Components/Toast";

export default function AddShippingPackage({
  setPercentDiscountObj,
  percentDiscountObj,
}) {
  let [percentDiscount, setPercentDiscount] = useState({
    from_country: "",
    to_country: "",
    days: "",
    shipping_type: "",
    unit_types: [],
  });

  const [unitTypes, setUnitTypes] = useState({
    KG: "",
    GRM: "",
    LTR: "",
  });

  useEffect(() => {
    if (percentDiscountObj?.from_country) {
      setPercentDiscount({
        from_country: percentDiscountObj?.from_country,
        to_country: percentDiscountObj?.to_country,
        days: percentDiscountObj?.days,
        shipping_type: percentDiscountObj?.shipping_type,
        unit_types: percentDiscountObj?.unit_types,
      });
      setUnitTypes({
        KG: percentDiscountObj?.unit_types[0]?.KG,
        GRM: percentDiscountObj?.unit_types[0]?.GRM,
        LTR: percentDiscountObj?.unit_types[0]?.LTR,
      });
    }
  }, [percentDiscountObj]);

  const [addPercentDiscount, { isSuccess: ispercentDiscountSuccess }] =
    useAddShippingPackageMutation();
  const [
    editpercentDiscount,
    { isSuccess: isEditpercentDiscountSuccess, isLoading, isError, error },
  ] = useEditShippingPackageMutation();

  useEffect(() => {
    if (ispercentDiscountSuccess) {
      toast.success("Successfully Percent Discount added.");
      setPercentDiscount({
        from_country: "",
        to_country: "",
        days: "",
        shipping_type: "",
        unit_types: [],
      });
    }
  }, [ispercentDiscountSuccess]);

  useEffect(() => {
    if (isEditpercentDiscountSuccess) {
      toast.success("Successfully Percent Discount Updated.");
      setPercentDiscount({
        from_country: "",
        to_country: "",
        days: "",
        shipping_type: "",
        unit_types: [],
      });
      setPercentDiscountObj({});
    }
    if (isError) {
      toast.error(error.error);
    }
  }, [isEditpercentDiscountSuccess, isError]);

  const handleUnitAdd = () => {
    setPercentDiscount({
      ...percentDiscount,
      unit_types: [...percentDiscount?.unit_types, unitTypes],
    });
    setUnitTypes({
      KG: "",
      GRM: "",
      LTR: "",
    });
  };

  const handleCategoryAdd = (e) => {
    addPercentDiscount(percentDiscount);
  };

  const handleCategoryEdit = async (e) => {
    await editpercentDiscount({
      id: percentDiscountObj?.id,
      data: {
        ...percentDiscount,
      },
    });
  };

  console.log("percentDiscount", percentDiscount);

  return (
    <>
      <div className="w-1/3">
        <div className="bg-white rounded-md shadow-xl ">
          <p className="px-8 py-4 font-semibold">
            {percentDiscountObj?.from_country ? "Update" : "Add"} Shipping
            Package
          </p>
          <div className="border-b border-b-gray-200"></div>

          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  From Country<span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder="Name"
                    value={percentDiscount.from_country}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,

                        from_country: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  To Country <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder=""
                    value={percentDiscount.to_country}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        to_country: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Days <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder=""
                    value={percentDiscount.days}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        days: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex p-8 pb-0">
            <div className="w-full px-4">
              <div className="flex flex-col mb-4 ">
                <label
                  for=""
                  className="w-full mb-3 block text-base text-black font-light"
                >
                  Shipping Type <span className="text-orange-500">*</span>
                </label>
                <div className="w-full">
                  <Input
                    type="text"
                    placeholder=""
                    value={percentDiscount.shipping_type}
                    onChange={(e) =>
                      setPercentDiscount({
                        ...percentDiscount,
                        shipping_type: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="bg-gray-100 mb-6 py-6">
            <div className="flex  gap-2 flex-col px-10">
              {percentDiscount?.unit_types?.map((varientObj) => (
                <div className="bg-gray-200 p-2">
                  <p>
                    <span className="font-bold">KG:</span> {varientObj?.KG}
                  </p>
                  <p>
                    <span className="font-bold">GRM: </span>
                    {varientObj?.GRM}
                  </p>
                  <p>
                    <span className="font-bold">LTR: </span>
                    {varientObj?.LTR}
                  </p>
                </div>
              ))}
            </div>
            <h1 className="px-10 ">Unit types</h1>
            <div className="flex p-8 pb-0">
              <div className="w-full px-4">
                <div className="flex flex-col ">
                  <label
                    for=""
                    className="w-full mb-3 block text-base text-black font-light"
                  >
                    KG <span className="text-orange-500">*</span>
                  </label>
                  <div className="w-full">
                    <Input
                      type="number"
                      placeholder=""
                      value={unitTypes?.KG}
                      onChange={(e) =>
                        setUnitTypes({
                          ...unitTypes,
                          KG: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex px-8 py-4 pb-0">
              <div className="w-full px-4">
                <div className="flex flex-col ">
                  <label
                    for=""
                    className="w-full mb-3 block text-base text-black font-light"
                  >
                    GRM <span className="text-orange-500">*</span>
                  </label>
                  <div className="w-full">
                    <Input
                      type="number"
                      placeholder=""
                      value={unitTypes?.GRM}
                      onChange={(e) =>
                        setUnitTypes({
                          ...unitTypes,
                          GRM: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex px-8 pt-4 pb-0">
              <div className="w-full px-4">
                <div className="flex flex-col mb-4 ">
                  <label
                    for=""
                    className="w-full mb-3 block text-base text-black font-light"
                  >
                    LTR <span className="text-orange-500">*</span>
                  </label>
                  <div className="w-full">
                    <Input
                      type="number"
                      placeholder=""
                      value={unitTypes?.LTR}
                      onChange={(e) =>
                        setUnitTypes({
                          ...unitTypes,
                          LTR: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="px-12">
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleUnitAdd}
              >
                Add
              </button>
            </div>
          </div>

          <div className="pb-8 pr-8 text-right">
            {percentDiscountObj?.from_country ? (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryEdit}
              >
                Update
              </button>
            ) : (
              <button
                className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
                onClick={handleCategoryAdd}
              >
                Save
              </button>
            )}
          </div>
        </div>
        <Toast />
      </div>
    </>
  );
}
