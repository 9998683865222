import React, { useState } from "react";
import CountryTable from "./CountryTable";
import AddCountry from "./AddCountry";

export default function CountryComponent() {
  let [categoryObj, setCategoryObj] = useState({});
  return (
    <>
      <p className="p-4 font-bold md:pl-8">All Country</p>
      <div className="flex flex-col p-4 mb-8  md:flex-row">
        <CountryTable setCategoryObj={setCategoryObj} />
        <AddCountry categoryObj={categoryObj} setCategoryObj={setCategoryObj} />
      </div>
    </>
  );
}
