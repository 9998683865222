import { apiSlice } from "../api/apiSlice";

export const varientApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVarient: builder.query({
      query: () => `/admin/get-variant`,
      providesTags: ["varient"],
    }),

    addVarient: builder.mutation({
      query: (data) => ({
        url: "/admin/add-variant",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["varient"],
    }),

    editVarient: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/variant/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["varient"],
    }),

    deleteVarient: builder.mutation({
      query: (id) => ({
        url: `/admin/variant/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["varient"],
    }),
  }),
});

export const {
  useGetVarientQuery,
  useAddVarientMutation,
  useEditVarientMutation,
  useDeleteVarientMutation,
} = varientApi;
