import React, { useState } from "react";
import PercentDiscountTable from "./PercentDiscountTable";
import AddPercentDiscount from "./AddPercentDiscount";

export default function PercentDiscountComponent() {
  let [percentDiscountObj, setPercentDiscountObj] = useState({});
  return (
    <>
      <p className="p-4 font-bold md:pl-8">All Percent discount</p>
      <div className="flex flex-col p-4 mb-8  md:flex-row">
        <PercentDiscountTable setPercentDiscountObj={setPercentDiscountObj} />
        <AddPercentDiscount
          setPercentDiscountObj={setPercentDiscountObj}
          percentDiscountObj={percentDiscountObj}
        />
      </div>
    </>
  );
}
