import { apiSlice } from "../api/apiSlice";

export const categoryApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCategory: builder.query({
            query: () => `/admin/catagory`,
            providesTags: ["category"],
        }),
        addCategory: builder.mutation({
            query: (data) => ({
                url: "/admin/catagory",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["category"],
        }),
        editCategoryOperation: builder.mutation({
            query: ({ id, data }) => ({
                url: `/admin/catagory/${id}`,
                method: 'PUT',
                body: data
            }),
            // Invalidates all queries that subscribe to this Post `id` only.
            invalidatesTags: ["category"],
        }),
        deleteCategory: builder.mutation({
            query: (id) => ({
                url: `/admin/catagory/${id}`,
                method: 'DELETE',
            }),
            // Invalidates all queries that subscribe to this Post `id` only.
            invalidatesTags: ["category"],
        })
    })
})

export const { useGetCategoryQuery, useAddCategoryMutation, useEditCategoryOperationMutation, useDeleteCategoryMutation } = categoryApi;