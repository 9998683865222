import React, { useEffect, useState } from "react";
import Input from "../../../Shared/Components/Input";
import {
  useAddUnitMutation,
  useEditUnitOperationMutation,
} from "../../../features/units/unitsApi";
import { toast } from "react-toastify";
import Toast from "../../../Shared/UI/Toast";

export default function AddUnit({ editUnit, setEditUnit }) {
  const [name, setName] = useState("");
  const [addUnit, { isSuccess: isAddUnitSuccess }] = useAddUnitMutation();
  const [editUnitOperation, { isSuccess: isEditUnitSuccess, isError, error }] =
    useEditUnitOperationMutation();

  useEffect(() => {
    if (isAddUnitSuccess) {
      toast.success("successfully unit added.");
      setEditUnit({});
      setName("");
    }
  }, [isAddUnitSuccess]);

  useEffect(() => {
    if (isEditUnitSuccess) {
      toast.success("successfully unit Edit.");
      setEditUnit({});
      setName("");
    }
  }, [isEditUnitSuccess]);

  useEffect(() => {
    if (editUnit?.unit_name) {
      setName(editUnit?.unit_name);
    }
  }, [editUnit]);

  // console.log("isEditUnitSuccess", isEditUnitSuccess, isError, error);

  const handleUnitAdd = () => {
    if (!name) {
      toast.error("Please add unit name");
      return;
    }

    console.log("unit_name", name);
    addUnit({
      unit_name: name,
    });
  };

  const handleUnitEdit = () => {
    console.log("editobj", {
      id: editUnit?.id,
      data: {
        unit_name: name,
      },
    });
    editUnitOperation({
      id: editUnit?.id,
      data: {
        unit_name: name,
      },
    });
  };
  return (
    <div className="w-1/3">
      <div className="bg-white rounded-md shadow-xl ">
        <p className="px-8 py-4 font-semibold">
          {editUnit?.unit_name ? "Update" : "Add"} unit
        </p>
        <div className="border-b border-b-gray-200"></div>
        <div className="flex p-8 pb-0">
          <div className="w-full px-4">
            <div className="flex flex-col mb-4 ">
              <label
                for=""
                className="w-full mb-3 block text-base text-black font-light"
              >
                Unit Name <span className="text-orange-500">*</span>
              </label>
              <div className="w-full">
                <Input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pb-8 pr-8 text-right">
          {editUnit?.unit_name ? (
            <button
              className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
              onClick={handleUnitEdit}
            >
              Update
            </button>
          ) : (
            <button
              className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
              onClick={handleUnitAdd}
            >
              Save
            </button>
          )}
        </div>
      </div>
      <Toast />
    </div>
  );
}
