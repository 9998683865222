import { apiSlice } from "../api/apiSlice";

export const rmsGroupMenuApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRmsGroupMenu: builder.query({
      query: () => `/admin/rms/groups-menu`,
      providesTags: ["rmsGroupMenus"],
    }),

    addGroupMenu: builder.mutation({
      query: (data) => ({
        url: "/admin/rms/groups-menu",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["rmsGroupMenus"],
    }),

    editGroupMenu: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/rms/groups-menu/${id}`,
        method: "PUT",
        body: data,
      }),
      // Invalidates all queries that subscribe to this Post `id` only.
      invalidatesTags: ["rmsGroupMenus"],
    }),

    deleteGroupMenu: builder.mutation({
      query: (id) => ({
        url: `/admin/rms/groups-menu/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["rmsGroupMenus"],
    }),
  }),
});

export const {
  useGetRmsGroupMenuQuery,
  useAddGroupMenuMutation,
  useEditGroupMenuMutation,
  useDeleteGroupMenuMutation,
} = rmsGroupMenuApi;
