import React, { useEffect, useState } from "react";
import {
  useAddBuyerCreateMutation,
  useEditBuyerCreateMutation,
} from "../../../features/buyerCreate/buyerCreateApi";
import { toast } from "react-toastify";
import Input from "../../../Shared/Components/Input";
import InputFile from "../../../Shared/Components/InputFile";
import Toast from "../../../Shared/Components/Toast";

export default function AddBuyer({ paymentMethodObj, setPaymentMethodObj }) {
  let initialObj = {
    name: "",
    number: "",
    country: "",
    email: "",
    password: "",
    address: "",
    city: "",
    postcode: "",
  };
  const [sellerObj, setSellerObj] = useState({ ...initialObj });

  useEffect(() => {
    setSellerObj({
      name: paymentMethodObj?.name,
      number: paymentMethodObj?.number,
      country: paymentMethodObj?.country,
      email: paymentMethodObj?.email,
      password: paymentMethodObj?.password,
      address: paymentMethodObj?.address,
      city: paymentMethodObj?.city,
      postcode: paymentMethodObj?.postcode,
    });
  }, [paymentMethodObj]);

  const [addBuyerCreate, { isSuccess: isAddSellerSuccess }] =
    useAddBuyerCreateMutation();

  const [
    editBuyerCreate,
    { isSuccess: isEditBuyerSuccess, isLoading, isError, error },
  ] = useEditBuyerCreateMutation();

  useEffect(() => {
    if (isEditBuyerSuccess) {
      toast.success("Successfully Buyer Updated.");
      setSellerObj({ ...initialObj });
      setPaymentMethodObj({});
    }
    if (isError) {
      toast.error(error.error);
    }
  }, [isEditBuyerSuccess, isError]);

  function handleImageUpload(e) {
    const file = e.target.files[0];
    console.log("file", file);
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      setSellerObj({ ...sellerObj, logo: reader.result });
    };
  }

  useEffect(() => {
    if (isAddSellerSuccess) {
      toast.success("Buyer addedd successfully");
      setSellerObj({ ...initialObj });
    }
  }, [isAddSellerSuccess]);

  const handleAddSeller = () => {
    if (
      !sellerObj?.name ||
      !sellerObj?.number ||
      !sellerObj?.country ||
      !sellerObj?.email ||
      !sellerObj?.password ||
      !sellerObj?.address ||
      !sellerObj.city ||
      !sellerObj.postcode
    ) {
      toast.error("Please fill all the required filled");
      return;
    }
    addBuyerCreate(sellerObj);
  };

  const handleEditBuyer = async () => {
    await editBuyerCreate({
      id: paymentMethodObj?.id,
      data: {
        ...sellerObj,
      },
    });
  };

  return (
    <div className="max-w-3xl w-full mx-auto bg-white rounded-md shadow-xl mt-8 m-8">
      <p className="px-8 py-4 font-semibold">
        {paymentMethodObj?.name ? "Update" : "Add"} Buyer
      </p>
      <div className="border-b border-b-gray-200"></div>
      <div className="flex flex-col p-8 pb-0">
        <div className="w-full px-4">
          <div className="flex flex-col mb-4 md:flex-row">
            <label
              for=""
              className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
            >
              Name <span className="text-orange-500">*</span>
            </label>
            <div className="w-full md:w-3/4">
              <Input
                type="text"
                placeholder="Name"
                value={sellerObj?.name}
                onChange={(e) =>
                  setSellerObj({ ...sellerObj, name: e.target.value })
                }
              />
            </div>
          </div>
        </div>

        <div className="w-full px-4 ">
          <div className="flex flex-col mb-4 md:flex-row">
            <label
              for=""
              className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
            >
              Number <span className="text-orange-500">*</span>
            </label>
            <div className="w-full md:w-3/4">
              <Input
                type="text"
                placeholder="Number"
                value={sellerObj?.number}
                onChange={(e) =>
                  setSellerObj({ ...sellerObj, number: e.target.value })
                }
              />
            </div>
          </div>
        </div>

        <div className="w-full px-4 ">
          <div className="flex flex-col mb-4 md:flex-row">
            <label
              for=""
              className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
            >
              Country <span className="text-orange-500">*</span>
            </label>
            <div className="w-full md:w-3/4">
              <Input
                type="text"
                placeholder="Country"
                value={sellerObj?.country}
                onChange={(e) =>
                  setSellerObj({ ...sellerObj, country: e.target.value })
                }
              />
            </div>
          </div>
        </div>

        <div className="w-full px-4 ">
          <div className="flex flex-col mb-4 md:flex-row">
            <label
              for=""
              className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
            >
              Email <span className="text-orange-500">*</span>
            </label>
            <div className="w-full md:w-3/4">
              <Input
                type="email"
                placeholder="Email"
                value={sellerObj?.email}
                onChange={(e) =>
                  setSellerObj({ ...sellerObj, email: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <div className="w-full px-4 ">
          <div className="flex flex-col mb-4 md:flex-row">
            <label
              for=""
              className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
            >
              Password <span className="text-orange-500">*</span>
            </label>
            <div className="w-full md:w-3/4">
              <Input
                type="password"
                placeholder="Password"
                value={sellerObj?.password}
                onChange={(e) =>
                  setSellerObj({ ...sellerObj, password: e.target.value })
                }
              />
            </div>
          </div>
        </div>

        <div className="w-full px-4 ">
          <div className="flex flex-col mb-4 md:flex-row">
            <label
              for=""
              className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
            >
              Address <span className="text-orange-500">*</span>
            </label>
            <div className="w-full md:w-3/4">
              <Input
                type="text"
                placeholder="Address"
                value={sellerObj?.address}
                onChange={(e) =>
                  setSellerObj({ ...sellerObj, address: e.target.value })
                }
              />
            </div>
          </div>
        </div>

        <div className="w-full px-4 ">
          <div className="flex flex-col mb-4 md:flex-row">
            <label
              for=""
              className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
            >
              City <span className="text-orange-500">*</span>
            </label>
            <div className="w-full md:w-3/4">
              <Input
                type="text"
                placeholder="City"
                value={sellerObj?.city}
                onChange={(e) =>
                  setSellerObj({ ...sellerObj, city: e.target.value })
                }
              />
            </div>
          </div>
        </div>

        <div className="w-full px-4 ">
          <div className="flex flex-col mb-4 md:flex-row">
            <label
              for=""
              className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
            >
              Postcode <span className="text-orange-500">*</span>
            </label>
            <div className="w-full md:w-3/4">
              <Input
                type="text"
                placeholder="Postcode"
                value={sellerObj?.postcode}
                onChange={(e) =>
                  setSellerObj({ ...sellerObj, postcode: e.target.value })
                }
              />
            </div>
          </div>
        </div>

        {/* <div className="w-full px-4 ">
          <div className="flex flex-col mb-4 md:flex-row">
            <label
              for=""
              className="w-full md:w-1/4 mb-3 block text-base text-black font-light"
            >
              Logo
            </label>
            <div className="w-full md:w-3/4">
              <InputFile onChange={handleImageUpload} />
            </div>
          </div>
          {sellerObj?.logo && (
            <img
              src={sellerObj?.logo}
              alt="Uploaded image"
              className="w-full h-auto"
            />
          )}
        </div> */}
      </div>
      <div className="pb-8 pr-8 text-right">
        {paymentMethodObj?.name ? (
          <button
            className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
            onClick={handleEditBuyer}
          >
            Update
          </button>
        ) : (
          <button
            className="bg-orange-500 text-white py-2 px-8 rounded-md mr-4"
            onClick={handleAddSeller}
          >
            Save
          </button>
        )}
      </div>

      <Toast />
    </div>
  );
}
