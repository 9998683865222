import React from "react";
import PieChat from "./PieChat";
import cardBg from "../../../assets/svg/cardbg.svg";

export default function Analytics({ allDashbaordData }) {
  return (
    <div className="flex flex-col space-y-6 p-4 md:flex-row md:space-y-0  md:space-x-6">
      <div className="w-full md:w-1/2 grid grid-cols-2 gap-6 bg-blue-100">
        <div className="relative flex flex-col justify-between bg-gradient-to-r from-[#875fc0] to-[#5346ba]  col-span-1  h-60 text-white rounded-md">
          <div className=" p-4">
            <p>Total Buyer</p>
            <p className="text-5xl font-bold">
              {allDashbaordData?.analysis?.total_buyer}
            </p>
          </div>
          <div>
            <img
              src={cardBg}
              alt="cardbg"
              className="w-full absolute bottom-0 left-0 right-0"
            />
          </div>
        </div>

        <div className="relative flex flex-col justify-between bg-gradient-to-r from-[#47c5f4] to-[#6791d9]  col-span-1  h-60 text-white rounded-md">
          <div className="p-4">
            <p>Total Category</p>
            <p className="text-5xl font-bold">
              {allDashbaordData?.analysis?.total_category}
            </p>
          </div>
          <div>
            <img
              src={cardBg}
              alt="cardbg"
              className="w-full absolute bottom-0 left-0 right-0"
            />
          </div>
        </div>
        <div className="relative flex flex-col justify-between bg-gradient-to-r from-[#eb4786] to-[#b854a6] col-span-1 h-60 text-white rounded-md">
          <div className=" p-4">
            <p>Total Orders</p>
            <p className="text-5xl font-bold">
              {allDashbaordData?.analysis?.total_orders}
            </p>
          </div>
          <div>
            <img
              src={cardBg}
              alt="cardbg"
              className="w-full absolute bottom-0 left-0 right-0"
            />
          </div>
        </div>
        <div className="relative flex flex-col justify-between bg-gradient-to-r from-[#ffb72c] to-[#f57f59] col-span-1 h-60 text-white rounded-md">
          <div className="p-4">
            <p>Total Seller</p>
            <p className="text-5xl font-bold">
              {allDashbaordData?.analysis?.total_seller}
            </p>
          </div>
          <div>
            <img
              src={cardBg}
              alt="cardbg"
              className="w-full absolute bottom-0 left-0 right-0"
            />
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 flex flex-col space-y-6 md:flex-row md:space-y-0 md:space-x-6">
        <div className="bg-white w-full md:w-1/2 shadow-lg">
          <p className="text-xl p-4 text-gray-500">Products</p>
          <div className="border-b border-b-gray-300"></div>
          <PieChat data={allDashbaordData?.products} />
        </div>
        <div className="bg-white w-full md:w-1/2 shadow-lg">
          <p className="text-xl p-4 text-gray-500">Sellers</p>
          <div className="border-b border-b-gray-300"></div>
          <PieChat data={allDashbaordData?.sellers} />
        </div>
      </div>
    </div>
  );
}
