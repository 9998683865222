import React from "react";
import { useGetPercentDiscountQuery } from "../../../features/percentDiscount/percentDiscountApi";
import { FaRegEdit, FaTrashAlt } from "react-icons/fa";
import EditOption from "../../../Shared/Components/EditOption";

export default function PercentDiscountTable({ setPercentDiscountObj }) {
  const {
    data: allPercentDiscount,
    isLoading,
    isError,
    error,
  } = useGetPercentDiscountQuery();

  let content = null;

  if (isLoading) {
    content = <div>Loading</div>;
  }
  if (!isLoading && isError) {
    content = <div>{error?.error}</div>;
  }

  if (!isLoading && !isError) {
    content = (
      <tbody>
        {allPercentDiscount?.data?.map((discount) => (
          <tr className="border-t border-[#ddd] text-left text-dark py-4">
            <td className="py-2 px-2  text-base">
              <div className="flex items-center">{discount?.id}</div>
            </td>
            <td className="py-2 px-2  text-base">{discount?.coupon_code}</td>
            <td className="py-2 px-2  text-base">{discount?.discount_type}</td>
            <td className="py-2 px-2  text-base">{discount?.discount_value}</td>
            <td className="py-2 px-2  text-base">
              {discount?.max_disc_amount}
            </td>
            <td className="py-2 px-2  text-base">
              {discount?.min_order_amount}
            </td>
            <td className="py-2 px-2  text-base">{discount?.min_qty}</td>
            <td className="py-2 px-2  text-base">{discount?.seller_id}</td>
            {/* <td
                                  className="py-2 px-2  text-base"
                              >
                                  <div className='flex space-x-2'>
                                      <img src={discount?.image} alt={discount?.name} className='w-20 h-auto' />
                                  </div>
                              </td> */}
            <td className="y-2 px-2  text-base">
              <div className="flex space-x-2 cursor-pointer">
                <EditOption onClick={() => setPercentDiscountObj(discount)} />
                {/* <div className="p-2 bg-red-200 rounded-full cursor-pointer">
                  <FaTrashAlt className='fill-red-500' onClick={() => setPercentDiscountObj(discount?.id)} />

                </div> */}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
  return (
    <div className="w-full md:w-2/3 overflow-x-hidden">
      <section className="bg-white shadow-xl rounded-md mb-4 m-0 md:m-4 py-6 lg:my-0 lg:py-8 overflow-x-hidden">
        <div className="flex flex-col justify-between items-center pb-4 px-4 md:flex-row">
          <p className="w-3/4 px-8 py-4 font-semibold">All percent discount</p>
        </div>
        <div className="border-b border-b-gray-200"></div>
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4 mx-4">
            <div className="max-w-full overflow-x-auto">
              <table className="w-full table-auto">
                <thead>
                  <tr className="text-left text-dark font-bold">
                    <th className="w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      #
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Coupon Code
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Discount Type
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Discount
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Max Discount
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Min Order Amount
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Min Qty
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Seller ID
                    </th>
                    <th className="w-1/6 min-w-[160px] py-4 px-3 text-lg font-semibold lg:py-7 lg:px-4">
                      Options
                    </th>
                  </tr>
                </thead>
                {content}
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
