import { useSelector } from "react-redux";

export default function useWirehouseAuth() {
  const auth = useSelector((state) => state.wirehouseAuth);

  if (auth?.wirehouseToken) {
    return true;
  } else {
    return false;
  }
}
