import React, { useState } from "react";
import RmsGroupMenuTable from "./RmsGroupMenuTable";
import AddRmsGroupMenu from "./AddRmsGroupMenu";

export default function RmsGroupMenuComponent() {
  let [rmsMenuObj, setrmsMenuObj] = useState({});
  return (
    <>
      <p className="p-4 font-bold md:pl-8">All Group Menu</p>
      <div className="flex flex-col p-4 mb-8  md:flex-row">
        <RmsGroupMenuTable setCategoryObj={setrmsMenuObj} />
        <AddRmsGroupMenu
          categoryObj={rmsMenuObj}
          setCategoryObj={setrmsMenuObj}
        />
      </div>
    </>
  );
}
