import React, { useState } from "react";
import AddressTable from "./AddressTable";
import AddAddress from "./AddAddress";

export default function AddressComponent() {
  let [categoryObj, setCategoryObj] = useState({});
  return (
    <>
      <p className="p-4 font-bold md:pl-8">All Address</p>
      <div className="flex flex-col p-4 mb-8  md:flex-row">
        <AddressTable setCategoryObj={setCategoryObj} />
        <AddAddress categoryObj={categoryObj} setCategoryObj={setCategoryObj} />
      </div>
    </>
  );
}
